<!--
    ! WARNING !
    This file is a total mess. It is a quick and dirty solution to a problem that should be solved in a better way.
 -->
<template>
  <SmLoading :loading="loadingSoftware" />

  <div class="content">
    <!-- User -->
    <div class="user">
      <div class="user__avatar">
        <SmAvatar :name="user?.name || user?.email || '??'" />
      </div>
      <div class="user__information">
        <div class="user__name">
          {{ user?.name }}
        </div>
        <div class="user__email">
          {{ user?.email }}
        </div>
      </div>
    </div>

    <!-- Message -->
    <el-collapse-transition>
      <div v-if="result" :class="alertClasses">
        <div class="icon">
          <v-icon
            v-if="result.status == 'success'"
            name="md-checkcircle-round"
            scale="1.5"
            class="fill-primary" />

          <v-icon
            v-else
            name="md-cancel-round"
            scale="1.5"
            class="fill-magenta" />
        </div>
        <div class="message">
          {{ result.message }}
        </div>
      </div>
    </el-collapse-transition>

    <SmInput v-model="search" label="Search" size="small" outline />

    <!-- License Groups -->
    <div class="license-group">
      <SmTable
        ref="softwareLicenseTable"
        key-field="id"
        :default-sorting="{
          by: 'name',
          asc: false,
        }"
        :columns="columns"
        :data="lincenceGroupsFiltered"
        :select-disabled="state !== 'selection'"
        selectable>
        <!-- Name -->
        <template #name="{ row }">
          <div
            :class="{
              'license-model-deleted': licensesToDelete.includes(row.id),
              'license-model-added': licensesToAdd.includes(row.id),
              'license-model': true,
            }">
            {{ row.name }}
          </div>
        </template>
      </SmTable>
    </div>

    <!-- Button -->
    <div class="button">
      <SmButton
        :disabled="!user"
        size="large"
        :loading="state === 'loading'"
        @click="handleButtonClick">
        <span v-if="state === 'selection'">{{ i18n.t('save') }}</span>
        <span v-else-if="state === 'loading'">{{
          i18n.t('licenseAddingProcess')
        }}</span>
        <span v-else-if="state === 'done'">{{ i18n.t('close') }}</span>
      </SmButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    LicenceGroup,
    LicenceOut,
    SoftwareOut,
    UserLicencesService,
  } from '@/client'
  import SmTable from '@/components/sm/SmTable.vue'
  import { Column } from './sm/SmTable.types'
  import { useSoftwareStore } from '@/stores/softwareStore'

  const i18n = useI18n()
  const softwareStore = useSoftwareStore()

  const search = ref<string>('')

  const emit = defineEmits<{
    (e: 'groupEdited'): void
    (e: 'close'): void
  }>()

  const softwareLicenseTable =
    ref<ComponentExposed<typeof SmTable<LicenceGroup>>>()

  const state = ref<'selection' | 'loading' | 'done'>('selection')
  const result = ref<{
    status: 'success' | 'error'
    message: string
  }>()

  const props = defineProps<{
    user: Account
    softwareId: string
    license: LicenceOut | null
  }>()

  const columns: Column<LicenceGroup>[] = [
    {
      label: 'Name',
      key: 'name',
      width: 100,
      sortable: true,
      sortFn: (a, b) => a.name.localeCompare(b.name),
    },
  ]

  interface LicenseGroupWithId extends LicenceGroup {
    id: string
  }

  const software = ref<SoftwareOut>()
  const loadingSoftware = ref<boolean>(false)
  async function loadSoftware(softwareId: string) {
    loadingSoftware.value = true
    software.value = await softwareStore.getSoftwareById(softwareId, {
      excludeLicenceGroups: false,
      useLocalCache: false,
    })
    loadingSoftware.value = false
  }

  watch(
    () => props.softwareId,
    () => {
      loadSoftware(props.softwareId)
    },
    { immediate: true }
  )

  const licenseGroups = computed(() => {
    if (!software.value) return []
    // Return entries of props.software?.licence_groups and add name as id to each entry
    return Object.entries(software.value?.licence_groups || {}).map(
      ([id, model]) => ({ ...model, id: id })
    )
  })

  const lincenceGroupsFiltered = computed(() => {
    return licenseGroups.value.filter((group) =>
      group.name.toLowerCase().includes(search.value.toLowerCase())
    )
  })

  // Get the license models that are not already in the license
  const licensesToAdd = computed(() => {
    if (!props.license) return [] // Guard clause if no license is given (create new license)
    if (!props.license.licence_groups) return []
    const softwareLicenseKeys = props.license.licence_groups
    const selection: LicenseGroupWithId[] = softwareLicenseTable.value
      ?.selection as LicenseGroupWithId[]
    if (!selection) return []
    const selectionIds = selection.map((model) => model.id)
    const toAdd = selectionIds.filter(
      (id: string) => !softwareLicenseKeys.includes(id)
    )
    return toAdd
  })

  const licensesToDelete = computed(() => {
    if (!props.license) return [] // Guard clause if no license is given (create new license)
    if (!props.license.licence_groups) return []
    const softwareLicenseKeys = props.license.licence_groups
    const selection: LicenseGroupWithId[] = softwareLicenseTable.value
      ?.selection as LicenseGroupWithId[]
    if (!selection) return []
    const selectionIds = selection.map((model) => model.id)
    const toDelete = softwareLicenseKeys.filter(
      (name: string) => !selectionIds.includes(name)
    )
    return toDelete
  })

  const alertClasses = computed(() => {
    return {
      alert: true,
      'alert-success': result.value?.status === 'success',
      'alert-error': result.value?.status === 'error',
    }
  })

  watch(
    () => props.license,
    () => {
      if (!props.license) return
      if (!props.license.licence_groups) return
      const softwareLicenseKeys = props.license.licence_groups
      softwareLicenseTable.value?.selectByIds(softwareLicenseKeys)
    },
    { immediate: true }
  )

  // Select all license models that are already in the license
  onMounted(() => {
    if (!props.license) return
    if (!props.license.licence_groups) return
    const softwareLicenseKeys = props.license.licence_groups
    softwareLicenseTable.value?.selectByIds(softwareLicenseKeys)
  })

  async function addLicenseGroup(groupToAdd: string) {
    if (!props.license) return // Id is always present if license is given

    await UserLicencesService.assignLicenceToLicenceGroupApiV1SoftwareLicencesLicenceIdLicenceGroupsPost(
      {
        licenceId: props.license._id!,
        requestBody: {
          licence_group_id: groupToAdd,
        },
      }
    )
  }

  async function removeLicenseGroup(groupToRemove: string) {
    if (!props.license) return // Id is always present if license is given

    await UserLicencesService.unassignLicenceFromLicenceGroupApiV1SoftwareLicencesLicenceIdLicenceGroupsLicenceGroupIdDelete(
      {
        licenceId: props.license._id!,
        licenceGroupId: groupToRemove,
      }
    )
  }

  function handleButtonClick() {
    if (state.value === 'selection') {
      state.value = 'loading'
      Promise.all([
        ...licensesToAdd.value.map((licenseToAdd) =>
          addLicenseGroup(licenseToAdd)
        ),
        ...licensesToDelete.value.map((licenseToDelete) =>
          removeLicenseGroup(licenseToDelete)
        ),
      ])
        .then(() => {
          state.value = 'done'
          result.value = {
            status: 'success',
            message: i18n.t('licenseGroupEditSuccess'),
          }
        })
        .catch(() => {
          state.value = 'selection'
          result.value = {
            status: 'error',
            message: i18n.t('licenseGroupEditError'),
          }
        })
        .finally(() => {
          emit('groupEdited')
        })
    } else if (state.value === 'done') {
      emit('close')
    }
  }
</script>

<style lang="scss" scoped>
  .user {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    max-width: 100%;

    &__name {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__avatar {
      margin-right: 1rem;
      border-radius: var(--border-radius-base);
      box-shadow: var(--shadow);
      background-color: var(--sm-white);
    }
  }

  .button {
    width: 70%;
    margin: 30px auto 0 auto;
  }

  .alert {
    $message-padding: 0.5rem;
    margin: 1.5rem 0 1.5rem 0;
    padding: $message-padding;
    width: calc(100% - #{$message-padding * 2});
    border-radius: var(--border-radius-base);
    display: flex;
    align-items: center;

    &-success {
      border: 1px solid var(--sm-primary);
    }

    &-error {
      border: 1px solid var(--sm-magenta);
    }

    .message {
      margin-left: 0.5rem;
    }
  }

  .license-group {
    max-height: 350px;
    overflow: auto;
    margin-top: 1.3rem;
  }
</style>

<style lang="scss">
  .license-model {
    transition: all 0.2s ease-in-out;
  }

  .license-model-deleted {
    font-weight: 600;
    color: var(--sm-magenta);
  }

  .license-model-added {
    font-weight: 600;
    color: var(--sm-primary);
  }
</style>
