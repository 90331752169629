<template>
  <!-- ACCOUNT_GROUP_ACCOUNTS_ADDED  -->
  <div>
    <i18n-t
      keypath="jobMessage.account_group_accounts_added"
      tag="p"
      class="flex items-center gap-3 break-normal">
      <template #user>
        <SmTag
          :label="user"
          icon="md-person-round"
          class="cursor-pointer transition hover:bg-gray-300 dark:hover:bg-gray-500"
          :hide-tooltip="true"
          :loading="loadingUser"
          @click="
            (userDetailsDrawerUserId = userObj?._id),
              (userDetailsDrawerVisibility = true)
          " />
      </template>
      <template #group>
        <SmTag :label="group" icon="md-groups-round" :loading="loadingGroup" />
      </template>
    </i18n-t>
  </div>

  <UsersDrawer
    v-if="userDetailsDrawerVisibility && userDetailsDrawerUserId"
    v-model:drawerVisibility="userDetailsDrawerVisibility"
    :user-id="userDetailsDrawerUserId"
    @close="userDetailsDrawerUserId = undefined" />
</template>

<script setup lang="ts">
  import {
    Account,
    AccountGroup,
    BackgroundJobAccountGroupAccountsAddedData,
    UserGroupsService,
  } from '@/client'
  import { useUserStore } from '@/stores/userStore'

  const userStore = useUserStore()

  const props = defineProps<{
    data: BackgroundJobAccountGroupAccountsAddedData
  }>()

  const userObj = ref<Account>()
  const user = ref<string>()
  const loadingUser = ref(true)
  const userDetailsDrawerVisibility = ref(false)
  const userDetailsDrawerUserId = ref<string | undefined>()

  const groupObj = ref<AccountGroup>()
  const group = ref<string>()
  const loadingGroup = ref(true)

  onMounted(async () => {
    // load user
    if (!props.data.accounts_added) return
    userObj.value = await userStore.getUserByID(props.data.accounts_added[0])
    user.value = userObj.value?.name || undefined
    loadingUser.value = false

    // load group
    groupObj.value =
      await UserGroupsService.getAccountGroupApiV1ManagementGroupsAccountGroupIdGet(
        {
          accountGroupId: props.data.account_group_id,
          includeDeleted: true,
        }
      )

    group.value = groupObj.value?.name || undefined
    loadingGroup.value = false
  })
</script>
