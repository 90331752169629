<template>
  <div class="sidebar">
    <el-menu
      :default-active="props.activeTab"
      @select="
        (index: string) => {
          emit('update:activeTab', index)
        }
      ">
      <!-- Account -->
      <el-menu-item index="account" class="settings-sidebar-item">
        <template #title>
          <v-icon name="io-person" scale="1.2" style="margin-right: 5px" />
          <span>{{ i18n.t('sidebar.settings.account') }}</span>
        </template>
      </el-menu-item>

      <!-- Password -->
      <el-menu-item index="password" class="settings-sidebar-item">
        <template #title>
          <v-icon name="io-lock-closed" scale="1.2" style="margin-right: 5px" />
          <div class="password-tab">
            {{ i18n.t('sidebar.settings.password') }}
            & <br />
            {{ i18n.t('sidebar.settings.security') }}
          </div>
        </template>
      </el-menu-item>

      <!-- Company & Access -->
      <el-menu-item index="company" class="settings-sidebar-item">
        <template #title>
          <v-icon
            name="md-business-round"
            scale="1.2"
            style="margin-right: 5px" />
          <span class="password-tab"
            >{{ i18n.t('members') }} & <br />
            {{ i18n.t('invites') }}</span
          >
        </template>
      </el-menu-item>

      <!-- Subscription -->
      <el-menu-item index="subscription" class="settings-sidebar-item">
        <template #title>
          <v-icon
            name="md-receiptlong-round"
            scale="1.2"
            style="margin-right: 5px" />
          <span>{{ i18n.t('sidebar.settings.subscription') }}</span>
        </template>
      </el-menu-item>

      <!-- API -->
      <el-menu-item index="api" class="settings-sidebar-item">
        <template #title>
          <v-icon
            name="md-webhook-round"
            scale="1.2"
            style="margin-right: 5px" />
          <span>{{ i18n.t('sidebar.settings.api') }}</span>
        </template>
      </el-menu-item>

      <!-- Help -->
      <el-menu-item index="help" class="settings-sidebar-item">
        <template #title>
          <v-icon name="io-help-buoy" scale="1.2" style="margin-right: 5px" />
          <span>{{ i18n.t('sidebar.settings.help') }}</span>
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  const props = defineProps({
    activeTab: {
      type: String,
      default: '1',
    },
  })

  const emit = defineEmits(['update:activeTab'])

  const i18n = useI18n()
</script>

<style scoped>
  .password-tab {
    line-height: 1.5rem;
  }
  .sidebar {
    height: 100%;
    text-align: left;
  }
  .el-menu {
    height: 100%;
  }

  .settings-sidebar-item {
    border-radius: 10px;
    margin-right: 12px;
    margin-bottom: 3px;
    transition: all 0.3s ease-in-out;
  }

  .settings-sidebar-item:hover:not(.is-active) {
    background-color: var(--el-border-color-extra-light);
  }
  .settings-sidebar-item.is-active {
    color: var(--el-text-color-regular) !important;
    background-color: var(--el-menu-hover-bg-color);
  }
</style>
