<script setup lang="ts">
  import { SettingsFlow } from '@ory/client'
  import { useClipboard } from '@vueuse/core'
  import { useI18n } from 'vue-i18n'
  import vueQr from 'vue-qr/src/packages/vue-qr.vue'

  import { getCsrfToken } from '@/common/util/oryUtil'
  import SmDialogMessage from '@/components/sm/SmDialogMessage.vue'
  import { useSessionStore } from '@/stores/sessionStore'
  import { useSettingsStore } from '@/stores/settings.store'

  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'

  const i18n = useI18n()
  const sessionStore = useSessionStore()
  const settingsStore = useSettingsStore()

  const visibility = defineModel('visibility', {
    default: false,
  })

  interface Message {
    message: string
    type: DialogMessageTypes
    visibility: boolean
  }

  const message = ref<Message>({
    message: '',
    type: 'success',
    visibility: false,
  })

  const enableDialogLoading = ref(false)
  const otpEnabled = ref(false)

  const uri = ref<string>('Error')
  const secret = ref<string>('Not Found')

  //   Ory specific
  const settingsFlow = ref<SettingsFlow | null>(null)
  const csrfToken = ref<string | null>(null)

  //   Validate OTP
  const validationCode = ref<string>('')
  const wasVerified = ref(false)

  //   Copy to clipboard
  const { copy, copied, isSupported } = useClipboard()

  function copyCode() {
    copy(secret.value)
  }

  //  1. Get OTP secret
  async function getOPT() {
    enableDialogLoading.value = true
    if (!otpEnabled.value) {
      settingsFlow.value = (
        await sessionStore.frontend.createBrowserSettingsFlow()
      ).data

      // Get csrf token
      csrfToken.value = getCsrfToken(settingsFlow.value.ui.nodes) || null

      settingsStore.dialogs.otpSetup = true

      // Get OTP secret (attributes.id === 'totp_secret_key')
      const relevantNode = settingsFlow.value.ui.nodes.find(
        (node) => node.type === 'text' && node.group === 'totp'
      )

      if (!relevantNode) {
        showOtpError()
        return
      }

      // @ts-expect-error: should exist
      secret.value = relevantNode.attributes.text.text
      uri.value = `otpauth://totp/${sessionStore.currentUser?.email}?secret=${secret.value}&issuer=saasmetrix`
    }
    enableDialogLoading.value = false
  }

  // 2. Verify OTP and enable 2FA
  async function enableOTP() {
    enableDialogLoading.value = true

    if (!settingsFlow.value || !csrfToken.value) {
      showOtpError()
      return
    }

    await sessionStore.frontend
      .updateSettingsFlow({
        flow: settingsFlow.value.id,
        updateSettingsFlowBody: {
          csrf_token: csrfToken.value,
          method: 'totp',
          totp_code: validationCode.value,
        },
      })
      .then(() => {
        handleOTPActivated()
      })
      .catch(() => {
        showOtpError()
      })
      .finally(() => {
        sessionStore.checkSession()
        enableDialogLoading.value = false
      })
  }

  function showOtpError() {
    message.value = {
      message: i18n.t('otpSetpupError'),
      type: 'error',
      visibility: true,
    }
  }

  function handleOTPActivated() {
    wasVerified.value = true
    settingsStore.dialogs.otpSetup = false
    settingsStore.otp.enabled = true
    otpEnabled.value = true

    message.value = {
      message: i18n.t('otpSetpupSuccess'),
      type: 'success',
      visibility: true,
    }
  }

  onMounted(() => {
    getOPT()
  })
</script>

<template>
  <SmDialog
    v-model:visibility="visibility"
    size="small"
    :title="i18n.t('setupOTP')">
    <div class="otp-wrapper">
      <SmDialogMessage
        style="width: 98%; margin-bottom: 1rem"
        :message="message.message"
        :type="message.type"
        :visible="message.visibility" />

      <p>{{ i18n.t('dialogs.twoFADialog.text1') }}</p>

      <SmTooltip :active="isSupported">
        <div class="qr" @click="copyCode">
          <vue-qr
            v-if="uri"
            :text="uri"
            :logo-scale="0.3"
            color-light="rgba(255, 255, 255, 1)"
            color-dark="rgba(0, 0, 0, 0.8)"
            background-color="rgba(255, 255, 255, 1)"
            :dot-scale="0.7"
            :auto-color="true"
            :correct-level="1"
            :size="500"
            :margin="0"
            style="width: 100%" />
        </div>

        <template #content>
          <!-- Copy code -->
          <div style="display: flex; align-items: center; gap: 0.5rem">
            <span>{{ i18n.t('copyCode') }}</span>
            <v-icon
              v-if="copied"
              name="md-check-round"
              class="fill-primary"></v-icon>
          </div>
        </template>
      </SmTooltip>

      <div style="width: 99%; margin-top: 1rem">
        <SmInput
          v-model="validationCode"
          :label="i18n.t('validationCode')"
          :disabled="wasVerified" />
      </div>

      <SmButton
        v-if="!wasVerified"
        size="large"
        :loading="enableDialogLoading"
        @click="enableOTP">
        {{ i18n.t('confirm') }}
      </SmButton>
      <SmButton v-else size="large" @click="visibility = false">
        {{ i18n.t('close') }}
      </SmButton>
    </div>
  </SmDialog>
</template>

<style scoped>
  .qr {
    margin: 0 auto;
    width: 100%;
    max-width: 250px;
  }

  .otp-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1rem;
  }
</style>
