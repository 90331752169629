<template>
  <!-- List of applications -->
  <el-scrollbar max-height="100%">
    <div v-auto-animate class="application-grid">
      <div
        v-for="item in softwareList"
        :key="getSoftwareIdentifier(item)"
        :class="{
          'item-is-active': isCardActive(getSoftwareIdentifier(item)),
          item: true,
        }"
        @click="handleIntegrationCardClick(item)">
        <div class="item-image-wrapper">
          <ApplicationIcon :software-name="getSoftwareName(item)" size="50px" />
        </div>
        <div class="item-text">
          <span v-html="highlightSearchSubstring(getDisplayName(item))" />
          <span
            v-if="(item as SoftwareInformation).subtitle"
            class="item-subtext">
            ({{ (item as SoftwareInformation).subtitle }})</span
          >
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script
  lang="ts"
  setup
  generic="T extends SoftwareMiniOut | SoftwareInformation">
  import { PropType } from 'vue'

  import { SoftwareInformation, SoftwareMiniOut } from '@/client'

  const props = withDefaults(
    defineProps<{
      softwareList: T[]
      highlightText?: string

      singleSelect?: boolean
    }>(),
    { highlightText: '' }
  )

  const emit = defineEmits<{
    (e: 'applicationSelected', app: T): void
    (e: 'applicationDeselected', app: T): void
  }>()

  const selectedSoftware = defineModel('selectedSoftware', {
    type: Object as PropType<T[]>,
    default: [],
  })

  function isInformation(
    software: SoftwareInformation | SoftwareMiniOut
  ): software is SoftwareInformation {
    return (software as SoftwareInformation).name !== undefined
  }

  function getSoftwareName(software: T) {
    if (isInformation(software)) return software.name
    return software.software_name
  }

  function getDisplayName(software: T): string {
    if (isInformation(software)) return software.display_name
    return software.display_name || software.software_name
  }

  function getSoftwareIdentifier(software: T) {
    if (isInformation(software)) return software.name + software.type
    return software._id
  }

  function handleIntegrationCardClick(item: T) {
    // check if software was selected
    const identifierOfParamSoftware = getSoftwareIdentifier(item)
    const identifiersOfSelectedSoftware = selectedSoftware.value.map((s) =>
      getSoftwareIdentifier(s)
    )

    // if software was selected, deselect it
    if (identifiersOfSelectedSoftware.includes(identifierOfParamSoftware)) {
      selectedSoftware.value = selectedSoftware.value.filter(
        (s) => getSoftwareIdentifier(s) != identifierOfParamSoftware
      )
      emit('applicationDeselected', item)
    }
    // else select it
    else {
      // if single select, remove item before adding a new
      if (props.singleSelect) selectedSoftware.value.pop()
      selectedSoftware.value.push(item)
      emit('applicationSelected', item)
    }
  }

  // Highlight the active card
  function isCardActive(identifier: string) {
    const getId = getSoftwareIdentifier
    // looks better if it is a one-liner
    const selectedIdentifiers = selectedSoftware.value.map((s) => getId(s))
    return selectedIdentifiers.includes(identifier)
  }

  // For highlighting search substring
  function highlightSearchSubstring(text: string) {
    const index = text.toLowerCase().indexOf(props.highlightText.toLowerCase())
    if (index === -1) {
      return text
    }
    return (
      text.substring(0, index) +
      '<span class="highlight">' +
      text.substring(index, index + props.highlightText.length) +
      '</span>' +
      text.substring(index + props.highlightText.length)
    )
  }
</script>

<style scoped lang="scss">
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    background-color: var(--el-border-color-extra-lighter);
    border: 1px solid var(--el-border-color);
    cursor: pointer;
    padding: 10px 0 10px 0;
    transition: all 0.1s ease-in-out;

    &:hover {
      .item-image-wrapper {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
        scale: 1.02;
      }
    }

    &-image-wrapper {
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 8px;
      margin-bottom: 5px;
      // Box shadow for logos with transparent background
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.1s ease-in-out;
    }

    &-is-active {
      background-color: var(--el-color-primary-light-9);
      border: 1px solid var(--el-color-primary);

      .item-image-wrapper {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
        scale: 1.05;
      }

      .item-text {
        font-weight: 600;
        margin: 0;
      }
    }

    &-text {
      text-align: center;
      transition: all 0.2s ease-in-out;
      padding: 5px 0;
    }
  }

  .application-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 15px;

    max-width: 100%;
    height: 100%;
    padding-bottom: 40px;
  }
</style>

<style>
  .highlight {
    color: var(--el-color-primary);
  }
</style>
