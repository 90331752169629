import { useI18n } from 'vue-i18n'

import { ApiError } from '@/client'

export function getApiErrorMessage(
  error: ApiError,
  i18n: ReturnType<typeof useI18n>,
  defaultMessage?: string
): string {
  let translation
  if (
    error.body?.detail?.error &&
    i18n.te(error.body.detail.error + '.message')
  ) {
    translation = i18n.t(error.body.detail.error + '.message')
  } else if (defaultMessage) {
    translation = defaultMessage
  } else if (error.body?.detail?.message)
    translation = error.body?.detail?.message
  else translation = i18n.t('error.unknown.unknown_error.message')
  return translation
}
