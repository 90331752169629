<template>
  <!-- Edit Mode -->
  <div v-if="mode == 'edit'">
    <ul class="licenseSelection">
      <li
        v-for="option in optimization?.data?.redundant_software_license_ids"
        :key="option.parent_software_license_id">
        <div style="display: flex; gap: 0.6rem; cursor: pointer">
          <input v-model="checked" type="radio" :value="option" />

          <div class="license-model-description" @click="checked = option">
            <span
              >{{ i18n.t('optimizationsContent.redundantLicenses.content1') }}
              <b>{{
                getLicenseModelByID(option.parent_software_license_id)?.name
              }}</b>
              {{ i18n.t('optimizationsContent.redundantLicenses.content2') }}
            </span>
            <span
              v-for="id in option.redundant_software_license_ids"
              :key="id"
              class="redundant-model"
              >{{ getLicenseModelByID(id)?.name }}</span
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
  <!-- Preview Mode -->
  <div v-if="mode == 'preview'">
    <!-- Message -->
    <el-collapse-transition>
      <div v-if="result" :class="alertClasses">
        <div class="icon">
          <v-icon
            v-if="result.status == 'success'"
            name="md-checkcircle-round"
            scale="1.5"
            class="fill-primary" />

          <v-icon
            v-else
            name="md-cancel-round"
            scale="1.5"
            class="fill-magenta" />
        </div>
        <div class="message">
          {{ result.message }}
          <br />
          <!-- One time password -->
          <span v-if="result.response?.application_specific?.default_password">
            {{ i18n.t('optimizationsContent.oneTimePassword') }}
            <b>
              {{ result.response?.application_specific.default_password }}</b
            ></span
          >
        </div>
      </div>
    </el-collapse-transition>

    <div style="margin-bottom: 1rem">
      {{ i18n.t('optimizationsContent.editRedundantLicenses.content1') }}
      <input type="checkbox" checked />
      {{ i18n.t('optimizationsContent.editRedundantLicenses.content2') }}
      <span style="font-weight: 600; color: var(--sm-magenta)">
        {{ i18n.t('optimizationsContent.editRedundantLicenses.content3') }}
      </span>
      {{ i18n.t('optimizationsContent.editRedundantLicenses.content4') }}
      <input type="checkbox" />
      {{ i18n.t('optimizationsContent.editRedundantLicenses.content5') }}
    </div>

    <div style="max-height: 450px; overflow: scroll">
      <LicenseModelEditTable
        v-if="license && user && software"
        ref="softwareLicenseEditTable"
        only-show-flatrate-licenses
        :software-licenses="softwareLicenses"
        :sub-account-id="license.sub_account_id"
        :license="license"
        filter="ActiveAndEdit"
        :software="software"
        :user="[user]" />
    </div>
  </div>

  <div class="footer">
    <SmButton v-if="mode == 'edit'" @click="mode = 'preview'">
      {{ i18n.t('preview') }} & {{ i18n.t('edit') }}
    </SmButton>
    <div
      v-if="mode == 'preview' && previewState != 'done'"
      style="display: flex; justify-content: space-between; width: 100%">
      <SmButton outline @click="mode = 'edit'">{{ i18n.t('back') }}</SmButton>
      <SmButton :loading="previewState == 'loading'" @click="execute">
        {{ i18n.t('save') }}
        &
        {{ i18n.t('execute') }}
      </SmButton>
    </div>

    <div v-if="mode == 'preview' && previewState == 'done'">
      <SmButton @click="emit('close')">{{ i18n.t('close') }}</SmButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    LicenceOut,
    Optimization,
    SoftwareLicense,
    SoftwareOut,
  } from '@/client'

  import LicenseModelEditTable from './LicenseModelEditTable.vue'

  const softwareLicenseEditTable =
    ref<ComponentExposed<typeof LicenseModelEditTable>>()

  const i18n = useI18n()

  const emit = defineEmits(['close'])
  const props = defineProps<{
    license: LicenceOut
    optimization: Optimization
    software: SoftwareOut
    softwareLicenses: SoftwareLicense[]
    user: Account
  }>()

  const checked = ref(
    props.optimization?.data?.redundant_software_license_ids
      ? props.optimization.data.redundant_software_license_ids[0]
      : null
  )

  function execute() {
    softwareLicenseEditTable.value?.execute()
  }

  const previewState = computed(() => {
    return softwareLicenseEditTable.value?.state
  })

  //   Modes: preview, edit
  const mode: Ref<string> = ref('edit')

  // Watch mode and update selection
  watch(
    [mode, softwareLicenseEditTable],
    ([newMode]) => {
      if (newMode == 'preview') {
        if (!softwareLicenseEditTable.value) {
          return
        }

        checked.value?.redundant_software_license_ids.forEach((id) => {
          softwareLicenseEditTable.value?.deselectLicenseModel(id)
        })
      }
    },
    { immediate: true }
  )

  const alertClasses = computed(() => {
    return {
      alert: true,
      'alert-success': result.value?.status === 'success',
      'alert-error': result.value?.status === 'error',
    }
  })

  const result = computed(() => {
    return softwareLicenseEditTable.value?.result
  })

  function getLicenseModelByID(id: string) {
    return props.softwareLicenses.find((license) => license._id === id)
  }
</script>

<style lang="scss" scoped>
  .licenseSelection {
    padding: 0.5rem;
    display: flex;
    gap: 0.7rem;
    flex-direction: column;
    max-height: 350px;
    overflow-y: auto;
    border: 1px solid var(--sm-contrast-muted);
    border-radius: var(--border-radius-base);
  }

  // Remove ul bullets
  ul {
    list-style-type: none;
    padding: 0;
  }
  .license-model-description {
    display: flex;
    flex-direction: column;
  }

  .parent-model {
    font-weight: bold;
  }

  .redundant-model {
    color: var(--sm-magenta);
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }

  .alert {
    $message-padding: 0.5rem;
    margin: 0 0 1.5rem 0;
    padding: $message-padding;
    width: calc(100% - #{$message-padding * 2});
    border-radius: var(--border-radius-base);
    display: flex;
    align-items: center;

    &-success {
      border: 1px solid var(--sm-primary);
    }

    &-error {
      border: 1px solid var(--sm-magenta);
    }

    .message {
      margin-left: 0.5rem;
    }
  }
</style>
