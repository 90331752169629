<template>
  <DocumentUploadDialog
    v-if="documentUploadDrawerVisibility"
    v-model:showDrawer="documentUploadDrawerVisibility"
    v-model:existing-document="documentUploadDrawerDocument"
    :edit-mode="true"
    :delete-highlight="documentUploadDrawerDeleteHighlight"
    :software-id="$props.document.software_id" />

  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0px;
    ">
    <span @click="handleDocumentEdit(document)">{{
      props.document.title || props.document.filename
    }}</span>
    <div style="display: flex; flex-direction: row; align-items: center">
      <v-icon
        name="md-clouddownload-round"
        style="cursor: pointer; margin-right: 10px"
        @click="() => downloadDocument(document)" />
      <SmDropdown trigger="click">
        <SmDropdownItem
          icon="md-modeedit-round"
          @click="() => handleDocumentEdit(document)">
          {{ i18n.t('editDocument') }}
        </SmDropdownItem>
        <SmDropdownItem
          hover-color="var(--sm-magenta)"
          icon="md-delete-round"
          @click="() => handleDocumentDelete(document)">
          {{ i18n.t('deleteDocument') }}
        </SmDropdownItem>
      </SmDropdown>
    </div>
  </div>
  <el-divider
    v-if="currentIndex !== documentsCount - 1"
    style="margin: 10px 0px !important" />
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { DocumentInfo } from '@/client'
  import { getDocument } from '@/common/document'
  import DocumentUploadDialog from '@/components/DocumentUploadDialog.vue'
  import { useGlobalStore } from '@/stores/globalStore'

  const props = defineProps({
    document: {
      type: Object as PropType<DocumentInfo>,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    documentsCount: {
      type: Number,
      required: true,
    },
  })

  const store = useGlobalStore()
  const i18n = useI18n()

  const documentUploadDrawerVisibility = ref<boolean>(false)
  const documentUploadDrawerDeleteHighlight = ref<boolean>(false)
  const documentUploadDrawerDocument = ref<DocumentInfo>()

  function handleDocumentEdit(document: DocumentInfo) {
    documentUploadDrawerDocument.value = document
    documentUploadDrawerVisibility.value = true
  }

  function handleDocumentDelete(document: DocumentInfo) {
    documentUploadDrawerDocument.value = document
    documentUploadDrawerDeleteHighlight.value = true
    documentUploadDrawerVisibility.value = true
  }

  function downloadDocument(rowDocument: DocumentInfo) {
    getDocument(store.getIp, store.getJWT(), rowDocument._id).then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      Object.assign(a, {
        href: url,
        download: rowDocument.filename,
        target: '_blank',
      })
      a.style.display = 'none'
      // the filename you want
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }
</script>
