<script setup lang="ts">
  import dayjs from 'dayjs'

  import { useI18n } from 'vue-i18n'

  import { AvailableCompany } from '@/client'

  const i18n = useI18n()

  const company = defineProps({
    company: {
      type: Object as PropType<AvailableCompany>,
      required: true,
    },
  })

  function formatDate(date: string) {
    const d = new Date(date)
    return d.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  }

  const getDaysToNow = (date: string) => {
    const now = dayjs()
    const then = dayjs(date)
    return now.diff(then, 'day')
  }
</script>

<template>
  <div class="company">
    <div>
      <SmAvatar
        :name="company.company.name"
        class="bg-gray-200"
        form="circle" />
    </div>
    <div style="margin-bottom: auto">
      <div>{{ company.company.name }}</div>
      <div>{{ i18n.t(company.company.role) }}</div>
    </div>
    <div style="margin-left: auto; margin-bottom: auto">
      <div v-if="company.company.last_login">
        <SmTooltip placement="right">
          <span>
            {{
              i18n.t(
                'daysAgo',
                [getDaysToNow(company.company.last_login)],
                getDaysToNow(company.company.last_login)
              )
            }}
          </span>

          <!-- Tooltip Content -->
          <template #content>
            <div>
              {{ i18n.t('lastLogin') }}:
              {{ formatDate(company.company.last_login) }}
            </div>
          </template>
        </SmTooltip>
      </div>
      <div v-else>
        {{ i18n.t('never') }}
      </div>
    </div>
  </div>
</template>
