/*
Color Utilities

This file contains functions for converting between different color formats.
*/

function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

function checkIfHex(hex: string) {
  return /^#([0-9A-F]{3}){1,2}$/i.test(hex)
}

function checkIfRgb(rgb: string) {
  return /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/i.test(rgb)
}

function checkIfRgba(rgba: string) {
  return /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)$/i.test(rgba)
}

function rgbToObj(rgb: string) {
  const result = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/i.exec(rgb)
  return result
    ? {
        r: parseInt(result[1]),
        g: parseInt(result[2]),
        b: parseInt(result[3]),
      }
    : null
}

function rgbaToObj(rgba: string) {
  const result = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)$/i.exec(rgba)
  return result
    ? {
        r: parseInt(result[1]),
        g: parseInt(result[2]),
        b: parseInt(result[3]),
        a: parseInt(result[4]),
      }
    : null
}

export function parseRGB(input: string): { r: number; g: number; b: number } {
  if (checkIfHex(input)) {
    const rgb = hexToRgb(input)
    if (rgb) {
      return rgb
    }
  } else if (checkIfRgb(input)) {
    const rgb = rgbToObj(input)
    if (rgb) {
      return rgb
    }
  } else if (checkIfRgba(input)) {
    const rgb = rgbaToObj(input)
    if (rgb) {
      return rgb
    }
  }
  throw new Error('Invalid color')
}

export function generateFallbackColor() {
  return { r: 52, g: 144, b: 239 }
}
