/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TokenRequestOut = {
    access_token: string;
    token_type?: TokenRequestOut.token_type;
};
export namespace TokenRequestOut {
    export enum token_type {
        BEARER = 'Bearer',
    }
}

