<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <div style="height: 99%">
      <el-card
        style="width: 99%; height: 100%"
        :body-style="{
          height: 'calc(100% - 40px)',
        }"
        shadow="never">
        <!-- Searchbar -->
        <el-input
          v-model="searchQuery"
          class="search-input"
          style="width: 100%"
          :placeholder="
            i18n.t(
              'dialogs.onboardingDialog.content.applicationSelect.searchPlaceholder'
            )
          " />

        <!-- List of applications -->
        <ApplicationSelect
          v-model:selected-software="
            onboardingStore.$state.selectedIntegrations
          "
          :highlight-text="searchQuery"
          :software-list="filteredSoftwareList" />
      </el-card>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    SoftwareInformation,
    SoftwareIntegrationsService,
    SoftwareStatus,
    SoftwareTypes,
  } from '@/client'

  import { useOnboardingDialogStore } from '../BaseOnboardingDialog.store'

  const onboardingStore = useOnboardingDialogStore()
  const i18n = useI18n()

  const searchQuery = ref<string>('')
  const softwareList = ref<SoftwareInformation[]>([])

  const filteredSoftwareList = computed(() => {
    // Filter duplicates
    const filteredList = softwareList.value.filter(
      (item, index) =>
        softwareList.value.findIndex((item2) => item2.name === item.name) ===
          index && item.type !== SoftwareTypes.MANUALLY
    )

    if (searchQuery.value.length == 0) {
      return filteredList
    }

    return filteredList.filter((item) => {
      return item.display_name
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase())
    })
  })

  SoftwareIntegrationsService.getIntegrationsApiV1SoftwareIntegrationsGet(
    {}
  ).then((integrations) => {
    softwareList.value = softwareList.value
      .concat(
        integrations.filter(
          (entry) => entry.status !== SoftwareStatus.COMING_SOON
        )
      )
      .sort((a, b) => a.display_name.localeCompare(b.display_name))
  })

  async function confirm(): Promise<boolean> {
    return true
  }
  defineExpose({ confirm })
</script>

<style scoped lang="scss">
  .search-input {
    margin-bottom: 10px;
  }
</style>
