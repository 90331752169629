<template>
  <SmDropdown
    :items="availableTags"
    :selected="selected"
    divider
    @select="tagClickHandler">
    <!-- Trigger -->
    <template #trigger>
      <slot />
    </template>

    <!-- Items -->
    <SmDropdownItem
      v-for="item in availableTags"
      :key="item._id"
      divider
      :value="item._id"
      @click="tagClickHandler(item._id)">
      <div class="tag-row">
        <TagComponent
          :tag="{
            color: { ...generateFallbackColor(), ...item.color },
            name: item.name,
          }" />
        <v-icon
          v-if="selected.includes(item._id)"
          fill="var(--sm-text-color)"
          scale="0.9"
          name="md-check-round" />
      </div>
    </SmDropdownItem>
    <!-- Add new -->
    <SmDropdownItem
      v-if="showCreateTag"
      :value="null"
      @click="emit('createTag')">
      <div class="tag-row">
        <v-icon class="fill-contrast" scale="0.9" name="hi-plus" />
        <span>{{ i18n.t('addNew') }}</span>
      </div>
    </SmDropdownItem>
  </SmDropdown>
</template>

<script setup lang="ts">
  import { AccountGroup, Tag } from '@/client'
  import { generateFallbackColor } from '@/common/colors'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  const props = defineProps<{
    showCreateTag?: boolean
    selected: string[]
    availableTags: Tag[] | AccountGroup[]
  }>()

  const emit = defineEmits<{
    deleteTag: [value: string]
    addTag: [value: string]
    createTag: []
  }>()

  function tagClickHandler(tagId: string) {
    if (props.selected.includes(tagId)) emit('deleteTag', tagId)
    else emit('addTag', tagId)
  }
</script>

<style scoped lang="scss">
  .tag-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 0.5rem;
    height: 100%;
    padding: 0.1rem;
    cursor: pointer;
  }
</style>
