<template>
  <el-card
    shadow="always"
    :body-style="{
      height: '100%',
      width: '100%',
      padding: '10px !important',
    }"
    class="application-details-savings-card">
    <el-tabs v-model="activeTabName">
      <el-tab-pane
        :label="i18n.t('views.dashboard.informationCard.tabs.licences')"
        name="licence_amount"
        lazy />
      <el-tab-pane
        :label="i18n.t('views.dashboard.informationCard.tabs.saasSpends')"
        name="cost"
        lazy />
    </el-tabs>

    <div style="height: calc(100% - 50px); position: relative">
      <InformationChart
        :requested-range="requestedRange"
        :software-id="props.softwareId"
        :field="activeTabName"
        :statistic-type="StatisticType.SOFTWARE_STATISTIC"
        :chart-color="chartColor"
        :hide-axes="true"
        @button-click="() => emit('emptyStateButtonClicked')" />
    </div>
  </el-card>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { MetadataQueryField, StatisticType } from '@/client'
  import InformationChart from '@/components/charts/InformationChart.vue'

  const emit = defineEmits(['emptyStateButtonClicked'])

  const props = defineProps<{
    softwareId: string
  }>()

  // the date range for the information chart
  const requestedRange = ref<[Date, Date]>([
    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
    new Date(),
  ])

  const i18n = useI18n()

  const activeTabName = ref<MetadataQueryField>(
    MetadataQueryField.LICENCE_AMOUNT
  )

  const chartColor = computed(() => {
    switch (activeTabName.value) {
      case MetadataQueryField.LICENCE_AMOUNT:
        return '#e34a6f'
      case MetadataQueryField.COST:
        return '#08e9c5'
      default:
        return '#F8222A'
    }
  })
</script>

<style lang="scss">
  .application-details-savings-card {
    .el-tabs__item {
      padding: 0 10px !important;
      height: 35px !important;
      font-size: 1rem;
    }
  }
</style>
