<template>
  <div
    :class="[
      'dropdown-item',
      `dropdown-item--${size}`,
      `dropdown-item--${hoverAnimation}`,
      `dropdown-item--${props.disabled ? 'disabled' : 'enabled'}`,
    ]"
    @click="handleClick">
    <v-icon
      v-if="$props.icon"
      :name="$props.icon"
      fill="inherit"
      :scale="iconSize" />
    <slot />
  </div>
</template>

<script setup lang="ts">
  type Size = 'small' | 'medium'
  type Animations = 'indent' | 'background'

  const emit = defineEmits(['itemClick'])

  interface Props {
    size?: Size
    icon?: string
    hoverColor?: string
    hoverAnimation?: Animations
    disabled?: boolean
    tooltip?: string | boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    size: 'small',
    icon: undefined,
    hoverColor: 'unset',
    hoverAnimation: 'background',
    disabled: false,
    tooltip: false,
  })

  function handleClick() {
    if (props.disabled) return
    emit('itemClick')
  }

  const iconSize = computed(() => {
    switch (props.size) {
      case 'small':
        return '1'
      case 'medium':
        return '1.1'
      default:
        return '1'
    }
  })
</script>

<style lang="scss">
  $hover-color: v-bind(hoverColor);

  .dropdown-item {
    font-size: var(--font-size-small);

    display: flex;
    align-items: center;

    gap: 6px;

    padding: 6px 10px;
    cursor: pointer;
    pointer-events: all;

    border-radius: var(--border-radius);

    text-align: left;

    transition:
      color 0.2s ease,
      padding 0.2s ease,
      fill 0.2s ease,
      background-color 0.1s ease;

    &.dropdown-item--disabled {
      cursor: not-allowed;
      color: var(--sm-contrast-muted);
      fill: var(--sm-contrast-muted);

      &:hover {
        color: var(--sm-contrast-muted);
        background-color: transparent;
        fill: var(--sm-contrast-muted);
      }
    }
  }

  .dropdown-item--indent {
    padding-right: 14px;

    &:hover {
      padding-left: 14px;
      padding-right: 10px;
      color: $hover-color;
      fill: $hover-color;
    }
  }

  .dropdown-item--background {
    background-color: transparent;
    transition: all 0.2s ease;

    &:hover {
      background-color: theme('colors.gray[100]');
      color: $hover-color;
      fill: $hover-color;
    }
  }

  .dark .dropdown-item--background:hover {
    background-color: theme('colors.gray[600]');
  }

  .dropdown-item--medium {
    font-size: var(--font-size);
  }
</style>
