import { ExportLicencesService, LicenceExportConfig } from '@/client'
import { OpenAPI } from '@/client/core/OpenAPI'
import handleError from '@/middlewares/handleError'

export async function exportResource({
  requestBody,
  queryParams,
}: {
  requestBody: LicenceExportConfig
  queryParams: Parameters<
    typeof ExportLicencesService.exportLicencesApiV1ExportLicencesPost
  >[0]
}) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${OpenAPI.TOKEN}`)
  const body: BodyInit = JSON.stringify(requestBody)

  const query: Record<string, string> = {}

  // Change the casing to fit BE Parameters (Camel Case to Pascal Case)
  for (const [key, value] of Object.entries(queryParams)) {
    const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
    if (value == undefined) continue

    query[newKey] = value?.toString()
  }

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: headers,
    body: body,
  }

  return fetch(
    `${OpenAPI.BASE}/api/v1/export/licences?${new URLSearchParams(query)}`,
    requestOptions
  ).then(handleError)
}
