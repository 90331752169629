<template>
  <div class="hello-content">
    <span style="font-size: 1.8em; font-weight: 700; margin-bottom: 20px">
      {{ i18n.t('dialogs.onboardingDialog.content.hello.headline') }}
    </span>
    <div>
      <LogoText :is-dark="isDark" />
    </div>
    <div style="margin-top: 20px">
      <SmButton size="large" style="width: 200px" @click="emit('nextPage')">
        {{ i18n.t('dialogs.onboardingDialog.content.hello.letsBegin') }}
      </SmButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useDark } from '@vueuse/core'
  import { useI18n } from 'vue-i18n'

  import LogoText from '@/assets/img/LogoText.vue'

  const i18n = useI18n()
  const isDark = useDark()

  const emit = defineEmits(['nextPage'])

  async function confirm(): Promise<boolean> {
    return true
  }

  defineExpose({ confirm })
</script>

<style>
  .hello-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    place-content: center;
    align-items: center;
  }
</style>
