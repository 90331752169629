<template>
  <SmDialog
    v-model:visibility="visibility"
    size="large"
    :title="dialogTitle"
    :before-close="handleClose"
    @close="handleClose">
    <h4 v-if="props.deleteLicences.length > 0">
      {{
        i18n.t(
          'drawers.applicationDetailDrawer.selectUserTab.saveDialog.deleteLicencesText'
        )
      }}
    </h4>

    <!-- the 'unreachableValue' of the table is needed, to prevent element-plus el-table from
      rendering the .children of licenses as children rows (https://element-plus.org/en-US/component/table.html#table-attributes)-->
    <el-table
      v-if="props.deleteLicences.length > 0"
      :tree-props="{
        children: 'unreachableValue',
        hasChildren: 'unreachableValue',
      }"
      :data="props.deleteLicences"
      :style="{
        margin: '10px auto 20px auto',
      }"
      max-height="400"
      row-key="email"
      border>
      <el-table-column
        prop="email"
        :label="
          i18n.t(
            'drawers.applicationDetailDrawer.selectUserTab.saveDialog.table.email'
          )
        "></el-table-column>
      <el-table-column
        :label="
          i18n.t(
            'drawers.applicationDetailDrawer.selectUserTab.saveDialog.table.licenceModel'
          )
        "
        prop="software_licenses">
        <template #default="scope">
          <LicenceModelNamesDisplay
            :software-licenses="Object.values(scope.row.software_licenses)" />
        </template>
      </el-table-column>
      <el-table-column
        v-if="deleteLicenceLoading || deletedLicences"
        width="80">
        <template #header>
          <div style="display: flex; justify-content: center">
            <v-icon
              v-if="allLicencesDeleted && !deleteLicenceLoading"
              name="io-checkmark-circle"
              scale="1.2" />
            <v-icon
              v-else-if="!allLicencesDeleted && !deleteLicenceLoading"
              name="io-close-circle"
              scale="1.2" />
            <span v-else>{{
              i18n.t(
                'drawers.applicationDetailDrawer.selectUserTab.saveDialog.table.status'
              )
            }}</span>
          </div>
        </template>
        <template #default="scope">
          <div style="display: flex; justify-content: center">
            <span
              v-if="licenceIsDeleted(scope.row.email) === true"
              class="status-text">
              <v-icon name="io-checkmark-circle" scale="1.2" />
            </span>
            <span
              v-else-if="licenceIsDeleted(scope.row.email) === false"
              class="status-text">
              <v-icon name="io-close-circle" scale="1.2" />
            </span>
            <span v-else class="status-text">
              <v-icon
                name="io-reload-circle"
                scale="1.2"
                animation="spin"
                speed="slow" />
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <h4 v-if="props.saveLicences.length > 0">
      {{
        i18n.t(
          'drawers.applicationDetailDrawer.selectUserTab.saveDialog.saveLicencesText'
        )
      }}
    </h4>

    <!-- the 'unreachableValue' of the table is needed, to prevent element-plus el-table from
      rendering the .children of licenses as children rows (https://element-plus.org/en-US/component/table.html#table-attributes)-->
    <el-table
      v-if="props.saveLicences.length > 0"
      :data="props.saveLicences"
      :style="{
        margin: '10px auto 20px auto',
      }"
      :tree-props="{
        children: 'unreachableValue',
        hasChildren: 'unreachableValue',
      }"
      max-height="400"
      row-key="email"
      border>
      <el-table-column
        prop="email"
        :label="
          i18n.t(
            'drawers.applicationDetailDrawer.selectUserTab.saveDialog.table.email'
          )
        "></el-table-column>
      <el-table-column
        :label="
          i18n.t(
            'drawers.applicationDetailDrawer.selectUserTab.saveDialog.table.licenceModel'
          )
        "
        prop="software_licenses">
        <template #default="scope">
          <LicenceModelNamesDisplay
            :software-licenses="Object.values(scope.row.software_licenses)" />
        </template>
      </el-table-column>
      <el-table-column
        v-if="savedLicences && isTableColumnComponentExisting"
        :label="
          i18n.t(
            'drawers.applicationDetailDrawer.selectUserTab.saveDialog.table.defaultPassword'
          )
        ">
        <template #default="scope">
          <BaseApplicationTableColumns
            v-if="getColumnLicense(scope.row.email)?.application_specific"
            v-model:email="scope.row.name"
            :default-password="getDefaultPassword(scope.row.email)"
            :software-name="props.softwareName" />
        </template>
      </el-table-column>
      <el-table-column v-if="saveLicenceLoading || savedLicences" width="80">
        <template #header>
          <div style="display: flex; justify-content: center">
            <v-icon
              v-if="allLicencesSaved && !saveLicenceLoading"
              name="io-checkmark-circle"
              scale="1.2" />
            <v-icon
              v-else-if="!allLicencesSaved && !saveLicenceLoading"
              name="io-close-circle"
              scale="1.2" />
            <span v-else>{{
              i18n.t(
                'drawers.applicationDetailDrawer.selectUserTab.saveDialog.table.status'
              )
            }}</span>
          </div>
        </template>
        <template #default="{ row }">
          <div style="display: flex; justify-content: center">
            <span
              v-if="licenceIsSaved(row.email || row.name) === true"
              class="status-text">
              <v-icon name="io-checkmark-circle" scale="1.2" />
            </span>
            <span
              v-else-if="licenceIsSaved(row.email || row.name) === false"
              class="status-text">
              <v-icon name="io-close-circle" scale="1.2" />
            </span>
            <span v-else class="status-text">
              <v-icon
                name="io-reload-circle"
                scale="1.2"
                animation="spin"
                speed="slow" />
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <span class="dialog-footer">
      <el-button
        v-if="(!deleteLicenceLoading || !saveLicenceLoading) && !savedLicences"
        @click="handleAbortButtonClick">
        {{ i18n.t('general.cancel') }}
      </el-button>
      <el-button
        type="primary"
        :loading="
          (deleteLicenceLoading && !deletedLicences) ||
          (saveLicenceLoading && !savedLicences)
        "
        @click="handleButtonClick"
        >{{
          deletedLicences && savedLicences
            ? i18n.t('general.close')
            : i18n.t('general.save')
        }}</el-button
      >
    </span>
  </SmDialog>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { LicenceOut } from '@/client'
  import BaseApplicationTableColumns from '@/components/BaseApplicationTableColumns/BaseApplicationTableColumns.vue'
  import { sendToast } from '@/components/sm/SmNotification'
  import { licenseStore } from '@/stores/licenseV2.store'
  import { computedAsync } from '@vueuse/core'

  const i18n = useI18n()

  const emit = defineEmits(['update:visibility', 'refetchLicences', 'abort'])
  const props = defineProps({
    visibility: {
      type: Boolean,
      default: false,
    },
    softwareName: {
      type: String,
      default: '',
    },
    softwareId: {
      type: String,
      default: '',
    },
    deleteLicences: {
      type: Array as PropType<LicenceOut[]>,
      default: [] as LicenceOut[],
    },
    saveLicences: {
      type: Array as PropType<LicenceOut[]>,
      default: [] as LicenceOut[],
    },
    selectedSubAccountId: {
      required: true,
      type: null as unknown as PropType<string | null | undefined>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validator: (v: any) => typeof v === 'string' || v === null,
    },
  })

  const visibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })

  const dialogTitle = computed(() =>
    savedLicences.value && deletedLicences
      ? i18n.t(
          'drawers.applicationDetailDrawer.selectUserTab.saveDialog.title.savedChanges'
        )
      : i18n.t(
          'drawers.applicationDetailDrawer.selectUserTab.saveDialog.title.default',
          {
            deletedCount: props.deleteLicences.length,
            savedCount: props.saveLicences.length,
          }
        )
  )

  const databaseLicences = computedAsync(async () => {
    const filter = [
      new licenseStore.filter.SoftwaresFilter([props.softwareId]),
      new licenseStore.filter.SubAccountFilter(
        props.selectedSubAccountId ? [props.selectedSubAccountId] : []
      ),
    ]

    return await licenseStore.get(filter)
  })

  type RequestState = {
    licenceEmail: string
    // delete successfully => boolean if its successfully or not and its undefined while loading
    successfully: boolean | undefined
  }

  const handleButtonClick = () => {
    if (!deletedLicences.value && !savedLicences.value) {
      saveChanges()
    } else {
      handleClose()
    }
  }

  function handleAbortButtonClick() {
    visibility.value = false
    emit('abort')
  }

  function getColumnLicense(email: string): LicenceOut {
    return savedLicenceResponses.value.find(
      (licence: LicenceOut) => licence.email === email
    ) as LicenceOut // Should never be undefined
  }

  function getDefaultPassword(email: string): string {
    return getColumnLicense(email)?.application_specific?.default_password
  }

  // computed return true if deletion was successfully, false if not, and undefined while loading
  const licenceIsDeleted = (email: string): boolean | undefined => {
    return deletedLicenceStates.value.find(
      (licence) => licence.licenceEmail.toLowerCase() === email.toLowerCase()
    )?.successfully
  }

  // This computed returns true if all licence deletes where successfully
  const allLicencesDeleted = computed(
    () =>
      !deletedLicenceStates.value.some(
        (licence) => licence.successfully === false
      )
  )

  // computed return true if deletion was successfully, false if not, and undefined while loading
  const licenceIsSaved = (email: string): boolean | undefined => {
    return savedLicenceStates.value.find(
      (licence) => licence.licenceEmail.toLowerCase() === email.toLowerCase()
    )?.successfully
  }

  // This computed returns true if all licence saves where successfully
  const allLicencesSaved = computed(
    () =>
      !savedLicenceStates.value.some(
        (licence) => licence.successfully === false
      )
  )

  const deleteLicenceLoading = ref<boolean>(false)
  const deletedLicences = ref<boolean>(false)
  const deletedLicenceStates = ref<RequestState[]>([])

  const saveLicenceLoading = ref<boolean>(false)
  const savedLicences = ref<boolean>(false)
  const savedLicenceResponses = ref<LicenceOut[]>([])
  const savedLicenceStates = ref<RequestState[]>([])

  const handleClose = () => {
    deleteLicenceLoading.value = false
    deletedLicences.value = false
    deletedLicenceStates.value = []

    saveLicenceLoading.value = false
    savedLicences.value = false
    savedLicenceStates.value = []

    visibility.value = false
    emit('refetchLicences')
  }

  function saveChanges() {
    if (props.deleteLicences.length === 0) {
      deleteLicenceLoading.value = true
      deletedLicences.value = true
    }
    if (props.saveLicences.length === 0) {
      saveLicenceLoading.value = true
      //savedLicences.value = true
    }

    deleteLicenceLoading.value = true
    saveLicenceLoading.value = true

    const deletePromises: Promise<unknown>[] = []
    const savePromises: Promise<unknown>[] = []

    props.deleteLicences.forEach((licence) => {
      deletedLicenceStates.value.push({
        licenceEmail: licence.email,
        successfully: undefined,
      })
      const promise = licenseStore
        .deleteLicense(licence._id!)
        .then(() => {
          // Check if deleteUsersStates contains tempUser.email and if not return
          const elementIndex = deletedLicenceStates.value.findIndex(
            (tempLicence) =>
              licence.email.toLowerCase() ===
              tempLicence.licenceEmail.toLowerCase()
          )
          if (elementIndex === -1) return

          // Update deleteSuccessfully to true
          deletedLicenceStates.value[elementIndex] = {
            ...deletedLicenceStates.value[elementIndex],
            successfully: true,
          }
        })
        .catch(() => {
          // Check if deleteUsersStates contains tempUser.email and if not return
          const elementIndex = deletedLicenceStates.value.findIndex(
            (tempLicence) =>
              licence.email.toLowerCase() ===
              tempLicence.licenceEmail.toLowerCase()
          )
          if (elementIndex === -1) return

          // Update deleteSuccessfully to true
          deletedLicenceStates.value[elementIndex] = {
            ...deletedLicenceStates.value[elementIndex],
            successfully: false,
          }
          return Promise.reject()
        })
      deletePromises.push(promise)
    })

    props.saveLicences.forEach((licence) => {
      savedLicenceStates.value.push({
        licenceEmail: licence.email,
        successfully: undefined,
      })

      let promise = null

      if (
        databaseLicences.value.find(
          (dbLicence: LicenceOut) => dbLicence.email === licence.email
        )
      ) {
        promise = licenseStore
          .updateLicense(licence._id!, licence)
          .then((response) => {
            // Check if deleteUsersStates contains tempUser.email and if not return
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            savedLicenceResponses.value.push({
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(response as any),
              application_specific: {
                default_password: '-',
              },
            })

            const elementIndex = savedLicenceStates.value.findIndex(
              (tempLicence) =>
                licence.email.toLowerCase() ===
                tempLicence.licenceEmail.toLowerCase()
            )

            if (elementIndex === -1) return

            // Update deleteSuccessfully to true
            savedLicenceStates.value[elementIndex] = {
              ...savedLicenceStates.value[elementIndex],
              successfully: true,
            }
          })
          .catch(() => {
            // Check if deleteUsersStates contains tempUser.email and if not return
            const elementIndex = savedLicenceStates.value.findIndex(
              (tempLicence) =>
                licence.email.toLowerCase() ===
                tempLicence.licenceEmail.toLowerCase()
            )
            if (elementIndex === -1) return

            // Update deleteSuccessfully to true
            savedLicenceStates.value[elementIndex] = {
              ...savedLicenceStates.value[elementIndex],
              successfully: false,
            }
            return Promise.reject()
          })
        savePromises.push(promise)
      } else {
        promise = licenseStore
          .createLicense(licence)
          .then((response) => {
            if (!response) return
            // Check if deleteUsersStates contains tempUser.email and if not return
            if (response.data) savedLicenceResponses.value.push(response.data)

            const elementIndex = savedLicenceStates.value.findIndex(
              (tempLicence) =>
                licence.email.toLowerCase() ===
                tempLicence.licenceEmail.toLowerCase()
            )
            if (elementIndex === -1) return

            // Update deleteSuccessfully to true
            savedLicenceStates.value[elementIndex] = {
              ...savedLicenceStates.value[elementIndex],
              successfully: true,
            }
          })
          .catch(() => {
            // Check if deleteUsersStates contains tempUser.email and if not return
            const elementIndex = savedLicenceStates.value.findIndex(
              (tempLicence) =>
                licence.email.toLowerCase() ===
                tempLicence.licenceEmail.toLowerCase()
            )
            if (elementIndex === -1) return

            // Update deleteSuccessfully to true
            savedLicenceStates.value[elementIndex] = {
              ...savedLicenceStates.value[elementIndex],
              successfully: false,
            }
            return Promise.reject()
          })
        savePromises.push(promise)
      }
    })

    Promise.all([...deletePromises, ...savePromises])
      .then(() => {
        sendToast(
          i18n.t(
            'notifications.applicationsDetailView.licences.saveLicencesSuccess.title'
          ),
          i18n.t(
            'notifications.applicationsDetailView.licences.saveLicencesSuccess.message'
          )
        )
      })
      .catch(() => {
        sendToast(
          i18n.t(
            'notifications.applicationsDetailView.licences.saveLicencesFailed.title'
          ),
          i18n.t(
            'notifications.applicationsDetailView.licences.saveLicencesFailed.message'
          ),
          'error'
        )
      })
      .finally(() => {
        saveLicenceLoading.value = false
        deleteLicenceLoading.value = false

        savedLicences.value = true
        deletedLicences.value = true
      })
  }

  const pages = import.meta.glob(
    '/src/components/BaseApplicationTableColumns/modules/**.vue'
  )
  const existingRequirementComponents = Object.keys(pages).map((key) => {
    return (
      key.match(
        '\\/src\\/components\\/BaseApplicationTableColumns\\/modules\\/(.*)\\.vue'
      ) as RegExpMatchArray
    )[1].toLowerCase()
  })

  const isTableColumnComponentExisting = computed(() =>
    existingRequirementComponents.includes(
      'column' + props.softwareName.toLowerCase()
    )
  )
</script>
