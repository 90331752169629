<!--
  User Form
  This component is used to display a form for a user.
  It is used in the UserCreate and UserEdit components.
 -->

<template>
  <el-form
    ref="formRef"
    :model="user"
    :rules="rules"
    require-asterik-position="right"
    :label-width="labelWidth">
    <div class="form-names">
      <!-- First Name -->
      <el-form-item :label="i18n.t('general.firstName')">
        <el-input v-model="user.first_name" />
      </el-form-item>
      <!-- Last Name -->
      <el-form-item :label="i18n.t('general.lastName')">
        <el-input v-model="user.last_name" />
      </el-form-item>
    </div>
    <!-- E-Mail -->
    <el-form-item :label="i18n.t('email')" prop="email">
      <el-input v-model="user.email" />
    </el-form-item>
    <!-- Phone -->
    <el-form-item :label="i18n.t('phone')">
      <el-input v-model="user.phone"></el-input>
    </el-form-item>
    <!-- Employee ID -->
    <el-form-item :label="i18n.t('general.employeeId')">
      <el-input v-model="user.employee_id"></el-input>
    </el-form-item>
    <span v-for="item in extraItems" :key="item.i18nLabel">
      <el-form-item
        :label="returnLabel(item.i18nLabel, item.label)"
        :data-tutorial-id="item.dataTutorialId">
        <slot :name="`extra-item-${item.name}`"> </slot>
      </el-form-item>
    </span>
  </el-form>
</template>

<script setup lang="ts">
  import {
    FormInstance,
    FormRules,
    FormValidateCallback,
    FormValidationResult,
  } from 'element-plus'

  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import { AccountUpdateIn } from '@/client'

  import UserForm from './UserForm.vue'

  export type UserFormInstance = ComponentExposed<typeof UserForm>

  export type ExtraFormItem = {
    name: string
    required?: boolean
    i18nLabel?: string
    label?: string
    dataTutorialId?: string
  }

  const props = withDefaults(
    defineProps<{
      user: AccountUpdateIn
      labelWidth?: string
      extraItems?: ExtraFormItem[]
    }>(),
    { labelWidth: '8rem', extraItems: () => [] }
  )
  const emit = defineEmits(['update:user'])

  const i18n = useI18n()

  const formRef = ref<FormInstance>()

  const user = computed({
    get: () => props.user,
    set: (value) => {
      emit('update:user', value)
    },
  })

  const rules: FormRules = {
    email: [
      {
        required: true,
        message: i18n.t('general.form.requireEmail'),
        trigger: ['blur'],
      },
      {
        type: 'email',
        message: i18n.t('general.form.validEmail'),
        trigger: ['blur'],
      },
    ],
  }

  function validate(callback?: FormValidateCallback) {
    if (!formRef.value) return Promise.resolve(false) as FormValidationResult
    return formRef.value.validate(callback)
  }

  function returnLabel(i18nLabel?: string, label?: string): string {
    if (i18nLabel) return i18n.t(i18nLabel)
    if (label) return label
    return ''
  }

  defineExpose({ validate })
</script>

<style scoped>
  .form-names {
    display: flex;
    justify-content: space-between;
  }
</style>
