/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OptimizationStatus {
    OPEN = 'open',
    CLOSED = 'closed',
    DEPRECATED = 'deprecated',
    HIDDEN = 'hidden',
}
