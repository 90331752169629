<template>
  <div
    :class="{ clickable: props.clickable }"
    class="data-source-stats-card"
    @click="emit('click')">
    <div :class="['sm-icon', `icon-${props.color}`]">
      <v-icon
        :name="props.icon"
        scale="1.4"
        :style="{ fill: `var(--sm-${props.color})` }" />
    </div>
    <div class="data-source-stats-card-content">
      <p class="m-0 text-sm text-contrast-muted">
        {{ i18n.t(props.displayI18nKey) }}
      </p>

      <div v-if="props.displayNumber || props.displayNumber === 0">
        <h2 class="text-2xl">{{ getDispalyString(props.displayNumber) }}</h2>
      </div>
      <div v-else>
        <h2 class="text-2xl"><SmSkeleton /></h2>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import SmSkeleton from './sm/SmSkeleton.vue'
  import { getCorrectedPrice } from '@/common/licenceModel'

  const i18n = useI18n()

  const emit = defineEmits(['click'])

  function getDispalyString(text: string | number | null) {
    if (text === null) return i18n.t('notAvailable')
    // If string --> return string
    if (typeof text === 'string') return text

    if (props.isPrice) return getCorrectedPrice(text)
    else return text.toLocaleString()
  }

  const props = defineProps({
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    displayI18nKey: {
      type: String,
      required: true,
    },
    displayNumber: {
      type: [String, Number],
      required: false,
      default: null,
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPrice: {
      type: Boolean,
      required: false,
      default: false,
    },
  })
</script>

<style scoped lang="scss">
  .data-source-stats-card {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 13px;
  }

  .data-source-stats-card-content {
    display: flex;
    flex-direction: column;

    h2 {
      font-weight: 500;
    }
  }

  .sm-icon {
    &::before {
      background-color: #93bd55;
    }
  }

  // Colors
  .icon-yellow {
    &::before {
      background-color: var(--sm-yellow);
    }
  }

  .icon-purple {
    &::before {
      background-color: var(--sm-purple);
    }
  }

  .icon-primary {
    &::before {
      background-color: var(--sm-primary);
    }
  }

  .icon-red {
    &::before {
      background-color: var(--sm-red);
    }
  }

  .clickable {
    cursor: pointer;
  }
</style>
