<template>
  <div class="container">
    <div class="message-line">
      <slot />
      <SmSpinner v-if="requestLoading" size="small" />
    </div>
    <SmDialogMessage
      :visible="messageData.visible"
      :type="messageData.type"
      :message="messageData.message" />
  </div>
</template>

<script setup lang="ts" generic="T">
  import { useI18n } from 'vue-i18n'

  import { getApiErrorMessage } from '@/common/util/apiError'
  import SmDialogMessage, {
    DialogMessageTypes,
  } from '@/components/sm/SmDialogMessage.vue'

  const i18n = useI18n()

  const status = defineModel('status', {
    type: Object as PropType<'not-started' | 'loading' | 'finished'>,
    default: 'not-started',
    required: false,
  })

  const props = withDefaults(
    defineProps<{
      requestOnSetup?: boolean
      showLoadingIcon?: boolean
      successMessage: string
      request: () => Promise<T>
    }>(),
    {
      requestOnSetup: true,
      showLoadingIcon: true,
    }
  )

  const requestLoading = ref(false)

  const messageData = ref<{
    type: DialogMessageTypes
    visible: boolean
    message: string
  }>({ type: 'success', visible: false, message: '' })

  function executeRequest(): Promise<T> {
    requestLoading.value = true
    status.value = 'loading'
    return props
      .request()
      .then(
        (res) => {
          messageData.value.type = 'success'
          messageData.value.message = props.successMessage
          return res
        },
        (err) => {
          messageData.value.type = 'error'
          messageData.value.message = getApiErrorMessage(
            err,
            i18n as ReturnType<typeof useI18n>
          )
          return new Promise<T>((resolve, reject) => {
            reject(err)
          })
        }
      )
      .finally(() => {
        status.value = 'finished'
        messageData.value.visible = true
        requestLoading.value = false
      })
  }

  if (props.requestOnSetup) executeRequest()

  defineExpose({ executeRequest })
</script>

<style scoped lang="scss">
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .message-line {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
</style>
