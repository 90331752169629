<template>
  <div>
    <div class="software-more-filter">
      <div class="filter-grid">
        <span>{{ i18n.t('hideWithoutCosts') }}</span>
        <SmSwitch
          v-model="moreApplicationsFilter.hideWithoutCosts"
          style="font-size: 0.75rem" />
      </div>

      <div class="filter-grid">
        <span>{{ i18n.t('hideWithCosts') }}</span>
        <SmSwitch
          v-model="moreApplicationsFilter.hideWithCosts"
          style="font-size: 0.75rem" />
      </div>

      <div class="filter-grid">
        <span>{{ i18n.t('hideWithoutUser') }}</span>
        <SmSwitch
          v-model="moreApplicationsFilter.hideWithoutUser"
          style="font-size: 0.75rem" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'
  import { MoreApplicationsFilter } from './ApplicationsTable.vue'

  const i18n = useI18n()

  const moreApplicationsFilter = defineModel<MoreApplicationsFilter>(
    'moreApplicationsFilter',
    {
      required: true,
    }
  )
</script>
<style>
  .software-more-filter {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .filter-grid {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }
</style>
