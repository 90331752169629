<template>
  <div class="sm-progress-bar bg-gray-100 dark:bg-gray-700"></div>
</template>

<script lang="ts" setup>
  const props = defineProps({
    value: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    color: {
      type: String,
      default: 'var(--sm-primary)',
    },
    size: {
      type: String,
      default: 'medium',
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  })

  const value = computed(() => {
    if (props.value > props.max) {
      return props.max
    }
    return props.value
  })

  const percentage = computed(() => {
    return (value.value / props.max) * 100
  })

  const percentageString = computed(() => {
    return `${percentage.value}%`
  })
</script>

<style lang="scss">
  // Progress bar
  .sm-progress-bar {
    width: 110px;
    height: 10px;
    border-radius: 5px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: v-bind(percentageString);
      background-color: v-bind(color);
      border-radius: 5px;
    }
  }
</style>
