<template>
  <div class="chart-box-wrapper">
    <div class="chart-wrapper">
      <OptimizationsCardChartBoxChartComponent :optimizations="optimizations" />
    </div>
    <div class="text-wrapper">
      <span class="title">{{ optimizations.length }}</span>
      <span class="sub-title">{{
        i18n.t('views.dashboard.optimizations.subTitle')
      }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { Optimization } from '@/client'

  defineProps({
    optimizations: {
      type: Array as PropType<Optimization[]>,
      required: true,
    },
  })

  const i18n = useI18n()
</script>

<style lang="scss" scoped>
  .chart-box-wrapper {
    height: calc(100% - 60px);

    .chart-wrapper {
      margin-bottom: 1rem;
      margin-top: 1rem;
      height: 100%;

      canvas {
        height: 100%;
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      float: left;

      .title {
        text-align: left;
        font-size: 2.3rem;
        font-weight: 600;
        line-height: 2.3rem;
      }

      .sub-title {
        text-align: left;
        font-size: 0.8rem;
        color: var(--el-text-color-secondary);
        font-weight: 600;
      }
    }
  }
</style>
