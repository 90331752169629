<template>
  <div class="container">
    <h4>{{ i18n.t('priceDivision.title') }}</h4>
    <p>
      {{
        i18n.t('priceDivision.text', {
          active: props.totalActiveQuota,
          total: props.externalQuota,
        })
      }}
    </p>
    <p v-if="props.syncedPrice">
      {{
        i18n.t('priceDivision.sync_info', {
          syncPrice: props.syncedPrice.description,
        })
      }}
    </p>

    <div class="quota-bar-background">
      <template v-for="form in props.priceInformations" :key="form._id">
        <template
          v-if="
            !checkContractDate(form) && form.billing_unit === BillingUnit.QUOTA
          ">
          <span
            v-if="form.quota ?? 0 > 0"
            class="quota-bar"
            :style="{
              width: `${((form.quota ?? 0) / (props.externalQuota ?? 1)) * 100}%`,
            }">
            <SmTooltip>
              <template #content>
                <p>{{ form.description }}:</p>
                <p>
                  {{ form.quota }} /
                  {{ props.externalQuota }}
                </p>
              </template>
              <span style="display: block; width: 100%; height: 12px"></span>
            </SmTooltip>
          </span>

          <span v-else class="quota-bar-sync">
            <SmTooltip>
              <template #content>
                <p>{{ i18n.t('priceDivision.sync_model') }}</p>
                <p>{{ form.description }}</p>
              </template>
              <span style="display: block; width: 100%; height: 8px"></span>
            </SmTooltip>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { BillingUnit, PriceInformation, SoftwareLicense } from '@/client'
  import dayjs from 'dayjs'
  import { useI18n } from 'vue-i18n'

  export type LicenseModelFormModel = {
    license: SoftwareLicense
  }
  const i18n = useI18n()

  export type Props = {
    syncedPrice?: PriceInformation | undefined
    totalActiveQuota?: number
    externalQuota?: number | undefined
    priceInformations?: PriceInformation[] | undefined
  }

  const props = withDefaults(defineProps<Props>(), {
    syncedPrice: undefined,
    totalActiveQuota: 0,
    externalQuota: undefined,
    priceInformations: undefined,
  })

  const checkContractDate = (item: PriceInformation) => {
    const isBetween = dayjs().isBetween(item.from_date, item.end_date)
    const isAfter = dayjs().isAfter(item.end_date)
    const isBefore = dayjs().isBefore(item.from_date)

    if (isBetween || !item.from_date) {
      return false
    }

    if (isAfter) {
      return i18n.t('deprecated')
    } else if (isBefore) {
      return i18n.t('upcoming')
    }
  }
</script>

<style scoped lang="scss">
  .container {
    padding: 2rem;
    background: theme('colors.gray.100');
    border-radius: var(--border-radius);
  }

  .quota-bar {
    border-right: 2px solid theme('colors.gray.100');

    &:last-child {
      border-right: unset;
    }
    &:nth-child(4n + 1) {
      background-color: var(--sm-purple);
    }

    &:nth-child(4n + 2) {
      background-color: var(--sm-primary);
    }

    &:nth-child(4n + 3) {
      background-color: var(--sm-yellow);
    }

    &:nth-child(4n + 4) {
      background-color: var(--sm-red);
    }

    transition: width 0.3s ease;
  }

  .quota-bar-sync {
    width: 0px;
    background-color: theme('colors.gray.500');
    order: 1;
    flex-grow: 1;
  }

  .quota-bar-background {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: theme('colors.gray.200');
    border-radius: 4px;
    overflow: hidden;
    margin-top: 12px;
  }

  .dark .quota-bar-background {
    background: theme('colors.gray.700');
  }

  .dark .container {
    background: theme('colors.gray.800');
  }

  .dark .quota-bar {
    border-color: theme('colors.gray.800');
  }
</style>
