/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SoftwareStatus {
    ALPHA = 'Alpha',
    BETA = 'Beta',
    COMING_SOON = 'Coming-Soon',
    WORK_IN_PROGRESS = 'Work-In-Progress',
    ACTIVE = 'Active',
}
