<template>
  <div>
    <div class="instructions-wrapper">
      <div class="instructions">
        <ol class="instructions-list">
          <li class="instructions-list-item">
            <a @click="downloadTemplate">{{
              i18n.t('licenseCsvImport.downloadTheTemplate.one')
            }}</a>
            {{ i18n.t('licenseCsvImport.downloadTheTemplate.two') }}
          </li>
          <li class="instructions-list-item">
            {{ i18n.t('licenseCsvImport.addTheFile.one') }}
            <b>{{ i18n.t('licenseCsvImport.addTheFile.two') }}</b>
          </li>
          <li class="instructions-list-item">
            {{ i18n.t('licenseCsvImport.submitTheImport.one') }}
            <b> {{ i18n.t('licenseCsvImport.submitTheImport.two') }} </b>
          </li>
        </ol>
        <SmButton class="template-download-button" @click="downloadTemplate">
          <div>
            <v-icon name="io-download" />
            {{ i18n.t('licenseCsvImport.downloadTemplate') }}
          </div>
        </SmButton>
      </div>
    </div>
    <el-upload
      v-show="fileList.length == 0"
      ref="uploadRef"
      v-model:file-list="fileList"
      class="upload-content"
      :limit="1"
      drag
      :on-exceed="handleExceed"
      accept=".csv"
      :auto-upload="false"
      action="">
      <div class="file-input-text">
        {{ i18n.t('licenseCsvImport.dragFileInside.one') }}
        <a>{{ i18n.t('licenseCsvImport.dragFileInside.two') }}</a>
      </div>
      <template #tip>
        {{ i18n.t('licenseCsvImport.fileNeedsSpecificFormat') }}
      </template>
    </el-upload>
    <span v-if="fileList.length > 0">
      <h3>{{ i18n.t('licenseCsvImport.importSettings') }}</h3>
      <div class="import-settings">
        <div class="import-settings-content">
          <span class="import-settings-content-boxes">
            <div>{{ i18n.t('licenseCsvImport.pleaseChooseOptions') }}</div>
            <div class="select-options">
              <SmCheckbox v-model:modelValue="deleteMissingLicenses">
                <div class="help-text-wrapper">
                  {{ i18n.t('licenseCsvImport.deleteMissingLicenses') }}
                  <SmTooltip :content="deleteLicensesHelpText"
                    ><v-icon name="md-help-round"
                  /></SmTooltip>
                </div>
              </SmCheckbox>
              <SmCheckbox v-model:modelValue="deleteMissingSoftwareLicenses">
                <div class="help-text-wrapper">
                  {{ i18n.t('licenseCsvImport.deleteMissingLicenseModels') }}
                  <SmTooltip :content="deleteSoftwareLicensesHelpText"
                    ><v-icon name="md-help-round"
                  /></SmTooltip></div
              ></SmCheckbox>
            </div>
          </span>
          <div class="import-settings-content-icons">
            <div class="icon-card">
              <v-icon scale="3" name="md-description-round" />
              <TextMultiline class="filename">
                {{ fileList[0].name }}
              </TextMultiline>
            </div>
          </div>
        </div>
        <div class="import-settings-buttons">
          <SmButton outline @click="resetFiles">
            {{ i18n.t('reset') }}
          </SmButton>
          <SmButton :loading="loading" @click="importLicenses">
            {{ i18n.t('licenseCsvImport.importLicenses') }}
          </SmButton>
        </div>
      </div>
    </span>
  </div>
</template>

<script setup lang="ts">
  import {
    genFileId,
    UploadInstance,
    UploadRawFile,
    UploadUserFile,
  } from 'element-plus'

  import { useI18n } from 'vue-i18n'

  import { ImporterSupportedFile, ImportLicencesService } from '@/client'
  import { getApiErrorMessage } from '@/common/util/apiError'
  import { sendToast } from '@/components/sm/SmNotification'
  import SmTooltip from '@/components/sm/SmTooltip.vue'

  const props = defineProps<{ softwareId: string }>()
  const emit = defineEmits<{ (e: 'successfulImport'): void }>()
  const i18n = useI18n()

  const uploadRef = ref<UploadInstance>()

  const loading = ref(false)
  const fileList = ref<UploadUserFile[]>([])
  const deleteMissingLicenses = ref(true)
  const deleteMissingSoftwareLicenses = ref(false)
  const deleteLicensesHelpText = i18n.t(
    'licenseCsvImport.deleteLicensesHelpText'
  )
  const deleteSoftwareLicensesHelpText = i18n.t(
    'licenseCsvImport.deleteLicenseModelsHelpText'
  )

  function importLicenses() {
    loading.value = true
    ImportLicencesService.importLicencesToSoftwareApiV1ImportLicencesSoftwareIdPost(
      {
        softwareId: props.softwareId,
        formData: {
          file: new Blob([fileList.value[0]!.raw as UploadRawFile], {
            type: 'text/csv',
          }),
          remove_missing_licences: deleteMissingLicenses.value,
          remove_missing_software_licenses: deleteMissingSoftwareLicenses.value,
        },
      }
    )
      .then(() => {
        sendToast(
          i18n.t('licenseCsvImport.importSuccess'),
          undefined,
          'success'
        )
        emit('successfulImport')
      })
      .catch((err) => {
        sendToast(
          getApiErrorMessage(err, i18n as ReturnType<typeof useI18n>),
          undefined,
          'error'
        )
      })
      .finally(() => {
        loading.value = false
      })
  }
  function downloadTemplate() {
    ImportLicencesService.getLicenceImportExampleFileApiV1ImportLicencesExampleGet(
      {
        fileType: ImporterSupportedFile.CSV,
      }
    ).then((csvString) => {
      const url = 'data:text/csv;charset=utf-8,' + encodeURI(csvString)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = i18n.t('licenseCsvImport.templateFileName')
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }

  function handleExceed(files: UploadUserFile[]) {
    uploadRef.value!.clearFiles()
    const file = files[0] as UploadRawFile
    file.uid = genFileId()
    uploadRef.value!.handleStart(file)
  }

  function resetFiles() {
    uploadRef.value!.clearFiles()
  }
</script>
<style scoped lang="scss">
  .file-input-text {
    a {
      color: var(--sm-primary);
    }
  }
  .instructions-wrapper {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }
  .template-download-button {
    margin-right: auto;
  }
  .instructions {
    display: flex;
    align-items: center;
  }
  .instructions-list {
    padding-left: 0;
    list-style: inside decimal;

    &-item {
      padding-bottom: 0.5rem;
    }
  }
  .upload-content {
    margin-bottom: 1rem;
  }
  .import-settings {
    margin-top: 1rem;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    &-content {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 1rem;

      &-boxes {
        flex-basis: 2;
        flex-shrink: 0;
      }
      &-icons {
        margin-right: auto;
        margin-left: auto;
      }
    }

    &-buttons {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
  }
  .icon-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .select-options {
    margin-top: 0.5rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  a {
    cursor: pointer;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }

  .help-text-wrapper {
    display: flex;
    gap: 0.5rem;
  }
</style>
