<template>
  <div class="container">
    <!--  Active Step Content -->
    <div class="content">
      <div v-for="content in steps" :key="content.key">
        <slot
          v-if="content.key == activeStepKey"
          :key="content.key"
          :name="`step-${content.key}`">
        </slot>
      </div>
    </div>
    <!-- Buttons for back-step, next-step, finish-step -->
    <span v-for="content in steps" :key="content.key">
      <div
        v-if="!hideSteps && content.key == activeStepKey"
        class="step-buttons">
        <slot
          v-if="stepIndex != 0"
          :back="handleBackClick"
          :name="`step-${activeStepKey}-back-button`">
          <div
            class="step-buttons--back"
            :class="{ 'step-buttons--disabled': content.backButtonDisabled }"
            @click="handleBackClick">
            <v-icon
              name="md-arrowforwardios-round"
              scale="1"
              flip="horizontal"
              style="color: inherit" />
            {{ content.backText || 'Back' }}
          </div>
        </slot>
        <slot
          v-if="stepIndex != steps.length - 1"
          :go-to-next-step="goToNextStep"
          :name="`step-${activeStepKey}-next-button`">
          <div
            class="step-buttons--next step-buttons--next--black"
            :class="{ 'step-buttons--disabled': content.nextButtonDisabled }"
            @click="goToNextStep">
            {{ content.nextText || 'Next' }}
            <v-icon
              name="md-arrowforwardios-round"
              scale="1"
              style="color: inherit" />
          </div>
        </slot>
        <div v-if="stepIndex == steps.length - 1 && $slots['finish-button']">
          <slot name="finish-button"> </slot>
        </div>
      </div>
    </span>
  </div>
</template>

<script setup lang="ts">
  export type StepContent = {
    key: string
    nextText?: string
    backText?: string
    nextButtonDisabled?: boolean
    backButtonDisabled?: boolean
    nextStepValidator?: () => Promise<boolean>
  }

  const props = withDefaults(
    defineProps<{
      hideSteps?: boolean
      steps: StepContent[]
      contentHeight?: string
    }>(),
    {
      hideSteps: false,
      contentHeight: '100%',
    }
  )

  const activeStepKey = defineModel<string>('activeStepKey', {
    required: true,
  })

  async function goToNextStep() {
    const currentStep = props.steps[stepIndex.value]

    if (
      currentStep.nextStepValidator !== undefined &&
      !(await currentStep.nextStepValidator())
    )
      return

    if (props.steps[stepIndex.value].nextButtonDisabled) return

    incrementStep()
  }

  function incrementStep() {
    if (stepIndex.value == props.steps.length - 1) return

    activeStepKey.value = props.steps[stepIndex.value + 1].key
  }

  function handleBackClick() {
    if (props.steps[stepIndex.value].backButtonDisabled) return

    decrementStep()
  }

  function decrementStep() {
    if (stepIndex.value == 0) return
    activeStepKey.value = props.steps[stepIndex.value - 1].key
  }

  const stepIndex = computed(() => {
    return props.steps.findIndex((c) => {
      return c.key == activeStepKey.value
    })
  })

  defineExpose({ stepIndex })
</script>

<style scoped lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .content,
  .step-buttons {
    overflow: auto;
    max-height: v-bind(contentHeight);
    width: 100%;
  }

  .step-buttons {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--back,
    &--next {
      display: flex;
      align-items: center;
      gap: 0.4rem;

      margin: 0 2rem 0.5rem 2rem;
      font-size: var(--font-size);
    }

    &--back:not(&--disabled),
    &--next:not(&--disabled) {
      cursor: pointer;
    }

    &--back:hover:not(&--disabled),
    &--next:hover:not(&--disabled) {
      scale: 1.05;
    }

    &--back:active:not(&--disabled),
    &--next:active:not(&--disabled) {
      scale: 1.01;
    }

    &--back:not(&--disabled) {
      margin-right: auto;
      color: var(--sm-contrast);
    }

    &--next {
      margin-left: auto;

      &--black {
        color: var(--sm-contrast);
      }
    }

    &--disabled {
      color: var(--sm-contrast-muted);
    }
  }
</style>
