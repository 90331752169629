/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_send_feedback_api_v1_intern_feedback_post } from '../models/Body_send_feedback_api_v1_intern_feedback_post';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FeedbackService {
    /**
     * Send Feedback
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendFeedbackApiV1InternFeedbackPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: Body_send_feedback_api_v1_intern_feedback_post,
        xTransactionId?: (string | null),
    }): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/intern/feedback',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
