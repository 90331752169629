<template>
  <div class="container">
    <SmDialogMessage
      :message="error.message"
      :visible="error.visible"
      :type="error.type" />

    <el-form
      ref="formRef"
      label-width="8rem"
      :model="accountGroup"
      :rules="rules">
      <!-- Name -->
      <el-form-item label="Name" prop="name">
        <el-input v-model="accountGroup.name" />
      </el-form-item>

      <!-- Color -->
      <el-form-item :label="i18n.t('color')">
        <div class="form-item-color">
          <div class="color-select">
            <div class="color-selector"></div>
          </div>
          <div v-if="accountGroup.name" class="tag-wrapper">
            <TagComponent
              :tag="{
                color: {
                  r: accountGroup.color?.r || fallBackColor.r,
                  g: accountGroup.color?.g || fallBackColor.g,
                  b: accountGroup.color?.b || fallBackColor.b,
                },
                name: accountGroup.name,
              }" />
          </div>
        </div>
      </el-form-item>

      <!-- Description -->
      <el-form-item :label="i18n.t('description')">
        <el-input
          v-model="accountGroup.notes"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }" />
      </el-form-item>
    </el-form>

    <!-- Azure Sync -->
    <GroupAddMicrosoftForm
      v-if="!('created' in accountGroup)"
      ref="groupAddMicrosoftForm"
      v-model:account-group="accountGroup as AccountGroupIn" />
  </div>
</template>

<script setup lang="ts">
  import { generateFallbackColor } from '@/common/colors'
  import '@simonwep/pickr/dist/themes/nano.min.css' // 'nano' theme

  import Pickr from '@simonwep/pickr'
  import { useI18n } from 'vue-i18n'

  import {
    AccountGroup,
    AccountGroupIn,
    AccountGroupUpdateIn,
    RGB_Output,
    RGBUpdate,
  } from '@/client'
  import GroupAddMicrosoftForm from './GroupAddMicrosoftForm.vue'
  import { FormRules, ElForm } from 'element-plus'

  const i18n = useI18n()
  const emit = defineEmits<{
    next: [value: AccountGroupIn | AccountGroupUpdateIn]
    updateSync: [newValue: boolean]
  }>()
  const fallBackColor = generateFallbackColor()

  const formRef = ref<typeof ElForm | null>(null)

  const accountGroup = defineModel<
    AccountGroupIn | AccountGroupUpdateIn | AccountGroup
  >('accountGroup', {
    required: true,
  })

  const rules: FormRules = {
    name: [
      {
        required: true,
        message: i18n.t('general.form.requireName'),
        trigger: ['blur', 'change'],
      },
    ],
  }

  defineProps({
    editMode: {
      type: Boolean,
      default: false,
    },
  })

  let pickr: Pickr | null = null
  onMounted(() => {
    pickr = Pickr.create({
      el: '.color-selector',
      theme: 'nano', // or 'monolith', or 'nano'
      default: accountGroup.value.color
        ? toRGBString({ ...fallBackColor, ...accountGroup.value.color })
        : '#409EFF',
      lockOpacity: true,
      comparison: false,
      components: {
        // Main components
        preview: true,
        opacity: false,
        hue: true,
        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          hsla: true,
          hsva: true,
          cmyk: true,
          input: true,
          clear: false,
          save: false,
        },
      },
    })

    pickr.on('change', (color: { toRGBA: () => number[] }) => {
      accountGroup.value.color = {
        r: Math.floor(color.toRGBA()[0]),
        g: Math.floor(color.toRGBA()[1]),
        b: Math.floor(color.toRGBA()[2]),
      }
    })
  })

  const error = ref({
    message: '',
    visible: false,
    type: 'success' as 'success' | 'error',
  })

  function requestNext() {
    if (accountGroup.value.name && accountGroup.value.name.length < 1) {
      error.value = {
        message: 'Der Name muss mindestens 3 Zeichen lang sein.',
        visible: true,
        type: 'error',
      }
    } else {
      emit('next', accountGroup.value)
    }
  }

  function toRGBString(color: RGB_Output | RGBUpdate) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, 1)`
  }

  // Validation
  const groupAddMicrosoftForm =
    ref<InstanceType<typeof GroupAddMicrosoftForm>>()
  function checkForm(): boolean {
    if (!groupAddMicrosoftForm.value) return true
    return groupAddMicrosoftForm.value.checkForm()
  }

  defineExpose({
    requestNext,
    formRef,
    checkForm,
  })
</script>

<style scoped lang="scss">
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .form-item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
  }

  .color-select {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }

  .form-item-color {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .color-item {
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    opacity: 0.5;

    &--active {
      transform: scale(1.09);
      opacity: 0.7;
    }
  }

  .tag-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
  }
</style>
