import { Licence_Input, LicenceUpdateIn, UserLicencesService } from '@/client'
import { useFetch } from '@/common/useFetch'

//#region New Filter
export function escapeRegex(string: string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

export async function _deleteLicense(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.deleteApiV1SoftwareLicencesLicenceIdDelete({
        licenceId: id,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _postLicense(license: Licence_Input) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.postLicenceApiV1SoftwareLicencesPost({
        requestBody: license,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _patchLicense(id: string, license: LicenceUpdateIn) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.patchLicenceApiV1SoftwareLicencesLicenceIdPatch({
        licenceId: id,
        requestBody: license,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _disableLicense(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.disableLicenceApiV1SoftwareLicencesLicenceIdDisablePost(
        {
          licenceId: id,
        }
      ),
    null,
    { sendToast: true }
  )

  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _enableLicense(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UserLicencesService.enableLicenceApiV1SoftwareLicencesLicenceIdEnablePost(
        {
          licenceId: id,
        }
      ),
    null,
    { sendToast: true }
  )

  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}
