/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobExecutionBase_Literal_software_user_enable__BackgroundJobSoftwareUserEnableExecutionData_ } from './BackgroundJobExecutionBase_Literal_software_user_enable__BackgroundJobSoftwareUserEnableExecutionData_';
import type { BackgroundJobSoftwareUserEnableData } from './BackgroundJobSoftwareUserEnableData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
export type BackgroundJobSoftwareUserEnable = {
    created: string;
    updated: string;
    deleted: boolean;
    deleted_at: (string | null);
    _id: string;
    type: BackgroundJobSoftwareUserEnable.type;
    status: BackgroundJobStatus;
    executions: Array<BackgroundJobExecutionBase_Literal_software_user_enable__BackgroundJobSoftwareUserEnableExecutionData_>;
    data: BackgroundJobSoftwareUserEnableData;
    last_executed_at: (string | null);
    next_execution_at: (string | null);
    execution_token: string;
    created_by_job_id: (string | null);
    created_by_identity_id: (string | null);
};
export namespace BackgroundJobSoftwareUserEnable {
    export enum type {
        SOFTWARE_USER_ENABLE = 'software_user_enable',
    }
}

