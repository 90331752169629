<template>
  <el-card v-loading="loading" :body-style="{ height: props.cardHeight }">
    <div class="card-content">
      <h3 class="card-header">
        {{ t('views.dashboard.lastActivity.title') }}
      </h3>
      <el-scrollbar max-height="100%">
        <ActivityContent
          :activities="activities"
          :user-profiles="userProfiles" />
      </el-scrollbar>
    </div>
  </el-card>
</template>
<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import {
    ActivityBaseModel,
    LogsService,
    PaginationSortOrder,
    UserProfilePublic,
  } from '@/client'

  const { t } = useI18n()

  const props = defineProps({
    cardHeight: {
      type: String,
      default: '100%',
    },
  })

  const activities = ref<ActivityBaseModel[]>([])
  const userProfiles = ref<Record<string, UserProfilePublic>>({})

  const loading = ref(true)

  onMounted(() => {
    LogsService.getActivitiesApiV1LogActivitiesGet({
      limit: 40,
      sortBy: 'created',
      sortOrder: PaginationSortOrder.DESC,
    })
      .then((a) => {
        activities.value = a.activities
        userProfiles.value = a.user_profiles
      })
      .finally(() => {
        loading.value = false
      })
  })
</script>

<style scoped>
  .card-content {
    height: 100%;
  }
  .card-header {
    text-align: left;
    color: var(--el-text-color-secondary);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--sm-elevation);
    z-index: 100;
  }
</style>
