/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QuotaStatus {
    AVAILABLE = 'available',
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    PENDING = 'pending',
}
