/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSoftwareUserEnableExecutionData } from './BackgroundJobSoftwareUserEnableExecutionData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
import type { ErrorDetails } from './ErrorDetails';
export type BackgroundJobExecutionBase_Literal_software_user_enable__BackgroundJobSoftwareUserEnableExecutionData_ = {
    type: BackgroundJobExecutionBase_Literal_software_user_enable__BackgroundJobSoftwareUserEnableExecutionData_.type;
    execution_id: string;
    executed_at: string;
    data?: (BackgroundJobSoftwareUserEnableExecutionData | null);
    status?: BackgroundJobStatus;
    error_event_id?: (string | null);
    background_job_ids_enqueued?: Array<string>;
    error?: (ErrorDetails | null);
};
export namespace BackgroundJobExecutionBase_Literal_software_user_enable__BackgroundJobSoftwareUserEnableExecutionData_ {
    export enum type {
        SOFTWARE_USER_ENABLE = 'software_user_enable',
    }
}

