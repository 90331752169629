/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountGroup } from '../models/AccountGroup';
import type { AccountGroupIn } from '../models/AccountGroupIn';
import type { AccountGroupUpdateIn } from '../models/AccountGroupUpdateIn';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserGroupsService {
    /**
     * Get Account Groups
     * @returns AccountGroup Successful Response
     * @throws ApiError
     */
    public static getAccountGroupsApiV1ManagementGroupsGet({
        colorRExists,
        colorREq,
        colorRGt,
        colorRGte,
        colorRIn,
        colorRLt,
        colorRLte,
        colorRNe,
        colorRNin,
        colorR,
        colorGExists,
        colorGEq,
        colorGGt,
        colorGGte,
        colorGIn,
        colorGLt,
        colorGLte,
        colorGNe,
        colorGNin,
        colorG,
        colorBExists,
        colorBEq,
        colorBGt,
        colorBGte,
        colorBIn,
        colorBLt,
        colorBLte,
        colorBNe,
        colorBNin,
        colorB,
        color,
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        accountsExists,
        accountsEq,
        accountsGt,
        accountsGte,
        accountsIn,
        accountsLt,
        accountsLte,
        accountsNe,
        accountsNin,
        accountsAll,
        accountsSize,
        accounts,
        dataSourceDataSourceIdExists,
        dataSourceDataSourceIdEq,
        dataSourceDataSourceIdGt,
        dataSourceDataSourceIdGte,
        dataSourceDataSourceIdIn,
        dataSourceDataSourceIdLt,
        dataSourceDataSourceIdLte,
        dataSourceDataSourceIdNe,
        dataSourceDataSourceIdNin,
        dataSourceDataSourceId,
        dataSourceExternalIdExists,
        dataSourceExternalIdEq,
        dataSourceExternalIdGt,
        dataSourceExternalIdGte,
        dataSourceExternalIdIn,
        dataSourceExternalIdLt,
        dataSourceExternalIdLte,
        dataSourceExternalIdNe,
        dataSourceExternalIdNin,
        dataSourceExternalId,
        dataSourceNameExists,
        dataSourceNameEq,
        dataSourceNameGt,
        dataSourceNameGte,
        dataSourceNameIn,
        dataSourceNameLt,
        dataSourceNameLte,
        dataSourceNameNe,
        dataSourceNameNin,
        dataSourceName,
        dataSourceDeletedExternalExists,
        dataSourceDeletedExternalEq,
        dataSourceDeletedExternalGt,
        dataSourceDeletedExternalGte,
        dataSourceDeletedExternalIn,
        dataSourceDeletedExternalLt,
        dataSourceDeletedExternalLte,
        dataSourceDeletedExternalNe,
        dataSourceDeletedExternalNin,
        dataSourceDeletedExternal,
        dataSource,
        xTransactionId,
    }: {
        colorRExists?: (boolean | null),
        colorREq?: (number | null),
        colorRGt?: (number | null),
        colorRGte?: (number | null),
        colorRIn?: (Array<number> | null),
        colorRLt?: (number | null),
        colorRLte?: (number | null),
        colorRNe?: (number | null),
        colorRNin?: (Array<number> | null),
        colorR?: (number | null),
        colorGExists?: (boolean | null),
        colorGEq?: (number | null),
        colorGGt?: (number | null),
        colorGGte?: (number | null),
        colorGIn?: (Array<number> | null),
        colorGLt?: (number | null),
        colorGLte?: (number | null),
        colorGNe?: (number | null),
        colorGNin?: (Array<number> | null),
        colorG?: (number | null),
        colorBExists?: (boolean | null),
        colorBEq?: (number | null),
        colorBGt?: (number | null),
        colorBGte?: (number | null),
        colorBIn?: (Array<number> | null),
        colorBLt?: (number | null),
        colorBLte?: (number | null),
        colorBNe?: (number | null),
        colorBNin?: (Array<number> | null),
        colorB?: (number | null),
        color?: null,
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        accountsExists?: (boolean | null),
        accountsEq?: (string | null),
        accountsGt?: (string | null),
        accountsGte?: (string | null),
        accountsIn?: (Array<string> | null),
        accountsLt?: (string | null),
        accountsLte?: (string | null),
        accountsNe?: (string | null),
        accountsNin?: (Array<string> | null),
        accountsAll?: (Array<string> | null),
        accountsSize?: (number | null),
        accounts?: (string | null),
        dataSourceDataSourceIdExists?: (boolean | null),
        dataSourceDataSourceIdEq?: (string | null),
        dataSourceDataSourceIdGt?: (string | null),
        dataSourceDataSourceIdGte?: (string | null),
        dataSourceDataSourceIdIn?: (Array<string> | null),
        dataSourceDataSourceIdLt?: (string | null),
        dataSourceDataSourceIdLte?: (string | null),
        dataSourceDataSourceIdNe?: (string | null),
        dataSourceDataSourceIdNin?: (Array<string> | null),
        dataSourceDataSourceId?: (string | null),
        dataSourceExternalIdExists?: (boolean | null),
        dataSourceExternalIdEq?: (string | null),
        dataSourceExternalIdGt?: (string | null),
        dataSourceExternalIdGte?: (string | null),
        dataSourceExternalIdIn?: (Array<string> | null),
        dataSourceExternalIdLt?: (string | null),
        dataSourceExternalIdLte?: (string | null),
        dataSourceExternalIdNe?: (string | null),
        dataSourceExternalIdNin?: (Array<string> | null),
        dataSourceExternalId?: (string | null),
        dataSourceNameExists?: (boolean | null),
        dataSourceNameEq?: (string | null),
        dataSourceNameGt?: (string | null),
        dataSourceNameGte?: (string | null),
        dataSourceNameIn?: (Array<string> | null),
        dataSourceNameLt?: (string | null),
        dataSourceNameLte?: (string | null),
        dataSourceNameNe?: (string | null),
        dataSourceNameNin?: (Array<string> | null),
        dataSourceName?: (string | null),
        dataSourceDeletedExternalExists?: (boolean | null),
        dataSourceDeletedExternalEq?: (boolean | null),
        dataSourceDeletedExternalGt?: (boolean | null),
        dataSourceDeletedExternalGte?: (boolean | null),
        dataSourceDeletedExternalIn?: (Array<boolean> | null),
        dataSourceDeletedExternalLt?: (boolean | null),
        dataSourceDeletedExternalLte?: (boolean | null),
        dataSourceDeletedExternalNe?: (boolean | null),
        dataSourceDeletedExternalNin?: (Array<boolean> | null),
        dataSourceDeletedExternal?: (boolean | null),
        dataSource?: null,
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<AccountGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/groups',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'color.r.$exists': colorRExists,
                'color.r.$eq': colorREq,
                'color.r.$gt': colorRGt,
                'color.r.$gte': colorRGte,
                'color.r.$in': colorRIn,
                'color.r.$lt': colorRLt,
                'color.r.$lte': colorRLte,
                'color.r.$ne': colorRNe,
                'color.r.$nin': colorRNin,
                'color.r': colorR,
                'color.g.$exists': colorGExists,
                'color.g.$eq': colorGEq,
                'color.g.$gt': colorGGt,
                'color.g.$gte': colorGGte,
                'color.g.$in': colorGIn,
                'color.g.$lt': colorGLt,
                'color.g.$lte': colorGLte,
                'color.g.$ne': colorGNe,
                'color.g.$nin': colorGNin,
                'color.g': colorG,
                'color.b.$exists': colorBExists,
                'color.b.$eq': colorBEq,
                'color.b.$gt': colorBGt,
                'color.b.$gte': colorBGte,
                'color.b.$in': colorBIn,
                'color.b.$lt': colorBLt,
                'color.b.$lte': colorBLte,
                'color.b.$ne': colorBNe,
                'color.b.$nin': colorBNin,
                'color.b': colorB,
                'color': color,
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'accounts.$exists': accountsExists,
                'accounts.$eq': accountsEq,
                'accounts.$gt': accountsGt,
                'accounts.$gte': accountsGte,
                'accounts.$in': accountsIn,
                'accounts.$lt': accountsLt,
                'accounts.$lte': accountsLte,
                'accounts.$ne': accountsNe,
                'accounts.$nin': accountsNin,
                'accounts.$all': accountsAll,
                'accounts.$size': accountsSize,
                'accounts': accounts,
                'data_source.data_source_id.$exists': dataSourceDataSourceIdExists,
                'data_source.data_source_id.$eq': dataSourceDataSourceIdEq,
                'data_source.data_source_id.$gt': dataSourceDataSourceIdGt,
                'data_source.data_source_id.$gte': dataSourceDataSourceIdGte,
                'data_source.data_source_id.$in': dataSourceDataSourceIdIn,
                'data_source.data_source_id.$lt': dataSourceDataSourceIdLt,
                'data_source.data_source_id.$lte': dataSourceDataSourceIdLte,
                'data_source.data_source_id.$ne': dataSourceDataSourceIdNe,
                'data_source.data_source_id.$nin': dataSourceDataSourceIdNin,
                'data_source.data_source_id': dataSourceDataSourceId,
                'data_source.external_id.$exists': dataSourceExternalIdExists,
                'data_source.external_id.$eq': dataSourceExternalIdEq,
                'data_source.external_id.$gt': dataSourceExternalIdGt,
                'data_source.external_id.$gte': dataSourceExternalIdGte,
                'data_source.external_id.$in': dataSourceExternalIdIn,
                'data_source.external_id.$lt': dataSourceExternalIdLt,
                'data_source.external_id.$lte': dataSourceExternalIdLte,
                'data_source.external_id.$ne': dataSourceExternalIdNe,
                'data_source.external_id.$nin': dataSourceExternalIdNin,
                'data_source.external_id': dataSourceExternalId,
                'data_source.name.$exists': dataSourceNameExists,
                'data_source.name.$eq': dataSourceNameEq,
                'data_source.name.$gt': dataSourceNameGt,
                'data_source.name.$gte': dataSourceNameGte,
                'data_source.name.$in': dataSourceNameIn,
                'data_source.name.$lt': dataSourceNameLt,
                'data_source.name.$lte': dataSourceNameLte,
                'data_source.name.$ne': dataSourceNameNe,
                'data_source.name.$nin': dataSourceNameNin,
                'data_source.name': dataSourceName,
                'data_source.deleted_external.$exists': dataSourceDeletedExternalExists,
                'data_source.deleted_external.$eq': dataSourceDeletedExternalEq,
                'data_source.deleted_external.$gt': dataSourceDeletedExternalGt,
                'data_source.deleted_external.$gte': dataSourceDeletedExternalGte,
                'data_source.deleted_external.$in': dataSourceDeletedExternalIn,
                'data_source.deleted_external.$lt': dataSourceDeletedExternalLt,
                'data_source.deleted_external.$lte': dataSourceDeletedExternalLte,
                'data_source.deleted_external.$ne': dataSourceDeletedExternalNe,
                'data_source.deleted_external.$nin': dataSourceDeletedExternalNin,
                'data_source.deleted_external': dataSourceDeletedExternal,
                'data_source': dataSource,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Account Group
     * @returns AccountGroup Successful Response
     * @throws ApiError
     */
    public static postAccountGroupApiV1ManagementGroupsPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: AccountGroupIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<AccountGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/groups',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Account Groups
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countAccountGroupsApiV1ManagementGroupsCountGet({
        colorRExists,
        colorREq,
        colorRGt,
        colorRGte,
        colorRIn,
        colorRLt,
        colorRLte,
        colorRNe,
        colorRNin,
        colorR,
        colorGExists,
        colorGEq,
        colorGGt,
        colorGGte,
        colorGIn,
        colorGLt,
        colorGLte,
        colorGNe,
        colorGNin,
        colorG,
        colorBExists,
        colorBEq,
        colorBGt,
        colorBGte,
        colorBIn,
        colorBLt,
        colorBLte,
        colorBNe,
        colorBNin,
        colorB,
        color,
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        accountsExists,
        accountsEq,
        accountsGt,
        accountsGte,
        accountsIn,
        accountsLt,
        accountsLte,
        accountsNe,
        accountsNin,
        accountsAll,
        accountsSize,
        accounts,
        dataSourceDataSourceIdExists,
        dataSourceDataSourceIdEq,
        dataSourceDataSourceIdGt,
        dataSourceDataSourceIdGte,
        dataSourceDataSourceIdIn,
        dataSourceDataSourceIdLt,
        dataSourceDataSourceIdLte,
        dataSourceDataSourceIdNe,
        dataSourceDataSourceIdNin,
        dataSourceDataSourceId,
        dataSourceExternalIdExists,
        dataSourceExternalIdEq,
        dataSourceExternalIdGt,
        dataSourceExternalIdGte,
        dataSourceExternalIdIn,
        dataSourceExternalIdLt,
        dataSourceExternalIdLte,
        dataSourceExternalIdNe,
        dataSourceExternalIdNin,
        dataSourceExternalId,
        dataSourceNameExists,
        dataSourceNameEq,
        dataSourceNameGt,
        dataSourceNameGte,
        dataSourceNameIn,
        dataSourceNameLt,
        dataSourceNameLte,
        dataSourceNameNe,
        dataSourceNameNin,
        dataSourceName,
        dataSourceDeletedExternalExists,
        dataSourceDeletedExternalEq,
        dataSourceDeletedExternalGt,
        dataSourceDeletedExternalGte,
        dataSourceDeletedExternalIn,
        dataSourceDeletedExternalLt,
        dataSourceDeletedExternalLte,
        dataSourceDeletedExternalNe,
        dataSourceDeletedExternalNin,
        dataSourceDeletedExternal,
        dataSource,
        xTransactionId,
    }: {
        colorRExists?: (boolean | null),
        colorREq?: (number | null),
        colorRGt?: (number | null),
        colorRGte?: (number | null),
        colorRIn?: (Array<number> | null),
        colorRLt?: (number | null),
        colorRLte?: (number | null),
        colorRNe?: (number | null),
        colorRNin?: (Array<number> | null),
        colorR?: (number | null),
        colorGExists?: (boolean | null),
        colorGEq?: (number | null),
        colorGGt?: (number | null),
        colorGGte?: (number | null),
        colorGIn?: (Array<number> | null),
        colorGLt?: (number | null),
        colorGLte?: (number | null),
        colorGNe?: (number | null),
        colorGNin?: (Array<number> | null),
        colorG?: (number | null),
        colorBExists?: (boolean | null),
        colorBEq?: (number | null),
        colorBGt?: (number | null),
        colorBGte?: (number | null),
        colorBIn?: (Array<number> | null),
        colorBLt?: (number | null),
        colorBLte?: (number | null),
        colorBNe?: (number | null),
        colorBNin?: (Array<number> | null),
        colorB?: (number | null),
        color?: null,
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        accountsExists?: (boolean | null),
        accountsEq?: (string | null),
        accountsGt?: (string | null),
        accountsGte?: (string | null),
        accountsIn?: (Array<string> | null),
        accountsLt?: (string | null),
        accountsLte?: (string | null),
        accountsNe?: (string | null),
        accountsNin?: (Array<string> | null),
        accountsAll?: (Array<string> | null),
        accountsSize?: (number | null),
        accounts?: (string | null),
        dataSourceDataSourceIdExists?: (boolean | null),
        dataSourceDataSourceIdEq?: (string | null),
        dataSourceDataSourceIdGt?: (string | null),
        dataSourceDataSourceIdGte?: (string | null),
        dataSourceDataSourceIdIn?: (Array<string> | null),
        dataSourceDataSourceIdLt?: (string | null),
        dataSourceDataSourceIdLte?: (string | null),
        dataSourceDataSourceIdNe?: (string | null),
        dataSourceDataSourceIdNin?: (Array<string> | null),
        dataSourceDataSourceId?: (string | null),
        dataSourceExternalIdExists?: (boolean | null),
        dataSourceExternalIdEq?: (string | null),
        dataSourceExternalIdGt?: (string | null),
        dataSourceExternalIdGte?: (string | null),
        dataSourceExternalIdIn?: (Array<string> | null),
        dataSourceExternalIdLt?: (string | null),
        dataSourceExternalIdLte?: (string | null),
        dataSourceExternalIdNe?: (string | null),
        dataSourceExternalIdNin?: (Array<string> | null),
        dataSourceExternalId?: (string | null),
        dataSourceNameExists?: (boolean | null),
        dataSourceNameEq?: (string | null),
        dataSourceNameGt?: (string | null),
        dataSourceNameGte?: (string | null),
        dataSourceNameIn?: (Array<string> | null),
        dataSourceNameLt?: (string | null),
        dataSourceNameLte?: (string | null),
        dataSourceNameNe?: (string | null),
        dataSourceNameNin?: (Array<string> | null),
        dataSourceName?: (string | null),
        dataSourceDeletedExternalExists?: (boolean | null),
        dataSourceDeletedExternalEq?: (boolean | null),
        dataSourceDeletedExternalGt?: (boolean | null),
        dataSourceDeletedExternalGte?: (boolean | null),
        dataSourceDeletedExternalIn?: (Array<boolean> | null),
        dataSourceDeletedExternalLt?: (boolean | null),
        dataSourceDeletedExternalLte?: (boolean | null),
        dataSourceDeletedExternalNe?: (boolean | null),
        dataSourceDeletedExternalNin?: (Array<boolean> | null),
        dataSourceDeletedExternal?: (boolean | null),
        dataSource?: null,
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/groups/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'color.r.$exists': colorRExists,
                'color.r.$eq': colorREq,
                'color.r.$gt': colorRGt,
                'color.r.$gte': colorRGte,
                'color.r.$in': colorRIn,
                'color.r.$lt': colorRLt,
                'color.r.$lte': colorRLte,
                'color.r.$ne': colorRNe,
                'color.r.$nin': colorRNin,
                'color.r': colorR,
                'color.g.$exists': colorGExists,
                'color.g.$eq': colorGEq,
                'color.g.$gt': colorGGt,
                'color.g.$gte': colorGGte,
                'color.g.$in': colorGIn,
                'color.g.$lt': colorGLt,
                'color.g.$lte': colorGLte,
                'color.g.$ne': colorGNe,
                'color.g.$nin': colorGNin,
                'color.g': colorG,
                'color.b.$exists': colorBExists,
                'color.b.$eq': colorBEq,
                'color.b.$gt': colorBGt,
                'color.b.$gte': colorBGte,
                'color.b.$in': colorBIn,
                'color.b.$lt': colorBLt,
                'color.b.$lte': colorBLte,
                'color.b.$ne': colorBNe,
                'color.b.$nin': colorBNin,
                'color.b': colorB,
                'color': color,
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'accounts.$exists': accountsExists,
                'accounts.$eq': accountsEq,
                'accounts.$gt': accountsGt,
                'accounts.$gte': accountsGte,
                'accounts.$in': accountsIn,
                'accounts.$lt': accountsLt,
                'accounts.$lte': accountsLte,
                'accounts.$ne': accountsNe,
                'accounts.$nin': accountsNin,
                'accounts.$all': accountsAll,
                'accounts.$size': accountsSize,
                'accounts': accounts,
                'data_source.data_source_id.$exists': dataSourceDataSourceIdExists,
                'data_source.data_source_id.$eq': dataSourceDataSourceIdEq,
                'data_source.data_source_id.$gt': dataSourceDataSourceIdGt,
                'data_source.data_source_id.$gte': dataSourceDataSourceIdGte,
                'data_source.data_source_id.$in': dataSourceDataSourceIdIn,
                'data_source.data_source_id.$lt': dataSourceDataSourceIdLt,
                'data_source.data_source_id.$lte': dataSourceDataSourceIdLte,
                'data_source.data_source_id.$ne': dataSourceDataSourceIdNe,
                'data_source.data_source_id.$nin': dataSourceDataSourceIdNin,
                'data_source.data_source_id': dataSourceDataSourceId,
                'data_source.external_id.$exists': dataSourceExternalIdExists,
                'data_source.external_id.$eq': dataSourceExternalIdEq,
                'data_source.external_id.$gt': dataSourceExternalIdGt,
                'data_source.external_id.$gte': dataSourceExternalIdGte,
                'data_source.external_id.$in': dataSourceExternalIdIn,
                'data_source.external_id.$lt': dataSourceExternalIdLt,
                'data_source.external_id.$lte': dataSourceExternalIdLte,
                'data_source.external_id.$ne': dataSourceExternalIdNe,
                'data_source.external_id.$nin': dataSourceExternalIdNin,
                'data_source.external_id': dataSourceExternalId,
                'data_source.name.$exists': dataSourceNameExists,
                'data_source.name.$eq': dataSourceNameEq,
                'data_source.name.$gt': dataSourceNameGt,
                'data_source.name.$gte': dataSourceNameGte,
                'data_source.name.$in': dataSourceNameIn,
                'data_source.name.$lt': dataSourceNameLt,
                'data_source.name.$lte': dataSourceNameLte,
                'data_source.name.$ne': dataSourceNameNe,
                'data_source.name.$nin': dataSourceNameNin,
                'data_source.name': dataSourceName,
                'data_source.deleted_external.$exists': dataSourceDeletedExternalExists,
                'data_source.deleted_external.$eq': dataSourceDeletedExternalEq,
                'data_source.deleted_external.$gt': dataSourceDeletedExternalGt,
                'data_source.deleted_external.$gte': dataSourceDeletedExternalGte,
                'data_source.deleted_external.$in': dataSourceDeletedExternalIn,
                'data_source.deleted_external.$lt': dataSourceDeletedExternalLt,
                'data_source.deleted_external.$lte': dataSourceDeletedExternalLte,
                'data_source.deleted_external.$ne': dataSourceDeletedExternalNe,
                'data_source.deleted_external.$nin': dataSourceDeletedExternalNin,
                'data_source.deleted_external': dataSourceDeletedExternal,
                'data_source': dataSource,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Account Group
     * @returns AccountGroup Successful Response
     * @throws ApiError
     */
    public static getAccountGroupApiV1ManagementGroupsAccountGroupIdGet({
        accountGroupId,
        includeDeleted = false,
        xTransactionId,
    }: {
        accountGroupId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<AccountGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/groups/{account_group_id}',
            path: {
                'account_group_id': accountGroupId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Account Group
     * @returns AccountGroup Successful Response
     * @throws ApiError
     */
    public static patchAccountGroupApiV1ManagementGroupsAccountGroupIdPatch({
        accountGroupId,
        requestBody,
        includeDeleted = false,
        xTransactionId,
    }: {
        accountGroupId: string,
        requestBody: AccountGroupUpdateIn,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<AccountGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/management/groups/{account_group_id}',
            path: {
                'account_group_id': accountGroupId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Account Group
     * @returns void
     * @throws ApiError
     */
    public static deleteAccountGroupApiV1ManagementGroupsAccountGroupIdDelete({
        accountGroupId,
        includeDeleted = false,
        xTransactionId,
    }: {
        accountGroupId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/management/groups/{account_group_id}',
            path: {
                'account_group_id': accountGroupId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Account To Account Group
     * @returns AccountGroup Successful Response
     * @throws ApiError
     */
    public static addAccountToAccountGroupApiV1ManagementGroupsAccountGroupIdAccountsAccountIdPost({
        accountId,
        accountGroupId,
        assignLicenses = false,
        includeDeleted = false,
        xTransactionId,
    }: {
        accountId: string,
        accountGroupId: string,
        assignLicenses?: boolean,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<AccountGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/groups/{account_group_id}/accounts/{account_id}',
            path: {
                'account_id': accountId,
                'account_group_id': accountGroupId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'assign_licenses': assignLicenses,
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Account From Account Group
     * @returns AccountGroup Successful Response
     * @throws ApiError
     */
    public static removeAccountFromAccountGroupApiV1ManagementGroupsAccountGroupIdAccountsAccountIdDelete({
        accountId,
        accountGroupId,
        unassignLicenses = false,
        includeDeleted = false,
        xTransactionId,
    }: {
        accountId: string,
        accountGroupId: string,
        unassignLicenses?: boolean,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<AccountGroup> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/management/groups/{account_group_id}/accounts/{account_id}',
            path: {
                'account_id': accountId,
                'account_group_id': accountGroupId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'unassign_licenses': unassignLicenses,
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
