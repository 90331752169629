<template>
  <SmDrawer
    v-model:visibility="localShowDrawer"
    :title="drawerTitle"
    :level="2"
    :before-close="handleClose">
    <template #default>
      <!-- licenceModelControl should be enabled, when the software does not already exist (is newly created) -->
      <!-- for the disabled name input, look into the computed below -->
      <SoftwareForm
        v-model:showDrawer="localShowDrawer"
        :software-name="softwareName"
        :software-type="softwareType || undefined"
        :is-name-equals-to-search="isNameEqualsToSearch"
        :show-software-license-control="!isAlreadyExistingSoftware"
        :is-already-existing-software="isAlreadyExistingSoftware"
        @software-changed="() => emit('closedDrawer')"
        @close-drawer="
          () => {
            formData = cloneDeep(initialFormData)
            localShowDrawer = false
          }
        "
        @software-deleted="emit('softwareDeleted')" />
    </template>
  </SmDrawer>
</template>

<script setup lang="ts">
  import { ElMessageBox } from 'element-plus'
  import { cloneDeep } from 'lodash-es'

  import { useI18n } from 'vue-i18n'

  import { SoftwarePostIn, SoftwareTypes } from '@/client'
  import { deleteSoftware, getSoftwares } from '@/common/software'
  import { useGlobalStore } from '@/stores/globalStore'
  import { ExtendedSoftware, Software } from '@/types/software'

  // Props and Emits
  const emit = defineEmits([
    'update:showDrawer',
    'softwareDeleted',
    'closedDrawer',
  ])
  const props = defineProps({
    showDrawer: {
      type: Boolean,
      default: true,
    },
    customSoftwareTemplate: {
      type: Object as PropType<SoftwarePostIn>,
      default: (): Software | undefined => {
        return undefined
      },
    },
    // used to enable/disable the name input of the EditsoftwareForm
    addingSoftwareFromTemplate: {
      type: Boolean,
      default: false,
    },
    // used to show/hide the licenceModel controlin the EditSoftwareForm
    isAlreadyExistingSoftware: {
      type: Boolean,
      default: false,
    },
    // used to keep the name input enabled if the software was created
    isNameEqualsToSearch: {
      type: Boolean,
      default: false,
    },
  })

  const store = useGlobalStore()
  const i18n = useI18n()

  const localShowDrawer = computed({
    get: () => props.showDrawer,
    set: (value) => emit('update:showDrawer', value),
  })

  const softwareName = computed(() => {
    if (props.customSoftwareTemplate != undefined) {
      return props.customSoftwareTemplate.software_name
    } else return ''
  })

  const softwareType = computed(() => {
    if (props.customSoftwareTemplate != undefined) {
      return props.customSoftwareTemplate.type
    } else return SoftwareTypes.MANUALLY
  })

  const initialFormData = {
    _id: '',
    desc: '',
    desc_short: '',
    type: 'manually',
    icon: '',
    display_name: '',
    software_name: '',
    price: '',
    count: -1,
    software_licenses: {},
    data: {},
    isValid: false,
  } as ExtendedSoftware

  // Custom software data
  const formData = ref<ExtendedSoftware>(cloneDeep(initialFormData))

  // *** MISC ****
  // Ask if the User is sure that he want so close the Dialog
  const handleClose = () => {
    ElMessageBox.confirm(
      i18n.t('drawers.editSoftwareDrawer.beforeClose.text'),
      {
        confirmButtonText: i18n.t('general.yes'),
        cancelButtonText: i18n.t('general.no'),
      }
    )
      .then(() => {
        getSoftwares(store.getIp, store.getJWT()).then((softwares) => {
          const isSoftwareExisting = softwares.find(
            (software) =>
              software.software_name === formData.value.software_name
          )

          if (isSoftwareExisting)
            deleteSoftware(store.getIp, store.getJWT(), softwareName.value)
        })
        formData.value = cloneDeep(initialFormData)
        localShowDrawer.value = false
      })
      .catch(() => {
        // catch error
      })
  }

  /**
   * Compute the title of the Drawer
   */
  const drawerTitle = computed(() => {
    if (formData.value && formData.value.software_name) {
      return formData.value.software_name
    } else if (props.customSoftwareTemplate !== undefined) {
      return props.customSoftwareTemplate.software_name
    } else {
      return i18n.t('drawers.editSoftwareDrawer.manuallySoftwareTitle')
    }
  })
</script>

<style scoped>
  .header {
    margin-bottom: 1rem;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  }
</style>
