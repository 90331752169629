import { defineStore } from 'pinia'

type SettingsStoreState = {
  otp: {
    enabled: boolean | null
  }
  dialogs: {
    otpSetup: boolean
    otpDisable: boolean
    otpChange: boolean
  }
}

export const useSettingsStore = defineStore({
  id: 'settings',
  state: (): SettingsStoreState => ({
    otp: {
      enabled: null,
    },
    dialogs: {
      otpSetup: false,
      otpDisable: false,
      otpChange: false,
    },
  }),
})
