import {
  BackgroundJobAccountGroupAccountsAdded,
  BackgroundJobAccountGroupAccountsRemoved,
  BackgroundJobSoftwareUserAdd,
  BackgroundJobSoftwareUserAddData,
  BackgroundJobSoftwareUserEnable,
  BackgroundJobSoftwareUserEnableData,
  BackgroundJobSoftwareUserLicenseAdd,
  BackgroundJobSoftwareUserLicenseAddData,
  BackgroundJobSoftwareUserLicenseRemove,
  BackgroundJobSoftwareUserLicenseRemoveData,
  BackgroundJobSoftwareUserRemove,
  BackgroundJobSoftwareUserRemoveData,
  BackgroundJobsService,
  BackgroundJobType,
} from '@/client'
import schema from '@/stores/backgroundjob.schema'

import { useFetch } from '@/common/useFetch'
import { filters } from './backgroundjob.filter'
import { db } from './_db'
import { MangoQuerySortPart } from 'rxdb'
import filterUtils from '@/common/util/filterUtils'
import { until } from '@vueuse/shared'

/**
 * Variables
 */
export type BackgroundjobFilterType = (typeof filters)[keyof typeof filters]
export type BackgroundjobFilterInstance = InstanceType<BackgroundjobFilterType>

export const _currentLoadingAllBackgroundjobs: Ref<boolean> = ref(false)
export const _allBackgroundjobsLoaded: Ref<Date> | Ref<undefined> =
  ref(undefined)
export const collectionInitiated = ref(false)

/**
 * Initialize
 */

export async function _initBackgroundjobCollection() {
  // check if db is initialized
  if (!db.value) {
    return
  }

  // Check if collection is already existing in DB
  const collections = db.value?.collections
  if (collections.backgroundjobs) {
    collectionInitiated.value = true
    return
  }

  await db.value
    .addCollections({
      backgroundjobs: {
        schema: schema,
      },
    })
    .catch((error) => {
      console.error(error)
    })

  collectionInitiated.value = true
}

export async function _checkBackgroundjobCollection() {
  if (!collectionInitiated.value) {
    await _initBackgroundjobCollection()
  }
}

/**
 * RXDB Helper
 */

export async function _getQuery(
  filters?: BackgroundjobFilterInstance[],
  {
    limit,
    skip,
    sort,
  }: {
    limit?: number
    skip?: number
    sort?: MangoQuerySortPart<
      | BackgroundJobAccountGroupAccountsAdded
      | BackgroundJobAccountGroupAccountsRemoved
      | BackgroundJobSoftwareUserAdd
      | BackgroundJobSoftwareUserEnable
      | BackgroundJobSoftwareUserLicenseAdd
      | BackgroundJobSoftwareUserLicenseRemove
      | BackgroundJobSoftwareUserRemove
    >[]
  } = {}
) {
  // For now, we just take the first filter
  const mergedFilter = await filterUtils.mergeFilters(filters || [])

  if (_currentLoadingAllBackgroundjobs.value === true) {
    await until(_currentLoadingAllBackgroundjobs).toBe(false)
  }

  if (!_allBackgroundjobsLoaded.value) {
    await _reloadBackgroundjobs()
  }

  return db.value.backgroundjobs.find({
    selector: mergedFilter,
    limit,
    skip,
    sort,
  })
}

export async function _addBackgroundjobs(
  backgroundjobs: Array<
    | BackgroundJobAccountGroupAccountsAdded
    | BackgroundJobAccountGroupAccountsRemoved
    | BackgroundJobSoftwareUserAdd
    | BackgroundJobSoftwareUserEnable
    | BackgroundJobSoftwareUserLicenseAdd
    | BackgroundJobSoftwareUserLicenseRemove
    | BackgroundJobSoftwareUserRemove
  >
) {
  if (!collectionInitiated.value) {
    await _initBackgroundjobCollection()
  }

  await db.value.backgroundjobs.bulkUpsert(backgroundjobs)
}

export async function _reloadBackgroundjobs(
  filter?: BackgroundjobFilterInstance[]
) {
  if (filter === undefined) {
    _currentLoadingAllBackgroundjobs.value = true
  }

  //  Get software from backend
  const { data, error, loading } = await _fetchBackgroundjobs(filter)
  //  Add software to DB
  if (data) {
    await _addBackgroundjobs(toRaw(data))

    _currentLoadingAllBackgroundjobs.value = false
    _allBackgroundjobsLoaded.value = new Date()
  }
  return { data, error, loading }
}
/**
 * API
 */

export async function _fetchBackgroundjobs(
  filter?: BackgroundjobFilterInstance[]
) {
  const _filter = {
    filters: filter || [],
    includeDeleted: true,
  }

  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.getBackgroundJobsApiV1BackgroundJobsGet(_filter),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _createBackgroundjob(
  type: BackgroundJobType,
  request_data:
    | BackgroundJobSoftwareUserAddData
    | BackgroundJobSoftwareUserEnableData
    | BackgroundJobSoftwareUserLicenseAddData
    | BackgroundJobSoftwareUserLicenseRemoveData
    | BackgroundJobSoftwareUserRemoveData,
  enqueue_in: number
) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.createBackgroundJobApiV1BackgroundJobsPost({
        requestBody: {
          type: type,
          data: request_data,
          enqueue_in: enqueue_in,
        },
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _enqueueBackgroundjob(id: string, dateInSeconds: number) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.enqueueBackgroundJobByIdApiV1BackgroundJobsBackgroundJobIdEnqueuePost(
        {
          backgroundJobId: id,
          requestBody: {
            enqueue_in: dateInSeconds,
          },
        }
      ),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _abortBackgroundjob(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.abortBackgroundJobApiV1BackgroundJobsBackgroundJobIdAbortPost(
        {
          backgroundJobId: id,
        }
      ),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _pauseBackgroundjob(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.pauseBackgroundJobApiV1BackgroundJobsBackgroundJobIdPausePost(
        {
          backgroundJobId: id,
        }
      ),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}
