<template>
  <el-tabs v-model="activeSubAccountId">
    <el-tab-pane
      v-for="subAccount in subAccountsSSOFilters"
      :key="subAccount.id"
      :name="subAccount.id"
      :label="subAccount.name">
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts" setup>
  import { SoftwareOut } from '@/client'
  import { useRoute } from 'vue-router'

  const activeSubAccountId = defineModel('activeSubAccountId', {
    type: String as PropType<string | undefined>,
    required: false,
    default: undefined,
  })

  const props = defineProps({
    software: {
      type: Object as PropType<SoftwareOut>,
      required: true,
    },
  })

  const route = useRoute()

  const subAccountsSSOFilters = computed(() => {
    if (route.query.sso === 'true' && activeSubAccountId.value) {
      return [props.software?.sub_accounts[activeSubAccountId.value]]
    } else {
      return subAccountsSorted.value
    }
  })

  // Sort sub accounts by sorting (integer)
  const subAccountsSorted = computed(() => {
    const subAccounts = props.software?.sub_accounts
    if (subAccounts === undefined) {
      return []
    }

    return Object.values(subAccounts).sort((a, b) => {
      // If sorting is undefined, sort it to the end
      if (a.sorting === undefined || b.sorting === undefined) {
        return 0
      }
      return a.sorting - b.sorting
    })
  })

  onMounted(() => {
    if (activeSubAccountId.value === undefined) {
      activeSubAccountId.value = subAccountsSorted.value[0].id
    }
  })
</script>
