<template>
  <SmDialog
    v-model:visibility="visible"
    :title="
      currentStep === 'form' ? currentBackgroundJob?.label : i18n.t('createJob')
    "
    class="dialog"
    size="medium">
    <!-- todo: optimize: Add SmSteps -->
    <div v-if="currentStep === 'type'">
      <el-form label-position="top">
        <el-form-item
          :prop="`typeForm.fields.type`"
          :error="getErrorMessage('type')">
          <SmSelect
            v-model:selection="typeForm.fields.type"
            :options="availableBackgroundJobTypes"
            :label="i18n.t('chooseJobType')"
            size="large"
            class="w-full"
            @blur="typeForm.validate(['type'])" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="currentStep === 'form'">
      <SmDialogMessage
        :message="submitResponseMsg"
        :visible="showSubmitResponse"
        :type="submitResponseType"
        class="mb-4" />
      <template v-if="currentBackgroundJob">
        <TasksJobFormSoftwareUserAdd
          v-if="
            currentBackgroundJob.value === BackgroundJobType.SOFTWARE_USER_ADD
          "
          ref="formEl" />
        <TasksJobFormSoftwareUserEnable
          v-if="
            currentBackgroundJob.value ===
            BackgroundJobType.SOFTWARE_USER_ENABLE
          "
          ref="formEl" />
        <TasksJobFormSoftwareUserLicenseAdd
          v-if="
            currentBackgroundJob.value ===
            BackgroundJobType.SOFTWARE_USER_LICENSE_ADD
          "
          ref="formEl" />
        <TasksJobFormSoftwareUserLicenseRemove
          v-if="
            currentBackgroundJob.value ===
            BackgroundJobType.SOFTWARE_USER_LICENSE_REMOVE
          "
          ref="formEl" />
        <TasksJobFormSoftwareUserRemove
          v-if="
            currentBackgroundJob.value ===
            BackgroundJobType.SOFTWARE_USER_REMOVE
          "
          ref="formEl" />
      </template>
    </div>

    <template #footer>
      <div v-if="currentStep === 'type'" class="submit-buttons">
        <SmButton size="small" outline @click="handleCancel">
          {{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton
          size="small"
          :disabled="typeForm.fields.type ? false : true"
          @click="handleNext()">
          {{ i18n.t('next') }}
        </SmButton>
      </div>

      <div v-else-if="currentStep === 'form'" class="submit-buttons">
        <SmButton
          v-if="!actionsDone"
          size="small"
          outline
          @click="currentStep = 'type'">
          {{ i18n.t('back') }}
        </SmButton>
        <SmButton
          v-if="!actionsDone"
          size="small"
          :loading="backgroundjobSubmitLoading"
          @click="handleSave">
          {{ i18n.t('general.save') }}
        </SmButton>
        <SmButton v-if="actionsDone" size="small" @click="handleSave">
          {{ i18n.t('close') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { BackgroundJobType } from '@/client'
  import useForm from '@/common/form'
  import { useI18n } from 'vue-i18n'
  import {
    ValidationResult,
    notEmpty,
    notEqual,
  } from './sm/SmInput/SmInputValidator'
  import { sendToast } from './sm/SmNotification'

  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'
  import { Option } from './sm/SmSelect.vue'

  const i18n = useI18n()

  //  currently not used because jobs can not be edited rn
  // const props = defineProps<{
  //   jobId: string
  // }>()

  const visible = defineModel<boolean>('visible', { default: false })

  const formEl = ref()
  const currentStep = ref('type')
  const backgroundjobSubmitLoading = ref(false)
  const showSubmitResponse = ref(false)
  const submitResponseMsg = ref('')
  const submitResponseType = ref<DialogMessageTypes>('success')
  const actionsDone = ref(false)

  const currentBackgroundJob = computed(() => {
    return typeForm.fields.type?.[0]
  })

  async function handleSave() {
    if (actionsDone.value) {
      handleCancel()
      return
    }

    backgroundjobSubmitLoading.value = true
    const result = await formEl?.value?.submit()

    if (result) {
      if (result.error) {
        // todo: replace type declaration to get the error code
        const errorMessage = (result.error as any).body.detail.error
        submitResponseMsg.value = i18n.t(errorMessage)
        submitResponseType.value = 'error'
      } else if (result.data) {
        submitResponseMsg.value = i18n.t(
          'notifications.tasksView.background_job.createSuccess'
        )
        submitResponseType.value = 'success'
        actionsDone.value = true
      }
      showSubmitResponse.value = true
    }
    backgroundjobSubmitLoading.value = false
  }

  function handleCancel() {
    visible.value = false
    actionsDone.value = false
    formEl.value?.resetForm()
    typeForm.reset()
  }

  function handleNext() {
    if (typeForm.validate()) {
      currentStep.value = 'form'
    } else {
      sendToast(i18n.t('validationFailed'), undefined, 'error')
    }
  }

  /**
   * Form
   */

  const job = reactive<{ type: Option<BackgroundJobType>[] | undefined }>({
    type: undefined,
  })

  const typesToKeep = [
    // selection of currently available types
    BackgroundJobType.SOFTWARE_USER_ADD,
    BackgroundJobType.SOFTWARE_USER_ENABLE,
    BackgroundJobType.SOFTWARE_USER_LICENSE_ADD,
    BackgroundJobType.SOFTWARE_USER_LICENSE_REMOVE,
    BackgroundJobType.SOFTWARE_USER_REMOVE,
  ]

  const availableBackgroundJobTypes: Option<BackgroundJobType>[] =
    Object.values(BackgroundJobType)
      .filter((type) => typesToKeep.includes(type))
      .map((type) => ({
        value: type,
        label: i18n.t(`jobtype.${type}`),
      }))

  const typeForm = useForm(
    {
      ...job,
    },
    // Form Hooks
    {},

    // Form Validators
    {
      type: [
        notEqual(BackgroundJobType._DEBUG_EXCEPTION),
        notEqual(BackgroundJobType._DEBUG_SLEEP),
        notEmpty,
      ],
    },
    {
      type: i18n.t('jobType'),
    }
  )

  function getErrorMessage(fieldName: 'type') {
    if (!((fieldName as string) in typeForm.errors)) return ''
    const errors = typeForm.errors[fieldName] as ValidationResult[]
    if (!errors || errors.length === 0 || !errors[0] || !errors[0].message)
      return ''
    const message = errors[0].message as string
    return message
  }

  /**
   * Watchers
   */

  watch(
    () => visible.value,
    (val) => {
      if (!val) {
        typeForm.reset()
        formEl.value?.resetForm()
        currentStep.value = 'type'
      }
    }
  )

  watch(
    () => currentStep.value,
    (val) => {
      if (val === 'form') {
        showSubmitResponse.value = false
      }
    }
  )
</script>

<style scoped>
  .submit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
</style>
