<template>
  <SmDialog
    v-model:visibility="visibility"
    :close-on-click-modal="false"
    :close-on-press-escape="false">
    <!-- HEADER -->
    <template #title>
      <div class="dialog-header">
        <el-image
          class="software-image"
          :src="`https://logos.saasmetrix.io/${software.software_name}.png`">
          <template #error>
            <v-icon name="md-hideimage-round" scale="1.6" />
          </template>
        </el-image>
        <h2>{{ software.software_name }}: {{ i18n.t('reauthentication') }}</h2>
      </div>
    </template>

    <!-- PROMPTING FOR REAUTHENTICATION -->
    <div v-if="dialogState == DialogState.PROMPTING">
      {{
        i18n.t('dialogReauthenticateOAuth.text', {
          name: props.software.display_name || software.software_name,
        })
      }}
    </div>
    <template v-if="dialogState != DialogState.AUTHFLOW" #footer>
      <div class="sm-dialog__footer">
        <!-- ABORT REAUTHENTICATION -->
        <SmButton outline @click="handleBackToApplications">
          {{ i18n.t('buttonBackToApplication') }}
        </SmButton>

        <!-- REAUTHENTICATE -->
        <SmButton
          :loading="currentlyCheckingConnection"
          @click="handleAuthentication">
          {{ i18n.t('dialogReauthenticateOAuth.button') }}
        </SmButton>
      </div>
    </template>

    <!-- CUSTOM_FLOW CONTENT -->
    <div v-if="dialogState == DialogState.AUTHFLOW">
      <SoftwareAuthFlowContent
        :software-id="software._id"
        :software-name="software.software_name"
        :software-type="software.type || SoftwareTypes.OAUTH"
        :auth-type="AuthType.REAUTHENTICATE"
        @authentication-completed="checkConnection" />
    </div>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useDocumentVisibility } from '@vueuse/core'

  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import {
    ApiError,
    SoftwareAuthorizationService,
    SoftwareOut,
    SoftwareService,
    SoftwareTypes,
  } from '@/client'
  import { editToast, sendToast } from '@/components/sm/SmNotification'
  import { useGlobalStore } from '@/stores/globalStore'
  import { AuthType } from '@/stores/softwareStore'

  import SoftwareAuthFlowContent from './SoftwareAuthFlowContent.vue'

  enum DialogState {
    PROMPTING = 'prompting',
    AUTHFLOW = 'authflow',
    OAUTH = 'oauth',
    TOKEN = 'token',
    BOT = 'bot',
  }

  const props = defineProps<{
    software: SoftwareOut
  }>()

  const dialogState = ref<DialogState>(DialogState.PROMPTING)

  const visibility = defineModel('visibility', { type: Boolean, default: true })
  const router = useRouter()
  const i18n = useI18n()

  const emit = defineEmits(['authentication-completed', 'update:visibility'])

  const checkConnectionEnabled = ref(false)
  const currentlyCheckingConnection = ref(false)

  // Check connection if the tab gets the focus
  const tabVisibility = useDocumentVisibility()
  watch(tabVisibility, (current, previous) => {
    if (current === 'visible' && previous === 'hidden') {
      if (checkConnectionEnabled.value) {
        checkConnection()
      }
    }
  })

  function handleAuthentication() {
    if (props.software.information.custom_authorization_flow_required)
      dialogState.value = DialogState.AUTHFLOW
    else
      switch (props.software.type) {
        case SoftwareTypes.OAUTH:
          dialogState.value = DialogState.OAUTH
          handleReauthenticateOAuth()
          break
        case SoftwareTypes.BOT:
          dialogState.value = DialogState.BOT
          handleReauthenticateBotOrToken()
          break
        case SoftwareTypes.TOKEN:
          dialogState.value = DialogState.TOKEN
          handleReauthenticateBotOrToken()
          break
        default:
          break
      }
  }

  function handleReauthenticateBotOrToken() {
    const routeData = router.resolve({
      path: '/authorize',
      query: {
        softwareName: props.software.software_name,
        softwareType: props.software.type,
        accessToken: useGlobalStore().getJWT(),
        reauthenticate: 'true',
        softwareId: props.software._id,
      },
    })
    window.open(routeData.href, '_blank')
    checkConnectionEnabled.value = true
  }

  function handleReauthenticateOAuth() {
    // Get oauth url
    SoftwareAuthorizationService.getOauthLinkToReAuthenticateApiV1OauthReAuthenticateSoftwareIdGet(
      {
        softwareId: props.software._id,
      }
    ).then(
      (response) => {
        // Open oauth url in new tab
        window.open(response, '_blank')
        checkConnectionEnabled.value = true
      },
      // on error
      (error) => {
        sendErrorToast(error)
      }
    )
  }

  function handleBackToApplications() {
    emit('update:visibility', false)
    router.push({ path: '/applications' })
  }

  function checkConnection() {
    currentlyCheckingConnection.value = true
    const notificationID = sendToast(
      i18n.t('dialogReauthenticateOAuth.toastCheckingConnection', {
        name: props.software.display_name || props.software.software_name,
      }),
      undefined,
      'loading'
    )
    // Check if connection works
    SoftwareService.getSoftwareApiV1SoftwareSoftwareSoftwareIdGet({
      softwareId: props.software._id,
      cached: false,
    })
      .then(
        () => {
          // Connection works
          checkConnectionEnabled.value = false

          editToast(notificationID, {
            title: i18n.t('dialogReauthenticateOAuth.toastConnectionWorks', {
              name: props.software.display_name || props.software.software_name,
            }),
            type: 'success',
          })

          emit('authentication-completed')
          emit('update:visibility', false)
        },
        () => {
          // Connection does not work
          editToast(notificationID, {
            title: i18n.t('dialogReauthenticateOAuth.toastConnectionFailed', {
              name: props.software.display_name || props.software.software_name,
            }),
            type: 'error',
          })
        }
      )
      .finally(() => {
        currentlyCheckingConnection.value = false
      })
  }

  function sendErrorToast(error: ApiError) {
    let translation
    if (i18n.te(error.body.detail.error + '.title')) {
      translation = i18n.t(error.body.detail.error + '.title')
    } else
      translation = i18n.t('dialogReauthenticateOAuth.toastConnectionFailed')
    sendToast(translation, undefined, 'error')
  }
</script>
<style scoped lang="scss">
  .sm-dialog__footer {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }

  .dialog-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
  .software-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }
</style>
