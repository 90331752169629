/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LicenceStatus {
    ACTIVE = 'active',
    DISABLED = 'disabled',
    INVITED = 'invited',
    INVITE_CANCELLED = 'invite-cancelled',
}
