import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import {
  LicenceOut,
  SoftwareOut,
  SoftwareLicense,
  SoftwareLicenseMini,
  QuotaInformation_Output,
  QuotaStatus,
  PriceInformation,
  BillingUnit,
} from '@/client'
import { Document } from '@/types/document'
import { Optimization } from '@/types/optimization'
import { SubAccount } from '@/types/software'
import { Statistic } from '@/types/statistics'
import { licenseStore } from '@/stores/licenseV2.store'
import { SoftwareLicensesFilter } from '@/stores/license.filter'
import { usePriceInformationStore } from '@/stores/priceInformationStore'

export const useApplicationDetailsStore = defineStore(
  'applicationDetailsViewStore',
  () => {
    const software = ref<SoftwareOut>()
    const optimizations = ref<Optimization[]>()
    const documents = ref<Document[]>()
    const costStatistics = ref<Statistic[]>()
    const currentCost = ref<number>()
    const selectedSubAccount = ref<SubAccount>()
    const quota = ref<Record<string, QuotaInformation_Output>>({})
    const licences = ref<SoftwareLicense[]>([])
    const licencesTable = ref<{
      selectedLicences?: LicenceOut[]
      searchQuery?: string
      isLoading: boolean
      onlyShowInvites: boolean
    }>({
      selectedLicences: undefined,
      searchQuery: '',
      isLoading: false,
      onlyShowInvites: false,
    })

    async function initSoftware(loadedSoftware: SoftwareOut) {
      software.value = loadedSoftware
    }

    async function initLicenseModels(
      loadedLicenseModels: { license: SoftwareLicense }[]
    ) {
      const transformedLicenses = loadedLicenseModels.map(
        (item) => item.license
      )
      licences.value = transformedLicenses
    }

    const _getCachedPriceInformation = async (id: string) => {
      const priceInformationStore = usePriceInformationStore()

      if (!priceInformationStore.getPriceInformation(id)) {
        await priceInformationStore.loadPriceInformation(id)
      }
      return priceInformationStore.getPriceInformation(id)
    }

    const _getActivePriceInformation = (
      priceInformations: PriceInformation[]
    ) => {
      if (!priceInformations) return []

      const activePriceInformations = ref<PriceInformation[]>([])

      for (const priceInformation of priceInformations) {
        const fromDate = priceInformation.from_date
          ? dayjs(priceInformation.from_date)
          : null
        const endDate = priceInformation.end_date
          ? dayjs(priceInformation.end_date)
          : null

        if (
          (fromDate && endDate && dayjs().isBetween(fromDate, endDate)) ||
          !fromDate ||
          !endDate
        ) {
          activePriceInformations.value.push(priceInformation)
        }
      }

      return activePriceInformations.value
    }

    async function loadQuota(licenseId: string) {
      // load relevant data
      const cachedPriceInformation = await _getCachedPriceInformation(licenseId)
      const activePriceInformations = _getActivePriceInformation(
        cachedPriceInformation ?? []
      )
      const license = licences.value.find(
        (license) => license._id === licenseId
      )
      const currentCount = await licenseStore
        .getLicensesCount([new SoftwareLicensesFilter([licenseId])])
        .then((count) => {
          return count
        })

      // check cases
      // for more infos to explain the workflow look into the affine board
      const externalQuota = license?.quota_information ?? undefined
      const hasManualPriceInformation = activePriceInformations.some(
        (priceInformation: PriceInformation) =>
          priceInformation.billing_unit === BillingUnit.QUOTA &&
          priceInformation.quota != null &&
          priceInformation.quota > 0
      )
      const totalManualQuota = activePriceInformations.reduce(
        (sum, obj) => sum + (obj.quota || 0),
        0
      )
      const manualQuotaIsLargerThenExternal = externalQuota
        ? Boolean(totalManualQuota > externalQuota?.quota)
        : true

      const quotaInformation: Ref<QuotaInformation_Output | undefined> = ref()

      if (hasManualPriceInformation || externalQuota) {
        // there is some kind of quota information available

        if (manualQuotaIsLargerThenExternal) {
          // overwrite external quota
          quotaInformation.value = {
            quota: totalManualQuota,
            used: currentCount,
            available: totalManualQuota - currentCount,
            status: QuotaStatus.AVAILABLE, // deprecated?
            free_quota: 0, //deprecated?
          }
        } else {
          // use external quota
          if (!externalQuota) {
            throw new Error('External Quota should be defined')
          }
          quotaInformation.value = {
            quota: externalQuota.quota,
            used: currentCount,
            available: externalQuota.quota - currentCount,
            status: QuotaStatus.AVAILABLE, // deprecated?
            free_quota: 0, //deprecated?
          }
        }
      }

      // handle quota information cache
      if (quotaInformation.value) {
        quota.value[licenseId] = quotaInformation.value
      } else {
        delete quota.value[licenseId]
      }
    }

    async function loadAllQuotas(values: SoftwareLicense[]) {
      for (const key in values) {
        const license: SoftwareLicense = values[key]
        loadQuota(license._id)
      }
    }

    function updateQuota(
      afterValue: Record<string, SoftwareLicenseMini | null> | undefined,
      beforeValue?: Record<string, SoftwareLicenseMini>
    ) {
      for (const id in afterValue) {
        const cachedQuota = quota.value[id]
        const licenseRemoved = afterValue[id] === null
        const licenseExisted = beforeValue?.[id]

        if (cachedQuota) {
          if (licenseRemoved) {
            cachedQuota.used -= 1
            cachedQuota.available = cachedQuota.quota - cachedQuota.used
          } else if (licenseExisted) {
            // do nothing
          } else {
            cachedQuota.used += 1
            cachedQuota.available = cachedQuota.quota - cachedQuota.used
          }
        }
      }
    }

    function $reset() {
      ;(software.value = undefined),
        (optimizations.value = undefined),
        (documents.value = undefined),
        (costStatistics.value = undefined),
        (currentCost.value = undefined),
        (selectedSubAccount.value = undefined),
        (quota.value = {}),
        (licences.value = []),
        (licencesTable.value = {
          selectedLicences: undefined,
          searchQuery: '',
          isLoading: false,
          onlyShowInvites: false,
        })
    }

    return {
      initSoftware,
      initLicenseModels,
      $reset,
      software,
      optimizations,
      documents,
      costStatistics,
      currentCost,
      selectedSubAccount,
      licences,
      licencesTable,

      // Quota related
      quota,
      loadQuota,
      loadAllQuotas,
      updateQuota,
    }
  }
)
