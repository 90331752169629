/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Scopes {
    OWNER_READ = 'owner:read',
    OWNER_WRITE = 'owner:write',
    ADMIN_READ = 'admin:read',
    ADMIN_WRITE = 'admin:write',
    MEMBER_READ = 'member:read',
    MEMBER_WRITE = 'member:write',
}
