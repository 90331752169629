<template>
  <!-- Groups are currently only available at Microsoft but the
           feature is expected to be expanded to other software -->

  <div v-auto-animate class="group-wrapper">
    <div
      v-for="(item, key) in filteredLicenseGroups"
      :key="key"
      class="group border border-gray-300 dark:border-gray-700">
      <span class="name">
        {{ item.name }}
      </span>

      <div class="group-information">
        <!-- Creation Date -->
        <div
          v-if="item.application_specific?.createdDateTime"
          class="group-information-item">
          <span> {{ i18n.t('creationDate') }}: </span>
          <span>
            {{ item.application_specific.createdDateTime }}
          </span>
        </div>

        <!-- Description -->
        <div
          v-if="item.application_specific?.description"
          class="group-information-item">
          <span> {{ i18n.t('description') }}: </span>
          <span>
            {{ item.application_specific.description }}
          </span>
        </div>

        <!-- Mail enabled -->
        <div
          v-if="item.application_specific?.mailEnabled"
          class="group-information-item">
          <span> {{ i18n.t('email') }} {{ i18n.t('enabled') }}: </span>
          <span>
            {{ item.application_specific.mailEnabled }}
          </span>
        </div>

        <!-- Mail address -->
        <div
          v-if="item.application_specific?.mail"
          class="group-information-item">
          <span> {{ i18n.t('email') }}: </span>
          <span>
            {{ item.application_specific.mail }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { SoftwareOut } from '@/client'

  const i18n = useI18n()

  const filteredLicenseGroups = computed(() => {
    const _filtered = Object.entries(props.software.licence_groups).filter(
      ([, group]) => {
        return group.name.toLowerCase().includes(props.searchterm.toLowerCase())
      }
    )

    return Object.fromEntries(_filtered)
  })

  const props = defineProps<{
    software: SoftwareOut
    searchterm: string
  }>()
</script>

<style lang="scss" scoped>
  .group-wrapper {
    max-height: 50vh;
    overflow-y: auto;
  }

  .group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;

    border: 1px solid;

    .name {
      font-size: 1.1rem;
      font-weight: 500;
    }

    .group-information {
      width: 100%;

      .group-information-item {
        display: grid;
        grid-template-columns: 1fr 3fr;

        width: 100%;
        justify-content: space-between;

        // Allow line breaks
        word-break: break-all;
      }
    }
  }
</style>
