<!-- eslint-disable -->
<template>
  <div ref="el" :style="style" class="debugging-modal debugging-modal-fps">
    <div class="header">
      <div></div>
      <h1 class="heading">FPS</h1>
      <el-tooltip
        class="box-item"
        effect="light"
        placement="right"
        style="z-index: 100001">
        <div class="settings-icon">
          <v-icon
            name="io-settings"
            scale="1.2"
            animation="spin"
            speed="slow"
            fill="rgba(0, 0, 0, 0.9)"
            hover
            style="cursor: pointer" />
        </div>
        <template #content>
          <div class="box-item">
            <h2>Einstellungen</h2>
            <div class="fps-settings">
              <div class="fps-settings-row">
                <span>Every</span>
                <el-input-number
                  v-model="every"
                  size="small"
                  :min="1"
                  :max="1000"
                  :step="1"
                  :precision="0" />
              </div>
              <div class="fps-settings-row">
                <span>History Count</span>
                <el-input-number
                  v-model="historyCount"
                  :min="1"
                  :max="1000"
                  size="small"
                  :step="1"
                  :precision="0" />
              </div>
              <div class="fps-settings-row">
                <span>Show Graph</span>
                <el-switch v-model="showGraph" />
              </div>
            </div>
          </div>
        </template>
      </el-tooltip>
    </div>
    <div class="debugging-modal-content">
      <div class="debugging-modal-content-row">
        <span>FPS</span>
        <span :style="{ color: fpsColor }">{{ fps }}</span>
      </div>
      <div class="debugging-modal-content-row">
        <span>⌀</span>
        <span>{{ fpsAvg }}</span>
      </div>
      <div class="fps-graph">
        <BarChart
          v-if="showGraph"
          :chart-data="fpsData"
          :options="fpsChartOptions" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useDraggable, useFps, useInterval } from '@vueuse/core'
  import { BarChart } from 'vue-chart-3'

  const el: Ref<HTMLElement | null> = ref(null)

  // `style` will be a helper computed for `left: ?px; top: ?px;`
  const { style } = useDraggable(el, {
    initialValue: { x: 40, y: 40 },
  })

  //   Settings
  const every = ref(100)
  const historyCount = ref(100)
  const showGraph = ref(true)

  //   FPS
  const fps = useFps({ every: every.value }) // 1s
  const fpsColor = computed(() => {
    if (fps.value > 60) return 'green'
    if (fps.value > 30) return 'orange'
    return 'red'
  })
  const fpsHistory = ref<number[]>([])
  const fpsAvg = computed(() => {
    const sum = fpsHistory.value.reduce((a, b) => a + b, 0)
    const avg = sum / fpsHistory.value.length || 0
    return Math.round(avg)
  })
  const counter = useInterval(200)

  watch(counter, () => {
    fpsHistory.value.push(fps.value)
    if (fpsHistory.value.length > historyCount.value) fpsHistory.value.shift()
  })

  watch(historyCount, () => {
    while (fpsHistory.value.length > historyCount.value)
      fpsHistory.value.shift()
  })

  const fpsHistoryColors = computed(() => {
    return fpsHistory.value.map((fps) => {
      if (fps > 60) return 'rgba(0, 255, 0, 0.5)'
      if (fps > 30) return 'rgba(255, 255, 0, 0.5)'
      return 'rgba(255, 0, 0, 0.5)'
    })
  })

  //   Graph
  const fpsData = computed(() => ({
    labels: fpsHistory.value.map((_, i) => i),
    datasets: [
      {
        data: fpsHistory.value,
        backgroundColor: fpsHistoryColors.value,
        borderSkipped: false,
        borderRadius: 3,
      },
    ],
  }))

  const fpsChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 10,
        },
      },
    },
  }
</script>

<style scoped lang="scss">
  %glass-morph {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
  }

  .debugging-modal {
    &-fps {
      min-width: 600px !important;
      min-height: 400px !important;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      margin: 0;
    }

    &-content {
      height: 100%;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      flex: 1;

      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .fps-graph {
      flex: 1;
    }
  }

  .fps-settings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 300px;
    .fps-settings-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .settings-icon {
    font-size: 2rem;
    cursor: pointer;
    transition: rotate 1s ease-in-out;
  }
</style>
