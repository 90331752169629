import handleError from '@/middlewares/handleError'

import { AliasType } from './../types/user'

const defaultHeadersList = (jwt: string | undefined) => ({
  Accept: '*/*',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${jwt}`,
})

export async function deleteAlias(
  ip: string,
  jwt: string | undefined,
  email: string,
  alias: AliasType,
  preview: boolean,
  unassign_licences: boolean,
  sendNotification?: boolean
) {
  const queryString = `?${
    unassign_licences ? 'unassign_licences=' + unassign_licences + '&' : ''
  }${preview ? 'preview=' + preview : ''}`

  return fetch(
    `${ip}/api/v1/management/users/${email}/alias/${alias.name}${queryString}`,
    {
      method: 'DELETE',
      redirect: 'follow',
      headers: defaultHeadersList(jwt),
    }
  )
    .then((response) => handleError(response, sendNotification))
    .then(async (response) => await response.json())
}
