<template>
  <div class="invite-display">
    <img src="https://cdn.saasmetrix.io/img/invite.png" :alt="i18n.t('logo')" />
    <h2 style="font-size: 2rem">{{ i18n.t('invite') }}</h2>

    <SmDialogMessage
      v-if="showErrorMessage"
      :type="dialogData.type"
      :title="dialogData.title"
      :message="dialogData.text" />

    <div>
      <p>
        {{ i18n.t('youHaveBeenInvited.part1')
        }}<b>{{ invite?.invitee_name }}</b>
        {{ i18n.t('youHaveBeenInvited.part2') }}
        <b>{{ invite?.company_display_name }}</b>
        {{ i18n.t('youHaveBeenInvited.part3') }} <b>{{ invite?.role }}</b>
        {{ i18n.t('youHaveBeenInvited.part4') }}
      </p>
      <p>
        {{ i18n.t('clickTheButton') }}
      </p>
    </div>

    <div class="button-group">
      <SmButton type="primary" outline @click="declineInvite">
        {{ i18n.t('declineInvite') }}
      </SmButton>

      <SmButton type="primary" @click="acceptInvite">
        {{ i18n.t('acceptInvite') }}
      </SmButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import { InviteOutUser, UserProfileService } from '@/client'
  import { sendToast } from '@/components/sm/SmNotification'

  const router = useRouter()
  const i18n = useI18n()
  const dialogData = ref({
    type: 'error' as 'error' | 'success',
    title: i18n.t('invitationExpiredText'),
    text: i18n.t('invitationExpiredText'),
  })

  const props = defineProps({
    inviteToken: {
      type: String,
      required: true,
    },
  })

  const invite = ref<InviteOutUser | undefined>(undefined)

  const showErrorMessage = ref(false)

  function setInvalidTokenError() {
    dialogData.value.text = i18n.t('invitationExpiredText')
    dialogData.value.title = i18n.t('invitationExpiredHeading')
    dialogData.value.type = 'error'

    showErrorMessage.value = true
  }

  async function getInvite() {
    if (!props.inviteToken) {
      return undefined
    }
    return UserProfileService.getInviteInvitesTokenOutGet({
      tokenOut: props.inviteToken as string,
    }).then(
      (res) => {
        return res
      },
      () => {
        setInvalidTokenError()
        return undefined
      }
    )
  }

  async function acceptInvite() {
    UserProfileService.acceptInviteInvitesTokenOutAcceptPost({
      tokenOut: props.inviteToken as string,
    }).then(
      () => {
        // Redirect to company selection
        sendToast(i18n.t('invitationAccepted'), undefined, 'success')
        router.push({ path: '/login/company' })
      },
      () => {
        setInvalidTokenError()
      }
    )
  }

  function declineInvite() {
    UserProfileService.declineInviteInvitesTokenOutDeclinePost({
      tokenOut: props.inviteToken,
    }).then(
      () => {
        sendToast(i18n.t('invitationDeclined'), undefined, 'success')
        router.push({ path: '/login/company' })
      },
      () => {
        setInvalidTokenError()
      }
    )
  }

  onMounted(() => {
    getInvite().then((res) => {
      invite.value = res
    })
  })
</script>

<style lang="scss" scoped>
  .invite-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    gap: 1rem;
  }

  .button-group {
    display: flex;
    gap: 1rem;
  }

  img {
    width: 200px;
    height: 200px;
  }
</style>
<i18n lang="yaml" scope="local">
en:
  youHaveBeenInvited:
    part1: 'You have been invited by '
    part2: to join the company
    part3: as
    part4: '.'
  clickTheButton: Click the Accept-Button to follow the invitation and log in to your existing account.
  invitationExpiredHeading: Invitation expired or invalid
  invitationExpiredText: Your Invitation is either expired or invalid. Please have a new invitation sent to you.
  invitationAccepted: Invitation accepted
  invitationDeclined: Invitation declined
de:
  youHaveBeenInvited:
    part1: 'Du wurdest von '
    part2: eingeladen, dem Unternehmen
    part3: als
    part4: beizutreten.
  clickTheButton: Klicke auf den Button, um der Einladung zu folgen und dich mit deinem bestehenden Account anzumelden.
  invitationExpiredHeading: Einladung abgelaufen oder ungültig
  invitationExpiredText: Deine Einladung ist abgelaufen oder ungültig. Bitte lass dir eine neue Einladung zusenden.
  invitationAccepted: Einladung angenommen
  invitationDeclined: Einladung abgelehnt
</i18n>
