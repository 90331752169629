<template>
  <div class="wrapper">
    <div class="spinner"></div>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{ size?: 'small' | 'medium' | 'large' }>(),
    { size: 'small' }
  )
  const size = computed(() => {
    switch (props.size) {
      case 'small':
        return '22px'
      case 'medium':
        return '30px'
      case 'large':
        return '40px'
    }
  })
</script>
<style scoped>
  .wrapper {
    width: v-bind(size);
    height: v-bind(size);
  }

  .spinner {
    margin: 1px;
    width: calc(v-bind(size) - 2px);
    height: calc(v-bind(size) - 2px);
    border: 2px solid var(--sm-primary);
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
