<!--
  The headline on the Upper left on every view.
 -->
<template>
  <div class="header-container">
    <v-icon
      v-if="backArrowRoute"
      class="back-arrow fill-contrast"
      name="md-arrowbackiosnew-round"
      scale="1.2"
      animation="pulse"
      hover
      @click="router.push(backArrowRoute || '')" />
    <div class="title">
      <slot name="title">
        <h2 v-if="titleI18nCode">
          {{ i18n.t(titleI18nCode) }}
        </h2>
        <h2 v-else-if="title">{{ title }}</h2>
        <h2 v-else></h2>
      </slot>
    </div>
    <BaseHeaderMenu />
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import BaseHeaderMenu from './BaseHeaderMenu.vue'

  const i18n = useI18n()
  defineProps<{
    title?: string
    titleI18nCode?: string
    backArrowRoute?: string
  }>()
  const router = useRouter()
</script>

<style scoped lang="scss">
  .header-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    padding-bottom: 1rem;
  }
  .title {
    margin-right: auto;
  }
  .back-arrow {
    cursor: pointer;
  }
</style>
