/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Available frontend languages
 */
export enum PreferenceLanguages {
    EN = 'en',
    DE = 'de',
}
