<template>
  <LicenseModelEditDialog
    v-if="licenseToEdit"
    ref="form"
    v-model:visible="modelDialogVisible"
    v-model:softwareLicense="licenseToEdit"
    :prevent-submit="true"
    @saved="handleSaveSoftwareLicense()" />

  <el-form
    :model="softwareForm"
    label-width="150px"
    label-position="top"
    require-asterisk-position="right"
    @submit.prevent="handleSubmit">
    <div data-tutorial-id="application-inputs">
      <!-- Name -->
      <el-form-item
        :label="i18n.t('name')"
        prop="display_name"
        :error="getErrorMessage('display_name')">
        <el-input
          v-model="softwareForm.fields.display_name"
          style="max-width: 313px"
          :placeholder="i18n.t('name')"
          data-test-id="software-name"
          @blur="softwareForm.validate(['display_name'])" />
      </el-form-item>
      <!-- Short Description -->
      <el-form-item
        :label="
          i18n.t('drawers.editSoftwareDrawer.form.shortDescription.label')
        "
        prop="description_short">
        <el-input
          v-model="softwareForm.fields.desc_short"
          maxlength="150"
          show-word-limit
          :placeholder="
            i18n.t(
              'drawers.editSoftwareDrawer.form.shortDescription.placeholder'
            )
          "
          data-test-id="software-short-description" />
      </el-form-item>

      <!-- Description -->
      <el-form-item
        :label="i18n.t('drawers.editSoftwareDrawer.form.description.label')"
        prop="description">
        <el-input
          v-model="softwareForm.fields.desc"
          :autosize="{ minRows: 2, maxRows: 10 }"
          type="textarea"
          :placeholder="
            i18n.t('drawers.editSoftwareDrawer.form.description.placeholder')
          "
          data-test-id="software-description" />
      </el-form-item>

      <!-- Notes -->
      <el-form-item
        :label="i18n.t('drawers.editSoftwareDrawer.form.notes.label')"
        prop="notes">
        <el-input
          v-model="softwareForm.fields.notes"
          :autosize="{ minRows: 2, maxRows: 10 }"
          type="textarea"
          :placeholder="
            i18n.t('drawers.editSoftwareDrawer.form.notes.placeholder')
          "
          data-test-id="software-note" />
      </el-form-item>

      <!-- Select Licence Models -->

      <!-- Todo: Handle data of temporary licenses and priceInfos correctly and only submit on software save -->

      <!-- <el-form-item
        v-if="showSoftwareLicenseControl"
        :label="i18n.t('drawers.editSoftwareDrawer.form.licenceModels.label')">
        <el-card style="width: 100%" shadow="never">
          <LicenseModelsDisplayTable
            class="licence-model-table"
            :models="softwareLicensesToSave.map((model) => model.license)"
            @delete-model="handleDeleteModel"
            @edit-model="handleEditModel" />
          <div class="licence-model-actions">
            <SmButton
              size="small"
              type="secondary"
              @click="handleAddSoftwareLicense">
              {{ i18n.t('drawers.editSoftwareDrawer.form.licenceModels.add') }}
            </SmButton>
          </div>
        </el-card>
      </el-form-item> -->
    </div>
    <div class="button-wrapper">
      <SmButton
        outline
        :loading="softwareForm.processing"
        @click="handleCancelButtonClick">
        {{ i18n.t('general.cancel') }}
      </SmButton>
      <SmButton
        type="primary"
        :loading="softwareForm.processing"
        @click="handleSaveButtonClick">
        {{ i18n.t('general.save') }}
      </SmButton>
    </div>
  </el-form>
</template>
<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    PriceInformationPostIn,
    PriceInformationUpdate,
    SoftwareAuthorizationService,
    SoftwareLicense,
    SoftwareLicensePostIn,
    SoftwareOut,
    SoftwarePostIn,
    SoftwareService,
    SoftwareTypes,
  } from '@/client'
  import useForm from '@/common/form'
  import { saveNewSoftwareLicenseAndPriceInfo } from '@/common/license'
  import { useSoftwareStore } from '@/stores/softwareStore'

  import { getApiErrorMessage } from '@/common/util/apiError'
  import { sendToast } from './sm/SmNotification'
  import { ValidationResult, isRequired } from './sm/SmInput/SmInputValidator'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import LicenseModelEditDialog from './LicenseModelEditDialog.vue'

  const softwareStore = useSoftwareStore()
  const i18n = useI18n()

  const licenseToEdit = ref()

  const form = ref<ComponentExposed<typeof LicenseModelEditDialog> | null>()

  const modelDialogVisible = ref(false)

  // Props and Emits
  const props = defineProps({
    softwareName: {
      type: String,
      default: undefined,
    },
    softwareId: {
      type: String,
      default: undefined,
    },
    softwareType: {
      type: String as PropType<SoftwareTypes>,
      default: undefined,
    },
    softwareData: {
      type: Object as PropType<SoftwareOut>,
      default: undefined,
    },
    // used to enable/disable the licenceModelControl
    showSoftwareLicenseControl: {
      type: Boolean,
      default: false,
    },
    // used to display the right elNotification ('aktualisiert/hinzugefügt')
    isAlreadyExistingSoftware: {
      type: Boolean,
      default: true,
    },
    isNameEqualsToSearch: {
      type: Boolean,
      default: false,
    },
  })

  const showDrawer = defineModel('showDrawer', {
    type: Boolean,
    default: true,
  })

  type SoftwareLicenseToSave = {
    license: Partial<SoftwareLicense> & { _id: string }
    priceInformations: PriceInformationPostIn[] | PriceInformationUpdate[]
  }

  const softwareToSave = ref<SoftwarePostIn>({
    software_name: props.softwareName || '',
    display_name:
      props.softwareData?.display_name ||
      props.softwareData?.software_name ||
      '',
    desc_short: props.softwareData?.desc_short || '',
    desc: props.softwareData?.desc || '',
    notes: props.softwareData?.notes || '',
  })
  const softwareLicensesToSave = ref<SoftwareLicenseToSave[]>([])
  // const priceInformationStore = usePriceInformationStore()

  const softwareForm = useForm(
    {
      ...softwareToSave.value,
    },
    // Form Hooks
    {},
    // Form Validators
    {
      display_name: [isRequired],
    },
    {
      display_name: i18n.t('name'),
    }
  )

  // function createNewSoftwareLicense() {
  //   return ref({ _id: 'new-' + nanoid() })
  // }

  function getErrorMessage(fieldName: keyof SoftwarePostIn) {
    if (!((fieldName as string) in softwareForm.errors)) return ''
    const errors = softwareForm.errors[fieldName] as ValidationResult[]
    if (!errors || errors.length === 0 || !errors[0] || !errors[0].message)
      return ''
    const message = errors[0].message as string
    return message
  }

  async function handleSubmit() {

    if (props.isAlreadyExistingSoftware) {
      await updateSoftware()
    } else {
      await createSoftware()
      // for (const model of softwareLicensesToSave.value) {
      //   const license = await createLicense(software._id, model.license) // create license with software id
      //   await createPriceInformations(license._id, model.priceInformations) // create priceInformations with license id
      // }
    }
    // Software Saved
    await softwareStore.reloadSoftware()
  }

  // if the licenceModelControl is shown, check the licenceModelEditingState
  async function handleSaveButtonClick() {
    if (softwareForm.validate()) {
      await handleSubmit()
      showDrawer.value = false
    }
  }

  const handleCancelButtonClick = async () => {
    softwareLicensesToSave.value = []
    showDrawer.value = false
  }

  /*
   * License Model Related Functions
   */
  // function handleDeleteModel(id: string) {
  //   softwareLicensesToSave.value = softwareLicensesToSave.value.filter(
  //     (model) => model.license._id !== id
  //   )
  // }

  // function handleEditModel(id: string) {
  //   const existingModel = softwareLicensesToSave.value.find(
  //     (model) => model.license._id === id
  //   )
  //   if (!existingModel) return
  //   licenseToEdit.value = {
  //     ...existingModel.license,
  //   }

  //   modelDialogVisible.value = true
  // }

  function handleSaveSoftwareLicense() {
    const licenseModelForm = form.value?.form?.licenseModelForm?.fields
    const model = {
      license: {
        ...(licenseModelForm as Partial<SoftwareLicense>),
      },

      priceInformations: form.value?.form?.priceInformationForms?.map(
        (form) => form?.fields as PriceInformationPostIn
      ),
    }

    const existingIndex = softwareLicensesToSave.value.findIndex(
      (l) => l.license._id === licenseModelForm?._id
    )

    if (existingIndex > -1) {
      // update
      softwareLicensesToSave.value[existingIndex] =
        model as SoftwareLicenseToSave
    } else {
      //create
      softwareLicensesToSave.value.push(model as SoftwareLicenseToSave)
    }

    modelDialogVisible.value = false
  }

  // function handleAddSoftwareLicense() {
  //   licenseToEdit.value = createNewSoftwareLicense()
  //   modelDialogVisible.value = true
  // }

  /**
   * Software Related Functions
   */

  async function updateSoftware() {
    await softwareForm.submit(async (fields) => {
      return await SoftwareService.putSoftwareApiV1SoftwareSoftwareSoftwareIdPut(
        {
          requestBody: fields,
          softwareId: props.softwareId as string,
        }
      ).then((software) => {
        softwareLicensesToSave.value.forEach((l) => {
          saveNewSoftwareLicenseAndPriceInfo(
            software._id,
            l.license as SoftwareLicensePostIn,
            l.priceInformations[0] as PriceInformationPostIn
          ).catch((err) => {
            sendToast(
              getApiErrorMessage(err, i18n as ReturnType<typeof useI18n>),
              undefined,
              'error'
            )
          })
        })
      })
    })
  }

  async function createSoftware() {
    let softwareResult

    await softwareForm.submit(async (fields) => {
      return await SoftwareAuthorizationService.postSoftwareApiV1SoftwareSoftwarePost(
        {
          requestBody: {
            ...fields,
            type: SoftwareTypes.MANUALLY, // Currently only manually created software is supported
            software_name:
              (props.softwareName as string) || (fields.display_name as string),
          },
        }
      )
        .then((software) => {
          softwareResult = software
          sendToast(
            i18n.t('notifications.editSoftwareForm.updateSuccess.title'),
            i18n.t('notifications.editSoftwareForm.updateSuccess.message', {
              softwareName: softwareForm.fields.display_name,
            }),
            'success'
          )
        })
        .catch((err) => {
          sendToast(
            getApiErrorMessage(err, i18n as ReturnType<typeof useI18n>),
            undefined,
            'error'
          )
        })
    })

    return softwareResult
  }

  // async function createLicense(softwareId: string, model: SoftwareLicense) {
  //   let license

  //   let newlyCreated = false
  //   if (model._id?.startsWith('new-')) {
  //     newlyCreated = true
  //   }

  //   // Update in backend
  //   if (!newlyCreated) {
  //     await updateSoftwareLicense(model, model._id)

  //     return
  //   }

  //   // Create in backend
  //   if (softwareId) {
  //     await saveNewSoftwareLicense(
  //       model as SoftwareLicensePostIn,
  //       softwareId
  //     ).then((res) => {
  //       license = res as SoftwareLicense
  //     })
  //   }

  //   return license
  // }

  // async function createPriceInformations(
  //   licenseId: string,
  //   model: PriceInformation[]
  // ) {
  //   model.forEach((form) => {
  //     // Check if newly created
  //     let newlyCreated = false
  //     if (form._id?.startsWith('new-')) {
  //       newlyCreated = true
  //     }

  //     // Update in backend
  //     if (!newlyCreated) {
  //       priceInformationStore.updatePriceInformation(licenseId, form)
  //       return
  //     }

  //     // Create in backend
  //     priceInformationStore.createPriceInformation(licenseId, form)
  //   })
  // }
</script>

<style scoped>
  .button-wrapper {
    display: flex;
    justify-content: flex-end;

    margin-top: 2rem;
  }

  .licence-model-actions {
    display: flex;
    justify-content: flex-start;
  }

  .licence-model-table {
    padding-bottom: 1rem;
  }
</style>
