<template>
  <DocumentUploadDialog
    v-model:show-drawer="createDocumentDrawerVisibility"
    :software-id="null"
    :edit-mode="false"
    view-mode
    @upload-document-success="updateTable()" />

  <!-- Page Content -->
  <div class="home">
    <ViewHeader :title-i18n-code="'views.documents.title'" />
    <div class="documents-grid">
      <div
        class="table-controls-container"
        style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        ">
        <div data-tutorial-id="documents-date-range">
          <el-date-picker
            v-model="requestedRange"
            type="monthrange"
            range-separator="-"
            :start-placeholder="i18n.t('views.documents.dateRange.from')"
            :end-placeholder="i18n.t('views.documents.dateRange.to')"
            format="MMM. YYYY"
            style="max-width: 300px" />
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            align-items: center;
          ">
          <el-dropdown
            v-if="selectedDocumentCount > 0"
            ref="actionDropdownRef"
            trigger="click">
            <SmButton type="secondary">
              {{
                i18n.t('views.documents.selectButton.text', {
                  count: selectedDocumentCount,
                })
              }}
            </SmButton>

            <template #dropdown>
              <div class="selection-edit-dropdown-wrapper">
                <el-button
                  type="primary"
                  size="small"
                  :disabled="selectedDocumentCount <= 0"
                  @click="downloadSelection">
                  {{
                    i18n.t('views.documents.selectButton.downloadButtonText', {
                      count: selectedDocumentCount,
                      documentsText: i18n.t(
                        selectedDocumentCount === 1
                          ? 'views.documents.selectButton.document'
                          : 'views.documents.selectButton.documents'
                      ),
                    })
                  }}
                </el-button>
                <DeleteConfirmPopup
                  v-require-permission="CompanyAccessRole.ADMIN"
                  :title="
                    i18n.t('views.documents.selectButton.deleteButtonText', {
                      count: selectedDocumentCount,
                      documentsText: i18n.t(
                        selectedDocumentCount === 1
                          ? 'views.documents.selectButton.document'
                          : 'views.documents.selectButton.documents'
                      ),
                    })
                  "
                  @confirm="handleBulkDocumentDelete">
                  <el-button
                    type="danger"
                    variant="outline"
                    size="small"
                    style="margin: 0"
                    :disabled="selectedDocumentCount <= 0">
                    {{
                      i18n.t('views.documents.selectButton.deleteButtonText', {
                        count: selectedDocumentCount,
                        documentsText: i18n.t(
                          selectedDocumentCount === 1
                            ? 'views.documents.selectButton.document'
                            : 'views.documents.selectButton.documents'
                        ),
                      })
                    }}
                  </el-button>
                </DeleteConfirmPopup>
              </div>
            </template>
          </el-dropdown>
          <SmInput
            v-model="searchQuery"
            outline
            style="width: 250px"
            size="small"
            :label="i18n.t('views.documents.table.searchPlaceholder')" />
          <SmButton
            v-require-permission="CompanyAccessRole.ADMIN"
            @click="createDocumentDrawerVisibility = true">
            {{ i18n.t('views.documents.addDocument') }}
          </SmButton>
        </div>
      </div>
      <el-card shadow="always">
        <BaseSoftwareDocumentTable
          :key="tableKey"
          ref="tableRef"
          v-model:search-query="searchQuery"
          v-model:selected-date-range="requestedRange"
          view-mode
          :small-card-mode="false"
          @load-document-list="tableLoading = true"
          @document-list-loaded="tableLoading = false"
          @selected-document-count-changed="
            (count: number) => (selectedDocumentCount = count)
          " />
      </el-card>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'

  import { CompanyAccessRole } from '@/client'
  import BaseSoftwareDocumentTable from '@/components/BaseSoftwareDocumentTable.vue'
  import DeleteConfirmPopup from '@/components/DeleteConfirmPopup.vue'
  import DocumentUploadDialog from '@/components/DocumentUploadDialog.vue'
  import ViewHeader from '@/components/ViewHeader.vue'

  const i18n = useI18n()
  const route = useRoute()

  const requestedRange = ref<[string, string]>()
  const tableLoading = ref<boolean>()
  const tableRef = ref<typeof BaseSoftwareDocumentTable>()
  const tableKey = ref<number>(0)
  const actionDropdownRef = ref()
  const searchQuery = ref<string>('')

  const selectedDocumentCount = ref<number>(0)

  const updateTable = () => {
    tableKey.value = tableKey.value += 1
  }

  const downloadSelection = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (tableRef.value) tableRef.value.downloadDocumentList()
    actionDropdownRef.value.handleClose()
  }

  const handleBulkDocumentDelete = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (tableRef.value) tableRef.value.deleteDocuments()
    actionDropdownRef.value.handleClose()
  }

  // Toggles add document drawer
  const createDocumentDrawerVisibility = ref<boolean>(
    route.query['open-add-document-drawer'] === 'true'
  )
  watchEffect(() => {
    if (route.query['open-add-document-drawer']) {
      createDocumentDrawerVisibility.value =
        route.query['open-add-document-drawer'] === 'true'
    }
  })
</script>

<style scoped>
  .header-grid {
    display: flex;
    justify-content: space-between;
  }
  .documents-grid {
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .selection-edit-dropdown-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px 10px;
  }
  .loading-select-button {
    color: var(--el-disabled-text-color);
  }
  .loading-select-button :hover {
    color: var(--el-disabled-text-color);
  }
</style>
