// Utility functions for license management
// ----------------------------------------------------------------------

import {
  PriceInformationPostIn,
  SoftwareLicense,
  SoftwareLicensePostIn,
  SoftwareLicenseUpdateIn,
  SoftwareLicensesService,
} from '@/client'
import { sendToast } from '@/components/sm/SmNotification'
import i18n from '@/i18n/i18n'
import { useSoftwareStore } from '@/stores/softwareStore'

// ----------------------------------------------------------------------
// Functions
// ----------------------------------------------------------------------

// Save a new license model
export async function saveNewSoftwareLicense(
  model: SoftwareLicensePostIn,
  softwareId: string
) {
  const softwareStore = useSoftwareStore()

  return SoftwareLicensesService.postSoftwareSoftwareLicenseApiV1SoftwareSoftwareSoftwareIdLicensesPost(
    {
      softwareId: softwareId,
      requestBody: model,
    }
  )
    .then(
      (response) => {
        return response
      },
      () => {
        sendToast(
          i18n.global.t(
            'dialogs.licenceModelEditDialog.licenseModelSaveFailed.title'
          ),
          i18n.global.t(
            'dialogs.licenceModelEditDialog.licenseModelSaveFailed.message'
          ),
          'error'
        )
      }
    )
    .finally(() => {
      softwareStore.reloadSoftware()
    })
}

// Update an existing license model
export async function updateSoftwareLicense(
  model: SoftwareLicenseUpdateIn,
  softwareLicenseId: string
) {
  const softwareStore = useSoftwareStore()

  return SoftwareLicensesService.patchSoftwareSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdPatch(
    {
      softwareLicenseId: softwareLicenseId,
      requestBody: model,
    }
  )
    .then(
      (response) => {
        return response
      },
      () => {
        sendToast(
          i18n.global.t(
            'dialogs.licenceModelEditDialog.licenseModelSaveFailed.title'
          ),
          i18n.global.t(
            'dialogs.licenceModelEditDialog.licenseModelSaveFailed.message'
          ),
          'error'
        )
      }
    )
    .finally(() => {
      softwareStore.reloadSoftware()
    })
}

// Delete an existing license model
export async function deleteSoftwareLicense(softwareLicenseId: string) {
  return SoftwareLicensesService.deleteSoftwareSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdDelete(
    {
      softwareLicenseId,
    }
  ).then(
    (response) => {
      sendToast(i18n.global.t('licenseModelDeleted'), undefined, 'success')
      return response
    },
    () => {
      sendToast(
        i18n.global.t('licenseModelDeleteFailed.title'),
        i18n.global.t('licenseModelDeleteFailed.message'),
        'error'
      )
    }
  )
}

export async function saveNewSoftwareLicenseAndPriceInfo(
  softwareId: string,
  softwareLicense: SoftwareLicensePostIn,
  priceInformation: PriceInformationPostIn
) {
  return SoftwareLicensesService.postSoftwareSoftwareLicenseApiV1SoftwareSoftwareSoftwareIdLicensesPost(
    {
      softwareId: softwareId,
      requestBody: softwareLicense,
    }
  ).then((response) => {
    return SoftwareLicensesService.createSoftwareLicensePriceInformationApiV1SoftwareSoftwareLicensesSoftwareLicenseIdPriceInformationsPost(
      {
        softwareLicenseId: response._id,
        requestBody: priceInformation,
      }
    )
  })
}

export async function getSoftwareLicenses(
  software_id: string
): Promise<{ license: SoftwareLicense }[]> {
  const returnArray = [] as {
    license: SoftwareLicense
  }[]
  const softwareLicenses =
    await SoftwareLicensesService.getSoftwareLicensesForSoftwareApiV1SoftwareSoftwareSoftwareIdLicensesGet(
      { softwareId: software_id }
    )
  const finalPromise = new Promise((resolve) => {
    softwareLicenses.forEach(async (license) => {
      returnArray.push({
        license: license,
      })
      if (returnArray.length === softwareLicenses.length) resolve(null)
    })
    if (softwareLicenses.length === 0) resolve(null)
  })
  await finalPromise
  return returnArray
}
