<template>
  <div
    class="user-grid-item-card-item"
    style=""
    :class="selected ? 'selected' : ''">
    <div style="padding: 0.75rem">
      <SmAvatar
        :name="item.label"
        class="shadow-2xl avatar"
        style="margin-left: auto; margin-right: auto" />
      <div style="margin-top: 0.75rem">
        <TextMultiline :lines-to-show="2">
          <p style="text-align: center">
            {{ props.item.label }}
          </p>
        </TextMultiline>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { selectionGridItem } from './sm/SmSelectionGrid.types'

  const props = defineProps({
    item: {
      type: Object as PropType<selectionGridItem>,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  })
</script>

<style>
  .user-grid-item-card-item {
    cursor: pointer;
    transition: all 0.2s;

    border: 1px solid var(--el-border-color);
    border-radius: var(--border-radius);
    width: calc(100% - 20px);
    max-width: 100%;
    height: calc(100% - 20px);
    max-height: 100%;
    margin-right: 20px;
  }

  .user-grid-item-card-item:hover {
    transform: scale(1.01);
  }

  .selected {
    border: 1px solid var(--sm-primary);
    background-color: var(--el-color-primary-light-9);
  }

  .selected p {
    font-weight: bold;
  }

  .avatar {
    background-color: #fff;
  }
</style>
