<!-- 
    FLoating Chat Button
 -->
<template>
  <button class="chat-button open-zammad-chat" @click="handleClickOpen">
    <v-icon name="md-chatbubble-round" class="fill-background" />
  </button>
</template>

<script setup lang="ts">
  import { useSessionStore } from '@/stores/sessionStore'
  import { loadScript } from 'vue-plugin-load-script'

  // Mock ZammadChat type to avoid errors (only used types --> in reality there is much more)
  // https://github.com/zammad/zammad/blob/develop/public/assets/chat/chat.js#L987
  interface ZammadChatType {
    onConnectionEstablished: (data: unknown) => void
    send: (type: string, data: unknown) => void
    open: () => void
    sessionId: string
    prototype: {
      onConnectionEstablished: (data: unknown) => void
      onReady: (data: unknown) => void
      onCloseAnimationEnd: (data: unknown) => void
      onError: (data: unknown) => void
      onCssLoaded: (data: unknown) => void
      chatId: number
      show: boolean
      host: string
      debug: boolean
      title: string
      isOpen: boolean
    }
  }

  const sessionStore = useSessionStore()

  const chat = ref<ZammadChatType | null>(null)
  function handleClickOpen() {
    // Find element with class .zammad-chat and set display to initial
    const chatElement = document.querySelector('.zammad-chat')

    if (chatElement) {
      chatElement.setAttribute('style', 'display: flex')
    }

    if (chat.value) {
      chat.value.open()
    }
  }

  async function mountChat() {
    await loadScript('https://code.jquery.com/jquery-3.6.0.min.js')
    await loadScript('https://ticket.saasmetrix.io/assets/chat/chat.min.js')

    const options = {
      background: '#1a232d',
      fontSize: '12px',
      chatId: 1,
      show: true,
      preferences: { dns_name: 'ticket.saasmetrix.io' },
      host: 'wss://ticket.saasmetrix.io/ws',
      cssUrl: 'https://ticket.saasmetrix.io/assets/chat/chat.css',
    }

    // @ts-expect-error: Import ZammadChat from js
    chat.value = new ZammadChat({
      ...options,
    }) as ZammadChatType

    chat.value.onConnectionEstablished = (data) => {
      // @ts-expect-error: Import ZammadChat from js
      ZammadChat.prototype.onConnectionEstablished.call(chat.value, data)

      if (!chat.value) return

      // Send user information to Zammad
      chat.value.send('chat_session_notice', {
        session_id: chat.value.sessionId,
        message: `User: ${sessionStore.currentUser?.display_name} (${sessionStore.currentUser?.email})`,
      })

      // Send company information to Zammad
      chat.value.send('chat_session_notice', {
        session_id: chat.value.sessionId,
        message: `Company: ${sessionStore.currentCompany?.name} (${sessionStore.currentCompany?.company_id})`,
      })
    }
  }

  onMounted(async () => {
    await mountChat()
  })
</script>

<style scoped lang="scss">
  @import url(https://ticket.saasmetrix.io/assets/chat/chat.css);
  .chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--sm-contrast);
    color: white;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: var(--shadow);
    transition: all 0.3s ease-in-out;
    z-index: 100;

    &:hover {
      transform: scale(1.1);
    }
  }
</style>
