type SortOrder = 'asc' | 'desc'

/**
 * Sort a table column by a given order
 * Can be used to sort a table column by ascending or descending order
 * Following types are supported:
 * - string
 * - number
 * - boolean
 * - date
 * If a value is none or undefined, it will be sorted as null/smaller
 *
 * @param a - first value to compare
 * @param b - second value to compare
 * @param sortKey - key to sort by
 * @param order - sort order
 * @returns -1 if a < b, 1 if a > b, 0 if a == b (naja gerade noch nicht xD)
 **/

export function useSortTableColumn(
  aValue: string | number | Date | unknown,
  bValue: string | number | Date | unknown,
  sortOrder: SortOrder
): number {
  const sortOrderAsc = sortOrder === 'asc'

  const aNumber = Number(aValue)
  const bNumber = Number(bValue)

  const aString = String(aValue)
  const bString = String(bValue)

  const aDate = new Date(aValue as string)
  const bDate = new Date(bValue as string)

  const aIsNumber = !isNaN(aNumber)
  const bIsNumber = !isNaN(bNumber)

  const aIsString = typeof aString === 'string'
  const bIsString = typeof bString === 'string'

  const aIsDate = aDate instanceof Date
  const bIsDate = bDate instanceof Date

  const aIsBoolean = typeof aValue === 'boolean'
  const bIsBoolean = typeof bValue === 'boolean'

  const aIsNull = aValue === null
  const bIsNull = bValue === null

  const aIsUndefined = typeof aValue === 'undefined'
  const bIsUndefined = typeof bValue === 'undefined'

  // Null and undefined checks
  // A + before an boolean converts it to a number
  if (aIsNull || bIsNull) {
    if (aIsNull && bIsNull) {
      return 0
    } else if (aIsNull) {
      return -1
    } else {
      return 1
    }
  }

  if (aIsUndefined || bIsUndefined) {
    if (aIsUndefined && bIsUndefined) {
      return 0
    } else if (aIsUndefined) {
      return -1
    } else {
      return 1
    }
  }

  // Number checks
  if (aIsNumber && bIsNumber) {
    if (sortOrderAsc) {
      return Number(aNumber <= bNumber)
    } else {
      return Number(bNumber <= aNumber)
    }
  }

  // String checks
  if (aIsString && bIsString) {
    if (sortOrderAsc) {
      return aString.localeCompare(bString)
    } else {
      return bString.localeCompare(aString)
    }
  }

  // Date checks
  if (aIsDate && bIsDate) {
    if (sortOrderAsc) {
      return aDate.getTime() - bDate.getTime()
    } else {
      return bDate.getTime() - aDate.getTime()
    }
  }

  // Boolean checks
  if (aIsBoolean && bIsBoolean) {
    if (sortOrderAsc) {
      return +aValue - +bValue
    }
    return +bValue - +aValue
  }

  return -1
}
