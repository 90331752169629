<script lang="ts" setup>
  import { type HTMLAttributes, computed } from 'vue'
  import {
    RangeCalendarCell,
    type RangeCalendarCellProps,
    useForwardProps,
  } from 'radix-vue'
  import { cn } from '@/lib/utils'

  const props = defineProps<
    RangeCalendarCellProps & { class?: HTMLAttributes['class'] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <RangeCalendarCell
    :class="
      cn(
        'relative h-9 w-9 p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([data-selected])]:bg-accent first:[&:has([data-selected])]:rounded-l-sm last:[&:has([data-selected])]:rounded-r-sm [&:has([data-selected][data-outside-view])]:bg-accent/50 [&:has([data-selected][data-selection-end])]:rounded-r-sm [&:has([data-selected][data-selection-start])]:rounded-l-sm',
        props.class
      )
    "
    v-bind="forwardedProps">
    <slot />
  </RangeCalendarCell>
</template>
