import { defineStore } from 'pinia'

export interface NotificationIn {
  title: string
  message?: string
  type?: 'success' | 'error' | 'info' | 'warning' | 'loading'
  duration?: number
  showClose?: boolean
  onClose?: () => void
}

export interface Notification extends NotificationIn {
  id: symbol
}

export const notificationManager = defineStore('notifications', {
  state: () => ({
    notifications: [] as Notification[],
  }),

  getters: {
    getNotifications: (state) => state.notifications,
  },

  actions: {
    addNotification(notification: NotificationIn) {
      // Add a unique id to the notification so we can remove/edit it later
      const notificationNew: Notification = {
        id: Symbol(),
        ...notification,
      }
      this.notifications.push(notificationNew)
    },
    removeNotification(id: symbol) {
      this.notifications = this.notifications.filter((n) => n.id !== id)
    },
    editNotification(id: symbol, notification: NotificationIn) {
      const index = this.notifications.findIndex((n) => n.id === id)
      if (index !== -1) {
        this.notifications[index] = {
          id: this.notifications[index].id,
          ...notification,
        }
      }
    },
    removeAllNotifications() {
      this.notifications = []
    },
  },
})

export function sendToast(
  title: string,
  message: string | undefined = undefined,
  type: 'success' | 'error' | 'info' | 'warning' | 'loading' = 'info',
  duration = 2000,
  showClose = true,
  onClose?: () => void
) {
  const manager = notificationManager()
  manager.addNotification({
    message,
    title,
    type,
    duration,
    showClose,
    onClose,
  })
  return manager.notifications[manager.notifications.length - 1].id
}

export function editToast(id: symbol, notification: NotificationIn) {
  const manager = notificationManager()
  manager.editNotification(id, notification)
}
