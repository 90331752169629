/* 
Dialog Manager

This composable manages the dialog states and visibilities.
*/

export const dialogStates = ref<Record<string, boolean>>({
  settings: false,
  feedback: false,
})

export function openDialog(dialogName: keyof typeof dialogStates.value) {
  dialogStates.value[dialogName] = true
}

export function closeDialog(dialogName: keyof typeof dialogStates.value) {
  dialogStates.value[dialogName] = false
}
