<template>
  <svg
    width="333"
    height="36"
    viewBox="0 0 333 36"
    :fill="props.fill"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2_3)">
      <path
        d="M13 35.7C10.6 35.7 8.40001 35.3 6.50001 34.4C4.60001 33.5 3.00001 32.4 1.90001 31C0.800006 29.6 0.200006 28 0.100006 26.3H7.70001C7.80001 27.4 8.40001 28.3 9.40001 29C10.4 29.7 11.6 30.1 13.1 30.1C14.5 30.1 15.6 29.8 16.5 29.3C17.3 28.8 17.7 28.1 17.7 27.2C17.7 26.3 17.2 25.6 16.2 25.1C15.2 24.6 13.7 24.1 11.5 23.6C9.30001 23.1 7.50001 22.6 6.10001 22C4.70001 21.5 3.50001 20.6 2.50001 19.5C1.50001 18.4 1.00001 16.9 1.00001 15C1.00001 13.4 1.50001 12 2.40001 10.7C3.30001 9.4 4.70001 8.4 6.50001 7.6C8.00001 6.9 10 6.5 12.4 6.5C15.9 6.5 18.8 7.3 20.9 9C23 10.7 24.2 13 24.4 15.8H17.2C17.1 14.7 16.6 13.8 15.7 13.1C14.8 12.4 13.7 12.1 12.2 12.1C10.8 12.1 9.80001 12.3 9.10001 12.8C8.40001 13.3 8.00001 13.9 8.00001 14.8C8.00001 15.8 8.50001 16.5 9.50001 17C10.5 17.5 12.1 18 14.2 18.5C16.3 19 18.1 19.5 19.5 20.1C20.9 20.6 22.1 21.5 23.1 22.6C24.1 23.7 24.6 25.2 24.7 27.1C24.7 28.7 24.2 30.2 23.3 31.5C22.4 32.8 21 33.8 19.2 34.5C17.4 35.4 15.3 35.7 13 35.7Z"
        :class="fill ? undefined : `st1-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M30.6 21C30.6 18.1 31.2 15.6 32.4 13.4C33.6 11.2 35.2 9.5 37.3 8.3C39.4 7.1 41.7 6.5 44.2 6.5C46.4 6.5 48.4 6.9 50 7.8C51.7 8.7 53 9.7 54 11V7H61.6V35.3H54V31.2C53 32.5 51.7 33.6 50 34.5C48.3 35.4 46.3 35.8 44.1 35.8C41.6 35.8 39.3 35.2 37.3 34C35.2 32.8 33.6 31 32.4 28.8C31.2 26.5 30.6 23.9 30.6 21ZM53.9 21.1C53.9 19.4 53.5 17.9 52.8 16.6C52.1 15.4 51.1 14.4 49.9 13.7C48.7 13 47.4 12.7 46 12.7C44.6 12.7 43.3 13 42.1 13.7C40.9 14.3 40 15.3 39.2 16.5C38.5 17.7 38.1 19.2 38.1 20.9C38.1 22.6 38.5 24.1 39.2 25.4C39.9 26.7 40.9 27.7 42.1 28.3C43.3 29 44.6 29.3 45.9 29.3C47.3 29.3 48.6 29 49.8 28.3C51 27.6 52 26.7 52.7 25.4C53.6 24.4 53.9 22.9 53.9 21.1Z"
        :class="fill ? undefined : `st1-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M68.8 21C68.8 18.1 69.4 15.6 70.6 13.4C71.8 11.2 73.4 9.5 75.5 8.3C77.6 7.1 79.9 6.5 82.4 6.5C84.6 6.5 86.6 6.9 88.2 7.8C89.9 8.7 91.2 9.7 92.2 11V7H99.8V35.3H92.2V31.2C91.2 32.5 89.9 33.6 88.2 34.5C86.5 35.4 84.5 35.8 82.3 35.8C79.8 35.8 77.5 35.2 75.5 34C73.4 32.8 71.8 31 70.6 28.8C69.4 26.5 68.8 23.9 68.8 21ZM92.2 21.1C92.2 19.4 91.8 17.9 91.1 16.6C90.4 15.4 89.4 14.4 88.2 13.7C87 13 85.7 12.7 84.3 12.7C82.9 12.7 81.6 13 80.4 13.7C79.2 14.3 78.3 15.3 77.5 16.5C76.8 17.7 76.4 19.2 76.4 20.9C76.4 22.6 76.8 24.1 77.5 25.4C78.2 26.7 79.2 27.7 80.4 28.3C81.6 29 82.9 29.3 84.2 29.3C85.6 29.3 86.9 29 88.1 28.3C89.3 27.6 90.3 26.7 91 25.4C91.8 24.4 92.2 22.9 92.2 21.1Z"
        :class="fill ? undefined : `st1-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M120.6 35.7C118.2 35.7 116 35.3 114.1 34.4C112.2 33.6 110.6 32.4 109.5 31C108.4 29.6 107.8 28 107.6 26.3H115.2C115.3 27.4 115.9 28.3 116.9 29C117.9 29.7 119.1 30.1 120.6 30.1C122 30.1 123.1 29.8 124 29.3C124.8 28.8 125.2 28.1 125.2 27.2C125.2 26.3 124.7 25.6 123.7 25.1C122.7 24.6 121.2 24.1 119 23.6C116.8 23.1 115 22.6 113.6 22C112.2 21.5 111 20.6 110 19.5C109 18.4 108.5 16.9 108.5 15C108.5 13.4 109 12 109.9 10.7C110.8 9.4 112.2 8.4 114 7.6C115.8 6.9 117.9 6.5 120.2 6.5C123.7 6.5 126.6 7.3 128.7 9C130.8 10.7 132 13 132.2 15.8H125C124.9 14.7 124.4 13.8 123.5 13.1C122.6 12.4 121.5 12.1 120 12.1C118.6 12.1 117.6 12.3 116.9 12.8C116.2 13.3 115.8 13.9 115.8 14.8C115.8 15.8 116.3 16.5 117.3 17C118.3 17.5 119.9 18 122 18.5C124.1 19 125.9 19.5 127.3 20.1C128.7 20.6 129.9 21.5 130.9 22.6C131.9 23.7 132.4 25.2 132.5 27.1C132.5 28.7 132 30.2 131.1 31.5C130.2 32.8 128.8 33.8 127 34.5C125 35.4 123 35.7 120.6 35.7Z"
        :class="fill ? undefined : `st1-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M178 6.59998C181.6 6.59998 184.6 7.69998 186.8 9.79998C189 11.9 190.2 14.9 190.2 18.7V35.3H182.7V19.7C182.7 17.5 182.1 15.8 180.9 14.6C179.7 13.4 178.1 12.8 176.1 12.8C174.1 12.8 172.4 13.4 171.2 14.6C170 15.8 169.4 17.5 169.4 19.7V35.3H161.9V19.7C161.9 17.5 161.3 15.8 160.1 14.6C158.9 13.4 157.3 12.8 155.3 12.8C153.2 12.8 151.6 13.4 150.4 14.6C149.2 15.8 148.6 17.5 148.6 19.7V35.3H141.1V6.99998H148.6V10.4C149.6 9.19998 150.8 8.29998 152.3 7.59998C153.8 6.89998 155.5 6.59998 157.3 6.59998C159.6 6.59998 161.7 7.09998 163.5 7.99998C165.3 8.89998 166.7 10.3 167.7 12C168.7 10.4 170.1 9.09998 171.9 8.09998C173.9 7.09998 175.9 6.59998 178 6.59998Z"
        :class="fill ? undefined : `st0-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M227.6 20.5C227.6 21.5 227.5 22.4 227.4 23.3H205.7C205.9 25.3 206.6 26.9 208 28.1C209.3 29.3 210.9 29.8 212.9 29.8C215.7 29.8 217.7 28.7 218.9 26.4H227C226.1 29.1 224.5 31.4 222.1 33.1C219.7 34.9 216.7 35.7 213.1 35.7C210.2 35.7 207.7 35.1 205.4 33.9C203.1 32.7 201.4 31 200.1 28.8C198.8 26.6 198.2 24 198.2 21.1C198.2 18.2 198.8 15.6 200.1 13.4C201.4 11.2 203.1 9.5 205.4 8.3C207.7 7.1 210.2 6.5 213.2 6.5C216 6.5 218.6 7.1 220.8 8.2C223 9.4 224.8 11 226 13.1C227 15.3 227.6 17.8 227.6 20.5ZM219.8 18.5C219.8 16.7 219.1 15.2 217.7 14.1C216.3 13 214.7 12.4 212.7 12.4C210.8 12.4 209.3 12.9 208 14C206.7 15.1 206 16.6 205.7 18.5H219.8Z"
        :class="fill ? undefined : `st0-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M244.2 12.9V26.6C244.2 27.6 244.4 28.2 244.9 28.7C245.4 29.1 246.2 29.3 247.3 29.3H250.8V35.3H246.1C239.8 35.3 236.6 32.4 236.6 26.5V0H244.2V7H250.8V12.9H244.2Z"
        :class="fill ? undefined : `st0-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M266.7 11.4C267.7 9.89998 268.9 8.69998 270.5 7.89998C272.1 6.99998 273.8 6.59998 275.8 6.59998V14.1H273.8C271.4 14.1 269.7 14.6 268.5 15.7C267.3 16.8 266.7 18.6 266.7 21.2V35.3H259.2V6.99998H266.7V11.4Z"
        :class="fill ? undefined : `st0-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M291.2 7V35.3H283.7V7H291.2Z"
        :class="fill ? undefined : `st0-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M308 21.1L311.7 26.5L306.3 35.3H298.3L308 21.1Z"
        :class="fill ? undefined : `st0-${props.isDark ? 'dark' : 'light'}`" />
      <path
        d="M300.1 0H308.2L315.7 10.9L322.6 0H332.6L320.4 17.6L332.7 35.3H324L300.1 0Z"
        :class="fill ? undefined : `st1-${props.isDark ? 'dark' : 'light'}`" />
    </g>
    <defs>
      <clipPath id="clip0_2_3">
        <rect width="332.7" height="35.3" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
  const props = defineProps({
    isDark: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: String,
      default: undefined,
    },
  })
</script>

<style scoped>
  .st0-light {
    fill: #1b242e;
    transition: fill 0.3s ease;
  }
  .st1-light {
    fill: #08e9c5;
    transition: fill 0.3s ease;
  }

  .st0-dark {
    fill: white;
    transition: fill 0.3s ease;
  }

  .st1-dark {
    fill: #08e9c5;
    transition: fill 0.3s ease;
  }
</style>
