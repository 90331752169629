/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SoftwareAuthorizationOutError } from '../models/SoftwareAuthorizationOutError';
import type { SoftwareAuthorizationOutNextStep } from '../models/SoftwareAuthorizationOutNextStep';
import type { SoftwareAuthorizationOutSuccess } from '../models/SoftwareAuthorizationOutSuccess';
import type { SoftwareAuthorizationStartData } from '../models/SoftwareAuthorizationStartData';
import type { SoftwareAuthorizationStep_Union_dict__NoneType__ } from '../models/SoftwareAuthorizationStep_Union_dict__NoneType__';
import type { SoftwareBase } from '../models/SoftwareBase';
import type { SoftwareOut } from '../models/SoftwareOut';
import type { SoftwarePostIn } from '../models/SoftwarePostIn';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SoftwareAuthorizationService {
    /**
     * Get
     * @returns SoftwareBase Successful Response
     * @throws ApiError
     */
    public static getApiV1OauthOauthRegisterGet({
        state,
        code,
        fullReload = false,
        xTransactionId,
    }: {
        state: string,
        /**
         * The OAuth authorization code of the integration
         */
        code: string,
        fullReload?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareBase> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/oauth/oauth_register',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'full_reload': fullReload,
                'state': state,
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start Authorize Software
     * @returns string Successful Response
     * @throws ApiError
     */
    public static startAuthorizeSoftwareApiV1SoftwareAuthorizeStartPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: SoftwareAuthorizationStartData,
        xTransactionId?: (string | null),
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/software/authorize/start',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Authorize Software Step
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authorizeSoftwareStepApiV1SoftwareAuthorizeStepPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: SoftwareAuthorizationStep_Union_dict__NoneType__,
        xTransactionId?: (string | null),
    }): CancelablePromise<(SoftwareAuthorizationOutNextStep | SoftwareAuthorizationOutSuccess | SoftwareAuthorizationOutError)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/software/authorize/step',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Software
     * @returns SoftwareOut Successful Response
     * @throws ApiError
     */
    public static postSoftwareApiV1SoftwareSoftwarePost({
        requestBody,
        fullReload = false,
        xTransactionId,
    }: {
        requestBody: SoftwarePostIn,
        fullReload?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareOut> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/software/software',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'full_reload': fullReload,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reauthenticate Software
     * @returns SoftwareBase Successful Response
     * @throws ApiError
     */
    public static reauthenticateSoftwareApiV1SoftwareSoftwareSoftwareIdReAuthenticatePost({
        softwareId,
        requestBody,
        xTransactionId,
    }: {
        softwareId: string,
        requestBody: Record<string, any>,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareBase> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/software/software/{software_id}/re_authenticate',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get
     * Get the OAuth login link for a specific integration. This is used to start the OAuth flow.
     *
     * An OAuthState is created and stored in the database. This is used to prevent CSRF attacks.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getApiV1OauthOauthLinkGet({
        softwareName,
        readOnly,
        xTransactionId,
    }: {
        softwareName: string,
        readOnly: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/oauth/oauth_link',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'software_name': softwareName,
                'read_only': readOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Oauth Link To Re Authenticate
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getOauthLinkToReAuthenticateApiV1OauthReAuthenticateSoftwareIdGet({
        softwareId,
        xTransactionId,
    }: {
        softwareId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/oauth/re-authenticate/{software_id}',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Refresh Oauth
     * @returns any Successful Response
     * @throws ApiError
     */
    public static refreshOauthApiV1OauthRefreshGet({
        softwareId,
        xTransactionId,
    }: {
        softwareId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/oauth/refresh',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'software_id': softwareId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
