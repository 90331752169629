<template>
  <!-- SOFTWARE_USER_ADD  -->
  <div>
    <i18n-t
      keypath="jobMessage.software_user_add"
      tag="p"
      class="flex items-center gap-3 break-normal">
      <template #user>
        <SmTag
          :label="user"
          icon="md-person-round"
          class="cursor-pointer transition hover:bg-gray-300 dark:hover:bg-gray-500"
          :hide-tooltip="true"
          :loading="loadingUser"
          @click="
            (userDetailsDrawerUserId = userObj?._id),
              (userDetailsDrawerVisibility = true)
          " />
      </template>
      <template #software>
        <SmTag
          :label="software"
          icon="md-gridview-round"
          :loading="loadingSoftware">
          <template #label>
            <div class="flex gap-2">
              <ApplicationIcon :software-name="software" size="18px" />
              <TextMultiline :text="software" />
            </div>
          </template>
        </SmTag>
      </template>
    </i18n-t>
  </div>

  <UsersDrawer
    v-if="userDetailsDrawerVisibility && userDetailsDrawerUserId"
    v-model:drawerVisibility="userDetailsDrawerVisibility"
    :user-id="userDetailsDrawerUserId"
    @close="userDetailsDrawerUserId = undefined" />
</template>

<script setup lang="ts">
  import {
    Account,
    BackgroundJobSoftwareUserAddData,
    SoftwareOut,
  } from '@/client'
  import { useSoftwareStore } from '@/stores/softwareStore'
  import { useUserStore } from '@/stores/userStore'

  const userStore = useUserStore()
  const softwareStore = useSoftwareStore()

  const props = defineProps<{
    data: BackgroundJobSoftwareUserAddData
  }>()

  const userObj = ref<Account>()
  const user = ref<string>()
  const loadingUser = ref(true)
  const userDetailsDrawerVisibility = ref(false)
  const userDetailsDrawerUserId = ref<string | undefined>()

  const softwareObj = ref<SoftwareOut>()
  const software = ref<string>()
  const loadingSoftware = ref(true)

  onMounted(async () => {
    // load user
    userObj.value = await userStore.getUserByID(props.data.account_id)
    user.value = userObj.value?.name || undefined
    loadingUser.value = false

    // load software
    softwareObj.value = await softwareStore.getSoftwareById(
      props.data.software_id
    )
    software.value = softwareObj.value?.software_name || undefined
    loadingSoftware.value = false
  })
</script>
