<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { CompanyAccessRole, CompanyManagementService } from '@/client'
  import useForm from '@/common/form'

  const i18n = useI18n()

  const show = defineModel('show', {
    type: Boolean,
    default: false,
  })

  const form = useForm({
    email: '',
    role: CompanyAccessRole.ADMIN,
  } as {
    email: string
    role: CompanyAccessRole
  })

  function reset() {
    form.reset()
  }

  async function sendInvite() {
    form
      .submit(async () => {
        return await CompanyManagementService.sendInviteApiV1CompanyInvitesPost({
          requestBody: {
            email: form.fields.email,
            role: form.fields.role,
          },
        })
      })
      .then(() => {
        close()
      })
  }

  function close() {
    reset()
    show.value = false
  }
</script>

<template>
  <SmDialog v-model:visibility="show" :title="i18n.t('invite')" size="small">
    <SmInput
      v-model="form.fields.email"
      label="Email"
      size="medium"
      outline
      style="width: 99%; margin-bottom: 1rem" />

    <h3>{{ i18n.t('role') }}</h3>
    <div style="width: fit-content">
      <!-- Role Selection -->
      <div class="role-select">
        <!-- Admin -->
        <input
          id="admin"
          v-model="form.fields.role"
          type="radio"
          :value="CompanyAccessRole.ADMIN" />
        <label
          for="admin"
          :class="{
            'is-active': form.fields.role === CompanyAccessRole.ADMIN,
          }">
          <div>
            <b>{{ i18n.t('admin') }}</b
            ><br />
            <span>
              {{ i18n.t('adminDescription') }}
            </span>
          </div>
        </label>

        <!-- Member -->
        <input
          id="member"
          v-model="form.fields.role"
          type="radio"
          :value="CompanyAccessRole.ADMIN_READ_ONLY" />
        <label
          for="member"
          :class="{
            'is-active': form.fields.role === CompanyAccessRole.ADMIN_READ_ONLY,
          }">
          <div>
            <b>{{ i18n.t('viewer') }}</b
            ><br />
            <span>
              {{ i18n.t('viewerDescription') }}
            </span>
          </div>
        </label>
      </div>
    </div>

    <template #footer>
      <div style="display: flex; gap: 1rem; margin-top: 1rem">
        <SmButton outline @click="close">{{ i18n.t('cancel') }}</SmButton>
        <SmButton :loading="form.processing" @click="sendInvite">
          {{ i18n.t('inviting') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<style lang="scss" scoped>
  .role-select {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;

    input {
      display: none;
    }

    label {
      display: flex;
      gap: 1rem;
      align-items: center;
      padding: 1rem;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      input:checked + & {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .is-active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
</style>
