/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Available frontend themes
 */
export enum PreferenceAppearanceThemes {
    LIGHT_THEME = 'light_theme',
    DARK_THEME = 'dark_theme',
    DEVICE_DEFAULT = 'device_default',
}
