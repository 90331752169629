/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CompanyAccessRole {
    OWNER = 'owner',
    ADMIN = 'admin',
    ADMIN_READ_ONLY = 'admin_read_only',
    MEMBER = 'member',
    SUSPENDED = 'suspended',
}
