import { Configuration, FrontendApi, Session } from '@ory/client'
import * as Sentry from '@sentry/vue'
import { StorageSerializers, useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

import { useRouter } from 'vue-router'

import {
  AccessTokenService,
  AvailableCompany,
  CompanyAccessRole,
  CompanyData,
  CompanyManagementService,
  OpenAPI,
  UserProfile,
  UserProfileService,
  CompanyIdentityAccessWithUserProfile,
} from '@/client'

export enum SessionStatus {
  LOGGED_OUT = 'LOGGED_OUT',
  LOGGED_IN = 'LOGGED_IN',
}

type AvailableCompanyWithToken = AvailableCompany & {
  token: string
  tokenType: string | undefined // Bearer
}

export const useSessionStore = defineStore('session', () => {
  // State
  // Ory related
  const status = ref<SessionStatus>(SessionStatus.LOGGED_OUT)
  const currentCompany = useStorage<null | AvailableCompanyWithToken>(
    'currentCompany',
    null,
    undefined,
    { serializer: StorageSerializers.object }
  )
  const currentUser = ref<null | UserProfile>(null)
  const currentOrySession = ref<null | Session>(null)
  const userCompanyAccessRole = ref<null | CompanyAccessRole>(null)
  const companyMembers = ref<CompanyIdentityAccessWithUserProfile[]>([])

  const router = useRouter()

  const oryUrl = computed(() => {
    switch (import.meta.env.MODE) {
      case 'production':
        return 'https://auth.saasmetrix.io'
      case 'staging':
        return 'https://auth.saasmetrix.io'
      case 'beta':
        return 'https://auth.saasmetrix.io'
      case 'syss':
        return 'https://auth.saasmetrix.io'
      default:
        return 'http://127.0.0.1:4000'
    }
  })

  const frontend = new FrontendApi(
    new Configuration({
      basePath: oryUrl.value,
      baseOptions: {
        withCredentials: true,
      },
    })
  )

  // saasmetrix related
  const currentCompanyInfo = ref<null | CompanyData>(null)

  // ************************************************************
  // Company related
  // ************************************************************

  // Login to a company
  async function loginToCompany(company: AvailableCompany) {
    const token = await AccessTokenService.requestTokenTokenPost({
      requestBody: { company_id: company.company_id },
    })

    // Set JWT in session storage
    currentCompany.value = {
      ...company,
      company_id: company.company_id,
      token: token.access_token,
      tokenType: token.token_type,
    }

    Sentry.setUser({
      email: currentOrySession.value?.identity?.traits.email,
      id: currentOrySession.value?.identity?.id,
      company_id: currentCompany.value?.company_id || null,
      company_name: currentCompany.value?.name || null,
    })

    return token
  }

  // Watch for changes in currentCompany and update the JWT
  watch(
    currentCompany,
    (currentCompany) => {
      if (currentCompany != null) {
        OpenAPI.TOKEN = currentCompany.token
      } else {
        OpenAPI.TOKEN = ''
      }
    },
    { immediate: true }
  )

  // Fetch saasmetrix company info
  async function fetchCompanyInfo() {
    return await CompanyManagementService.getCompanyInfoApiV1CompanyInfoGet(
      {}
    ).then((companyInfo) => {
      currentCompanyInfo.value = companyInfo
      return companyInfo
    })
  }

  async function getCompanyMembers() {
    if (companyMembers.value.length > 0) {
      return companyMembers.value
    }

    await CompanyManagementService.getCompanyIdentityAccessesWithUserProfileApiV1CompanyIdentityAccessesGet(
      {}
    ).then((res) => {
      companyMembers.value = res
    })

    return companyMembers.value
  }

  // ************************************************************
  // ORY Kratos Session
  // ************************************************************

  // Redirects to the login page if the user is not logged in
  async function requireLogin(reloadSession = true) {
    if (reloadSession) await checkSession()

    // Redirect to login page if not logged in
    if (status.value === SessionStatus.LOGGED_OUT) {
      // Do not redirect to login page if already on login page
      if (window.location.pathname !== '/login') window.location.href = '/login'
    }

    return status.value === SessionStatus.LOGGED_IN
  }

  // Gets the current session if it exists, otherwise returns null
  async function checkSession() {
    let currentSession = null
    await frontend
      .toSession()
      .then((session) => {
        if (session.data.active) {
          currentOrySession.value = session.data

          status.value = SessionStatus.LOGGED_IN
          currentSession = session.data
        } else {
          status.value = SessionStatus.LOGGED_OUT
        }
      })
      .catch((error) => {
        // If two factor is required, redirect to login page
        if (
          error.response.status === 403 &&
          error.response.data.error.id === 'session_aal2_required'
        ) {
          router.push('/login?mode=otp')
        }

        status.value = SessionStatus.LOGGED_OUT
      })

    return currentSession
  }

  // ************************************************************
  // User
  // ************************************************************

  // When logged in load user
  watch(
    status,
    async (status) => {
      if (status === SessionStatus.LOGGED_IN) {
        await get_user()
      }
    },
    { immediate: true }
  )

  async function get_user() {
    currentUser.value = await UserProfileService.getUserProfileUserGet({})

    // Set sentry user
    Sentry.setUser({
      email: currentOrySession.value?.identity?.traits.email,
      id: currentOrySession.value?.identity?.id,
      company_id: currentCompany.value?.company_id || null,
      company_name: currentCompany.value?.name || null,
    })

    return currentUser.value
  }

  // ************************************************************
  // Logout
  // ************************************************************

  async function logout() {
    const logoutFlow = await frontend.createBrowserLogoutFlow()

    await frontend.updateLogoutFlow({
      token: logoutFlow.data.logout_token,
    })

    // Delete the current company
    currentCompany.value = null
    status.value = SessionStatus.LOGGED_OUT

    // Redirect to login page
    router.push('/login')
  }

  // ************************************************************
  // CompanyAccessRole, Scopes
  // ************************************************************

  async function loadUserCompanyAccessRole() {
    const companyAccesses =
      await CompanyManagementService.getCompanyIdentityAccessesWithUserProfileApiV1CompanyIdentityAccessesGet(
        {}
      )

    // Check if user is loaded
    if (currentUser.value == null) {
      await get_user()
    }

    // Find company access
    const companyAccess = companyAccesses.find(
      (companyAccess) => companyAccess._id === currentUser.value?._id
    )

    // Set company access role
    if (companyAccess) {
      userCompanyAccessRole.value = companyAccess.company_access.role
    } else {
      console.error('Could not find company access')
    }
  }

  async function hasPermission(permission: CompanyAccessRole) {
    // Load company access role if not loaded
    if (userCompanyAccessRole.value == null) {
      await loadUserCompanyAccessRole()
    }

    // Check if user has permission
    if (userCompanyAccessRole.value == null) return false

    // Order of permissions
    const permissions = [
      CompanyAccessRole.ADMIN,
      CompanyAccessRole.ADMIN_READ_ONLY,
      CompanyAccessRole.MEMBER,
      CompanyAccessRole.SUSPENDED,
    ]

    // Check if user has permission
    return (
      permissions.indexOf(userCompanyAccessRole.value) <=
      permissions.indexOf(permission)
    )
  }

  //   Return
  return {
    status,
    frontend,
    currentUser,
    companyMembers,
    currentOrySession,

    // Company (saasmetrix)
    currentCompanyInfo,
    currentCompany,

    // Actions
    requireLogin,
    checkSession,
    get_user,
    fetchCompanyInfo,
    getCompanyMembers,
    loadUserCompanyAccessRole,

    loginToCompany,
    logout,

    // CompanyAccessRole
    userCompanyAccessRole,
    hasPermission,
  }
})
