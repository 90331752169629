export function updateFilter<T extends { name: string }>(
  currentFilter: Ref<T[]>,
  newFilter: T
) {
  // Replace old filter with new one if it exists
  const index = currentFilter.value.findIndex(
    (filter) => filter.name === newFilter.name
  )

  if (index !== -1) {
    currentFilter.value.splice(index, 1, newFilter)
  } else {
    currentFilter.value.push(newFilter)
  }
}

export function removeFilter<T extends { name: string }>(
  currentFilter: Ref<T[]>,
  filterName: string
) {
  currentFilter.value = currentFilter.value.filter(
    (filter) => filter.name !== filterName
  )
}

function mergeFilters<T extends { getFilter: () => any }>(
  filters: T[],
  mode: 'and' | 'or' = 'and'
) {
  const map: Record<string, any>[] = []

  switch (filters.length) {
    case 0:
      return {}
    case 1:
      return filters[0].getFilter()
    default:
      for (const filter of filters) {
        const filterObj = filter.getFilter() as {
          $or?: Record<string, any>[]
        }

        if (filterObj.$or && Array.isArray(filterObj.$or)) {
          const entryWithOr = map.find((entry) => Array.isArray(entry.$or))

          if (entryWithOr) {
            filterObj.$or.forEach((orCondition) => {
              entryWithOr.$or.push(orCondition)
            })
          } else {
            map.push({ $or: [...filterObj.$or] })
          }
        } else if (filterObj.$or) {
          // Unexpected type for $or
        } else {
          map.push(filterObj)
        }
      }

      return {
        [`$${mode}`]: map,
      }
  }
}

export default {
  updateFilter,
  removeFilter,
  mergeFilters,
}
