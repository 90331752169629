<template>
  <div>
    <component
      :is="pageComponents[filename]"
      v-if="pageComponents[filename]"
      v-model:software-name="softwareData"
      v-model:email="email"
      :default-password="defaultPassword" />
  </div>
</template>

<script lang="ts" setup>
  /**
   * Props and emits
   */
  const emit = defineEmits(['update:softwareName'])
  const props = defineProps<{
    softwareName: string
    email: string
    defaultPassword?: string | undefined | unknown
  }>()

  const softwareData = computed({
    get: () => props.softwareName,
    set: (value) => emit('update:softwareName', value),
  })

  const email = computed({
    get: () => props.email,
    set: () => {
      //
    },
  })

  /**
   * Computes the filename of the software requirements file
   */
  const filename = computed(() => {
    return `${props.softwareName.toLowerCase()}`
  })

  /**
   * Defines the async component for the software requirements
   * All software requirements are defined in the components/old/SoftwareRequirements/user folder
   * these files are imported and saves in the pageComponents object
   */
  const pages = import.meta.glob('./modules/*.vue')
  const pageComponents: Record<string, unknown> = {}
  Object.keys(pages).forEach((key) => {
    const name: string = (
      key.match('\\.\\/modules\\/Column(.*?).vue') as RegExpMatchArray
    )[1].toLowerCase()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageComponents[name] = defineAsyncComponent(() => pages[key]() as any)
  })
</script>
