<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import { openDialog } from '@/common/dialogManager'
  import { usePreferenceStore } from '@/stores/preferenceStore'
  import { useSessionStore } from '@/stores/sessionStore'

  const sessionStore = useSessionStore()
  const i18n = useI18n()
  const preferenceStore = usePreferenceStore()
  const router = useRouter()

  const languageSelectDialog = ref(false)

  const handleDarkModeChange = () => {
    preferenceStore.toggleColorMode()
  }

  // Logout the user and redirect to the login page
  function logout() {
    const sessionStore = useSessionStore()
    sessionStore.logout()
  }
</script>

<template>
  <LanguageSelectDialog v-model:visibility="languageSelectDialog" />

  <SmDropdown trigger="click">
    <template #trigger>
      <v-icon
        name="md-settings-round"
        scale="1.2"
        class="fill-contrast"
        hover
        style="cursor: pointer" />
    </template>

    <div style="min-width: 175px"></div>

    <!-- Dark Mode Toggle -->
    <SmDropdownItem
      :icon="
        preferenceStore.preferences?.appearance === 'dark_theme'
          ? 'md-lightmode-round'
          : 'md-darkmode-round'
      "
      size="medium"
      @click="handleDarkModeChange">
      {{
        preferenceStore.preferences?.appearance === 'dark_theme'
          ? i18n.t('headerMenu.settings.colorTheme.light')
          : i18n.t('headerMenu.settings.colorTheme.dark')
      }}
    </SmDropdownItem>

    <!-- Language select -->
    <SmDropdownItem
      icon="md-language-round"
      size="medium"
      @click="languageSelectDialog = true">
      {{ i18n.t('languageSelect') }}
    </SmDropdownItem>

    <!-- Feedback -->
    <SmDropdownItem
      icon="md-chat-round"
      size="medium"
      @click="openDialog('feedback')">
      {{ i18n.t('feedback') }}
    </SmDropdownItem>

    <!-- Settings -->
    <SmDropdownItem
      v-if="sessionStore.status === 'LOGGED_IN'"
      icon="md-settings-round"
      size="medium"
      @click="openDialog('settings')">
      {{ i18n.t('settings') }}
    </SmDropdownItem>

    <!-- Switch Company -->
    <SmDropdownItem
      v-if="sessionStore.status === 'LOGGED_IN'"
      icon="md-business-round"
      size="medium"
      @click="router.push('/login/company')">
      {{ i18n.t('switchCompany') }}
    </SmDropdownItem>

    <!-- Logout -->
    <SmDropdownItem
      v-if="sessionStore.status === 'LOGGED_IN'"
      icon="md-logout-round"
      size="medium"
      @click="logout">
      {{ i18n.t('headerMenu.settings.logout') }}
    </SmDropdownItem>
  </SmDropdown>
</template>
