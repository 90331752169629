<template>
  <!-- SOFTWARE_USER_REMOVE -->
  <div>
    <i18n-t
      keypath="jobMessage.software_user_remove"
      tag="p"
      class="flex items-center gap-3 break-normal">
      <template #user>
        <SmTag
          :label="license"
          icon="md-description-round"
          :loading="loadingLicense" />
      </template>
      <template #software>
        <SmTag
          :label="software"
          icon="md-gridview-round"
          :loading="loadingSoftware">
          <template #label>
            <div class="flex gap-2">
              <ApplicationIcon :software-name="software" size="18px" />
              <TextMultiline :text="software" />
            </div>
          </template>
        </SmTag>
      </template>
      <template #action>
        <span v-if="props.data.delete">
          {{
            i18n.locale.value === 'en'
              ? i18n.t('delete')
              : i18n.t('delete').toLowerCase()
          }}
        </span>
        <span v-else-if="props.data.disable">
          {{
            i18n.locale.value === 'en'
              ? i18n.t('general.disable')
              : i18n.t('general.disable').toLowerCase()
          }}
        </span>
      </template>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
  import {
    BackgroundJobSoftwareUserRemoveData,
    LicenceOut,
    SoftwareOut,
  } from '@/client'
  import { licenseStore } from '@/stores/licenseV2.store'
  import { useSoftwareStore } from '@/stores/softwareStore'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()
  const softwareStore = useSoftwareStore()

  const props = defineProps<{
    data: BackgroundJobSoftwareUserRemoveData
  }>()

  const licenseObj = ref<LicenceOut>()
  const license = ref<string>()
  const loadingLicense = ref(true)

  const softwareObj = ref<SoftwareOut>()
  const loadingSoftware = ref(true)
  const software = ref<string>()

  onMounted(async () => {
    // load license
    licenseObj.value = (await licenseStore.getByID(
      props.data.licence_id
    )) as LicenceOut

    license.value = licenseObj.value?.email || undefined
    loadingLicense.value = false

    // load software
    softwareObj.value = await softwareStore.getSoftwareById(
      licenseObj.value.software_id
    )
    software.value = softwareObj.value?.software_name || undefined
    loadingSoftware.value = false
  })
</script>
