/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobExecutionBase_Literal_software_user_license_remove__BackgroundJobSoftwareUserLicenseRemoveExecutionData_ } from './BackgroundJobExecutionBase_Literal_software_user_license_remove__BackgroundJobSoftwareUserLicenseRemoveExecutionData_';
import type { BackgroundJobSoftwareUserLicenseRemoveData } from './BackgroundJobSoftwareUserLicenseRemoveData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
export type BackgroundJobSoftwareUserLicenseRemove = {
    created: string;
    updated: string;
    deleted: boolean;
    deleted_at: (string | null);
    _id: string;
    type: BackgroundJobSoftwareUserLicenseRemove.type;
    status: BackgroundJobStatus;
    executions: Array<BackgroundJobExecutionBase_Literal_software_user_license_remove__BackgroundJobSoftwareUserLicenseRemoveExecutionData_>;
    data: BackgroundJobSoftwareUserLicenseRemoveData;
    last_executed_at: (string | null);
    next_execution_at: (string | null);
    execution_token: string;
    created_by_job_id: (string | null);
    created_by_identity_id: (string | null);
};
export namespace BackgroundJobSoftwareUserLicenseRemove {
    export enum type {
        SOFTWARE_USER_LICENSE_REMOVE = 'software_user_license_remove',
    }
}

