<template>
  <!-- Add Group -->
  <GroupAddDialog
    v-model:visibility="dialogVisibility"
    v-model:accountGroup="group"
    :all-applications
    :all-price-informations
    :all-software-licenses
    @groups-changed="emit('groups-changed')" />

  <!-- Content -->
  <div class="empty-state">
    <div class="empty-state-icon">
      <v-icon
        name="md-groups-round"
        scale="2.5"
        class="fill-primary"
        style="z-index: 4; position: relative" />
    </div>

    <h2>{{ i18n.t('groupsHeadline') }}</h2>
    <p>{{ i18n.t('groupsDescription') }}</p>

    <div class="empty-state__actions">
      <SmButton type="primary" size="large" @click="handleAddGroup">
        {{ i18n.t('addGroup') }}
      </SmButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    AccountGroupIn,
    PriceInformation,
    SoftwareLicense,
    SoftwareOut,
  } from '@/client'

  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()
  const emit = defineEmits(['groups-changed'])

  const dialogVisibility = ref<boolean>(false)
  defineProps<{
    allApplications: SoftwareOut[]
    allSoftwareLicenses: SoftwareLicense[]
    allPriceInformations: PriceInformation[]
  }>()
  const group = ref<AccountGroupIn>({
    name: '',
    accounts: [],
    permission: {
      software_permissions: {},
    },
    color: {
      r: 0,
      g: 0,
      b: 255,
    },
  })

  function handleAddGroup() {
    dialogVisibility.value = true
  }
</script>

<style scoped lang="scss">
  .empty-state {
    flex: 1;
    margin-top: 10vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100%);
    text-align: center;

    gap: 1.5rem;

    padding-left: 20%;
    padding-right: 20%;

    h2 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.3rem;
      line-height: 1.7;
    }

    .empty-state__actions {
      margin-top: 1rem;
    }
  }

  .empty-state-icon {
    position: relative;

    aspect-ratio: 1;
    padding: 1rem;

    aspect-ratio: 1;
    border-radius: 1rem;
    padding: 1rem;

    &::after {
      content: '';
      position: absolute;
      display: block;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      z-index: 1;

      border-radius: 1rem;

      opacity: 0.2;

      background-color: var(--sm-primary);
    }
  }
</style>
