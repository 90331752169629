<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { CompaniesService } from '@/client'

  import { sendToast } from './sm/SmNotification'

  const i18n = useI18n()

  const visible = defineModel('visible', {
    default: false,
    type: Boolean,
  })
  const createDemoData = defineModel('createDemoData', {
    default: false,
    type: Boolean,
  })
  const companyName = defineModel('companyName', {
    default: '',
    type: String,
  })

  const loading = ref(false)

  const error = ref({
    message: '',
    visible: false,
  })

  const emit = defineEmits(['companyCreated'])

  async function createCompany() {
    if (loading.value) return
    loading.value = true
    await CompaniesService.createCompanyCompaniesPost({
      requestBody: { name: companyName.value, demo: createDemoData.value },
    })
      .then(
        (response) => {
          sendToast(i18n.t('companyCreated'), undefined, 'success')
          emit('companyCreated', response)
          visible.value = false
        },
        (error) => {
          error.value = {
            message: i18n.t('errorCreatingCompany'),
            visible: true,
          }
        }
      )
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    () => visible.value,
    (newValue) => {
      if (!newValue) {
        error.value = {
          message: '',
          visible: false,
        }
        companyName.value = ''
        createDemoData.value = false
      }
    }
  )
</script>

<template>
  <SmDialog
    v-model:visibility="visible"
    :title="i18n.t('createCompany')"
    size="small">
    <div class="content-wrapper">
      <SmDialogMessage
        :message="error.message"
        :visible="error.visible"
        type="error" />

      <div style="width: 99%">
        <SmInput
          v-model="companyName"
          size="medium"
          :label="i18n.t('companyName')"
          outline />
      </div>

      <div
        style="display: flex; gap: 0.5rem; align-items: center"
        @click="createDemoData = !createDemoData">
        <SmCheckbox v-model="createDemoData" />
        <span>{{ i18n.t('demoData') }}</span>
      </div>
    </div>

    <template #footer>
      <div style="margin-top: 1rem; display: flex; gap: 1rem">
        <SmButton outline @click="visible = false">
          {{ i18n.t('cancel') }}
        </SmButton>
        <SmButton type="primary" :loading="loading" @click="createCompany">
          {{ i18n.t('create') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<style>
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    align-items: flex-start;
  }
</style>
