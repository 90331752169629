<template>
  <SmDialog
    v-model:visibility="showDrawer"
    :title="
      i18n.t(
        'drawers.documentUploadDrawer.title.' + (editMode ? 'edit' : 'create'),
        {
          name: existingDocument ? existingDocument.title || '' : '',
        }
      )
    "
    @close="closeDrawer">
    <template #close>
      <div class="header">
        <span class="headline-text"> </span>
        <div v-if="editMode">
          <DeleteConfirmPopup
            :title="
              i18n.t('drawers.documentUploadDrawer.deleteButtonText', {
                title: getFileName(),
              })
            "
            @confirm="
              () => {
                handleDocumentDelete(existingDocument?._id || '')
                showDrawer = false
              }
            ">
            <el-button id="delete-button">
              <v-icon name="md-delete-round" />
            </el-button>
          </DeleteConfirmPopup>
        </div>
      </div>
    </template>
    <template #default>
      <div
        class="document-upload-content"
        data-tutorial-id="documents-add-inputs">
        <!-- Form -->
        <DocumentUploadDialogForm
          v-model:document-form-ref="documentUpdateFormRef"
          v-model:documentForm="documentForm"
          :software-id="props.softwareId"
          :view-mode="props.viewMode" />
        <!-- Upload dialog -->
        <el-upload
          v-if="!editMode"
          ref="uploadRef"
          v-model:file-list="fileList"
          class="upload-document"
          :auto-upload="false"
          :action="`${store.getIp}/api/v1/software/documents`"
          :headers="{ Authorization: 'Bearer ' + store.getJWT() }"
          :data="{
            ...documentForm,
            date: dayjs(documentForm.date).format('YYYY-MM-DD'),
          }"
          style="width: 100%"
          accept="application/pdf"
          :limit="1"
          :on-exceed="handleExceed"
          :on-success="handleSuccess"
          :on-error="handleErrorLocally"
          drag
          data-tutorial-id="documents-add-file-input">
          <v-icon name="md-uploadfile-outlined" />
          <div class="el-upload__text">
            {{ i18n.t('drawers.documentUploadDrawer.fileDrop.drop') }}
            <em>{{ i18n.t('drawers.documentUploadDrawer.fileDrop.click') }}</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              {{ i18n.t('drawers.documentUploadDrawer.fileDrop.suffix') }}
            </div>
          </template>
        </el-upload>
      </div>
    </template>
    <template #footer>
      <div class="upload-buttons">
        <SmButton outline @click="closeDrawer">
          {{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton
          data-tutorial-id="documents-add-save-button"
          @click="() => uploadDocument()">
          {{ i18n.t('general.save') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import dayjs from 'dayjs'
  import {
    ElNotification,
    FormInstance,
    genFileId,
    UploadInstance,
    UploadProps,
    UploadRawFile,
    UploadUserFile,
  } from 'element-plus'

  import { useI18n } from 'vue-i18n'

  import {
    Body_add_document_api_v1_software_documents_post,
    DocumentInfo,
  } from '@/client'
  import DeleteConfirmPopup from '@/components/DeleteConfirmPopup.vue'
  import { sendToast } from '@/components/sm/SmNotification'
  import handleError from '@/middlewares/handleError'
  import validate from '@/middlewares/validate'
  import { useGlobalStore } from '@/stores/globalStore'

  import DocumentUploadDialogForm from './DocumentUploadDialogForm.vue'

  const i18n = useI18n()
  const store = useGlobalStore()
  const emit = defineEmits([
    'upload-document',
    'upload-document-success',
    'upload-document-error',
    'downloadDocument',
  ])

  interface Props {
    softwareId: string | null | undefined
    editMode: boolean
    viewMode?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    editMode: false,
    viewMode: false,
  })

  const existingDocument = defineModel<DocumentInfo | null>(
    'existingDocument',
    {
      default: null,
    }
  )

  const showDrawer = defineModel<boolean>('showDrawer', {
    default: null,
  })

  function getFileName() {
    if (existingDocument.value?.title) return existingDocument.value.title
    if (existingDocument.value?.filename) return existingDocument.value.filename
    return i18n.t('newDocument')
  }

  function closeDrawer() {
    showDrawer.value = false
  }

  const documentUpdateFormRef = ref<FormInstance>()

  const uploadRef = ref<UploadInstance>()
  const fileList = ref<UploadUserFile[]>([])

  /**
   * When the upload is finished, we need to update the document list
   * So we emit an event to the parent component and close the drawer
   */
  function handleSuccess() {
    emit('upload-document-success')
    closeDrawer()
    sendToast(
      i18n.t('notifications.documentUploadDrawer.uploadSuccess.title'),
      undefined,
      'success'
    )
  }

  /**
   * Starts the upload process
   */
  async function uploadDocument() {
    if (!documentUpdateFormRef.value) return
    if (!(await validate(documentUpdateFormRef.value))) return

    if (!props.editMode && fileList.value.length === 0) {
      sendToast(
        i18n.t('notifications.documentUploadDrawer.fileMissing.title'),
        i18n.t('notifications.documentUploadDrawer.fileMissing.message'),
        'error'
      )
    }

    if (props.editMode) {
      //  If no document is selected, we can't update
      if (!documentForm.value) {
        sendToast(i18n.t('error'), i18n.t('uploadError'), 'error')
        return
      }

      // Check if existing document (type DocumentInfo)
      if (!('_id' in documentForm.value)) return

      // Update the document
      return fetch(
        store.getIp + '/api/v1/software/documents/' + documentForm.value._id,
        {
          method: 'PUT',
          body: JSON.stringify({
            ...documentForm.value,
            date: transformDate(documentForm.value),
          }),
          redirect: 'follow',
          headers: {
            Authorization: `Bearer ${store.getJWT()}`,
            'Content-Type': 'application/json',
          },
        }
      )
        .then(handleError)
        .then(async () => {
          emit('upload-document-success')
          closeDrawer()
          sendToast(
            i18n.t('notifications.documentUploadDrawer.updateSuccess.title'),
            i18n.t('notifications.documentUploadDrawer.updateSuccess.message'),
            'success'
          )
        })
    }

    if (uploadRef.value) uploadRef.value.submit()
  }

  /**
   * If more than one file is selected, we replace the file list with the new file
   */
  const handleExceed: UploadProps['onExceed'] = (files) => {
    uploadRef.value?.clearFiles()
    const file = files[0] as UploadRawFile
    file.uid = genFileId()
    uploadRef.value?.handleStart(file)
  }

  const handleDocumentDelete = (documentId: string) => {
    const headersList = {
      Accept: '*/*',
      Authorization: `Bearer ${store.getJWT()}`,
    }
    fetch(
      `${store.getIp}/api/v1/software/documents/${encodeURIComponent(
        documentId
      )}`,
      {
        method: 'DELETE',
        headers: headersList,
      }
    ).then(function (response) {
      sendToast(
        i18n.t('notifications.documentUploadDrawer.deleteSuccess.title'),
        i18n.t('notifications.documentUploadDrawer.deleteSuccess.message'),
        'success'
      )
      // Trigger to refetch documents
      emit('upload-document-success')
      return response.text()
    })
  }

  const handleErrorLocally = () => {
    ElNotification.error({
      title: i18n.t('error.request.unknown.title'),
      message: i18n.t('error.request.unknown.message'),
      duration: 1500,
    })
  }

  const transformDate = (documentForm: DocumentInfo): Date => {
    // Use current date if no date is set
    if (!documentForm.date) return new Date(dayjs(new Date()).toISOString())

    // Add one day to the date, because the date is always one day behind
    const date = new Date(documentForm.date)
    date.setDate(date.getDate() + 1)
    return date
  }

  /**
   * Default Document initial values
   */
  export type DocumentForm = Omit<
    Body_add_document_api_v1_software_documents_post,
    'file'
  >

  const documentForm = ref<DocumentForm | DocumentInfo>(
    existingDocument.value || {
      title: '',
      date: dayjs(new Date()).toISOString(),
      notes: '',
      software_id: props.softwareId,
      file_size: 0,
      filename: '',
      content_type: 'application/pdf',
      tags: [],
    }
  )

  /**
   * Watch for changes of the prop
   * If the prop changes, we need to update the form values
   */
  watch(
    () => existingDocument.value,
    (value) => {
      if (value) {
        documentForm.value = {
          ...documentForm.value,
          ...value,
        }
      }
    }
  )

  watch(
    () => props.softwareId,
    (value) => {
      documentForm.value = {
        ...documentForm.value,
        software_id: value || null,
      }
    }
  )
</script>

<style scoped>
  .upload-document {
    width: 100%;
  }

  .upload-buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
</style>

<style>
  .document-upload-content .el-upload {
    width: 100% !important;
  }

  .document-upload-content .el-upload-dragger {
    width: 100% !important;
  }
</style>
