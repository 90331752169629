import { defineStore } from 'pinia'

import { SoftwareInformation } from '@/client'

export type ExtendedSoftwareInformation = SoftwareInformation & {
  connected?: boolean
}
type OnboardingDialogStoreState = {
  // Current Dialog Content
  currentContent?: unknown
  selectedIntegrations: ExtendedSoftwareInformation[]
}

export const useOnboardingDialogStore = defineStore('onboardingDialogStore', {
  state: (): OnboardingDialogStoreState => ({
    currentContent: undefined,
    selectedIntegrations: [],
  }),
  actions: {
    addIntegration(integration: ExtendedSoftwareInformation) {
      this.selectedIntegrations.push(integration)
    },
    removeIntegration(integration: ExtendedSoftwareInformation) {
      const lenBefore = this.selectedIntegrations.length
      this.selectedIntegrations = this.selectedIntegrations.filter(
        (i) => i.name !== integration.name
      )
      if (lenBefore === this.selectedIntegrations.length) {
        return false
      }
      return true
    },
  },
})
