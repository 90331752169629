import handleError from '@/middlewares/handleError'

export const getPreferences = (
  ip: string,
  jwt: string | undefined,
  email: string,
  preferenceKeys?: string[]
): Promise<Record<string, unknown>> => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt || ''}`,
    },
  }

  return fetch(
    `${ip}/api/v1/account/preferences?email=${encodeURIComponent(email)}${
      preferenceKeys !== undefined && preferenceKeys.length > 0
        ? '&prefs=' + preferenceKeys.join(',')
        : ''
    }`,
    options
  ).then(async (response) => {
    const jsonResponse = await response.json()

    return jsonResponse.preferences
  })
}

export const addPreference = (
  ip: string,
  jwt: string | undefined,
  email: string,
  preferences: Record<string, unknown>
) => {
  const body = JSON.stringify({
    email: email,
    preferences: preferences,
  })

  const options = {
    method: 'POST',
    body: body,
    headers: {
      Authorization: `Bearer ${jwt || ''}`,
      'Content-Type': 'application/json',
    },
  }

  return fetch(`${ip}/api/v1/account/preferences`, options)
    .then(handleError)
    .then(async (response) => await response.json())
}

export const updatePreferences = (
  ip: string,
  jwt: string | undefined,
  email: string,
  preferences: Record<string, unknown>
) => {
  const body = JSON.stringify({
    email: email,
    preferences: preferences,
  })

  const options = {
    method: 'PUT',
    body: body,
    headers: {
      Authorization: `Bearer ${jwt || ''}`,
      'Content-Type': 'application/json',
    },
  }

  return fetch(`${ip}/api/v1/account/preferences`, options)
    .then((response) => handleError(response, false))
    .then(async (response) => await response.json())
}

export const deletePreferences = (
  ip: string,
  jwt: string | undefined,
  email: string,
  preferenceKeys?: string[]
): Promise<Record<string, unknown>> => {
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt || ''}`,
    },
  }

  return fetch(
    `${ip}/api/v1/account/preferences?email=${encodeURIComponent(email)}${
      preferenceKeys !== undefined && preferenceKeys.length > 0
        ? '&prefs=' + preferenceKeys.join(',')
        : ''
    }`,
    options
  )
    .then(handleError)
    .then(async (response) => await response.json())
}
