export function turnFullnameIntoFirstAndLastName(fullname: string): {
  firstname: string
  lastname: string
} {
  const firstName = fullname.split(' ').slice(0, -1).join(' ')
  const lastName = fullname.split(' ').slice(-1).join(' ')

  return {
    firstname: firstName,
    lastname: lastName,
  }
}
