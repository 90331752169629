<template>
  <div class="sm-notification-item">
    <div class="sm-notification-item__icon">
      <slot name="icon">
        <v-icon :name="icon" scale="1.5" :fill="fill" :animation="animation" />
      </slot>
    </div>
    <div class="sm-notification-item__content">
      <div class="sm-notification-item__content__title">
        <slot name="title">
          <h4>{{ props.title }}</h4>
        </slot>
      </div>
      <div class="sm-notification-item__content__description">
        <slot name="description">{{ props.message }}</slot>
      </div>
    </div>
    <div class="sm-notification-item__action">
      <slot name="action">
        <div class="sm-notification-close">
          <SmButtonClose @close="close" />
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import SmButtonClose from '@/components/sm/SmButtonClose.vue'

  const props = defineProps({
    type: {
      type: String,
      default: 'success',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: 2000,
    },
    selfClose: {
      type: Boolean,
      default: true,
    },
  })

  const emit = defineEmits(['close'])

  const close = () => emit('close')

  const icon = computed(() => {
    switch (props.type) {
      case 'success':
        return 'md-checkcircle-round'
      case 'error':
        return 'md-cancel-round'
      case 'warning':
        return 'md-alert-circle'
      case 'info':
        return 'md-information-circle'
      case 'loading':
        return 'md-rotateright-round'
      default:
        return 'md-checkcircle-round'
    }
  })

  const fill = computed(() => {
    switch (props.type) {
      case 'success':
        return 'var(--sm-primary)'
      case 'error':
        return 'var(--sm-magenta)'
      case 'warning':
        return 'var(--sm-orange)'
      case 'info':
        return 'var(--sm-blue)'
      case 'loading':
        return 'var(--sm-blue)'
      default:
        return 'var(--sm-primary)'
    }
  })

  // Animation
  const animation = computed(() => {
    // Spin if loading
    return props.type === 'loading' ? 'spin' : undefined
  })

  onMounted(() => {
    if (!props.selfClose) return
    // Do not close if loading
    if (props.type === 'loading') return
    // Close after duration
    initSelfClose()
  })

  // If type changes init self close
  watch(
    () => props.type,
    () => {
      if (!props.selfClose) return
      // Do not close if loading
      if (props.type === 'loading') return
      // Close after duration
      initSelfClose()
    }
  )

  function initSelfClose() {
    // Close after duration
    setTimeout(() => {
      close()
    }, props.duration)
  }
</script>

<style lang="scss">
  .sm-notification-item {
    width: 300px;
    background-color: var(--sm-cta);
    border-radius: var(--border-radius-base);
    color: var(--sm-white);
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: var(--sm-cta);
    }

    &__icon {
      flex: 0 0 2rem;
      margin-right: 1rem;
      margin-left: 1rem;
    }

    &__content {
      flex: 1 1 auto;
      margin-right: 1rem;
      display: flex;
      flex-direction: column;
      align-items: baseline;

      &__title {
        font-size: var(--font-size);
      }

      &__description {
        font-size: var(--font-size-small);
      }
    }

    &__action {
      flex: 0 0 2rem;
    }
  }

  .sm-notification-close {
  }
</style>
