<template>
  <el-popconfirm
    icon-color="red"
    width="250px"
    :cancel-button-text="i18n.t('general.cancel')"
    :confirm-button-text="i18n.t('general.delete')"
    :title="returnTitle(titleI18nCode, title)"
    :teleported="teleported"
    @confirm="emit('confirm')"
    @cancel="emit('cancel')">
    <template #reference>
      <slot />
    </template>
  </el-popconfirm>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()
  const emit = defineEmits(['confirm', 'cancel'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const props = withDefaults(
    defineProps<{
      titleI18nCode?: string
      title?: string
      teleported?: boolean
    }>(),
    { teleported: true, title: undefined, titleI18nCode: undefined }
  )

  function returnTitle(i18nCode?: string, title?: string) {
    if (i18nCode && i18n.te(i18nCode)) return i18n.t(i18nCode)
    if (title) return title
    return ''
  }
</script>
