<template>
  <el-dialog
    v-model="visible"
    :width="width"
    :show-close="false"
    class="sm-popup"
    @close="emit('close')">
    <template #header="{ close }">
      <slot name="header">
        <div class="header">
          <div class="header-left">
            <h2 v-if="headline.length">{{ headline }}</h2>
            <h1 v-if="subHeadline.length">{{ subHeadline }}</h1>
          </div>
          <div class="header-right">
            <v-icon
              v-if="props.showCloseButton"
              name="io-close"
              scale="1.2"
              class="close-icon"
              @click="() => (close(), emit('close'))" />
          </div>
        </div>
      </slot>
    </template>
    <div class="content">
      <slot> </slot>
    </div>
    <template #footer>
      <slot name="footer">
        <div class="footer">
          <!-- Previous Button -->
          <div
            v-if="showPrevButton"
            :class="{
              'progress-button': true,
              'progress-button-prev': true,
            }"
            @click="emit('previousPage')">
            <div class="progress-button-icon">
              <v-icon
                name="md-arrowforwardios-round"
                scale="1.3"
                flip="horizontal" />
            </div>
            <div class="progress-button-text">
              {{ i18n.t('dialogs.onboardingDialog.general.back') }}
            </div>
          </div>
          <div v-else class="footer-spacer" />
          <!-- <div>Mitte</div> -->
          <div
            v-if="showNextButton"
            :class="{
              'progress-button': true,
              'progress-button-next': true,
              headShake: nextDisabledAnimation,
            }"
            @click="handleNextPage">
            <div
              class="progress-button-text"
              :style="{ color: nextButtonColor }">
              {{ i18n.t(nextButtonText) }}
            </div>
            <div class="progress-button-icon">
              <v-icon
                name="md-arrowforwardios-round"
                scale="1.3"
                :fill="nextButtonColor" />
            </div>
          </div>
        </div>
      </slot>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  // Define props
  const props = defineProps({
    visible: {
      type: Boolean,
      required: true,
    },
    showPrevButton: {
      type: Boolean,
      default: true,
    },
    prevButtonText: {
      type: String,
      default: 'general.back',
    },
    showNextButton: {
      type: Boolean,
      default: true,
    },
    nextButtonDisabled: {
      type: Boolean,
      default: false,
    },
    nextButtonText: {
      type: String,
      default: 'general.next',
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    headline: {
      type: String,
      default: '',
    },
    subHeadline: {
      type: String,
      default: '',
    },
  })

  // Define emits
  const emit = defineEmits([
    'update:visible',
    'previousPage',
    'changePage',
    'nextPage',
    'close',
  ])

  // Define reactive variables
  const visible = computed({
    get() {
      return props.visible
    },
    set(value) {
      emit('update:visible', value)
    },
  })

  // Disable next button animation
  const handleNextPage = () => {
    if (!props.nextButtonDisabled) emit('nextPage')
    else playNextDisabledAnimation()
  }
  const nextDisabledAnimation = ref(false)
  function playNextDisabledAnimation() {
    nextDisabledAnimation.value = true
    setTimeout(() => {
      nextDisabledAnimation.value = false
    }, 1500)
  }

  // Colors
  const nextButtonColor = computed(() => {
    if (props.nextButtonDisabled) {
      return 'var(--el-disabled-text-color)'
    } else {
      return 'var(--el-color-primary)'
    }
  })

  // Width based on screen size
  const width = computed(() => {
    if (window.innerWidth < 1300) {
      return '70%'
    } else {
      return '50%'
    }
  })
</script>

<style lang="scss">
  .el-overlay-dialog {
    overflow: hidden !important;
  }
</style>

<style scoped lang="scss">
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    &-left {
      h1,
      h2 {
        margin: 0;
        text-align: left;
      }
      h2 {
        text-transform: uppercase;
        color: var(--el-text-color-secondary);
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .content {
    height: 50vh;
    max-height: 50vh;
    overflow-y: auto;
  }
  // Define sizes for different screen sizes
  @media screen and (max-width: 1300px) {
    .content {
      height: 50vh;
      max-height: 50vh;
    }
  }

  .progress-button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &-next {
      right: 20px;
    }

    &-prev {
      left: 0px;
    }

    &:hover {
      scale: 1.03;
    }

    .progress-button-text {
      margin-right: 2px;
      margin-bottom: 2px;
      font-size: 1.1rem;
    }
  }

  .close-icon {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      scale: 1.1;
      fill: var(--el-color-danger);
      rotate: 90deg;
    }
  }
</style>
