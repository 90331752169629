<template>
  <SmDialog
    v-model:visibility="visible"
    :title="i18n.t('editLicense')"
    class="dialog"
    :size="dialogSize">
    <div style="padding-right: 12px">
      <LicenseModelForm
        ref="form"
        v-model:softwareLicense="model"
        label-width="12rem"
        :license-model-id="model.license._id"
        :custom-validators="customValidators" />
    </div>

    <template #footer>
      <div class="submit-buttons">
        <SmButton size="small" outline @click="handleCancel">
          {{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton size="small" @click="handleSave">
          {{ i18n.t('general.save') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { SoftwareLicense } from '@/client'
  import { CustomValidator } from '@/common/util/licenseModel'
  import { sendToast } from './sm/SmNotification'

  import { usePriceInformationStore } from '@/stores/priceInformationStore'

  import LicenseModelForm from './LicenseModelForm.vue'
  import { ComponentExposed } from 'vue-component-type-helpers'

  const props = defineProps<{
    visible: boolean
    customValidators?: CustomValidator[]
    preventSubmit?: boolean
    softwareLicense: SoftwareLicense
  }>()
  const emit = defineEmits<{
    add: []
    saved: []
    'update:visible': [visible: boolean]
    cancelled: []
  }>()

  const i18n = useI18n()
  const priceInformationStore = usePriceInformationStore()
  const form = ref<ComponentExposed<typeof LicenseModelForm> | null>()

  const visible = computed({
    get: () => props.visible,
    set: (val) => emit('update:visible', val),
  })

  const model = ref<{
    license: SoftwareLicense
  }>({
    license: {} as SoftwareLicense,
  })

  async function handleSave() {
    if (!form.value) return

    if (!form.value.validate()) {
      sendToast(i18n.t('validationFailed'), undefined, 'error')
      return
    }

    if (!props.preventSubmit) {
      await form.value.submitForms()
      sendToast(i18n.t('licenseSaved'), undefined, 'success')
    }

    emit('saved')
  }

  function handleCancel() {
    visible.value = false
    emit('cancelled')
  }

  const dialogSize = computed(() => {
    return form.value && form.value.priceInformationForms?.length > 1
      ? 'large'
      : 'medium'
  })

  watch(
    visible,
    (newValue) => {
      if (newValue === true) {
        const licenseId = (props.softwareLicense as SoftwareLicense)._id

        if (!licenseId.startsWith('new-')) {
          priceInformationStore.loadPriceInformation(licenseId)
        }

        model.value.license = props.softwareLicense
      }
    },
    { immediate: true }
  )

  defineExpose({
    form,
  })
</script>

<style scoped>
  .submit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
</style>
