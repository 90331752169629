/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSoftwareUserLicenseRemoveExecutionData } from './BackgroundJobSoftwareUserLicenseRemoveExecutionData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
import type { ErrorDetails } from './ErrorDetails';
export type BackgroundJobExecutionBase_Literal_software_user_license_remove__BackgroundJobSoftwareUserLicenseRemoveExecutionData_ = {
    type: BackgroundJobExecutionBase_Literal_software_user_license_remove__BackgroundJobSoftwareUserLicenseRemoveExecutionData_.type;
    execution_id: string;
    executed_at: string;
    data?: (BackgroundJobSoftwareUserLicenseRemoveExecutionData | null);
    status?: BackgroundJobStatus;
    error_event_id?: (string | null);
    background_job_ids_enqueued?: Array<string>;
    error?: (ErrorDetails | null);
};
export namespace BackgroundJobExecutionBase_Literal_software_user_license_remove__BackgroundJobSoftwareUserLicenseRemoveExecutionData_ {
    export enum type {
        SOFTWARE_USER_LICENSE_REMOVE = 'software_user_license_remove',
    }
}

