<template>
  <el-image
    v-if="props.softwareName"
    class="icon app-icon"
    :src="`https://logos.saasmetrix.io/${props.softwareName}.png`">
    <template #error>
      <span v-if="props.softwareType === 'SSO'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :height="props.size"
          viewBox="0 -960 960 960"
          class="opacity-70"
          width="props.size">
          <path
            d="M480-380Zm80 220H260q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q106 0 184.5 68.5T757-560q-21 0-40.5 4.5T679-543q-8-75-65-126t-134-51q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h300v80Zm120 0q-17 0-28.5-11.5T640-200v-120q0-17 11.5-28.5T680-360v-40q0-33 23.5-56.5T760-480q33 0 56.5 23.5T840-400v40q17 0 28.5 11.5T880-320v120q0 17-11.5 28.5T840-160H680Zm40-200h80v-40q0-17-11.5-28.5T760-440q-17 0-28.5 11.5T720-400v40Z" />
        </svg>
      </span>
      <v-icon
        v-if="props.softwareType === SoftwareTypes.MANUALLY"
        name="md-webasset-round"
        class="icon" />
      <v-icon v-else name="md-hideimage-round" class="icon" />
    </template>
  </el-image>
  <v-icon
    v-else
    class="icon"
    name="md-hideimage-round"
    fill="rgba(0, 0, 0, 0.1)" />
</template>

<script setup lang="ts">
  import { SoftwareTypes } from '@/client'

  interface Props {
    softwareName: string
    softwareType?: SoftwareTypes | 'SSO' | null
    size: string
  }

  const props = withDefaults(defineProps<Props>(), {
    size: '20px',
    softwareType: undefined,
  })
</script>

<style scoped lang="scss">
  .icon {
    height: v-bind(size);
    width: v-bind(size);
    transition: all 0.3s ease-in-out;
  }
</style>

<style lang="scss">
  .app-icon path {
    opacity: 0.7;
  }
</style>
