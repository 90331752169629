import { Currency, CurrencyCode } from '@/client'

import currencyToCountry from './country-code-to-currency-code-mapping.json'

export function currencyCodeToCountryCode(c: string): string | undefined {
  const country = currencyToCountry.find((currency) => currency.Code == c)
  if (!country) return
  return country.CountryCode.toLowerCase()
}

export function currencyCodeToIconName(c: string): string {
  if (c == 'BTC') return 'cryptocurrency-color:btc'
  return `circle-flags:${currencyCodeToCountryCode(c)}`
}

class CurrencyFinder {
  currencies: Currency[]
  constructor(currencies: Currency[]) {
    this.currencies = currencies
  }
  find(code: CurrencyCode) {
    return this.currencies.find((c) => c._id == code)
  }
  extract(currencies: Currency[]) {
    const codes = currencies.map((c) => c._id)
    return this.currencies.filter((c) => !codes.includes(c._id))
  }
}

export function sortCurrencies(c: Currency[]): Currency[] {
  const f = new CurrencyFinder(c)
  const eu = f.find(CurrencyCode.EUR)
  const usd = f.find(CurrencyCode.USD)
  const gbp = f.find(CurrencyCode.GBP)
  const chf = f.find(CurrencyCode.CHF)
  const trY = f.find(CurrencyCode.TRY)
  const dkk = f.find(CurrencyCode.DKK)
  const aud = f.find(CurrencyCode.AUD)

  let sortedCurrencies: Currency[] = []
  const important = [eu, usd, gbp, chf, trY, dkk, aud]

  for (const c of important) if (c) sortedCurrencies.push(c)

  sortedCurrencies = sortedCurrencies.concat(f.extract(sortedCurrencies))

  return sortedCurrencies
}
