/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationSortOrder } from '../models/PaginationSortOrder';
import type { SortableOrderUpdate } from '../models/SortableOrderUpdate';
import type { Tag } from '../models/Tag';
import type { TagPostIn } from '../models/TagPostIn';
import type { TagUpdate } from '../models/TagUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TagsService {
    /**
     * Get Tags
     * @returns Tag Successful Response
     * @throws ApiError
     */
    public static getTagsApiV1ManagementTagsGet({
        sortingExists,
        sortingEq,
        sortingGt,
        sortingGte,
        sortingIn,
        sortingLt,
        sortingLte,
        sortingNe,
        sortingNin,
        sorting,
        colorRExists,
        colorREq,
        colorRGt,
        colorRGte,
        colorRIn,
        colorRLt,
        colorRLte,
        colorRNe,
        colorRNin,
        colorR,
        colorGExists,
        colorGEq,
        colorGGt,
        colorGGte,
        colorGIn,
        colorGLt,
        colorGLte,
        colorGNe,
        colorGNin,
        colorG,
        colorBExists,
        colorBEq,
        colorBGt,
        colorBGte,
        colorBIn,
        colorBLt,
        colorBLte,
        colorBNe,
        colorBNin,
        colorB,
        color,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        name,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        sortingExists?: (boolean | null),
        sortingEq?: (number | null),
        sortingGt?: (number | null),
        sortingGte?: (number | null),
        sortingIn?: (Array<number> | null),
        sortingLt?: (number | null),
        sortingLte?: (number | null),
        sortingNe?: (number | null),
        sortingNin?: (Array<number> | null),
        sorting?: (number | null),
        colorRExists?: (boolean | null),
        colorREq?: (number | null),
        colorRGt?: (number | null),
        colorRGte?: (number | null),
        colorRIn?: (Array<number> | null),
        colorRLt?: (number | null),
        colorRLte?: (number | null),
        colorRNe?: (number | null),
        colorRNin?: (Array<number> | null),
        colorR?: (number | null),
        colorGExists?: (boolean | null),
        colorGEq?: (number | null),
        colorGGt?: (number | null),
        colorGGte?: (number | null),
        colorGIn?: (Array<number> | null),
        colorGLt?: (number | null),
        colorGLte?: (number | null),
        colorGNe?: (number | null),
        colorGNin?: (Array<number> | null),
        colorG?: (number | null),
        colorBExists?: (boolean | null),
        colorBEq?: (number | null),
        colorBGt?: (number | null),
        colorBGte?: (number | null),
        colorBIn?: (Array<number> | null),
        colorBLt?: (number | null),
        colorBLte?: (number | null),
        colorBNe?: (number | null),
        colorBNin?: (Array<number> | null),
        colorB?: (number | null),
        color?: null,
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        name?: (string | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<Tag>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/tags',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'sorting.$exists': sortingExists,
                'sorting.$eq': sortingEq,
                'sorting.$gt': sortingGt,
                'sorting.$gte': sortingGte,
                'sorting.$in': sortingIn,
                'sorting.$lt': sortingLt,
                'sorting.$lte': sortingLte,
                'sorting.$ne': sortingNe,
                'sorting.$nin': sortingNin,
                'sorting': sorting,
                'color.r.$exists': colorRExists,
                'color.r.$eq': colorREq,
                'color.r.$gt': colorRGt,
                'color.r.$gte': colorRGte,
                'color.r.$in': colorRIn,
                'color.r.$lt': colorRLt,
                'color.r.$lte': colorRLte,
                'color.r.$ne': colorRNe,
                'color.r.$nin': colorRNin,
                'color.r': colorR,
                'color.g.$exists': colorGExists,
                'color.g.$eq': colorGEq,
                'color.g.$gt': colorGGt,
                'color.g.$gte': colorGGte,
                'color.g.$in': colorGIn,
                'color.g.$lt': colorGLt,
                'color.g.$lte': colorGLte,
                'color.g.$ne': colorGNe,
                'color.g.$nin': colorGNin,
                'color.g': colorG,
                'color.b.$exists': colorBExists,
                'color.b.$eq': colorBEq,
                'color.b.$gt': colorBGt,
                'color.b.$gte': colorBGte,
                'color.b.$in': colorBIn,
                'color.b.$lt': colorBLt,
                'color.b.$lte': colorBLte,
                'color.b.$ne': colorBNe,
                'color.b.$nin': colorBNin,
                'color.b': colorB,
                'color': color,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'name': name,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Tag
     * @returns Tag Successful Response
     * @throws ApiError
     */
    public static addTagApiV1ManagementTagsPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: TagPostIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<Tag> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/tags',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Tags
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countTagsApiV1ManagementTagsCountGet({
        sortingExists,
        sortingEq,
        sortingGt,
        sortingGte,
        sortingIn,
        sortingLt,
        sortingLte,
        sortingNe,
        sortingNin,
        sorting,
        colorRExists,
        colorREq,
        colorRGt,
        colorRGte,
        colorRIn,
        colorRLt,
        colorRLte,
        colorRNe,
        colorRNin,
        colorR,
        colorGExists,
        colorGEq,
        colorGGt,
        colorGGte,
        colorGIn,
        colorGLt,
        colorGLte,
        colorGNe,
        colorGNin,
        colorG,
        colorBExists,
        colorBEq,
        colorBGt,
        colorBGte,
        colorBIn,
        colorBLt,
        colorBLte,
        colorBNe,
        colorBNin,
        colorB,
        color,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        name,
        xTransactionId,
    }: {
        sortingExists?: (boolean | null),
        sortingEq?: (number | null),
        sortingGt?: (number | null),
        sortingGte?: (number | null),
        sortingIn?: (Array<number> | null),
        sortingLt?: (number | null),
        sortingLte?: (number | null),
        sortingNe?: (number | null),
        sortingNin?: (Array<number> | null),
        sorting?: (number | null),
        colorRExists?: (boolean | null),
        colorREq?: (number | null),
        colorRGt?: (number | null),
        colorRGte?: (number | null),
        colorRIn?: (Array<number> | null),
        colorRLt?: (number | null),
        colorRLte?: (number | null),
        colorRNe?: (number | null),
        colorRNin?: (Array<number> | null),
        colorR?: (number | null),
        colorGExists?: (boolean | null),
        colorGEq?: (number | null),
        colorGGt?: (number | null),
        colorGGte?: (number | null),
        colorGIn?: (Array<number> | null),
        colorGLt?: (number | null),
        colorGLte?: (number | null),
        colorGNe?: (number | null),
        colorGNin?: (Array<number> | null),
        colorG?: (number | null),
        colorBExists?: (boolean | null),
        colorBEq?: (number | null),
        colorBGt?: (number | null),
        colorBGte?: (number | null),
        colorBIn?: (Array<number> | null),
        colorBLt?: (number | null),
        colorBLte?: (number | null),
        colorBNe?: (number | null),
        colorBNin?: (Array<number> | null),
        colorB?: (number | null),
        color?: null,
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        name?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/tags/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'sorting.$exists': sortingExists,
                'sorting.$eq': sortingEq,
                'sorting.$gt': sortingGt,
                'sorting.$gte': sortingGte,
                'sorting.$in': sortingIn,
                'sorting.$lt': sortingLt,
                'sorting.$lte': sortingLte,
                'sorting.$ne': sortingNe,
                'sorting.$nin': sortingNin,
                'sorting': sorting,
                'color.r.$exists': colorRExists,
                'color.r.$eq': colorREq,
                'color.r.$gt': colorRGt,
                'color.r.$gte': colorRGte,
                'color.r.$in': colorRIn,
                'color.r.$lt': colorRLt,
                'color.r.$lte': colorRLte,
                'color.r.$ne': colorRNe,
                'color.r.$nin': colorRNin,
                'color.r': colorR,
                'color.g.$exists': colorGExists,
                'color.g.$eq': colorGEq,
                'color.g.$gt': colorGGt,
                'color.g.$gte': colorGGte,
                'color.g.$in': colorGIn,
                'color.g.$lt': colorGLt,
                'color.g.$lte': colorGLte,
                'color.g.$ne': colorGNe,
                'color.g.$nin': colorGNin,
                'color.g': colorG,
                'color.b.$exists': colorBExists,
                'color.b.$eq': colorBEq,
                'color.b.$gt': colorBGt,
                'color.b.$gte': colorBGte,
                'color.b.$in': colorBIn,
                'color.b.$lt': colorBLt,
                'color.b.$lte': colorBLte,
                'color.b.$ne': colorBNe,
                'color.b.$nin': colorBNin,
                'color.b': colorB,
                'color': color,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tag
     * @returns Tag Successful Response
     * @throws ApiError
     */
    public static getTagApiV1ManagementTagsTagIdGet({
        tagId,
        xTransactionId,
    }: {
        tagId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Tag> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/tags/{tag_id}',
            path: {
                'tag_id': tagId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Tag
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateTagApiV1ManagementTagsTagIdPatch({
        tagId,
        requestBody,
        xTransactionId,
    }: {
        tagId: string,
        requestBody: TagUpdate,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/management/tags/{tag_id}',
            path: {
                'tag_id': tagId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Tag
     * @returns void
     * @throws ApiError
     */
    public static deleteTagApiV1ManagementTagsTagIdDelete({
        tagId,
        xTransactionId,
    }: {
        tagId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/management/tags/{tag_id}',
            path: {
                'tag_id': tagId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reorder Tags
     * @returns void
     * @throws ApiError
     */
    public static reorderTagsApiV1ManagementTagsReorderPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: SortableOrderUpdate,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/tags/reorder',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
