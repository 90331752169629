<template>
  <Teleport to="#overlay">
    <transition name="transparency" appear>
      <div
        v-if="visibility"
        class="sm-dialog-background"
        :style="{ zIndex: zIndex }"
        @click="handleClose"></div>
    </transition>
  </Teleport>

  <Teleport to="#modal">
    <transition name="bounce" appear>
      <div
        v-if="visibility"
        :class="classes"
        :style="{ zIndex: zIndex + 1 }"
        :attr="attrs">
        <div class="sm-dialog__header">
          <div class="sm-dialog__header__title">
            <!-- Title -->
            <slot name="title">
              <div class="flex items-center gap-2">
                <h2>{{ title }}</h2>

                <!-- Manual icon/link -->
                <a
                  class="cursor-pointer"
                  :href="props.linkToManual"
                  target="_blank"
                  v-if="props.linkToManual">
                  <v-icon
                    name="md-help-round"
                    class="opacity-60 hover:opacity-80" />
                </a>
              </div>
            </slot>
          </div>
          <div class="sm-dialog__header__close">
            <slot name="close">
              <SmButtonClose v-if="allowClose" @close="handleClose" />
            </slot>
          </div>
        </div>
        <div class="sm-dialog__body" :style="bodyStyle">
          <slot></slot>
        </div>
        <div v-if="$slots.footer" class="sm-dialog__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
  import { CSSProperties } from 'vue'

  import { getZindex } from '@/common/util/zIndex'

  import SmButtonClose from './SmButtonClose.vue'

  const attrs = useAttrs()
  let zIndex = 2000 // random value, will be overwritten in the watch method below

  export type DialogSize = 'small' | 'medium' | 'large'

  const visibility = defineModel<boolean>('visibility')

  interface Props {
    title?: string
    background?: boolean
    size?: DialogSize
    allowClose?: boolean
    height?: string
    bodyStyle?: CSSProperties
    linkToManual?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    title: '',
    background: false,
    size: 'medium',
    allowClose: true,
    height: 'auto',
    linkToManual: undefined,
  })

  const emit = defineEmits(['update:visibility'])

  const classes = computed(() => {
    return {
      'sm-dialog': true,
      'sm-dialog-background': props.background,
      [`sm-dialog--${props.size}`]: props.size,
    }
  })

  const bodyStyle = computed(() => {
    return {
      ...(props.bodyStyle || {}),
      height: props.height,
    }
  })

  function handleClose() {
    if (props.allowClose) {
      visibility.value = false
    }
  }

  watch(
    visibility,
    (value) => {
      if (value) {
        zIndex = getZindex()
      }
    },
    { immediate: true }
  )
</script>

<style lang="scss">
  $padding: 20px;

  h2 {
    margin: 0;
  }

  .sm-dialog {
    position: absolute;

    background-color: var(--sm-elevation);
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    padding: 25px;

    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);

    // Transition when the size changes
    transition: width 0.2s ease;

    // Sizes
    &--small,
    &--medium,
    &--large {
      width: 90vw;
    }

    @media screen and (min-width: 576px) {
      // Sizes
      &--small {
        width: clamp(300px, 30vw, 400px);
      }
      &--medium {
        width: clamp(400px, 50vw, 600px);
      }
      &--large {
        width: clamp(500px, 70vw, 1000px);
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    &__footer {
      display: flex;
      justify-content: center;
    }
  }

  .sm-dialog-background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
  }

  .sm-dialog__body {
    overflow-y: auto;
    max-height: 70vh;
  }

  .sm-dialog__footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .transparency-enter-active,
  .transparency-leave-active {
    transition: all 0.2s ease;
  }

  .transparency-enter-from,
  .transparency-leave-to {
    opacity: 0;
  }

  .bounce-enter-active {
    animation: bounce-in 0.3s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.3s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }

    50% {
      transform: translate(-50%, -50%) scale(1.25);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
</style>
