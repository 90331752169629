<template>
  <SmDialog v-model:visibility="visibility" :title="i18n.t('deleteAlias')">
    <template #default>
      <div class="text-center">
        <p class="mb-4">
          <i18n-t keypath="confirmDeleteAlias">
            <template #name>
              <b>{{ props.alias.name }}</b>
            </template>
          </i18n-t>
        </p>

        <AliasActionPreviewComponent
          :alias="props.alias.name"
          action="deleted"
          :user="response.user"
          :added-user="response.added_user"
          :deleted-user="response.deleted_user"
          :users="response.users"
          :licences="response.licences" />
      </div>
    </template>
    <template #footer>
      <div style="display: flex; float: right; margin-top: 15px">
        <el-button @click="() => emit('abort')">
          {{ i18n.t('general.cancel') }}
        </el-button>
        <el-button type="primary" @click="() => emit('confirm')">
          {{ i18n.t('acceptChanges') }}
        </el-button>
      </div>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import { AliasType } from '@/types/user'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  const emit = defineEmits(['update:visibility', 'confirm', 'abort'])
  const props = defineProps({
    visibility: {
      type: Boolean,
      default: false,
    },
    alias: {
      type: Object as PropType<AliasType>,
      required: true,
    },
    response: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<any>,
      required: true,
    },
  })

  const visibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })
</script>
