<template>
  <SmDialog
    v-model:visibility="visibility"
    link-to-manual="https://docs.saasmetrix.io/docs/users/aliases"
    :title="alias == null ? i18n.t('createAlias') : i18n.t('editAlias')">
    <div class="flex flex-col gap-3">
      <div class="flex flex-col gap-2">
        <label for="">{{ i18n.t('emailOrUsername') }}</label>
        <SmInput
          v-model="form.fields.name"
          size="small"
          outline
          label="Name"
          design="form"
          :disabled="alias?.name != undefined" />
      </div>

      <div class="flex flex-col gap-2">
        <label for="">{{ i18n.t('description') }}</label>
        <SmInput
          v-model="form.fields.description"
          size="small"
          outline
          :label="i18n.t('description')"
          design="form" />
      </div>
    </div>

    <div style="margin-bottom: 15px">
      <AliasActionPreviewComponent
        v-if="wasPreviewed && createAliasResponse != null"
        :alias="form.fields.name"
        action="created"
        :user="createAliasResponse.user"
        :added-user="createAliasResponse.added_user"
        :deleted-user="createAliasResponse.deleted_user"
        :users="createAliasResponse.users"
        :licences="createAliasResponse.licences" />
    </div>
    <template #footer>
      <div style="display: flex; flex-direction: row">
        <SmButton outline @click="visibility = false">
          {{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton type="primary" @click="saveAlias">
          {{ wasPreviewed ? i18n.t('save') : i18n.t('preview') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { Account, UserAliasesService } from '@/client'
  import { AliasType } from '@/types/user'
  import useForm from '@/common/form'

  const i18n = useI18n()

  const emit = defineEmits(['refetchUser'])

  interface Props {
    user: Account
    alias?: AliasType
  }

  const props = withDefaults(defineProps<Props>(), {
    alias: undefined,
  })

  const visibility = defineModel<boolean>('visibility')

  const form = useForm({
    name: props.alias?.name || '',
    description: props.alias?.description || '',
  })

  watch(props, (): void => {
    form.fields.name = props.alias?.name || ''
    form.fields.description = props.alias?.description || ''
  })

  const createAliasResponse = ref()

  const wasPreviewed = ref<boolean>(false)

  async function saveAlias() {
    // Create new alias
    if (props.alias === undefined) {
      UserAliasesService.addAliasApiV1ManagementUsersEmailOrIdAliasPost({
        emailOrId: props.user.email || '',
        requestBody: {
          user_name: form.fields.name,
          description: form.fields.description,
        },
        mergeUsers: true,
        force: true,
        preview: !wasPreviewed.value,
        removeFromOtherUsers: true,
      }).then((response) => {
        if (wasPreviewed.value === true) {
          visibility.value = false
          emit('refetchUser')
        } else {
          createAliasResponse.value = response
          wasPreviewed.value = true
        }
      })

      // Update existing alias
    } else {
      UserAliasesService.patchAliasApiV1ManagementUsersEmailOrIdAliasUserNamePatch(
        {
          userName: form.fields.name,
          emailOrId: props.user.email,
          requestBody: {
            description: form.fields.description,
          },
        }
      ).then(() => {
        visibility.value = false
        emit('refetchUser')
      })
    }
  }

  // If the Name/Email changes reset preview state
  watch(
    () => form.fields.name,
    () => {
      wasPreviewed.value = false
      createAliasResponse.value = undefined
    }
  )

  watch(visibility, () => {
    if (visibility.value) return
    form.fields.name = ''
    form.fields.description = ''
    createAliasResponse.value = undefined
  })
</script>
