/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Enum for the different types of software
 */
export enum SoftwareTypes {
    OAUTH = 'oauth',
    BOT = 'bot',
    MANUALLY = 'manually',
    TOKEN = 'token',
}
