/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LicenceFilter } from '../models/LicenceFilter';
import type { ResourceCounts } from '../models/ResourceCounts';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DashboardService {
    /**
     * Get Resource Counts
     * @returns ResourceCounts Successful Response
     * @throws ApiError
     */
    public static getResourceCountsApiV1DashboardResourceCountsGet({
        licenceFilter = 'billable',
        xTransactionId,
    }: {
        licenceFilter?: LicenceFilter,
        xTransactionId?: (string | null),
    }): CancelablePromise<ResourceCounts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dashboard/resource-counts',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'licence_filter': licenceFilter,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
