<template>
  <SmDialog
    v-model:visibility="visible"
    :title="t('activity.activityInformation')">
    <el-descriptions border :column="1">
      <el-descriptions-item width="5rem" label-class-name="description-label">
        <template #label>
          <span class="cell-item">{{ t('general.action') }} </span>
        </template>
        {{ activityMessage }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="description-label">
        <template #label>
          <span class="cell-item"> {{ t('general.timestamp') }} </span>
        </template>
        {{ timestamp }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="description-label">
        <template #label>
          <span class="cell-item"> {{ t('general.user') }} </span>
        </template>
        {{ accEmail }}
      </el-descriptions-item>
    </el-descriptions>
  </SmDialog>
</template>

<script setup lang="ts">
  import dayjs from 'dayjs'

  import { useI18n } from 'vue-i18n'

  import {
    Account,
    ActivityBaseModel,
    PriceInformation,
    SoftwareLicense,
    SoftwareOut,
    UserProfilePublic,
  } from '@/client'
  import { getI18nArgs } from '@/common/util/activityUtil'

  const { t } = useI18n()
  const props = defineProps<{
    activity: ActivityBaseModel
    userProfiles: Record<string, UserProfilePublic>
    visible: boolean
    allUser: Account[]
    allSoftware: SoftwareOut[]
    allSoftwareLicenses: SoftwareLicense[]
    allPriceInformations: PriceInformation[]
  }>()
  const emit = defineEmits(['update:visible'])

  const visible = computed({
    get: () => props.visible,
    set: (v) => {
      emit('update:visible', v)
    },
  })

  const tz = dayjs.tz.guess()

  const timestamp = computed(() =>
    dayjs(props.activity.created).tz(tz).format('DD.MM.YYYY HH:mm:ss')
  )

  const accEmail = computed(() => {
    return props.userProfiles[props.activity.identity_id]?.email
  })

  const activityMessage: Ref<string | null> = ref(null)

  async function fetchActivityMessage() {
    const args = await getI18nArgs(
      props.activity,
      props.allUser,
      props.allSoftware,
      props.allSoftwareLicenses,
      props.allPriceInformations,
      props.userProfiles[props.activity.identity_id]
    )

    activityMessage.value = t(`activity.${props.activity.action}`, args)
  }

  watch(() => props.activity, fetchActivityMessage)
</script>
