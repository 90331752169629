<template>
  <div @click="handleClick">
    <!-- Search -->
    <div>
      <SmInput
        v-model="searchQuery"
        size="small"
        outline
        :label="i18n.t('search')">
      </SmInput>
    </div>

    <!-- Tags -->
    <p
      v-if="filteredTags.length === props.tags.length"
      style="padding-top: 1rem; font-weight: bolder">
      {{ i18n.t('tags') }} ({{ props.tags.length }})
    </p>
    <p v-else style="padding-top: 1rem; font-weight: bolder">
      {{ i18n.t('tags') }} ({{ filteredTags.length }}/{{ props.tags.length }})
    </p>
    <div v-auto-animate class="tag-filter-wrapper">
      <div v-for="tag in filteredTags" :key="tag._id">
        <TagComponent
          :tag="tag"
          style="cursor: pointer"
          :selected="selectedTagIds.includes(tag._id)"
          @click="handleTagSelection(tag)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { Tag } from '@/client'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  interface Props {
    tags: Tag[]
  }

  const tagFilter = defineModel<Tag[]>('tagFilter', { required: true })

  const props = defineProps<Props>()

  function handleClick() {
    console.log('click')
  }

  const selectedTagIds = computed(() => {
    return tagFilter.value.map((tag) => tag._id)
  })

  //   Tag Filter
  function handleTagSelection(tag: Tag) {
    if (selectedTagIds.value.includes(tag._id)) {
      tagFilter.value = tagFilter.value.filter((t) => t._id !== tag._id)
    } else {
      tagFilter.value = [...tagFilter.value, tag]
    }
  }

  //   Search
  const searchQuery = ref('')

  const filteredTags = computed(() => {
    return props.tags.filter((tag) => {
      return tag.name.toLowerCase().includes(searchQuery.value.toLowerCase())
    })
  })
</script>

<style>
  .tag-filter-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    padding-top: 1rem;
    overflow-y: auto;
  }
</style>
