/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSoftwareUserLicenseAddData } from './BackgroundJobSoftwareUserLicenseAddData';
export type BackgroundJobSoftwareUserLicenseAddPostIn = {
    type: BackgroundJobSoftwareUserLicenseAddPostIn.type;
    data: BackgroundJobSoftwareUserLicenseAddData;
    /**
     * Time in seconds when to enqueue. Does not enqueue if value is `null`.
     */
    enqueue_in?: (number | null);
};
export namespace BackgroundJobSoftwareUserLicenseAddPostIn {
    export enum type {
        SOFTWARE_USER_LICENSE_ADD = 'software_user_license_add',
    }
}

