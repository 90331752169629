import { FormValidateCallback, FormValidationResult } from 'element-plus'

type ValidatableForm = {
  validate: (
    callback?: FormValidateCallback | undefined
  ) => FormValidationResult
}

export default async function validate(
  formRef: ValidatableForm
): Promise<boolean> {
  try {
    await formRef.validate()
    return true
  } catch (error) {
    return false
  }
}
