/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_import_licences_to_software_api_v1_import_licences__software_id__post } from '../models/Body_import_licences_to_software_api_v1_import_licences__software_id__post';
import type { ImporterSupportedFile } from '../models/ImporterSupportedFile';
import type { ImportResult } from '../models/ImportResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ImportLicencesService {
    /**
     * Get Licence Import Example File
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getLicenceImportExampleFileApiV1ImportLicencesExampleGet({
        fileType = 'csv',
        xTransactionId,
    }: {
        fileType?: ImporterSupportedFile,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/import/licences/example',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'file_type': fileType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Licences To Software
     * @returns ImportResult Successful Response
     * @throws ApiError
     */
    public static importLicencesToSoftwareApiV1ImportLicencesSoftwareIdPost({
        softwareId,
        formData,
        xTransactionId,
    }: {
        softwareId: string,
        formData: Body_import_licences_to_software_api_v1_import_licences__software_id__post,
        xTransactionId?: (string | null),
    }): CancelablePromise<ImportResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/import/licences/{software_id}',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
