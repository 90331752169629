<template>
  <!-- Message -->
  <el-collapse-transition>
    <div
      v-if="props.visible"
      :class="['sm-dialog-message', `sm-dialog-message-${props.type}`]">
      <div class="icon">
        <v-icon
          v-if="props.type == 'success'"
          name="md-checkcircle-round"
          scale="1.5"
          class="fill-primary" />

        <v-icon
          v-else-if="props.type == 'warning'"
          name="md-warningamber-round"
          scale="1.5"
          class="fill-orange" />

        <v-icon
          v-else-if="props.type == 'error'"
          name="md-cancel-round"
          scale="1.5"
          class="fill-magenta" />
      </div>
      <div class="message">
        <slot>
          {{ props.message }}
        </slot>
      </div>
    </div>
  </el-collapse-transition>
</template>

<script setup lang="ts">
  export type DialogMessageTypes = 'success' | 'error' | 'warning' | 'info'

  const props = defineProps({
    type: {
      type: String as PropType<DialogMessageTypes>,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: () => '' as string,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
  })
</script>

<style lang="scss">
  .sm-dialog-message {
    $message-padding: 0.5rem;
    margin: 0;
    padding: $message-padding;
    border-radius: var(--border-radius-base);
    display: flex;
    align-items: center;

    box-sizing: border-box;

    &-success {
      border: 1px solid var(--sm-primary);
    }

    &-warning {
      border: 1px solid var(--sm-orange);
    }

    &-error {
      border: 1px solid var(--sm-magenta);
    }

    .message {
      margin-left: 0.5rem;
    }
  }
</style>
