<template>
  <el-tabs v-model="activeTab">
    <!-- Padding -->
    <div style="height: 1rem"></div>

    <!-- Users -->
    <el-tab-pane name="users" :label="i18n.t('accounts')">
      <SmTable :data="tableAccounts" :columns="accountColumns" key-field="_id">
        <template #name="{ row }">
          <div class="avatar-row">
            <SmAvatar
              :name="row.name || row.email"
              size="small"
              class="bg-gray-200" />
            <TextMultiline :lines-to-show="2">
              <span>{{ row.name }}</span>
            </TextMultiline>
          </div>
        </template>
        <template #tags="{ row }">
          <div class="tag-wrapper">
            <TagComponent
              v-for="tag in getTagsByIds(row.tags)"
              :key="tag._id"
              :tag="tag" />
          </div>
        </template>
        <template #action="{ row }">
          <SmDropdown trigger="click">
            <SmDropdownItem
              v-require-permission="CompanyAccessRole.ADMIN"
              icon="md-delete-round"
              @click="removeAccount(row)">
              {{ i18n.t('removeAccount') }}
            </SmDropdownItem>
          </SmDropdown>
        </template>
      </SmTable>
      <div class="summarization">
        <div>
          {{ i18n.t('total') }}:
          {{ i18n.t('accountsCount', tableAccounts.length) }}
        </div>
        <div></div>
        <div></div>
      </div>
    </el-tab-pane>

    <!-- Licenses -->
    <el-tab-pane name="licenses" :label="i18n.t('licenses')">
      <SmTable :data="tableLicenses" :columns="licenseColumns" key-field="_id">
        <template #softwareName="{ row }">
          <div class="avatar-row">
            <ApplicationIcon
              :software-name="row.softwareName"
              size="30px"
              :software-type="row.softwareType" />
            <TextMultiline :lines-to-show="3">
              <span>{{ row.softwareDisplayName || row.softwareName }}</span>
            </TextMultiline>
          </div>
        </template>
        <template #name="{ row }">
          <TextMultiline :lines-to-show="3">
            {{ row.name }}
          </TextMultiline>
        </template>
        <template #action="{ row }">
          <SmDropdown trigger="click">
            <SmDropdownItem
              v-require-permission="CompanyAccessRole.ADMIN"
              icon="md-delete-round"
              @click="removeLicense(row)">
              {{ i18n.t('removeLicense') }}
            </SmDropdownItem>
          </SmDropdown>
        </template>
      </SmTable>
      <el-divider />
      <div class="summarization">
        <div>
          {{ i18n.t('total') }}:
          {{ i18n.t('licensesCount', tableLicenses.length) }}
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script setup lang="ts">
  import {
    AccountGroup,
    Account,
    CompanyAccessRole,
    SoftwareLicense,
    PriceInformation,
    SoftwareOut,
    Tag,
    UserGroupsService,
    SoftwareTypes,
  } from '@/client'
  import { getApiErrorMessage } from '@/common/util/apiError'

  import { useI18n } from 'vue-i18n'
  import { sendToast } from './sm/SmNotification'
  import { Column } from './sm/SmTable.types'

  type TableLicenseInfo = Partial<SoftwareLicense> & {
    softwareId: string
    softwareDisplayName: string | null
    softwareName: string
    softwareType: SoftwareTypes | null
  }

  const i18n = useI18n()
  const emit = defineEmits<{ groupChanged: [] }>()

  const activeTab = ref('users')

  const props = defineProps<{
    group: AccountGroup
    allAccounts: Account[]
    allApplications: SoftwareOut[]
    allTags: Tag[]
    allSoftwareLicenses: SoftwareLicense[]
    allPriceInformations: PriceInformation[]
  }>()

  function isSoftwareLicense(
    row: Partial<SoftwareLicense>
  ): row is SoftwareLicense {
    return Object.keys(row).includes('software_id')
  }

  function getTagsByIds(ids: string[]): Tag[] {
    return props.allTags.filter((tag) => ids.includes(tag._id))
  }

  function getLicensePermissionCount(license: TableLicenseInfo) {
    if (!isSoftwareLicense(license)) return 0
    const permissions =
      props.group.permission.software_permissions[license.softwareId]
    let i = 0
    for (const license_permissions of Object.values(
      permissions.sub_account_permissions
    )) {
      i += Object.keys(license_permissions.software_license_permissions).length
    }
    return i
  }

  function removeLicense(license: TableLicenseInfo) {
    let requestBody
    if (getLicensePermissionCount(license) <= 1) {
      requestBody = {
        permission: {
          software_permissions: {
            [license.softwareId]: null,
          },
        },
      }
    } else {
      // If the tableLicense does not include a `softwareLicense`, the above case will always be true
      if (!license.sub_account_id) return
      requestBody = {
        permission: {
          software_permissions: {
            [license.software_id!]: {
              sub_account_permissions: {
                [license.sub_account_id]: {
                  software_license_permissions: {
                    [license._id!]: null,
                  },
                },
              },
            },
          },
        },
      }
    }
    UserGroupsService.patchAccountGroupApiV1ManagementGroupsAccountGroupIdPatch(
      {
        accountGroupId: props.group._id,
        requestBody,
      }
    )
      .then(() => {
        sendToast(
          i18n.t('licenseRemovedSuccess', { name: license.name }),
          undefined,
          'success'
        )
        emit('groupChanged')
      })
      .catch((err) => {
        sendToast(getApiErrorMessage(err, i18n), undefined, 'error')
      })
  }

  function removeAccount(account: Account) {
    UserGroupsService.removeAccountFromAccountGroupApiV1ManagementGroupsAccountGroupIdAccountsAccountIdDelete(
      { accountId: account._id, accountGroupId: props.group._id }
    )
      .then(() => {
        sendToast(
          i18n.t('accountRemovedSuccess', { email: account.email }),
          undefined,
          'success'
        )
        emit('groupChanged')
      })
      .catch((err) => {
        sendToast(getApiErrorMessage(err, i18n), undefined, 'error')
      })
  }

  const accountColumns: Column<Account>[] = [
    {
      key: 'name',
      label: i18n.t('name'),
      sortable: true,
      width: 2,
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
      width: 3,
    },
    {
      key: 'tags',
      label: 'Tags',
      sortable: false,
      width: 2,
    },
    {
      key: 'action',
      label: '',
      sortable: false,
      width: '50px',
    },
  ]

  const licenseColumns: Column<TableLicenseInfo>[] = [
    {
      key: 'softwareName',
      label: i18n.t('software'),
      sortable: true,
      width: 2,
    },
    {
      key: 'name',
      label: i18n.t('license.license'),
      sortable: true,
      width: 3,
      sortFn: (a, b) => {
        const nameA = isSoftwareLicense(a) ? a.name : ''
        const nameB = isSoftwareLicense(b) ? b.name : ''
        return nameA.localeCompare(nameB)
      },
    },
    {
      key: 'action',
      label: '',
      sortable: false,
      width: '50px',
    },
  ]

  const tableAccounts = computed(() => {
    return props.allAccounts.filter((a) => props.group.accounts.includes(a._id))
  })

  const tableLicenses = computed(() => {
    // { software_id: [licence_model_id1, licence_model_id2, ...] }
    const licenseDisplayData: TableLicenseInfo[] = []
    const relevantIds: Record<string, string[]> = {}
    for (const [softwareId, permission] of Object.entries(
      props.group.permission.software_permissions
    )) {
      relevantIds[softwareId] = Object.keys(permission)
      const _app = props.allApplications.find((a) => a._id == softwareId)
      const _subAccountPermissions = permission.sub_account_permissions

      // Add the software itself and no sub-accounts are selected
      if (
        Object.keys(_subAccountPermissions).length == 1 &&
        Object.keys(
          Object.values(_subAccountPermissions)[0].software_license_permissions
        ).length == 0
      ) {
        if (_app)
          licenseDisplayData.push({
            _id: crypto.randomUUID(),
            softwareName: _app.software_name,
            softwareType: _app.type,
            softwareDisplayName: _app.display_name,
            softwareId: _app._id,
          })
      } else {
        // Add the sub-accounts and the selected license models
        Object.values(_subAccountPermissions).forEach(
          (subaccountPermission) => {
            Object.keys(
              subaccountPermission.software_license_permissions
            ).forEach((license_model_id) => {
              if (!_app) return
              const _softwareLicense = props.allSoftwareLicenses.find(
                (l) => l._id == license_model_id
              )
              licenseDisplayData.push({
                ..._softwareLicense,
                softwareName: _app.software_name,
                softwareType: _app.type,
                softwareDisplayName: _app.display_name,
                softwareId: _app._id,
              })
            })
          }
        )
      }
    }
    return licenseDisplayData
  })
</script>

<style scoped lang="scss">
  .avatar-row {
    display: grid;
    grid-template-columns: 40px auto;
    gap: 1rem;
    align-items: center;
  }

  .tag-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0.5rem 0 0.5rem 0;
    align-items: center;
    width: 100%;
  }

  .summarization {
    margin-top: 1rem;
    padding-left: 10px;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 2fr 3fr 1fr;

    div {
      color: var(--sm-contrast-muted);
    }
  }
</style>
