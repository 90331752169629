export default {
  properties: {
    _id: {
      title: 'Id',
      type: 'string',
      maxLength: 50,
    },
    created: {
      format: 'date-time',
      title: 'Created',
      type: 'string',
    },
    updated: {
      format: 'date-time',
      title: 'Updated',
      type: 'string',
    },
    deleted_at: {
      anyOf: [
        {
          format: 'date-time',
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Deleted At',
    },
    type: {
      enum: [
        'account_group_accounts_added',
        'account_group_accounts_removed',
        'data_source_access_token_update',
        'data_source_fetch_and_update',
        'generate_company_statistics',
        'software_access_token_update',
        'software_external_events_fetch_and_update',
        'software_fetch_and_update',
        'software_licence_statistics_fetch_and_update',
        'software_licences_fetch_and_update',
        'software_user_add',
        'software_user_enable',
        'software_user_license_add',
        'software_user_license_remove',
        'software_user_remove',
        '_debug_exception',
        '_debug_sleep',
      ],
      title: 'BackgroundJobType',
      type: 'string',
      maxLength: 100,
    },
    status: {
      enum: [
        'enqueued',
        'error',
        'paused',
        'aborted',
        'user_paused',
        'user_aborted',
        'user_action_required',
        'completed',
        'deprecated',
      ],
      title: 'BackgroundJobStatus',
      type: 'string',
      maxLength: 100,
    },
    executions: {
      type: 'array',
      title: 'Executions',
      items: {
        type: 'object',
        title: 'BackgroundJobExecutionBase',
        properties: {
          type: {
            enum: [
              'account_group_accounts_added',
              'account_group_accounts_removed',
              'data_source_access_token_update',
              'data_source_fetch_and_update',
              'generate_company_statistics',
              'software_access_token_update',
              'software_external_events_fetch_and_update',
              'software_fetch_and_update',
              'software_licence_statistics_fetch_and_update',
              'software_licences_fetch_and_update',
              'software_user_add',
              'software_user_enable',
              'software_user_license_add',
              'software_user_license_remove',
              'software_user_remove',
              '_debug_exception',
              '_debug_sleep',
            ],
            title: 'BackgroundJobType',
            type: 'string',
          },
          execution_id: {
            title: 'Execution Id',
            type: 'string',
          },
          executed_at: {
            format: 'date-time',
            title: 'Executed At',
            type: 'string',
          },
          data: {
            anyOf: [
              {
                type: 'object',
                title: 'BackgroundJobExecutionData',
                properties: {},
              },
              {
                type: 'null',
              },
            ],
          },
          status: {
            enum: [
              'enqueued',
              'error',
              'paused',
              'aborted',
              'user_paused',
              'user_aborted',
              'user_action_required',
              'completed',
              'deprecated',
            ],
            title: 'BackgroundJobStatus',
            type: 'string',
          },
          error_event_id: {
            anyOf: [
              {
                type: 'string',
              },
              {
                type: 'null',
              },
            ],
            title: 'Error Event Id',
          },
          background_job_ids_enqueued: {
            type: 'array',
            title: 'Background Job Ids Enqueued',
            items: {
              type: 'string',
            },
          },
        },
        required: ['type', 'execution_id', 'executed_at'],
      },
    },
    data: {
      type: 'object',
      title: 'BaseModel',
      properties: {},
    },
    last_executed_at: {
      anyOf: [
        {
          format: 'date-time',
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Last Executed At',
    },
    next_execution_at: {
      anyOf: [
        {
          format: 'date-time',
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Next Execution At',
    },
    created_by_job_id: {
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Created By Job Id',
    },
    created_by_identity_id: {
      title: 'creator_id',
      type: 'string',
      maxLength: 50,
    },
  },
  required: ['type', 'data'],
  title: 'BackgroundJobBase',
  type: 'object',
  version: 0,
  primaryKey: '_id',
  indexes: ['type', 'status'],
} as const
