<template>
  <!-- Blur div -->
  <div
    style="
      height: 100%;
      width: 100%;
      position: absolute;
      backdrop-filter: blur(2px);
    " />
  <!-- Center div -->
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 500;
    ">
    <slot />
  </div>
</template>
