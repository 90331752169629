<template>
  <div v-if="props.loading" class="sm-loading"></div>
</template>

<script lang="ts" setup>
  const props = defineProps({
    loading: {
      type: Boolean,
      required: true,
    },
  })
</script>

<style scoped lang="scss">
  .sm-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background-color: var(--el-mask-color-extra-light);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;

    &::after {
      content: '';
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 5px solid var(--el-color-primary);
      border-color: var(--el-color-primary) transparent var(--el-color-primary)
        transparent;
      animation: loading 1.2s linear infinite;
    }
  }
</style>
