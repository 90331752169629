/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SoftwareAuthorizationOutNextStep = {
    type: SoftwareAuthorizationOutNextStep.type;
    title: string;
    message: string;
    i18n_code: string;
    error_message: (string | null);
    data: (Record<string, any> | null);
};
export namespace SoftwareAuthorizationOutNextStep {
    export enum type {
        NEXT_STEP = 'next_step',
    }
}

