<template>
  <el-form
    ref="documentFormRef"
    :model="documentForm"
    label-width="12ch"
    label-position="left"
    require-asterisk-position="right">
    <!-- Software -->
    <el-form-item
      :label="i18n.t('drawers.documentUploadDrawer.form.software.label')"
      prop="software_id"
      data-tutorial-id="documents-add-software-input">
      <el-select-v2
        v-model="documentForm.software_id"
        :options="selectSoftwareOptions"
        :placeholder="
          i18n.t('drawers.documentUploadDrawer.form.software.placeholder')
        ">
        <template #default="{ item }">
          <div style="display: flex; align-items: center; gap: 10px">
            <el-image
              class="company-mini-img"
              :src="
                softwares?.find(
                  (software: Software) => software._id === item.value
                )?.icon != null
                  ? softwares?.find(
                      (software: Software) => software._id === item.value
                    )?.icon
                  : `https://logos.saasmetrix.io/${item.label}.png`
              ">
              <template #error>
                <v-icon name="md-hideimage-round" scale="1.3" />
              </template>
            </el-image>
            <span>{{ item.label }}</span>
          </div>
        </template>
      </el-select-v2>
    </el-form-item>

    <!-- Title -->
    <el-form-item
      :label="i18n.t('drawers.documentUploadDrawer.form.name.label')"
      data-tutorial-id="documents-add-name-input">
      <el-input
        v-model="documentForm.title"
        :placeholder="
          i18n.t('drawers.documentUploadDrawer.form.name.placeholder')
        " />
    </el-form-item>
    <!-- Date -->
    <el-form-item
      :label="i18n.t('drawers.documentUploadDrawer.form.date.label')"
      data-tutorial-id="documents-add-date-input">
      <div style="display: flex; flex-direction: column">
        <el-date-picker
          v-model="documentForm.date"
          type="date"
          format="DD.MM.YYYY" />
      </div>
    </el-form-item>
    <!-- Notes -->
    <el-form-item
      :label="i18n.t('drawers.documentUploadDrawer.form.note.label')"
      data-tutorial-id="documents-add-note-input">
      <el-input
        v-model="documentForm.notes"
        :autosize="{ minRows: 5, maxRows: 10 }"
        type="textarea"
        :placeholder="
          i18n.t('drawers.documentUploadDrawer.form.note.placeholder')
        " />
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { DocumentInfo } from '@/client'
  import { getSoftwares } from '@/common/software'
  import { useGlobalStore } from '@/stores/globalStore'
  import { Software } from '@/types/software'
  import { DocumentForm } from './DocumentUploadDialog.vue'
  import { FormInstance } from 'element-plus/es/components/form'

  const store = useGlobalStore()
  const i18n = useI18n()

  interface Props {
    viewMode: boolean
    softwareId?: string | null
  }

  withDefaults(defineProps<Props>(), {
    viewMode: false,
    softwareId: undefined,
  })

  const documentForm = defineModel<DocumentForm | DocumentInfo>(
    'documentForm',
    {
      required: true,
    }
  )

  const documentFormRef = defineModel<FormInstance>('documentFormRef', {
    required: true,
  })

  const softwares = ref<Software[]>()
  const selectSoftwareOptions = computed(() =>
    softwares.value
      ? softwares.value?.map((software) => ({
          label: software.display_name || software.software_name,
          value: software._id,
        }))
      : []
  )

  getSoftwares(store.getIp, store.getJWT()).then(
    (response) => (softwares.value = response)
  )
</script>

<style scoped>
  .company-mini-img {
    height: 15px;
    width: 15px;
  }
  .helper-text {
    font-weight: 300;
    line-height: 14px;
    font-size: 11px !important;
  }
</style>
