<template>
  <div class="descriptions-container">
    <div
      v-for="item in props.descriptions"
      :key="item.key"
      class="descriptions-item">
      <span class="heading">
        <slot :name="`heading-${item.key}`">
          <v-icon
            v-if="item.titleIcon"
            scale="0.8"
            :name="item.titleIcon"
            style="color: inherit" />
          {{ item.title }}
        </slot>
      </span>
      <span class="content">
        <slot :name="`field-${item.key}`">
          <TextMultiline :lines-to-show="3">{{
            item.content || noDataText
          }}</TextMultiline>
        </slot>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  type Description = {
    key: string
    title: string
    titleIcon?: string
    content?: string
  }
  const props = withDefaults(
    defineProps<{
      descriptions: Description[]
      numberColumns?: number
      noDataText?: string
    }>(),
    { numberColumns: 2, noDataText: 'No Data' }
  )
</script>

<style scoped lang="scss">
  .descriptions-container {
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(v-bind(numberColumns), 1fr);
  }

  .descriptions-item {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .heading {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-weight: 300;
    color: var(--sm-contrast-muted);
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
</style>
