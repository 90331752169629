<template>
  <!-- Delete Dialog -->
  <UserDeleteDialog
    v-if="user"
    v-model:visibility="deleteUserDialogVisibility"
    :user="user"
    @license-deleted="
      () => {
        deleteUserDialogVisibility = false
        localVisibility = false
        emit('refetchLicences')
      }
    " />

  <!-- Drawer content -->
  <SmDrawer v-model:visibility="localVisibility" :title="i18n.t('user')">
    <!-- Header -->
    <template #close>
      <div class="header">
        <div
          v-if="user"
          v-require-permission="CompanyAccessRole.ADMIN"
          style="margin-right: 10px; display: flex; align-items: center"
          @click="handleUserDelete(user?.email || '')">
          <v-icon name="md-delete-round" scale="1.1" class="trash-icon" />
        </div>
        <SmButtonClose
          data-test-id="user-detail-drawer-close-button"
          @click="localVisibility = !localVisibility" />
      </div>
    </template>

    <!-- Drawer Content -->
    <template #default>
      <!-- User Header -->
      <div class="user-header">
        <SmAvatar :name="user?.name || ''" class="bg-gray-200" />
        <div>
          <h3>{{ user?.name }}</h3>
          <p>{{ user?.email }}</p>
        </div>
      </div>

      <!-- Spacer -->
      <div style="height: 1rem" />

      <el-tabs v-model="activeTab" class="demo-tabs">
        <!-- Information Tab -->
        <el-tab-pane label="Information" name="first">
          <div class="user_header">
            <div style="width: 100%">
              <UsersDrawerInformation
                v-if="user"
                v-model:user="user"
                @refetch-user="refetchUsers" />
            </div>
          </div>
        </el-tab-pane>

        <!-- Licenses Tab -->
        <el-tab-pane :label="i18n.t('licenses')" name="second" lazy>
          <div style="margin-top: 0.5rem; overflow-y: auto; max-height: 50vh">
            <LicenseTable
              v-if="user"
              :softwares="softwares"
              display-software-name
              :filter="filter"
              :display-email="false"
              display-price
              display-sub-account
              :display-status="false"
              :display-last-logged-in="false"
              display-status-indicator
              @refetch-users="
                () => {
                  emit('refetchLicences')
                }
              " />
          </div>
        </el-tab-pane>

        <!-- Statistics Tab -->
        <el-tab-pane :label="i18n.t('statistics')" name="third" lazy>
          <UserDrawerStatistics
            :licenses="licenses"
            :software-costs="softwareCosts" />
        </el-tab-pane>

        <!-- Aliases Tab -->
        <el-tab-pane label="Aliases" name="fourth" lazy>
          <UsersDrawerAliases
            v-if="user"
            v-model:user="user"
            :softwares="softwares" />
        </el-tab-pane>
      </el-tabs>
    </template>
  </SmDrawer>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    CompanyAccessRole,
    SoftwareOut,
    UsersService,
  } from '@/client'
  import { useSoftwareStore } from '@/stores/softwareStore'
  import { useUserStore } from '@/stores/userStore'

  import { LicenceFilterInstance, licenseStore } from '@/stores/licenseV2.store'
  import { computedAsync } from '@vueuse/core'
  import LicenseTable from './LicenseTable.vue'
  import { AccountFilter } from '@/stores/license.filter'
  import { removeFilter, updateFilter } from '@/common/util/filterUtils'

  const i18n = useI18n()
  const userStore = useUserStore()
  const softwareStore = useSoftwareStore()

  const activeTab = ref('first')

  const props = defineProps({
    userId: {
      type: String,
      required: true,
    },
    drawerVisibility: {
      type: Boolean,
      required: true,
    },
  })

  const emit = defineEmits(['update:drawerVisibility', 'refetchLicences'])

  const user = ref<Account>()
  const softwares = ref<SoftwareOut[]>([])

  const deleteUserDialogVisibility = ref<boolean>(false)
  const deleteUserDialogEmail = ref<string>('')

  const localVisibility = computed({
    get: () => props.drawerVisibility,
    set: (value) => emit('update:drawerVisibility', value),
  })

  watch(
    () => props.userId,
    async () => {
      await refetchUsers()
    }
  )

  const handleUserDelete = (email: string) => {
    deleteUserDialogEmail.value = email
    deleteUserDialogVisibility.value = true
  }

  async function refetchUsers() {
    // Get the user
    await userStore.getUser({ allowCached: false })
    const _user = await userStore.getUserByID(props.userId)
    if (_user) user.value = _user

    // Fetch softwares from database
    softwares.value = await softwareStore.getSoftware()
  }

  // Statistic data
  const licenses = computedAsync(async () => {
    if (!user.value?._id) return []
    return await licenseStore.get([
      new licenseStore.filter.AccountFilter(user.value?._id),
    ])
  })

  // Software cost
  const softwareCosts = ref<number>(0)
  watch(
    () => user.value?._id,
    async () => {
      if (!user.value?._id) return
      softwareCosts.value =
        await UsersService.getUserCostApiV1ManagementUsersEmailOrIdCostGet({
          emailOrId: user.value?._id,
        })
    }
  )

  onMounted(async () => {
    await refetchUsers()
  })

  // Filter
  const filter = ref<LicenceFilterInstance[]>([])

  watch(
    () => props.userId,
    () => {
      if (props.userId) {
        updateFilter(filter, new AccountFilter(props.userId))
      } else {
        removeFilter(filter, 'AccountFilter')
      }
    },
    { immediate: true }
  )
</script>

<style scoped>
  .user_header {
    display: flex;
    flex-direction: row;
  }

  .user-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
</style>
