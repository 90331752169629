import { UnwrapRef } from 'vue'

import { ApiError } from '@/client'
import { editToast, sendToast } from '@/components/sm/SmNotification'
import i18n from '@/i18n/i18n'

export function useFetch<T>(
  fetch: () => Promise<T>,
  defaultData: T | null = null,
  options: {
    sendToast: boolean
    loadingToastTitle?: string
    successToastTitle?: string
  } = {
    sendToast: true,
    loadingToastTitle: 'notification.loading',
    successToastTitle: 'notification.loadingSuccess',
  }
) {
  const data = ref<T | null>(defaultData)
  const error = ref<Error | null>(null)
  const loading = ref(false)
  const toastId = ref<symbol | null>(null)

  //   Send a loading toast if we want to
  if (options.sendToast && options.successToastTitle) {
    if (!options.loadingToastTitle)
      options.loadingToastTitle = 'notification.loading'
    toastId.value = sendToast(
      i18n.global.t(options.loadingToastTitle),
      undefined,
      'loading'
    )
  }

  async function fetchData() {
    loading.value = true
    try {
      data.value = (await fetch()) as UnwrapRef<T>

      //   Send a success toast if we have a toastId
      if (toastId.value && options.successToastTitle) {
        editToast(toastId.value, {
          title: i18n.global.t(options.successToastTitle),
          message: '',
          type: 'success',
        })
      }
    } catch (e) {
      if (e instanceof Error) error.value = e

      //   Send an error toast if we have a toastId
      if (options.sendToast) {
        let translation
        if (
          error.value instanceof ApiError &&
          i18n.global.te(error.value?.body.detail.error + '.title')
        ) {
          translation = i18n.global.t(error.value?.body.detail.error + '.title')
        } else if (
          error.value instanceof ApiError &&
          error.value?.body.detail.message
        ) {
          translation = error.value.body.detail.message
        } else translation = i18n.global.t('notification.loadingError')

        if (toastId.value)
          editToast(toastId.value, {
            title: translation,
            message: '',
            type: 'error',
          })
        else sendToast(translation, undefined, 'error')
      }
    } finally {
      loading.value = false
    }
  }

  return {
    data,
    error,
    loading,
    fetchData,
  }
}
