<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    ">
    <img src="/img/Done.png" style="width: 30%; object-fit: contain" :alt="i18n.t('done')" />
    <div>
      <p style="text-align: center">
        <span style="font-size: 20px; font-weight: 500">
          {{ i18n.t('dialogs.onboardingDialog.content.success.headline') }}
        </span>
        <br />
        <span style="font-size: 16px; font-weight: 400">
          {{ i18n.t('dialogs.onboardingDialog.content.success.text') }}
        </span>
      </p>
    </div>
    <SmButton
      size="large"
      style="width: 200px; margin-top: 20px"
      :color="buttonColor"
      @click="() => emit('nextPage')">
      {{ i18n.t('dialogs.onboardingDialog.content.success.button') }}
    </SmButton>
  </div>
  <div
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    ">
    <ConfettiExplosion />
  </div>
</template>

<script lang="ts" setup>
  import { useDark } from '@vueuse/core'
  import { useI18n } from 'vue-i18n'

  const emit = defineEmits(['nextPage'])

  const i18n = useI18n()
  const isDark = useDark()

  const buttonColor = computed(() => (!isDark.value ? '#1a232d' : '#7f8a97'))

  async function confirm(): Promise<boolean> {
    return true
  }

  defineExpose({ confirm })
</script>
