<template>
  <SmDialog
    v-model:visibility="visibility"
    :title="i18n.t('dialogs.leaveDemoWarning.title')">
    <!-- Content -->
    <div class="content">
      <p>
        {{ i18n.t('dialogs.leaveDemoWarning.text1') }}
      </p>
    </div>

    <!-- Footer -->
    <template #footer>
      <div class="footer">
        <SmButton
          :text="i18n.t('general.cancel')"
          outline
          @click="visibility = false" />

        <SmButton
          :text="i18n.t('dialogs.leaveDemoWarning.leave')"
          type="danger"
          @click="executeLeaveDemo" />
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { executeLeaveDemo } from '@/common/util/demoData'

  const i18n = useI18n()

  // Visibility of the dialog
  const props = defineProps({
    visibility: {
      type: Boolean,
      default: true,
    },
  })

  const emit = defineEmits(['update:visibility'])

  const visibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })
</script>

<style lang="scss" scoped>
  .content {
    p {
      text-align: justify;
    }
  }
  .footer {
    display: flex;
    gap: 1rem;
  }
</style>
