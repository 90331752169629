<template>
  <div class="user">
    <div class="user__avatar">
      <SmAvatar :name="props.user?.name || props.user?.email || '??'" />
    </div>
    <div class="user__information">
      <div class="user__name">
        {{ props.user?.name }}
      </div>
      <div class="user__email">
        {{ props.user?.email }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Account } from '@/client'

  const props = defineProps({
    user: {
      type: Object as PropType<Account>,
      required: true,
    },
  })
</script>

<style lang="scss" scoped>
  .user {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;

    &__avatar {
      margin-right: 1rem;
      border-radius: var(--border-radius-base);
      box-shadow: var(--shadow);
      background-color: var(--sm-white);
    }

    &__information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__name {
      font-weight: 600;
    }

    &__email {
      color: var(--el-text-color-secondary);
    }
  }
</style>
