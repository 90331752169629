/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobExecutionBase_Literal_software_user_license_add__BackgroundJobSoftwareUserLicenseAddExecutionData_ } from './BackgroundJobExecutionBase_Literal_software_user_license_add__BackgroundJobSoftwareUserLicenseAddExecutionData_';
import type { BackgroundJobSoftwareUserLicenseAddData } from './BackgroundJobSoftwareUserLicenseAddData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
export type BackgroundJobSoftwareUserLicenseAdd = {
    created: string;
    updated: string;
    deleted: boolean;
    deleted_at: (string | null);
    _id: string;
    type: BackgroundJobSoftwareUserLicenseAdd.type;
    status: BackgroundJobStatus;
    executions: Array<BackgroundJobExecutionBase_Literal_software_user_license_add__BackgroundJobSoftwareUserLicenseAddExecutionData_>;
    data: BackgroundJobSoftwareUserLicenseAddData;
    last_executed_at: (string | null);
    next_execution_at: (string | null);
    execution_token: string;
    created_by_job_id: (string | null);
    created_by_identity_id: (string | null);
};
export namespace BackgroundJobSoftwareUserLicenseAdd {
    export enum type {
        SOFTWARE_USER_LICENSE_ADD = 'software_user_license_add',
    }
}

