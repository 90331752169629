<script setup lang="ts">
  import { SettingsFlow } from '@ory/client'

  import { useI18n } from 'vue-i18n'

  import { getCsrfToken } from '@/common/util/oryUtil'
  import { useSessionStore } from '@/stores/sessionStore'

  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'

  interface Message {
    message: string
    type: DialogMessageTypes
    visibility: boolean
  }

  const message = ref<Message>({
    message: '',
    type: 'success',
    visibility: false,
  })

  const i18n = useI18n()
  const sessionStore = useSessionStore()

  //   If deactivation was successful
  const success = ref<boolean>(false)

  const loading = ref<boolean>(false)

  //   Validation code to deactivate 2FA
  const validationCodeDeactivate = ref<string>('')

  const visibility = defineModel('visibility', {
    default: true,
  })

  //   Ory specific
  const settingsFlow = ref<SettingsFlow | null>(null)
  const csrfToken = ref<string | null>(null)

  async function handleDisable2FAButtonClick() {
    loading.value = true
    // Create settings flow
    settingsFlow.value = (
      await sessionStore.frontend.createBrowserSettingsFlow()
    ).data

    // Get csrf token
    csrfToken.value = getCsrfToken(settingsFlow.value.ui.nodes) || null // method returns undefined, but crsfToken.value is string | null

    if (!csrfToken.value) {
      throw new Error('csrf_token not found')
    }

    // Disable 2FA
    await sessionStore.frontend
      .updateSettingsFlow({
        flow: settingsFlow.value.id,
        updateSettingsFlowBody: {
          csrf_token: csrfToken.value,
          method: 'totp',
          totp_code: validationCodeDeactivate.value,
          totp_unlink: true,
        },
      })
      .then(() => {
        message.value.message = i18n.t('otpDeactivated')
        message.value.type = 'success'
        message.value.visibility = true

        success.value = true
      })
      .catch(() => {
        message.value.message = i18n.t('otpDeactivateError')
        message.value.type = 'error'
        message.value.visibility = true
      })
      .finally(() => {
        loading.value = false
        sessionStore.checkSession()
      })
  }
</script>

<template>
  <SmDialog
    v-model:visibility="visibility"
    size="small"
    :title="i18n.t('otpDeactivate')">
    <SmDialogMessage
      style="width: 98%; margin-bottom: 1rem"
      :message="message.message"
      :type="message.type"
      :visible="message.visibility" />

    <p>{{ i18n.t('otpDeactivateDesc') }}</p>

    <SmInput
      v-model="validationCodeDeactivate"
      style="width: 99%; margin-top: 1rem"
      type="text"
      :label="i18n.t('validationCode')"
      :placeholder="i18n.t('validationCode')" />

    <template #footer>
      <SmButton
        v-if="!success"
        :loading="loading"
        size="large"
        outline
        style="margin-top: 2rem"
        type="danger"
        @click="handleDisable2FAButtonClick">
        {{ i18n.t('deactivate') }}
      </SmButton>

      <SmButton
        v-else
        size="large"
        outline
        style="margin-top: 2rem"
        type="success"
        @click="visibility = false">
        {{ i18n.t('close') }}
      </SmButton>
    </template>
  </SmDialog>
</template>
