<template>
  <div class="wrapper">
    <p>
      {{ group.notes }}
    </p>
    <div class="stats">
      <span>{{ i18n.t('licensesCount', getCount()) }} </span>
      <div id="dot" />
      <span>
        {{ i18n.t('accountsCount', group.accounts.length) }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { AccountGroup } from '@/client'
  import { useI18n } from 'vue-i18n'
  const props = defineProps<{ group: AccountGroup }>()

  const i18n = useI18n()

  function getCount() {
    let count = 0
    for (const config of Object.values(props.group.config.software_config)) {
      count += Object.keys(config.software_license_config).length
    }
    return count
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  #dot {
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: var(--sm-contrast-muted);
  }

  .stats {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--sm-contrast-muted);
  }
</style>
