<template>
  <div>
    <el-descriptions :column="1" border>
      <el-descriptions-item :label="i18n.t('applicationCount')">
        {{ applicationCount }}
      </el-descriptions-item>
      <el-descriptions-item :label="i18n.t('licenseCount')">
        {{ licenseCount }}
      </el-descriptions-item>
      <el-descriptions-item :label="i18n.t('softwareCostMonthly')">
        {{ softwareCosts.toFixed(2) }} €
      </el-descriptions-item>
      <!-- Yearly -->
      <el-descriptions-item :label="i18n.t('softwareCostYearly')">
        {{ (softwareCosts * 12).toFixed(2) }} €
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { LicenceOut } from '@/client'

  const i18n = useI18n()

  const props = defineProps({
    licenses: {
      type: Array as PropType<LicenceOut[]>,
      required: true,
    },
    softwareCosts: {
      type: Number,
      required: true,
    },
  })

  // License count
  const licenseCount = computed(() => {
    return props.licenses.length
  })
  // Application count
  const applicationCount = computed(() => {
    return props.licenses.reduce((acc, license) => {
      if (license.software_id) acc.add(license.software_id)
      return acc
    }, new Set<string>()).size
  })
</script>
