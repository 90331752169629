/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TokenRequest } from '../models/TokenRequest';
import type { TokenRequestOut } from '../models/TokenRequestOut';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccessTokenService {
    /**
     * Request Token
     * @returns TokenRequestOut Successful Response
     * @throws ApiError
     */
    public static requestTokenTokenPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: TokenRequest,
        xTransactionId?: (string | null),
    }): CancelablePromise<TokenRequestOut> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static checkTokenTokenCheckGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/token/check',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
