<template>
  <BaseApplicationDetails
    v-if="props.software"
    :software-data="props.software" />
</template>

<script lang="ts" setup>
  import { SoftwareOut } from '@/client'
  import BaseApplicationDetails from '@/components/BaseApplicationDetails/BaseApplicationDetails.vue'

  const props = defineProps({
    software: {
      type: Object as PropType<SoftwareOut>,
      default: () => undefined,
    },
  })
</script>
