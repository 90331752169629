<template>
  <!-- SOFTWARE_USER_LICENSE_ADD -->
  <div>
    <i18n-t
      keypath="jobMessage.software_user_license_add"
      tag="p"
      class="flex items-center gap-3 break-normal">
      <template #softwarelicense>
        <SmTag
          :label="software + ': ' + softwareLicense"
          :loading="loadingSoftwareLicense">
          <template #label>
            <div class="flex gap-2">
              <ApplicationIcon :software-name="software" size="18px" />
              <TextMultiline :text="softwareLicense" />
            </div>
          </template>
        </SmTag>
      </template>
      <template #license>
        <SmTag
          :label="license"
          icon="md-description-round"
          :loading="loadingLicense" />
      </template>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
  import { licenseStore } from '@/stores/licenseV2.store'
  import {
    BackgroundJobSoftwareUserLicenseAddData,
    LicenceOut,
    SoftwareLicense,
    SoftwareLicensesService,
  } from '@/client'
  import { useSoftwareStore } from '@/stores/softwareStore'

  const softwareStore = useSoftwareStore()

  const props = defineProps<{
    data: BackgroundJobSoftwareUserLicenseAddData
  }>()

  const licenseObj = ref<LicenceOut>()
  const license = ref<string>()
  const loadingLicense = ref(true)

  const softwareLicensesObj = ref<SoftwareLicense[]>()
  const softwareLicenseObj = ref<SoftwareLicense>()
  const softwareLicense = ref<string>()
  const loadingSoftwareLicense = ref(true)

  const software = ref<string>()

  onMounted(async () => {
    // load license
    licenseObj.value = (await licenseStore.getByID(
      props.data.licence_id
    )) as LicenceOut
    license.value = licenseObj.value?.email || undefined
    loadingLicense.value = false

    // load software
    softwareLicensesObj.value =
      await SoftwareLicensesService.getSoftwareLicensesApiV1SoftwareSoftwareLicensesGet(
        {
          includeDeleted: true,
        }
      )
    softwareLicenseObj.value = softwareLicensesObj.value.find(
      (softwareLicense) => {
        return softwareLicense._id == props.data.software_license_id
      }
    )
    softwareLicense.value = softwareLicenseObj.value?.name
    loadingSoftwareLicense.value = false

    // load software
    if (softwareLicenseObj.value?.software_id) {
      const softwareObj = await softwareStore.getSoftwareById(
        softwareLicenseObj.value?.software_id
      )
      software.value = softwareObj?.software_name || undefined
    }
  })
</script>
