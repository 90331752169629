<template>
  <div style="height: 100%; width: 100%">
    <EmptyState v-if="!isDataExisting">
      <span>{{ i18n.t(`noStatisticsYet`) }}</span>
    </EmptyState>

    <div style="height: 100%; width: 100%" class="information-chart">
      <canvas
        id="information-line-chart"
        height="100%"
        width="100%"
        style="height: 100% !important; width: 100% !important"></canvas>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    GraphPeriodUnit,
    MetadataQueryField,
    StatisticsService,
    StatisticType,
  } from '@/client'

  import EmptyState from './ChartEmptyState.vue'

  const props = defineProps({
    activeTabName: {
      type: String as PropType<
        'users' | 'licenses' | 'applications' | 'spends' | undefined
      >,
      default: 'users',
    },
    softwareId: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    chartColor: {
      type: String as PropType<string | undefined | null>,
      default: undefined,
    },
    field: {
      type: String as PropType<MetadataQueryField>,
      required: true,
    },
    hideAxes: {
      type: Boolean,
      default: false,
    },
    statisticType: {
      type: String as PropType<StatisticType>,
      default: StatisticType.COMPANY_STATISTIC,
    },
  })

  const statistics = ref([])
  const requestedRange = defineModel<[Date, Date]>('requestedRange', {
    required: true,
  })

  const i18n = useI18n()

  const chartRef = ref()

  interface ChartData {
    x: Date
    y: number
  }

  const chartData = ref<ChartData[]>([])

  const isDataExisting = computed(() => {
    return chartData.value.length > 0
  })

  watch(
    () => requestedRange.value,
    (newRange) => {
      if (newRange) {
        fetchStatistics(props.field)
      }
    },
    { immediate: true }
  )

  // Fetch statistics
  function fetchStatistics(field: MetadataQueryField) {
    StatisticsService.getStatisticApiV1StatisticsGet({
      fromDate: requestedRange.value[0].toISOString(),
      tillDate: requestedRange.value[1].toISOString(),
      type: props.statisticType,
      field: field,
      periodUnit: GraphPeriodUnit.DAY,
      periodStepSize: 1,
      softwareId: props.softwareId,
    }).then((response) => {
      chartData.value = response.map((item) => ({
        x: new Date(item[0] * 1000),
        y: Math.round(item[1] * 100) / 100,
      }))
    })
  }

  watch(
    () => props.field,
    (newType) => {
      if (newType) {
        fetchStatistics(newType)
      }
    },
    { immediate: true }
  )

  // Chart Data
  const data = computed(() => ({
    datasets: [
      {
        data: chartData.value,
        pointHitRadius: 10, // widen the hit area of the points (hover)
        fill: false,
        borderColor: props.chartColor,
        cubicInterpolationMode: 'monotone',
        outerGlowColor: props.chartColor,
        outerGlowWidth: 40,
      },
    ],
  }))

  const scales = () => {
    if (props.hideAxes) {
      return {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: () => '',
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            offset: false,
            type: 'time',
            time: {
              unit: 'day',
              unitStepSize: 1,
              displayFormats: {
                day: 'MMM DD',
              },
            },
            ticks: {
              beginAtZero: true,
              callback: () => '',
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
      }
    } else
      return {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            offset: false,

            // Time format
            type: 'time',
            time: {
              unit: 'day',
              unitStepSize: 7,
              displayFormats: {
                day: 'DD.MM.YY',
              },
            },
            // Label rotation
            ticks: {
              maxRotation: 90,
              minRotation: 45,
            },
          },
        ],
        yAxes: [
          {
            // Show intervals without decimals
            ticks: {
              precision: 0,
            },
          },
        ],
      }
  }

  let delayed: boolean
  const options = computed(() => ({
    type: 'shadowedLineChart',
    responsive: true,
    maintainAspectRatio: false,
    data: data.value,
    plugins: {
      title: {
        display: false,
      },
    },
    legend: {
      display: true,
      labels: {
        generateLabels: () => {
          return [{ text: '' }]
        },
        boxWidth: 0,
        boxHeight: 0,
      },
    },
    interaction: {
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        top: 2,
      },
    },
    scales: scales(),
    animation: {
      duration: 1000,
      onComplete: () => {
        delayed = true
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delay: (context: any) => {
        let delay = 0
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 150 + context.datasetIndex * 100
        }
        return delay
      },
    },
  }))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let chart: any = null

  // This watch updated's the data set of the chart and rerenders it
  watch([props.activeTabName, statistics, data], () => {
    chart.data.datasets[0].data = chartData.value
    chart.update()
  })

  watch(
    () => props.chartColor,
    () => {
      chart.data.datasets[0].borderColor = props.chartColor
      chart.data.datasets[0].outerGlowColor = props.chartColor
      chart.update()
    }
  )

  // This event will be triggered if the sidebar collapse state in the localStorage changes.
  // And after the sidebar animation is completed, the chart will be re-rendered.
  document.addEventListener('sidebar-collapse-state-changed', () => {
    setTimeout(() => {
      chart.resize()
    }, 300)
  })

  onMounted(() => {
    if (document.getElementById('information-line-chart') !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion
      const ctx = (
        document.getElementById('information-line-chart') as HTMLCanvasElement
      ).getContext('2d')
      if (ctx !== null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore 2552
        // eslint-disable-next-line no-undef
        chart = new Chart(ctx, {
          type: 'line',
          data: data.value,
          options: options.value,
        })

        setTimeout(() => {
          chart.resize()
        }, 500)

        chartRef.value = chart
      }
    }
  })

  // Reload if softwarename changes
  watch(
    () => props.softwareId,
    () => {
      fetchStatistics(props.field)
    }
  )

  onUnmounted(() => {
    document.removeEventListener('sidebar-collapse-state-changed', () => {
      chart.resize('100%', '100%')
    })
  })
</script>

<style lang="scss">
  .information-chart {
    canvas {
      height: 100% !important;
    }
  }
</style>
