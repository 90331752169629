<!--
  TODOs:
  - Lazy load components
 -->
<template>
  <BasePopup
    ref="onboardingDialog"
    v-model:visible="visibility"
    custom-class="onboarding-dialog"
    :align-center="true"
    :show-prev-button="showPrevButton"
    :show-next-button="showNextButton"
    :show-close-button="false"
    :headline="i18n.t(contentOrder[currentIndex].header)"
    :sub-headline="i18n.t(contentOrder[currentIndex].subHeader || '')"
    :before-close="handleClose"
    @next-page="handleNextPage"
    @previous-page="handlePreviousPage">
    <template #default>
      <component
        :is="contentOrder[currentIndex].content"
        ref="currentContent"
        @next-page="handleNextPage"></component>
    </template>
  </BasePopup>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { usePreferenceStore } from '@/stores/preferenceStore'

  import BasePopup from '../BasePopup.vue'
  import { useOnboardingDialogStore } from './BaseOnboardingDialog.store'
  import ApplicationConnectContent from './contents/ApplicationConnectContent.vue'
  import ApplicationConnectSuccessContent from './contents/ApplicationConnectSuccessContent.vue'
  import EnvironmentContent from './contents/EnvironmentSelectContent.vue'
  import HelloContent from './contents/HelloContent.vue'
  import OnboardingApplicationSelect from './contents/OnboardingApplicationSelect.vue'

  const emit = defineEmits(['update:visibility'])
  const preferenceStore = usePreferenceStore()
  const props = defineProps({
    visibility: {
      type: Boolean,
      required: true,
    },
  })

  const onboardingDialogStore = useOnboardingDialogStore()
  const i18n = useI18n()
  const onBoardingStore = useOnboardingDialogStore()

  const visibility = computed({
    get: () => props.visibility,
    set: (value: boolean) => emit('update:visibility', value),
  })

  const currentContent = ref()

  type ContentEntry = {
    header: string
    content: Component
    subHeader?: string
  }

  const contentOrder = ref<ContentEntry[]>([
    {
      header: 'dialogs.onboardingDialog.content.hello.title',
      content: HelloContent,
    },
    {
      header: 'dialogs.onboardingDialog.content.applicationSelect.title',
      subHeader: 'dialogs.onboardingDialog.content.environment.title',
      content: EnvironmentContent,
    },
    {
      header: 'dialogs.onboardingDialog.content.applicationSelect.title',
      content: OnboardingApplicationSelect,
      subHeader: 'dialogs.onboardingDialog.content.applicationSelect.subTitle',
    },
    {
      header: 'dialogs.onboardingDialog.content.applicationConnect.title',
      content: ApplicationConnectContent,
      subHeader: 'dialogs.onboardingDialog.content.applicationSelect.subTitle',
    },
    {
      header: 'dialogs.onboardingDialog.content.success.title',
      content: ApplicationConnectSuccessContent,
    },
  ])

  /**
   * Page Changes
   */
  const currentIndex = ref(0)
  async function handleNextPage() {
    const allowNextPage = await currentContent.value?.confirm()
    if (allowNextPage) {
      if (currentIndex.value === contentOrder.value.length - 1) {
        preferenceStore.setPreference('show_onboarding_dialog', false)
        visibility.value = false
        return
      }
      // If no software is selected, we skip the application connect page
      if (
        currentIndex.value === 2 &&
        onBoardingStore.selectedIntegrations.length === 0
      ) {
        currentIndex.value++
      }

      currentIndex.value++
    }
  }
  function handlePreviousPage() {
    currentIndex.value--
  }

  /**
   * Close
   */
  function handleClose() {
    if (currentIndex.value === contentOrder.value.length - 1) {
      visibility.value = false
    } else {
      visibility.value = true
    }
  }

  /**
   * Buttons
   */
  const showPrevButton = computed(
    () =>
      currentIndex.value > 0 &&
      currentIndex.value < contentOrder.value.length - 1
  )
  const showNextButton = computed(
    () =>
      currentIndex.value > 0 &&
      currentIndex.value < contentOrder.value.length - 1
  )

  const currentContentName = ref<ContentEntry>(contentOrder.value[0])

  watch(
    () => currentContentName.value,
    () =>
      (onboardingDialogStore.$state.currentContent =
        currentContentName.value.content)
  )

  onboardingDialogStore.$state.currentContent = currentContentName.value.content

  onboardingDialogStore.$subscribe(() => {
    const index = contentOrder.value.findIndex(
      (entry) => entry.content === onboardingDialogStore.$state.currentContent
    )

    currentContentName.value = contentOrder.value[index]
  })
</script>
