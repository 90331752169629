/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSoftwareUserRemoveData } from './BackgroundJobSoftwareUserRemoveData';
export type BackgroundJobSoftwareUserRemovePostIn = {
    type: BackgroundJobSoftwareUserRemovePostIn.type;
    data: BackgroundJobSoftwareUserRemoveData;
    /**
     * Time in seconds when to enqueue. Does not enqueue if value is `null`.
     */
    enqueue_in?: (number | null);
};
export namespace BackgroundJobSoftwareUserRemovePostIn {
    export enum type {
        SOFTWARE_USER_REMOVE = 'software_user_remove',
    }
}

