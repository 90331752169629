/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableCompany } from '../models/AvailableCompany';
import type { CompanyIn } from '../models/CompanyIn';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CompaniesService {
    /**
     * Get Available Companies
     * @returns AvailableCompany Successful Response
     * @throws ApiError
     */
    public static getAvailableCompaniesCompaniesGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<AvailableCompany>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/companies',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Company
     * @returns AvailableCompany Successful Response
     * @throws ApiError
     */
    public static createCompanyCompaniesPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: CompanyIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<AvailableCompany> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/companies',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Available Company
     * @returns AvailableCompany Successful Response
     * @throws ApiError
     */
    public static getAvailableCompanyCompaniesCompanyIdGet({
        companyId,
        xTransactionId,
    }: {
        companyId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<AvailableCompany> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/companies/{company_id}',
            path: {
                'company_id': companyId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Company
     * @returns void
     * @throws ApiError
     */
    public static deleteCompanyCompaniesCompanyIdDelete({
        companyId,
        xTransactionId,
    }: {
        companyId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/companies/{company_id}',
            path: {
                'company_id': companyId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
