<template>
  <svg
    id="Ebene_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 34.6 35.5"
    style="enable-background: new 0 0 34.6 35.5"
    xml:space="preserve">
    <polygon
      :class="`st0-${props.isDark ? 'dark' : 'light'}`"
      points="9.7,21.3 13.4,26.7 8,35.5 0,35.5 " />
    <polygon
      :class="`st1-${props.isDark ? 'dark' : 'light'}`"
      points="1.8,0.2 9.9,0.2 17.4,11.1 24.3,0.2 34.3,0.2 22.1,17.8 34.4,35.5 25.7,35.5 " />
  </svg>
</template>

<script lang="ts" setup>
  const props = defineProps({
    isDark: {
      type: Boolean,
      default: false,
    },
  })
</script>

<style scoped>
  .st0-light {
    fill: #1b242e;
    transition: fill 0.3s ease;
  }
  .st1-light {
    fill: #08e9c5;
    transition: fill 0.3s ease;
  }

  .st0-dark {
    fill: white;
    transition: fill 0.3s ease;
  }

  .st1-dark {
    fill: white;
    transition: fill 0.3s ease;
  }
</style>
