/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobAccountGroupAccountsRemovedData } from './BackgroundJobAccountGroupAccountsRemovedData';
import type { BackgroundJobExecutionBase_Literal_account_group_accounts_removed__BackgroundJobAccountGroupAccountsRemovedExecutionData_ } from './BackgroundJobExecutionBase_Literal_account_group_accounts_removed__BackgroundJobAccountGroupAccountsRemovedExecutionData_';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
export type BackgroundJobAccountGroupAccountsRemoved = {
    created: string;
    updated: string;
    deleted: boolean;
    deleted_at: (string | null);
    _id: string;
    type: BackgroundJobAccountGroupAccountsRemoved.type;
    status: BackgroundJobStatus;
    executions: Array<BackgroundJobExecutionBase_Literal_account_group_accounts_removed__BackgroundJobAccountGroupAccountsRemovedExecutionData_>;
    data: BackgroundJobAccountGroupAccountsRemovedData;
    last_executed_at: (string | null);
    next_execution_at: (string | null);
    execution_token: string;
    created_by_job_id: (string | null);
    created_by_identity_id: (string | null);
};
export namespace BackgroundJobAccountGroupAccountsRemoved {
    export enum type {
        ACCOUNT_GROUP_ACCOUNTS_REMOVED = 'account_group_accounts_removed',
    }
}

