<template>
  <div>
    <LicenseTable
      ref="licenseTable"
      display-software-name
      :filter="filter"
      display-status-indicator
      :custom-columns="[actionColumn, statusColumn]"
      :display-actions="false"
      select
      :click-action="() => {}"
      :display-last-logged-in="false"
      :display-email="false">
      <!-- Licence Action (delete, deactivate) -->
      <template #licence_action="{ row }">
        <!-- Show Dropdown if supports delete and disable -->
        <SmDropdown
          v-if="
            row.software_id in applicationInformation &&
            applicationInformation[row.software_id]
              .implements_delete_user_method &&
            applicationInformation[row.software_id]
              .implements_disable_licence_method
          "
          @input="(value) => (manualChangedActions[row._id!] = value)">
          <SmDropdownItem
            v-if="
              applicationInformation[row.software_id]
                .implements_delete_user_method
            "
            @click="manualChangedActions[row._id!] = 'delete'">
            {{ i18n.t('delete') }}
          </SmDropdownItem>

          <SmDropdownItem
            v-if="
              applicationInformation[row.software_id]
                .implements_disable_licence_method
            "
            @click="manualChangedActions[row._id!] = 'deactivate'">
            {{ i18n.t('deactivate') }}
          </SmDropdownItem>

          <template #trigger>
            {{ i18n.t(currentActions[row._id!]) }}
            <v-icon name="md-expandmore-round" />
          </template>
        </SmDropdown>

        <!-- Else show text -->
        <template v-else>
          {{ i18n.t(currentActions[row._id!]) }}
        </template>
      </template>

      <!-- Staus Column -->
      <template #deletion_status="{ row }">
        <v-icon
          v-if="deleteUsersLoading"
          name="io-reload-circle"
          animation="spin" />

        <v-icon
          v-else-if="results[row._id!]?.status == 'fulfilled'"
          name="io-checkmark-circle" />

        <v-icon
          v-else-if="results[row._id!]?.status == 'rejected'"
          name="io-close-circle" />

        <div v-else />
      </template>
    </LicenseTable>
  </div>
</template>

<script lang="ts" setup>
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    LicenceOut,
    SoftwareInformation,
    SoftwareOut,
    UserLicencesService,
    UsersService,
  } from '@/client'
  import LicenseTable from '@/components/LicenseTable.vue'
  import { useSoftwareStore } from '@/stores/softwareStore'
  import { AccountFilter } from '@/stores/license.filter'
  import { updateFilter } from '@/common/util/filterUtils'
  import { LicenceFilterInstance } from '@/stores/licenseV2.store'

  const i18n = useI18n()

  const licenseTable = ref<ComponentExposed<typeof LicenseTable>>()
  const softwareStore = useSoftwareStore()

  // Hooks
  onMounted(async () => {
    software.value = await softwareStore.getSoftware()
  })

  const emit = defineEmits(['user-deleted', 'license-deleted'])

  interface Props {
    user: Account
  }
  const props = defineProps<Props>()

  const statusColumn = {
    key: 'deletion_status',
    label: '',
    sortable: false,
    width: '30px',
  }
  const actionColumn = {
    key: 'licence_action',
    label: i18n.t('action'),
    sortable: false,
    width: '130px',
  }

  // Deletion
  const deleteUsersLoading = ref(false)
  const userDeleted = ref(false)

  const results: Ref<Record<string, PromiseSettledResult<unknown>>> = ref({})

  async function deleteLicenses() {
    const licensesToDelete: LicenceOut[] =
      licenseTable.value?.selection || []
    const promises: Promise<unknown>[] = []

    licensesToDelete.forEach((license: LicenceOut) => {
      if (currentActions.value[license._id!] === 'delete') {
        promises.push(
          UserLicencesService.deleteApiV1SoftwareLicencesLicenceIdDelete({
            licenceId: license._id!,
          })
        )
      } else if (currentActions.value[license._id!] === 'deactivate') {
        promises.push(
          UserLicencesService.disableLicenceApiV1SoftwareLicencesLicenceIdDisablePost(
            {
              licenceId: license._id!,
            }
          )
        )
      }
    })

    deleteUsersLoading.value = true

    const result: PromiseSettledResult<unknown>[] =
      await Promise.allSettled(promises)

    // Map results to license ids
    result.forEach((res: PromiseSettledResult<unknown>, index: number) => {
      results.value[licensesToDelete[index]._id!] = res
    })

    deleteUsersLoading.value = false
  }

  async function excecute() {
    await deleteLicenses()

    // Delete User
    if (allLicensesSelected.value)
      await UsersService.deleteUserApiV1ManagementUsersEmailOrIdDelete({
        emailOrId: props.user._id,
      }).then(() => emit('user-deleted', props.user))

    finishDeletion()
  }

  function finishDeletion() {
    deleteUsersLoading.value = false
    userDeleted.value = true

    emit('license-deleted')
  }

  //  Software
  const software = ref<SoftwareOut[]>()

  const applicationInformation: ComputedRef<
    Record<string, SoftwareInformation>
  > = computed(() => {
    const softwareInformation: Record<string, SoftwareInformation> = {}

    software.value?.forEach((software) => {
      softwareInformation[software._id] = software.information
    })

    return softwareInformation
  })

  // Actions
  type PossibleActions = 'delete' | 'deactivate' | 'none'
  const manualChangedActions = ref<Record<string, PossibleActions>>({})

  const currentActions = computed(() => {
    const actions: Record<string, PossibleActions> = {}

    licenseTable.value?.licences.forEach((license) => {
      const softwareInformation =
        applicationInformation.value[license.software_id]

      if (manualChangedActions.value[license._id!]) {
        actions[license._id!] = manualChangedActions.value[license._id!]
      } else if (softwareInformation.implements_delete_user_method) {
        actions[license._id!] = 'delete'
      } else if (softwareInformation.implements_disable_licence_method) {
        actions[license._id!] = 'deactivate'
      } else {
        actions[license._id!] = 'none'
      }
    })

    return actions
  })

  // Misc
  const allLicensesSelected = computed(() => {
    return (
      licenseTable.value?.selection?.length ===
      licenseTable.value?.licences.length
    )
  })

  const allLicenseDeletionsSuccessful = computed(() => {
    return (
      Object.values(results.value).every(
        (result) => result.status === 'fulfilled'
      ) && userDeleted.value
    )
  })

  // ############################
  // ######### Filter ###########
  // ############################

  const filter = ref<LicenceFilterInstance[]>([])

  watch(
    () => props.user._id,
    () => {
      const newFilter = new AccountFilter(props.user._id)
      updateFilter(filter, newFilter)
    },
    { immediate: true }
  )

  defineExpose({
    licenseTable,
    excecute,
    allLicenseDeletionsSuccessful,
    allLicensesSelected,
    results,
    deleteUsersLoading,
    userDeleted,
  })
</script>
