<template>
  <SmDialog
    v-model:visibility="visibility"
    :title="i18n.t('employeeOptimization')"
    size="medium">
    <p>{{ i18n.t('employeeOptimizationText', { name: userDisplayName }) }}</p>

    <!-- Spacing -->
    <div class="spacing" />

    <UserDeleteLicenceTable
      v-if="user"
      ref="userDeleteLicenseTable"
      :user="user" />

    <template #footer>
      <span class="dialog-footer">
        <SmButton
          v-if="
            !userDeleteLicenseTable?.deleteUsersLoading &&
            !userDeleteLicenseTable?.userDeleted
          "
          outline
          @click="visibility = false"
          >{{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton
          v-if="!userDeleteLicenseTable?.userDeleted"
          type="primary"
          :loading="userDeleteLicenseTable?.deleteUsersLoading"
          @click="handleButtonClick"
          >{{ i18n.t('deleteLicenses') }}</SmButton
        >

        <!-- Close -->
        <SmButton
          v-if="userDeleteLicenseTable?.userDeleted"
          type="primary"
          outline
          @click="closeDialog"
          >{{ i18n.t('general.close') }}
        </SmButton>
      </span>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import {
    Account,
    DataSource,
    Employee,
    Optimization,
    UsersService,
  } from '@/client'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'
  import UserDeleteLicenceTable from './UserDeleteLicenceTable.vue'

  const i18n = useI18n()

  const visibility = defineModel<boolean>('visibility')

  interface Props {
    optimization: Optimization
    employee: Employee
    datasource: DataSource
  }

  const props = defineProps<Props>()

  const user = ref<Account>()
  const userDeleteLicenseTable =
    ref<ComponentExposed<typeof UserDeleteLicenceTable>>()

  const userDisplayName = computed(() => {
    if (props.employee.first_name && props.employee.last_name)
      return `${props.employee.first_name} ${props.employee.last_name}`
    return props.employee.email
  })

  function getUser() {
    UsersService.getUserApiV1ManagementUsersEmailOrIdGet({
      emailOrId: props.employee.email || '',
    }).then((res) => {
      user.value = res
    })
  }

  function handleButtonClick() {
    userDeleteLicenseTable.value?.excecute()
  }

  function closeDialog() {
    visibility.value = false
  }

  onMounted(() => {
    getUser()
  })
</script>

<style>
  .spacing {
    height: 1.5rem;
  }
</style>
