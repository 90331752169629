<template>
  <DeleteConfirmDialog
    v-if="priceInformationToDelete"
    v-model:visible="removePriceInformationDialogVisible"
    :loading="removePriceInformationLoading"
    :title="
      i18n.t('dialogs.licenceModelEditDialog.licenseModelForm.deletePriceModel')
    "
    :sub-title="
      i18n.t(
        'dialogs.licenceModelEditDialog.licenseModelForm.deletePriceModelWarning',
        {
          description: priceInformationToDelete?.description,
        }
      )
    "
    @cancel="removePriceInformationDialogVisible = false"
    @delete="removePriceInformation(priceInformationToDelete!)" />

  <el-form
    label-position="top"
    :model="licenseModelForm"
    require-asterisk-position="right">
    <!-- Name -->
    <el-form-item
      :error="licenseModelForm.getErrorMessage('name')"
      :label="i18n.t('general.name')"
      prop="licenseModelForm.fields.name"
      label-position="top">
      <el-input
        v-model="licenseModelForm.fields.name"
        data-test-id="software-licence-model-name" />
    </el-form-item>

    <PriceInformationDivision
      v-if="externalQuota && hasMultiPriceModel"
      :synced-price="syncedPrice as PriceInformation"
      :total-active-quota="totalActiveQuota"
      :external-quota="licenseModelForm.fields.quota_information?.quota"
      :price-informations="priceInformationForms.map((form) => form.fields)" />

    <el-form-item
      v-if="
        !('external_id' in licenseModelForm.fields) ||
        !licenseModelForm.fields.external_id
      "
      style="flex-direction: column">
      <div>
        <!-- Defaults -->
        <SmCheckbox
          v-model="licenseModelForm.fields.auto_assign!.add_to_new_user">
          {{ i18n.t('assignToNewUsers') }}
        </SmCheckbox>

        <SmCheckbox
          v-model="licenseModelForm.fields.auto_assign!.add_to_new_guest">
          {{ i18n.t('assignToNewGuests') }}
        </SmCheckbox>
      </div>
    </el-form-item>

    <div class="tab-heading">
      <h2>
        {{ i18n.t('prices') }}
      </h2>

      <SmButton outline prevent @click="addPriceInformation">
        <v-icon name="md-add-round" style="margin-right: 6px" />
        {{ i18n.t('addPrice') }}
      </SmButton>
    </div>

    <component
      :is="hasMultiPriceModel ? 'el-tabs' : 'div'"
      ref="tabsRef"
      v-model="activeTab"
      tab-position="left"
      class="price-wrapper">
      <component
        :is="hasMultiPriceModel ? 'el-tab-pane' : 'div'"
        v-for="(item, index) in priceInformations"
        :key="(item as PriceInformation)._id"
        :name="index">
        <!-- Tabs -->
        <template v-if="hasMultiPriceModel" #label>
          <!-- Validation warning -->
          <div
            v-if="Object.keys(childRefs[item._id]!.form.errors).length > 0"
            class="tab-warning">
            <SmTooltip>
              <v-icon
                name="md-priorityhigh-round"
                scale="1.3"
                class="fill-magenta" />
              <template #content>
                <div
                  v-for="(value, key) in childRefs[item._id]!.form.errors"
                  :key="key">
                  <h4>
                    {{ childRefs[item._id]!.form.fieldNames[key] || key }}
                  </h4>
                  <p v-for="message in value" :key="message?.message">
                    {{ message?.message }}
                  </p>
                </div>
              </template>
            </SmTooltip>
          </div>
          <!-- Sync Icon -->
          <div
            v-if="childRefs[item._id]?.form.fields.quota === 0"
            class="tab-warning">
            <SmTooltip>
              <v-icon
                name="md-bolt-round"
                scale="1.3"
                fill="var(--sm-primary)" />
              <template #content>
                {{
                  i18n.t(
                    'dialogs.licenceModelEditDialog.licenseModelForm.syncPriceModel'
                  )
                }}
              </template>
            </SmTooltip>
          </div>
          <div class="tab-nav">
            <div>
              <!-- Description -->
              <p class="label" style="white-space: normal">
                <TextMultiline
                  v-if="childRefs[item._id]?.form.fields.description"
                  :text="childRefs[item._id]?.form.fields.description"
                  :lines-to-show="1"
                  :show-tool-tip="true" />
                <TextMultiline
                  v-else
                  :text="
                    i18n.t(
                      'dialogs.licenceModelEditDialog.licenseModelForm.priceModel'
                    )
                  "
                  :lines-to-show="1"
                  :show-tool-tip="true" />
              </p>

              <!-- Billing Unit -->
              <p
                v-if="
                  childRefs[item._id]?.form.fields.billing_unit &&
                  childRefs[item._id]?.form.fields.billing_unit !=
                    BillingUnit.UNKNOWN
                "
                class="pricing">
                {{
                  i18n.t(
                    `billingUnitValues.${childRefs[item._id]?.form.fields.billing_unit}`
                  )
                }}

                <!-- Quota -->
                <span v-if="childRefs[item._id]?.form.fields.quota">
                  (
                  {{
                    i18n.t(
                      'general.licenses',
                      childRefs[item._id]?.form.fields.quota as number
                    )
                  }})
                </span>
              </p>

              <!-- Pricing -->
              <p class="pricing">
                <span
                  v-if="
                    childRefs[item._id]?.form.fields.price &&
                    childRefs[item._id]?.form.fields.currency
                  "
                  >{{ childRefs[item._id]?.form.fields.price }}
                  {{ childRefs[item._id]?.form.fields.currency }}</span
                >

                <span
                  v-if="
                    childRefs[item._id]?.form.fields.price &&
                    childRefs[item._id]?.form.fields.currency &&
                    childRefs[item._id]?.form.fields.billing_period &&
                    childRefs[item._id]?.form.fields.billing_period !=
                      BillingPeriod.UNKNOWN
                  ">
                  /
                </span>

                <!-- Billing Period -->
                <span
                  v-if="
                    childRefs[item._id]?.form.fields.billing_period &&
                    childRefs[item._id]?.form.fields.billing_period !=
                      BillingPeriod.UNKNOWN
                  ">
                  {{
                    availableBillingPeriods[
                      childRefs[item._id]?.form.fields.billing_period
                    ]
                  }}
                </span>
              </p>

              <div
                v-if="checkContractDate(childRefs[item._id]?.form.fields)"
                class="tag">
                <span class="tag-label">
                  {{ checkContractDate(childRefs[item._id]?.form.fields) }}
                </span>
              </div>

              <div
                v-else-if="childRefs[item._id]?.form.fields.reminder_date"
                class="tag">
                <v-icon name="hi-bell" scale="0.9" class="fill-gray-400" />
                <span class="tag-label">
                  {{
                    dayjs(
                      childRefs[item._id]?.form.fields.reminder_date
                    ).format('DD.MM.YYYY')
                  }}
                </span>
              </div>
            </div>

            <div style="display: flex; gap: 12px">
              <SmTooltip
                v-if="childRefs[item._id]?.form.dirty"
                :content="
                  i18n.t(
                    'dialogs.licenceModelEditDialog.licenseModelForm.discardChanges'
                  )
                "
                placement="top">
                <v-icon
                  name="md-replay"
                  class="action-btn"
                  @click="resetPriceInformation(item._id)" />
              </SmTooltip>

              <SmTooltip
                :content="
                  i18n.t(
                    'dialogs.licenceModelEditDialog.licenseModelForm.deletePriceModel'
                  )
                "
                placement="top">
                <v-icon
                  name="md-delete-round"
                  class="action-btn"
                  @click="
                    handlePriceInformationRemove(item as PriceInformation)
                  " />
              </SmTooltip>
            </div>
          </div>
        </template>

        <PriceInformationForm
          :ref="
            (el) =>
              setChildRef(
                item as PriceInformation,
                el as PriceInformationFormInstance
              )
          "
          v-model:priceInformation="item as PriceInformation"
          :has-synced-price="!!syncedPrice"
          :total-active-quota="totalActiveQuota"
          :external-quota="externalQuota"
          :currencies="currencies" />
      </component>
    </component>
  </el-form>
  <div v-if="hasMultiPriceModel" class="tab-bottom">
    <div class="tab-bottom__header">
      <div class="add-btn" @click="addPriceInformation">
        <v-icon name="md-add-round" style="margin-right: 6px" />
        <span>
          {{
            i18n.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.newPriceModel'
            )
          }}
        </span>
      </div>
    </div>
    <div class="tab-bottom__content"></div>
  </div>
</template>

<script setup lang="ts">
  /* eslint @intlify/vue-i18n/no-raw-text: 0 */
  import dayjs from 'dayjs'
  import { usePriceInformationStore } from '@/stores/priceInformationStore'
  import { useI18n } from 'vue-i18n'
  import {
    PriceInformation,
    SoftwareLicense,
    BillingPeriod,
    BillingUnit,
    CurrencyService,
    Currency,
  } from '@/client'
  import { sortCurrencies } from '@/common/util/currencyUtil'
  import type { CustomValidator } from '@/common/util/licenseModel'
  import PriceInformationForm from './PriceInformationForm.vue'
  import { nanoid } from 'nanoid'
  import useForm from '@/common/form'
  import { isRequired } from './sm/SmInput/SmInputValidator'
  import {
    saveNewSoftwareLicense,
    updateSoftwareLicense,
  } from '@/common/license'
  import { useApplicationDetailsStore } from '@/views/ApplicationsDetail/ApplicationDetailsView.store'

  type PriceInformationFormInstance = InstanceType<typeof PriceInformationForm>

  export type LicenseModelFormModel = {
    license: SoftwareLicense
  }

  const props = withDefaults(
    defineProps<{
      labelWidth?: string
      customValidators?: CustomValidator[]
      licenseModelId: string
    }>(),
    { labelWidth: '8rem', customValidators: () => [] }
  )

  const model = defineModel<LicenseModelFormModel>('softwareLicense', {
    required: true,
  })

  const i18n = useI18n()
  const tabsRef = ref()
  const activeTab = ref<number>(0)
  const childRefs = ref<Record<string, PriceInformationFormInstance>>({})
  const priceInformationStore = usePriceInformationStore()
  const externalQuota = model.value.license.external_id
    ? model.value.license.quota_information?.quota
    : undefined
  const currencies = ref<Currency[]>([])
  const priceInformationToDelete = ref<PriceInformation>()
  const removePriceInformationDialogVisible = ref(false)
  const removePriceInformationLoading = ref(false)
  const syncedPrice = ref<null | PriceInformation>(null)
  const totalActiveQuota = computed(() => {
    return priceInformationForms.value.reduce((sum, item) => {
      const fromDate = item.fields.from_date
      const endDate = item.fields.end_date

      const isActive =
        (!fromDate && !endDate) ||
        (!fromDate && dayjs().isBefore(endDate)) ||
        (!endDate && dayjs().isAfter(fromDate)) ||
        dayjs().isBetween(fromDate, endDate)

      if (isActive) {
        return sum + (item.fields.quota || 0)
      }
      return sum
    }, 0)
  })

  if (!model.value.license.auto_assign) model.value.license.auto_assign = {}

  interface InitalPriceInformation {
    _id: string
  }

  const priceInformations = ref<
    Array<PriceInformation | InitalPriceInformation>
  >([])

  const cachedPriceInformations = ref<PriceInformation[] | undefined>(
    priceInformationStore.getPriceInformation(
      (model.value.license as SoftwareLicense)._id
    )
  )

  function convertCache() {
    if (
      cachedPriceInformations.value &&
      cachedPriceInformations.value.length > 0
    ) {
      for (const item of cachedPriceInformations.value) {
        priceInformations.value.push({ ...item })
      }
    } else {
      addPriceInformation()
    }
  }

  function setChildRef(
    item: PriceInformation,
    el: PriceInformationFormInstance | null
  ) {
    if (el) {
      childRefs.value[item._id] = el
    }
  }

  const priceInformationCount = computed(() => {
    return priceInformations.value.length
  })

  const hasMultiPriceModel: ComputedRef<boolean> = computed(() => {
    return priceInformationCount.value > 1
  })

  const handlePriceInformationRemove = (PriceInformation: PriceInformation) => {
    removePriceInformationDialogVisible.value = true
    priceInformationToDelete.value = PriceInformation
  }

  async function removePriceInformation(PriceInformation: PriceInformation) {
    const priceInformationId = (PriceInformation as PriceInformation)._id

    // Check if newly created price information
    let newlyCreated = false
    if (priceInformationId.startsWith('new-')) {
      newlyCreated = true
    }

    // Delete from backend if not new
    if (!newlyCreated) {
      await priceInformationStore.deletePriceInformation(
        props.licenseModelId,
        priceInformationId
      )
    }

    // Remove from frontend
    priceInformations.value = priceInformations.value.filter(
      (item) => item._id !== priceInformationId
    )

    // Remove from form
    delete childRefs.value[priceInformationId]

    removePriceInformationDialogVisible.value = false

    activeTab.value = 0
  }

  async function resetPriceInformation(priceInformationID: string) {
    if (childRefs.value[priceInformationID]) {
      childRefs.value[priceInformationID]?.form.reset()
    }
  }

  function addPriceInformation() {
    let title

    if (priceInformationCount.value > 0) {
      title = i18n.t('priceModelFrom', {
        date: dayjs().format('DD.MM.YYYY'),
      })
    } else {
      title = i18n.t('standard')
    }

    const tempId = 'new-' + nanoid()
    const newForm = {
      _id: tempId,
      description: title,
      currency: 'EUR',
    } as PriceInformation

    if (
      model.value.license.external_id &&
      model.value.license.quota_information?.quota
    ) {
      // is external quota
      newForm['billing_unit'] = BillingUnit.QUOTA
      newForm['quota'] =
        priceInformationCount.value > 0
          ? 0
          : model.value.license.quota_information?.quota
    }

    priceInformations.value.push(newForm)

    activeTab.value = priceInformationCount.value - 1
  }

  onMounted(() => {
    convertCache()
  })

  const checkContractDate = (item: PriceInformation) => {
    const isBetween = dayjs().isBetween(item.from_date, item.end_date)
    const isAfter = dayjs().isAfter(item.end_date)
    const isBefore = dayjs().isBefore(item.from_date)

    if (isBetween || !item.from_date) {
      return false
    }

    if (isAfter) {
      return i18n.t('deprecated')
    } else if (isBefore) {
      return i18n.t('upcoming')
    }
  }

  const availableBillingPeriods = computed(() => {
    const periods: Record<string, string> = {}
    Object.values(BillingPeriod).forEach((enumValue) => {
      const label = i18n.te(`billingPeriodValues.${enumValue}`)
        ? i18n.t(`billingPeriodValues.${enumValue}`)
        : enumValue
      periods[enumValue] = label
    })
    return periods
  })

  CurrencyService.getCurrenciesCurrenciesGet({}).then((c) => {
    currencies.value = sortCurrencies(c)
  })

  function validate() {
    const results: boolean[] = []
    priceInformationForms.value.forEach((form) => {
      if (!form) return
      results.push(form.validate())
    })

    results.push(licenseModelForm.validate())

    const result = results.every((result) => result === true)
    return result
  }

  if ('auto_assign' in model.value && model.value.auto_assign == undefined)
    model.value.auto_assign = {
      add_to_new_guest: false,
    }

  const licenseModelForm = useForm(
    {
      ...toRaw(model.value.license),
    },
    // Form Hooks
    {},
    // Form Validators
    {
      name: [isRequired],
    },
    {
      name: i18n.t('name'),
    }
  )
  const priceInformationForms = computed(() => {
    return Object.values(childRefs.value).map((ref) => ref?.form)
  })

  const applicationDetailsStore = useApplicationDetailsStore()

  async function submitForms() {
    let licenseId: string = model.value.license._id

    // submit license model form
    await licenseModelForm.submit(async () => {
      // Check if newly created
      let newlyCreated = false
      if (licenseModelForm?.fields._id?.startsWith('new-')) {
        newlyCreated = true
      }

      // Update in backend
      if (!newlyCreated) {
        licenseId = model.value.license._id
        if (licenseModelForm.dirty) {
          await updateSoftwareLicense(licenseModelForm.fields, licenseId)
        }

        return
      }

      // Create in backend
      if (applicationDetailsStore.software?._id) {
        await saveNewSoftwareLicense(
          licenseModelForm.fields,
          applicationDetailsStore.software._id
        ).then((res) => {
          licenseId = (res as SoftwareLicense)._id
        })
      }
    })

    // submit price information form
    priceInformationForms.value.forEach((form) => {
      if (form?.dirty) {
        form?.submit(async () => {
          // Check if newly created
          let newlyCreated = false
          if (form?.fields._id?.startsWith('new-')) {
            newlyCreated = true
          }

          // Update in backend
          if (!newlyCreated) {
            await priceInformationStore.updatePriceInformation(
              licenseId,
              form.fields
            )
            return
          }

          // Create in backend
          await priceInformationStore.createPriceInformation(
            licenseId,
            form.fields
          )
        })
      }
    })
  }

  watch(
    priceInformationForms,
    (newForms) => {
      const syncedForm = newForms.find((form) => form.fields.quota === 0)
      syncedPrice.value = syncedForm?.fields || null
    },
    { deep: true }
  )

  watch(activeTab, () => {
    scrollToActiveTab()
  })

  const scrollToActiveTab = () => {
    setTimeout(() => {
      const tabsContainer = document.querySelector(
        '.price-wrapper .el-tabs__nav'
      )
      const activeTabElement = tabsContainer?.querySelector('.is-active')

      if (activeTabElement) {
        activeTabElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    })
  }

  defineExpose({
    validate,
    submitForms,
    priceInformationForms,
    licenseModelForm,
  })
</script>

<style scoped lang="scss">
  .grid-2-col {
    display: grid;
    gap: 20px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  .action-btn {
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      fill: var(--el-color-danger);
    }
  }

  .tab-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin: 28px 0 10px 0;
  }

  .tab-warning {
    padding-right: 0.6rem;
  }

  .is-active .tab-nav .pricing {
    font-weight: normal;
    color: var(--el-text-color-primary);
  }

  .tab-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid var(--el-border-color-light);
    text-align: left;

    .pricing {
      font-weight: normal;
      color: var(--text-color-secondary);
    }

    .tag {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      font-weight: normal;
      padding: 0.125rem 0.75rem;
      margin-top: 0.5rem;
      border-radius: var(--border-radius);
      color: theme('colors.gray[400]');
      border: 1px solid theme('colors.gray[400]');
    }
  }

  .tab-bottom {
    display: flex;

    .tab-bottom__header {
      display: block;
      width: 100%;
      max-width: 40%;
      flex-basis: 40%;
      margin-right: 9px;
      border-right: 2px solid var(--el-border-color-light);

      .add-btn {
        padding-top: 20px;
        margin-right: 20px;
        border-top: 1px solid var(--el-border-color-light);
        cursor: pointer;

        &:hover {
          color: var(--sm-primary);
          .ov-icon {
            color: var(--sm-primary);
          }
        }
      }
    }

    .tab-bottom__content {
      display: block;
      width: 100%;
      max-width: 60%;
      flex-basis: 60%;
    }
  }

  :deep(.el-tabs) {
    display: flex;
    width: 100%;
    align-items: stretch;

    &.el-tabs--left {
      .el-tabs__nav-wrap {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 !important;

        .el-tabs__nav-prev,
        .el-tabs__nav-next {
          display: none;
        }
        .el-tabs__nav-scroll {
          overflow-y: auto;
        }
      }
      .el-tab-pane {
        padding: 0 0 0 20px;
      }

      .el-tabs__item {
        height: auto;
        padding: 0 20px 0 0;

        &:last-of-type .tab-nav {
          border-bottom: unset;
        }
      }

      .el-tabs__header {
        width: 100%;
        max-width: 40%;
        flex-basis: 40%;
        height: auto !important;
      }

      .el-tabs__content {
        width: 100%;
        max-width: 60%;
        flex-basis: 60%;
      }

      .el-tabs__nav {
        width: 100%;
        transform: none !important;
      }
    }
  }
</style>
