/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InviteOutUser } from '../models/InviteOutUser';
import type { UserProfile } from '../models/UserProfile';
import type { UserProfileUpdateIn } from '../models/UserProfileUpdateIn';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserProfileService {
    /**
     * Get User Profile
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public static getUserProfileUserGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update User Profile
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public static updateUserProfileUserPatch({
        requestBody,
        xTransactionId,
    }: {
        requestBody: UserProfileUpdateIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Invite
     * @returns InviteOutUser Successful Response
     * @throws ApiError
     */
    public static getInviteInvitesTokenOutGet({
        tokenOut,
        xTransactionId,
    }: {
        tokenOut: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<InviteOutUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invites/{token_out}',
            path: {
                'token_out': tokenOut,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Accept Invite
     * @returns string Company id to login in
     * @throws ApiError
     */
    public static acceptInviteInvitesTokenOutAcceptPost({
        tokenOut,
        xTransactionId,
    }: {
        tokenOut: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/invites/{token_out}/accept',
            path: {
                'token_out': tokenOut,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Decline Invite
     * @returns void
     * @throws ApiError
     */
    public static declineInviteInvitesTokenOutDeclinePost({
        tokenOut,
        xTransactionId,
    }: {
        tokenOut: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/invites/{token_out}/decline',
            path: {
                'token_out': tokenOut,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
