<!-- eslint-disable -->
<template>
  <DebuggingModalFPS v-if="globalStore.state.showDebugModalFPS" />
  <div ref="el" :style="style" class="debugging-modal">
    <h1 class="heading">Super Secret Menu</h1>
    <div class="debugging-modal-content">
      <div class="debugging-modal-content-row">
        <span
          @click="
            globalStore.state.showDebugModalFPS =
              !globalStore.state.showDebugModalFPS
          "
          >FPS</span
        >
        <span :style="{ color: fpsColor }">{{ fps }}</span>
      </div>
      <div class="debugging-modal-content-row">
        <span>Online</span>
        <span :style="{ color: online ? 'green' : 'red' }">{{
          online ? 'Jap!' : 'Nöp'
        }}</span>
      </div>
      <div class="debugging-modal-content-row">
        <span>Onboarding</span>
        <el-switch v-model="globalStore.state.showOnboardingDialog" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useDraggable, useFps } from '@vueuse/core'

  import { useGlobalStore } from '@/stores/globalStore'

  import DebuggingModalFPS from './DebuggingModalFPS.vue'

  const globalStore = useGlobalStore()

  const el: Ref<HTMLElement | null> = ref(null)

  // `style` will be a helper computed for `left: ?px; top: ?px;`
  const { style } = useDraggable(el, {
    initialValue: { x: 40, y: 40 },
  })

  //   FPS
  const fps = useFps({ every: 100 }) // 1s
  const fpsColor = computed(() => {
    if (fps.value > 60) return 'green'
    if (fps.value > 30) return 'orange'
    return 'red'
  })

  //   Online
  const online = computed(() => {
    return navigator.onLine
  })
</script>

<style scoped lang="scss">
  %glass-morph {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
  }

  .debugging-modal {
    @extend %glass-morph;
    position: fixed;
    z-index: 10000;
    min-width: 200px;
    min-height: 200px;
    padding: 10px;
    box-sizing: border-box;
    resize: both;
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0;
    }

    &-content {
      height: 100%;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;

      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .heading {
    & span {
      position: absolute;
      left: 10px;
      color: rgba($color: #000000, $alpha: 1);
      transition:
        color 0s ease,
        opacity 0s ease,
        transform 1s ease 0.2s;
    }

    & span:nth-child(3) {
      color: rgba($color: #000000, $alpha: 0);
      font-size: 1rem;
      top: 1.5rem;
      width: 93%;
      transition: all 0.2s ease 0s;
    }

    &:hover {
      color: #fff;

      & span {
        opacity: 1;
        color: black;
        transition:
          color 0s ease,
          opacity 0s ease,
          transform 1s ease;
      }

      & span:nth-child(1) {
        transform: translateY(-0.8rem);
      }

      & span:nth-child(2) {
        transform: translateY(0.8rem);
      }

      & span:nth-child(3) {
        color: #fff;
        background: red;
        transition: all 0.2s ease 0 s;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
      }
    }
  }
</style>
