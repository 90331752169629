/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobExecutionBase_Literal_software_user_remove__BackgroundJobSoftwareUserRemoveExecutionData_ } from './BackgroundJobExecutionBase_Literal_software_user_remove__BackgroundJobSoftwareUserRemoveExecutionData_';
import type { BackgroundJobSoftwareUserRemoveData } from './BackgroundJobSoftwareUserRemoveData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
export type BackgroundJobSoftwareUserRemove = {
    created: string;
    updated: string;
    deleted: boolean;
    deleted_at: (string | null);
    _id: string;
    type: BackgroundJobSoftwareUserRemove.type;
    status: BackgroundJobStatus;
    executions: Array<BackgroundJobExecutionBase_Literal_software_user_remove__BackgroundJobSoftwareUserRemoveExecutionData_>;
    data: BackgroundJobSoftwareUserRemoveData;
    last_executed_at: (string | null);
    next_execution_at: (string | null);
    execution_token: string;
    created_by_job_id: (string | null);
    created_by_identity_id: (string | null);
};
export namespace BackgroundJobSoftwareUserRemove {
    export enum type {
        SOFTWARE_USER_REMOVE = 'software_user_remove',
    }
}

