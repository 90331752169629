<template>
  <div class="home">
    <!-- Header -->
    <ViewHeader :title="i18n.t('actions')" />

    <!-- Content -->
    <div class="tasks-grid">
      <el-card
        shadow="always"
        body-class="flex flex-col"
        style="overflow-y: auto"
        :body-style="{
          height: '100%',
          width: '100%',
        }">
        <TasksJobTable />
      </el-card>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  // const activeTab = ref('job-table')
</script>

<style lang="scss" scoped>
  .tasks-grid {
    display: grid;
    grid-template-rows: 1fr;
  }

  .tasks-tabs {
    display: grid;
    grid-template-rows: auto 1fr;
    max-height: 100%;
  }

  .job-pane {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
</style>
