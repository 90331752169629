<template>
  <div style="height: calc(100%); display: flex; flex-direction: column">
    <el-card
      style="width: 99%; height: 100%; margin-top: 5px"
      :body-style="{
        height: 'calc(100% - 40px)',
      }"
      shadow="never">
      <el-scrollbar max-height="100%">
        <div
          v-for="(item, index) in onboardingDialogStore.$state
            .selectedIntegrations"
          :key="item.name">
          <div class="line">
            <div
              style="display: flex; align-items: center; gap: 10px; width: 66%">
              <el-image
                :src="`https://logos.saasmetrix.io/${item.name}.png`"
                fit="contain"
                style="height: 30px; width: 30px">
                <template #error>
                  <v-icon name="md-hideimage-round" scale="0.8" />
                </template>
              </el-image>
              <span>
                {{ item.display_name }}
              </span>
            </div>

            <div style="width: 33%; display: flex; justify-content: end">
              <div v-if="item.type != 'manually'">
                <v-icon
                  v-if="item.connected === true"
                  name="md-done-round"
                  class="fill-primary"
                  scale="1.3" />
                <el-button
                  v-else
                  type="primary"
                  :loading="connectLoading"
                  @click="connectButtonClick(item)">
                  {{
                    i18n.t(
                      'dialogs.onboardingDialog.content.applicationConnect.connect'
                    )
                  }}
                </el-button>
              </div>
              <div v-else>
                <span>
                  {{
                    i18n.t(
                      'dialogs.onboardingDialog.content.applicationConnect.dontNeedConnectHint',
                      {
                        software_name: item.display_name,
                      }
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
          <el-divider
            v-if="
              onboardingDialogStore.$state.selectedIntegrations.length - 1 !=
              index
            "
            style="margin: 12px 0 !important"></el-divider>
        </div>
      </el-scrollbar>
    </el-card>
  </div>
</template>

<script lang="ts" setup>
  import { useDocumentVisibility } from '@vueuse/core'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import { SoftwareAuthorizationService } from '@/client'
  import { getSoftwares } from '@/common/software'
  import { useGlobalStore } from '@/stores/globalStore'

  import {
    ExtendedSoftwareInformation,
    useOnboardingDialogStore,
  } from '../BaseOnboardingDialog.store'

  const router = useRouter()
  const store = useGlobalStore()
  const onboardingDialogStore = useOnboardingDialogStore()
  const i18n = useI18n()

  // Connect button
  const connectLoading = ref<boolean>(false)
  const connectButtonClick = async (item: ExtendedSoftwareInformation) => {
    if (item.type === 'oauth') {
      connectLoading.value = true
      await getOAuthLink(item)
      connectLoading.value = false
    } else {
      connectLoading.value = true
      const routeData = router.resolve({
        path: '/authorize',
        query: {
          softwareName: item.name,
          softwareType: item.type,
          accessToken: store.getJWT(),
        },
      })

      window.open(routeData.href, '_blank')
      connectLoading.value = false
    }
  }

  // OAuth Link
  const getOAuthLink = async (integration: ExtendedSoftwareInformation) => {
    SoftwareAuthorizationService.getApiV1OauthOauthLinkGet({
      softwareName: integration.name,
      readOnly: false,
    }).then((result) => {
      window.open(result, '_blank')
    })
  }

  // Update the connected state of the integrations when the user comes back to the tab
  const visibility = useDocumentVisibility()
  watch(visibility, (current, previous) => {
    if (current === 'visible' && previous === 'hidden') {
      getSoftwares(store.getIp, store.getJWT()).then((softwares) => {
        onboardingDialogStore.$state.selectedIntegrations =
          onboardingDialogStore.$state.selectedIntegrations.map(
            (integration: ExtendedSoftwareInformation) => {
              return {
                ...integration,
                connected: !!softwares.find(
                  (entry) => entry.software_name === integration.name
                ),
              }
            }
          )
      })
    }
  })

  async function confirm(): Promise<boolean> {
    return true
  }
  defineExpose({ confirm })
</script>

<style scoped>
  .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
