<template>
  <div class="checkbox-wrapper">
    <input
      :id="id"
      ref="checkbox"
      v-model="modelValue"
      :class="{ disabled: props.disabled }"
      type="checkbox"
      :disabled="props.disabled"
      @change="handleChange" />
    <label
      :for="id"
      class="content-wrapper"
      :class="{ 'content-wrapper--disabled': props.disabled }">
      <slot />
    </label>
  </div>
</template>
<script setup lang="ts">
  import { nanoid } from 'nanoid'

  const checkbox = ref<HTMLInputElement>()

  const id = nanoid()

  const props = defineProps({
    modelValue: {
      type: Object as PropType<boolean | null>,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  })
  const emit = defineEmits(['change', 'update:modelValue'])

  const modelValue = computed({
    get() {
      return props.modelValue
    },
    set(value: boolean | null | undefined) {
      emit('update:modelValue', value)
    },
  })

  function handleChange(event: Event) {
    emit('change', (event.target as HTMLInputElement).checked)
  }

  watch(
    () => modelValue.value,
    (newValue) => {
      if (!checkbox.value) return
      checkbox.value.checked = newValue || false
      emit('change', checkbox.value.checked)
    }
  )
</script>

<style scoped lang="scss">
  input {
    accent-color: var(--el-color-primary);
    cursor: pointer;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .content-wrapper {
    cursor: pointer;

    &--disabled {
      cursor: initial;
      color: var(--sm-contrast-muted);
    }
  }

  .disabled {
    cursor: initial;
  }
</style>
