<template>
  <DocumentUploadDialog
    v-model:show-drawer="showUploadDrawer.show"
    v-model:existing-document="showUploadDrawer.existingDocument"
    v-model:edit-mode="showUploadDrawer.editMode"
    :software-id="props.softwareId"
    @upload-document-success="
      () => {
        showUploadDrawer.editMode = false
        showUploadDrawer.existingDocument = null
        getDocumentList()
      }
    "
    @download-document="(document: DocumentInfo) => getDocument(document)" />

  <el-table
    ref="documentsTableRef"
    v-loading="loading"
    :data="filteredDocumentList"
    :style="{
      'max-width': '100%',
    }"
    :row-style="{
      cursor: 'pointer',
    }"
    :show-header="!props.smallCardMode"
    :max-height="
      props.smallCardMode
        ? '400px'
        : 'calc(100vh - 100px - var(--home-padding) * 2 - var(--el-card-padding) * 2)'
    "
    :default-sort="{ prop: 'date', order: 'descending' }"
    data-tutorial-id="documents-list"
    @selection-change="handleSelectionChange"
    @row-click="handleRowClick">
    <el-table-column v-if="props.viewMode" type="selection" width="40" />

    <!-- SoftwareName -->
    <el-table-column
      v-if="props.viewMode"
      prop="software_id"
      :label="i18n.t('views.documents.table.softwareName')"
      :min-width="`${rowWidth(
        filteredDocumentList,
        'software_name',
        'Software Name'
      )}`"
      sortable>
      <template #default="{ row }">
        <div
          :style="{
            position: 'relative',
            display: 'inline-flex',
            'align-items': 'center',
            'flex-wrap': 'nowrap',
            // 100% - icon width - arrow width - indent
            width: `calc(100% - 20px)`,
          }">
          <div
            v-if="
              getSoftware(row.software_id)?.display_name ||
              getSoftware(row.software_id)?.software_name
            "
            style="display: flex; align-items: center; margin-right: 10px">
            <el-image
              class="company-mini-img"
              :src="`https://logos.saasmetrix.io/${
                getSoftware(row.software_id)?.software_name
              }.png`"
              fit="contain">
              <template #error>
                <v-icon name="md-hideimage-round" scale="1.4" />
              </template>
            </el-image>
          </div>
          <div v-else>
            <span class="no-data"> {{ i18n.t('noSoftwareConnected') }} </span>
          </div>
          <!-- Plan's name -->
          <!-- max-width = 100% - expand arrow width - arrow indent - icon size -->
          <span style="display: inline-flex; max-width: 100%">
            <TextMultiline
              :text="
                getSoftware(row.software_id)?.display_name ||
                getSoftware(row.software_id)?.software_name
              "
              :lines-to-show="1"
              tooltip-max-width="250px"
              tooltip-placement="right" />
          </span>
        </div>
      </template>
    </el-table-column>

    <!-- Title -->
    <el-table-column
      prop="title"
      :label="i18n.t('views.documents.table.title')"
      :min-width="`${rowWidth(filteredDocumentList, 'title', 'Titel')}`"
      sortable>
      <template #default="{ row }">
        <TextMultiline
          :text="row.title"
          :lines-to-show="1"
          :show-tool-tip="true"
          tooltip-max-width="250px"
          tooltip-placement="right" />
      </template>
    </el-table-column>

    <!-- File Size -->
    <el-table-column
      v-if="!props.smallCardMode"
      prop="file_size"
      :label="i18n.t('views.documents.table.fileSize')"
      :min-width="`${rowWidth(
        filteredDocumentList,
        'file_size',
        'Dateigröße'
      )}`"
      sortable>
      <template #default="scope">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <span>{{ (scope.row.file_size / 1000).toFixed(2) }} KB</span>
      </template>
    </el-table-column>

    <!-- Date -->
    <el-table-column
      v-if="!props.smallCardMode"
      prop="date"
      :label="i18n.t('views.documents.table.date')"
      :min-width="`${rowWidth(filteredDocumentList, 'date', 'Datum')}`"
      sortable>
      <template #default="scope">
        <span>{{ dayjs(scope.row.date).format('DD.MM.YYYY') }}</span>
      </template>
    </el-table-column>

    <!-- Buttons -->
    <el-table-column column-key="document-last-column" align="right">
      <template #default="scope">
        <el-space :size="15">
          <!-- Download Document -->
          <span @click="getDocument(scope.row)">
            <SmTooltip
              :content="i18n.t('views.documents.table.tooltips.download')"
              placement="top">
              <v-icon
                name="io-cloud-download"
                animation="float"
                hover
                style="cursor: pointer"
                data-tutorial-id="documents-download-button" />
            </SmTooltip>
          </span>

          <!-- Edit Document -->
          <span @click="handleEdit(scope.$index, scope.row)">
            <SmTooltip
              :content="i18n.t('views.documents.table.tooltips.edit')"
              placement="top">
              <v-icon
                name="io-create"
                animation="wrench"
                hover
                style="cursor: pointer"
                data-tutorial-id="documents-edit-button" />
            </SmTooltip>
          </span>

          <!-- Delete Document -->
          <DeleteConfirmPopup
            :title="
              i18n.t('views.documents.table.tooltips.delete', {
                title: scope.row.title || scope.row.filename,
              })
            "
            @confirm="handleDelete(scope.$index, scope.row)">
            <v-icon
              class="delete-icon"
              name="md-delete-round"
              hover
              style="cursor: pointer"
              data-tutorial-id="documents-delete-button" />
          </DeleteConfirmPopup>
        </el-space>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup lang="ts">
  import dayjs from 'dayjs'
  import { saveAs } from 'file-saver'
  import JSZip from 'jszip'
  import { useI18n } from 'vue-i18n'

  import { DocumentInfo, DocumentsService, SoftwareOut } from '@/client'
  import { getDocument as fetchDocument } from '@/common/document'
  import { rowWidth } from '@/common/util/calculateTableRowWidth'
  import DeleteConfirmPopup from '@/components/DeleteConfirmPopup.vue'
  import DocumentUploadDialog from '@/components/DocumentUploadDialog.vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import { useSoftwareStore } from '@/stores/softwareStore'

  import { sendToast } from './sm/SmNotification'
  import SmTooltip from './sm/SmTooltip.vue'

  const store = useGlobalStore()
  const softwareStore = useSoftwareStore()
  const i18n = useI18n()
  const documentList = ref<DocumentInfo[]>([])
  const selectedDocuments = ref<DocumentInfo[]>()

  const documentsTableRef = ref()

  // Define Props
  const props = withDefaults(
    defineProps<{
      softwareId?: string
      smallCardMode: boolean
      searchQuery?: string
      viewMode?: boolean
      selectedDateRange?: [string, string]
    }>(),
    {
      softwareId: undefined,
      smallCardMode: false,
      searchQuery: '',
      viewMode: false,
      selectedDateRange: undefined,
    }
  )

  // Define Emits
  const emit = defineEmits([
    'loadDocumentList',
    'documentListLoaded',
    'documentList',
    'selectedDocumentCountChanged',
  ])

  const allSoftware = ref<SoftwareOut[]>([])
  const loading = ref(false)
  const searchQuery = computed(() => props.searchQuery)
  const selectedDateRange = computed(() => props.selectedDateRange)

  const filteredDocumentList = computed(() =>
    documentList.value
      .filter((document) => {
        // Filter by Date Range
        if (selectedDateRange.value) {
          const to = new Date(selectedDateRange.value[1])

          if (!document.date) return false
          return isBetween(
            document.date,
            new Date(selectedDateRange.value[0]).toISOString(),
            new Date(
              to.getFullYear(),
              to.getMonth() + 1,
              to.getDate()
            ).toISOString()
          )
        } else return true
      })
      .filter((document) => {
        // Filter by filename, softwarename or upload date
        return (
          !document.software_id ||
          !searchQuery.value ||
          (document.software_id &&
            getSoftware(document.software_id)
              ?.software_name.toLocaleLowerCase()
              .includes(searchQuery.value.toLocaleLowerCase())) ||
          (document.title
            ? document.title
                .toLocaleLowerCase()
                .includes(searchQuery.value.toLocaleLowerCase())
            : false) ||
          dayjs(document.date)
            .format('DD.MM.YYYY')
            .includes(searchQuery.value.toLocaleLowerCase())
        )
      })
  )

  const isBetween = (
    currentDateString: string,
    fromString: string,
    toString: string
  ) => {
    const currentDate = new Date(currentDateString)
    const from = new Date(fromString)
    const to = new Date(toString)
    return currentDate >= from && currentDate <= to
  }

  interface ShowUploadDrawer {
    show: boolean
    editMode: boolean
    existingDocument: DocumentInfo | null
  }

  const showUploadDrawer = ref<ShowUploadDrawer>({
    show: false,
    editMode: false,
    existingDocument: null,
  })

  /**
   * Opens a drawer:
   * @param editMode If true, the drawer will be opened in edit mode
   * @param existingDocument If editMode is true, this will be the document that will be edited
   */
  function openDrawer(
    editMode: boolean,
    existingDocument: DocumentInfo | null
  ) {
    showUploadDrawer.value.editMode = editMode
    showUploadDrawer.value.existingDocument = existingDocument
    showUploadDrawer.value.show = true
  }

  /**
   * Opens the Edit Drawer or the Upload Drawer
   * @param index The index of the document in the list
   * @param row The document to edit
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleEdit(index: number, row: DocumentInfo) {
    openDrawer(true, row)
  }

  /**
   * Updates the document list. All documents for this software are fetched from the server.
   */
  function getDocumentList() {
    emit('loadDocumentList')
    loading.value = true
    DocumentsService.getDocumentInfoListApiV1SoftwareDocumentsGet({}).then(
      (data) => {
        documentList.value = data
        loading.value = false
        emit('documentListLoaded', data)

        nextTick(() => {
          documentsTableRef.value.sort('software_name', 'ascending')
        })
      }
    )
  }

  function getSoftware(id: string) {
    return allSoftware.value.find((software) => software._id === id)
  }

  /**
   * Gets the document from the server
   * @param row The document
   */
  function getDocument(rowDocument: DocumentInfo) {
    fetchDocument(store.getIp, store.getJWT(), rowDocument._id).then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      Object.assign(a, {
        href: url,
        download: rowDocument.filename,
        target: '_blank',
      })
      a.style.display = 'none'
      // the filename you want
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }

  /**
   * Delete a document
   * @param index The index of the document in the list
   * @param row The document to delete
   */
  function handleDelete(index: number, row: Record<string, unknown>) {
    DocumentsService.deleteDocumentApiV1SoftwareDocumentsDocumentIdDelete({
      documentId: row._id as string,
    }).then(function (response) {
      getDocumentList()
      sendToast(
        i18n.t('notifications.documentTable.deleteSuccess.title'),
        i18n.t('notifications.documentTable.deleteSuccess.message'),
        'success'
      )
      return response.text()
    })
  }

  const deleteDocuments = () => {
    const promises: Promise<Response>[] = []

    selectedDocuments.value?.forEach((document) => {
      const promise =
        DocumentsService.deleteDocumentApiV1SoftwareDocumentsDocumentIdDelete({
          documentId: document._id,
        })
      promises.push(promise)
    })

    Promise.all(promises)
      .then(() => {
        sendToast(
          i18n.t('notifications.documentTable.bulkDeleteSuccess.title'),
          i18n.t('notifications.documentTable.bulkDeleteSuccess.message'),
          'success'
        )
        getDocumentList()
      })
      // Beim Löschen ... (der Dokumente) ... ist ein Fehler aufgetreten
      .catch(() => {
        sendToast(
          i18n.t('notifications.documentTable.deleteFailed.title'),
          i18n.t('notifications.documentTable.deleteFailed.message'),
          'error'
        )
        getDocumentList()
      })
  }

  const handleSelectionChange = (documents: DocumentInfo[]) => {
    selectedDocuments.value = documents
    emit('selectedDocumentCountChanged', documents.length)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRowClick = (row: DocumentInfo, column: any) => {
    if (column.columnKey !== 'document-last-column') handleEdit(0, row)
  }

  const downloadDocumentList = async () => {
    sendToast(
      i18n.t('notifications.documentTable.downloadStarted.title'),
      i18n.t('notifications.documentTable.downloadStarted.message'),
      'info'
    )
    const zip = new JSZip()

    // Create Promise to wait for the document download
    new Promise<void>((resolve) => {
      selectedDocuments.value?.forEach(async (document, index, array) => {
        await DocumentsService.downloadDocumentApiV1SoftwareDocumentsDocumentIdDownloadGet(
          { documentId: document._id }
        ).then(async (blob) => {
          // Add downloaded file to zip folder, gernateRandomId is need because the filename can have duplicates
          zip.file(
            document.software_id +
              '_' +
              gernerateRandomId(5) +
              '_' +
              document.filename,
            blob
          )

          // Reslove promise if all entry are handled
          if (index === array.length - 1) resolve()
        })
      })
    }).then(() => {
      // Generate Zip Folder
      zip
        .generateAsync({ type: 'blob' })
        .then((blob) => {
          // Save Zip Folder als Dokumente.zip
          saveAs(blob, 'Dokumente.zip')
        })
        .finally(() => {
          sendToast(
            i18n.t('notifications.documentTable.downloadSuccess.title'),
            i18n.t('notifications.documentTable.downloadSuccess.message'),
            'success'
          )
        })
    })
  }

  const gernerateRandomId = (length: number): string => {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  getDocumentList()

  defineExpose({
    getDocumentList,
    openDrawer,
    downloadDocumentList,
    deleteDocuments,
  })

  onMounted(async () => {
    allSoftware.value = await softwareStore.getSoftware()
  })
</script>

<style scoped lang="scss">
  .company-mini-img {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
  }
</style>
