<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
  <div class="sm-input-number">
    <button class="decrease" @click.prevent="decrease" :disabled="value <= 1">
      -
    </button>
    <div class="container">
      <input
        v-model="value"
        inputmode="numeric"
        pattern="[0-9]*"
        :style="{ width: `${maxLength}ch` }"
        :size="maxLength" />
      <span v-if="props.max" style="margin: 0 0.5rem">/</span>
      <span v-if="props.max">{{ props.max }}</span>
    </div>
    <button
      class="increase"
      @click.prevent="increase"
      :disabled="value >= props.max">
      +
    </button>
  </div>
</template>

<script lang="ts" setup>
  const value = defineModel<number>({ default: 0 })

  const props = withDefaults(
    defineProps<{
      max?: number
    }>(),
    {
      max: 10000,
    }
  )

  const maxLength = computed(() => {
    return props.max.toString().length
  })

  function increase() {
    if (value.value < props.max) {
      value.value++
    }
  }

  function decrease() {
    if (value.value > 1) {
      value.value--
    }
  }
</script>

<style lang="scss">
  .sm-input-number {
    display: flex;
    border-radius: var(--border-radius-small);
    border: 1.5px solid var(--el-border-color);

    .container {
      padding: 0 1rem;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      border: none;
      padding: 0;
      background-color: unset;
      font-size: inherit;
      text-align: right;
    }

    .decrease,
    .increase {
      background-color: var(--el-fill-color-light);
      padding: 5px;
      width: 4ch;
      height: 30px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.2s;

      border: none;

      cursor: pointer;

      &[disabled] {
        cursor: not-allowed;
      }
      &:hover {
        background-color: var(--el-fill-color-lighter);
      }
    }

    .decrease {
      border-right: 1.5px solid var(--el-border-color);
    }

    .increase {
      border-left: 1.5px solid var(--el-border-color);
    }
  }
</style>
