<template>
  <div class="sm-switch">
    <input :id="id" v-model="value" type="checkbox" class="sm-switch-input" />
    <label
      class="sm-switch-lable bg-gray-300 dark:bg-gray-400"
      :for="id"></label>
  </div>
</template>

<script setup lang="ts">
  import { nanoid } from 'nanoid'

  const id = nanoid()

  const emit = defineEmits(['select', 'unselect'])

  const value = defineModel('modelValue', {
    type: Boolean,
    default: false,
  })

  watch(
    () => value.value,
    (newValue: boolean) => {
      if (newValue) {
        emit('select')
      } else {
        emit('unselect')
      }
    }
  )

  defineExpose({
    value,
  })
</script>

<style lang="scss">
  $height: 2em;

  .sm-switch-input {
    opacity: 0;
    position: absolute;
    left: -900000px;
    top: -900000px;
  }

  .sm-switch-input + .sm-switch-lable {
    cursor: pointer;
    display: flex;
    position: relative;
    height: $height;
    width: 3.5em;
    border-radius: 1em;
    transition: background-color 0.2s ease;
  }

  //   Indicator
  .sm-switch-input + .sm-switch-lable::before {
    content: '';
    display: block;
    position: absolute;

    // Center
    top: 50%;
    transform: translateY(-50%);

    left: 0.25em;
    height: calc(#{$height} - 0.5em);
    aspect-ratio: 1;
    border-radius: 50%;

    background-color: #fff;

    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease;
  }

  .sm-switch-input:checked + .sm-switch-lable {
    background-color: var(--sm-primary);
  }

  .sm-switch-input:checked + .sm-switch-lable::before {
    transform: translate(100%, -50%);
  }

  .sm-switch-input:focus + .sm-switch-lable {
    outline: none;
  }

  //   Disabled
  .sm-switch-input:disabled + .sm-switch-lable {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .sm-switch-input:disabled + .sm-switch-lable::before,
  .sm-switch-input:disabled + .sm-switch-lable::after {
    box-shadow: none;
    background-color: #ddd;
  }

  .sm-switch-input:disabled:checked + .sm-switch-lable::before {
    background-color: #ccc;
  }
</style>
