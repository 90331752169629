<template>
  <span v-if="props.softwareLicenses.length > 20">
    <SmTooltip
      :content="i18n.t('views.documents.table.tooltips.download')"
      placement="top">
      <template #content>
        <!-- TODO: Name as key cloud be a problem -->
        <TextMultiline
          v-for="license in props.softwareLicenses"
          :key="license.name">
          {{ license.name }}
        </TextMultiline>
      </template>
      {{ i18n.t('licensesCount', { count: softwareLicenses.length }) }}
    </SmTooltip>
  </span>
  <span v-else-if="softwareLicenses.length > 0" class="py-3">
    <TextMultiline v-for="license in softwareLicenses" :key="license.name">
      {{ license.name }}
    </TextMultiline></span
  >
  <span v-else class="text-muted">{{ i18n.t('general.noModel') }}</span>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { SoftwareLicenseMini } from '@/client'

  type Props = {
    softwareLicenses: SoftwareLicenseMini[]
  }
  const props = defineProps<Props>()

  const i18n = useI18n()
</script>
