/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSoftwareUserLicenseRemoveData } from './BackgroundJobSoftwareUserLicenseRemoveData';
export type BackgroundJobSoftwareUserLicenseRemovePostIn = {
    type: BackgroundJobSoftwareUserLicenseRemovePostIn.type;
    data: BackgroundJobSoftwareUserLicenseRemoveData;
    /**
     * Time in seconds when to enqueue. Does not enqueue if value is `null`.
     */
    enqueue_in?: (number | null);
};
export namespace BackgroundJobSoftwareUserLicenseRemovePostIn {
    export enum type {
        SOFTWARE_USER_LICENSE_REMOVE = 'software_user_license_remove',
    }
}

