/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivitiesResponse } from '../models/ActivitiesResponse';
import type { ActivityActions } from '../models/ActivityActions';
import type { ActivityResponse } from '../models/ActivityResponse';
import type { PaginationSortOrder } from '../models/PaginationSortOrder';
import type { RequestLogResponse } from '../models/RequestLogResponse';
import type { RequestLogsResponse } from '../models/RequestLogsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LogsService {
    /**
     * Get Activities
     * @returns ActivitiesResponse Successful Response
     * @throws ApiError
     */
    public static getActivitiesApiV1LogActivitiesGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        actionExists,
        actionEq,
        actionGt,
        actionGte,
        actionIn,
        actionLt,
        actionLte,
        actionNe,
        actionNin,
        action,
        accountIdExists,
        accountIdEq,
        accountIdGt,
        accountIdGte,
        accountIdIn,
        accountIdLt,
        accountIdLte,
        accountIdNe,
        accountIdNin,
        accountId,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        actionExists?: (boolean | null),
        actionEq?: (ActivityActions | null),
        actionGt?: (ActivityActions | null),
        actionGte?: (ActivityActions | null),
        actionIn?: (Array<ActivityActions> | null),
        actionLt?: (ActivityActions | null),
        actionLte?: (ActivityActions | null),
        actionNe?: (ActivityActions | null),
        actionNin?: (Array<ActivityActions> | null),
        action?: (ActivityActions | null),
        accountIdExists?: (boolean | null),
        accountIdEq?: (string | null),
        accountIdGt?: (string | null),
        accountIdGte?: (string | null),
        accountIdIn?: (Array<string> | null),
        accountIdLt?: (string | null),
        accountIdLte?: (string | null),
        accountIdNe?: (string | null),
        accountIdNin?: (Array<string> | null),
        accountId?: (string | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<ActivitiesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log/activities',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'action.$exists': actionExists,
                'action.$eq': actionEq,
                'action.$gt': actionGt,
                'action.$gte': actionGte,
                'action.$in': actionIn,
                'action.$lt': actionLt,
                'action.$lte': actionLte,
                'action.$ne': actionNe,
                'action.$nin': actionNin,
                'action': action,
                'account_id.$exists': accountIdExists,
                'account_id.$eq': accountIdEq,
                'account_id.$gt': accountIdGt,
                'account_id.$gte': accountIdGte,
                'account_id.$in': accountIdIn,
                'account_id.$lt': accountIdLt,
                'account_id.$lte': accountIdLte,
                'account_id.$ne': accountIdNe,
                'account_id.$nin': accountIdNin,
                'account_id': accountId,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Activities
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countActivitiesApiV1LogActivitiesCountGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        actionExists,
        actionEq,
        actionGt,
        actionGte,
        actionIn,
        actionLt,
        actionLte,
        actionNe,
        actionNin,
        action,
        accountIdExists,
        accountIdEq,
        accountIdGt,
        accountIdGte,
        accountIdIn,
        accountIdLt,
        accountIdLte,
        accountIdNe,
        accountIdNin,
        accountId,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        actionExists?: (boolean | null),
        actionEq?: (ActivityActions | null),
        actionGt?: (ActivityActions | null),
        actionGte?: (ActivityActions | null),
        actionIn?: (Array<ActivityActions> | null),
        actionLt?: (ActivityActions | null),
        actionLte?: (ActivityActions | null),
        actionNe?: (ActivityActions | null),
        actionNin?: (Array<ActivityActions> | null),
        action?: (ActivityActions | null),
        accountIdExists?: (boolean | null),
        accountIdEq?: (string | null),
        accountIdGt?: (string | null),
        accountIdGte?: (string | null),
        accountIdIn?: (Array<string> | null),
        accountIdLt?: (string | null),
        accountIdLte?: (string | null),
        accountIdNe?: (string | null),
        accountIdNin?: (Array<string> | null),
        accountId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log/activities/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'action.$exists': actionExists,
                'action.$eq': actionEq,
                'action.$gt': actionGt,
                'action.$gte': actionGte,
                'action.$in': actionIn,
                'action.$lt': actionLt,
                'action.$lte': actionLte,
                'action.$ne': actionNe,
                'action.$nin': actionNin,
                'action': action,
                'account_id.$exists': accountIdExists,
                'account_id.$eq': accountIdEq,
                'account_id.$gt': accountIdGt,
                'account_id.$gte': accountIdGte,
                'account_id.$in': accountIdIn,
                'account_id.$lt': accountIdLt,
                'account_id.$lte': accountIdLte,
                'account_id.$ne': accountIdNe,
                'account_id.$nin': accountIdNin,
                'account_id': accountId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Activity
     * @returns ActivityResponse Successful Response
     * @throws ApiError
     */
    public static getActivityApiV1LogActivitiesActivityIdGet({
        activityId,
        xTransactionId,
    }: {
        activityId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<ActivityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log/activities/{activity_id}',
            path: {
                'activity_id': activityId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Request Logs
     * @returns RequestLogsResponse Successful Response
     * @throws ApiError
     */
    public static getRequestLogsApiV1LogRequestLogsGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        userIdExists,
        userIdEq,
        userIdGt,
        userIdGte,
        userIdIn,
        userIdLt,
        userIdLte,
        userIdNe,
        userIdNin,
        userId,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        userIdExists?: (boolean | null),
        userIdEq?: (string | null),
        userIdGt?: (string | null),
        userIdGte?: (string | null),
        userIdIn?: (Array<string> | null),
        userIdLt?: (string | null),
        userIdLte?: (string | null),
        userIdNe?: (string | null),
        userIdNin?: (Array<string> | null),
        userId?: (string | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<RequestLogsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log/request-logs',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'user_id.$exists': userIdExists,
                'user_id.$eq': userIdEq,
                'user_id.$gt': userIdGt,
                'user_id.$gte': userIdGte,
                'user_id.$in': userIdIn,
                'user_id.$lt': userIdLt,
                'user_id.$lte': userIdLte,
                'user_id.$ne': userIdNe,
                'user_id.$nin': userIdNin,
                'user_id': userId,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Request Logs
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countRequestLogsApiV1LogRequestLogsCountGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        userIdExists,
        userIdEq,
        userIdGt,
        userIdGte,
        userIdIn,
        userIdLt,
        userIdLte,
        userIdNe,
        userIdNin,
        userId,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        userIdExists?: (boolean | null),
        userIdEq?: (string | null),
        userIdGt?: (string | null),
        userIdGte?: (string | null),
        userIdIn?: (Array<string> | null),
        userIdLt?: (string | null),
        userIdLte?: (string | null),
        userIdNe?: (string | null),
        userIdNin?: (Array<string> | null),
        userId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log/request-logs/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'user_id.$exists': userIdExists,
                'user_id.$eq': userIdEq,
                'user_id.$gt': userIdGt,
                'user_id.$gte': userIdGte,
                'user_id.$in': userIdIn,
                'user_id.$lt': userIdLt,
                'user_id.$lte': userIdLte,
                'user_id.$ne': userIdNe,
                'user_id.$nin': userIdNin,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Request Log
     * @returns RequestLogResponse Successful Response
     * @throws ApiError
     */
    public static getRequestLogApiV1LogRequestLogsRequestLogIdGet({
        requestLogId,
        xTransactionId,
    }: {
        requestLogId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<RequestLogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log/request-logs/{request_log_id}',
            path: {
                'request_log_id': requestLogId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
