<template>
  <!-- Login Form -->
  <div class="form-content">
    <!-- Header -->
    <div class="header">
      <div
        :class="{
          'header-left': true,
          'header-left-active':
            mode == 'forgot-password' ||
            mode == 'enter-recovery-code' ||
            mode == 'otp',
        }"
        @click="backButtonPressed">
        <div class="back-button">
          <v-icon
            name="md-arrowbackios-round"
            scale="1.5"
            class="fill-contrast" />
        </div>
      </div>
      <div class="header-right">
        <header>{{ i18n.t(mode) }}</header>
        <!-- Message -->
        <div v-if="i18n.te(`login.${mode}.message`)">
          {{ i18n.t(`login.${mode}.message`) }}
          <a
            v-if="mode === 'register'"
            href="#"
            @click="mode = 'login-password'"
            >{{ i18n.t('login-password') }}</a
          >
          <a
            v-if="mode === 'login-password'"
            href="#"
            @click="mode = 'register'"
            >{{ i18n.t('register') }}</a
          >
        </div>
      </div>
    </div>

    <!-- Error Message -->
    <SmDialogMessage
      :message="dialogMessage.message"
      :visible="dialogMessage.visible"
      style="width: 100%"
      :type="dialogMessage.type" />

    <form
      @submit.prevent="buttonClickHandler(null)"
      @keyup.enter="buttonClickHandler(null)">
      <!-- Email -->
      <el-collapse-transition>
        <div
          v-show="
            mode == 'login-password' ||
            mode == 'forgot-password' ||
            mode == 'register'
          "
          class="field input-field">
          <SmInput
            ref="emailRef"
            v-model="formData.email"
            autocomplete="email"
            :validator="[notEmpty, validateEmail]"
            label="E-Mail"></SmInput>
        </div>
      </el-collapse-transition>

      <!-- Password -->
      <el-collapse-transition>
        <div
          v-show="mode == 'login-password' || mode == 'register'"
          class="field input-field">
          <SmInput
            ref="passwordRef"
            v-model="formData.password"
            :autocomplete="
              mode == 'register' ? 'new-password' : 'current-password'
            "
            :label="i18n.t('general.password')"
            :validator="[notEmpty, minLength(8)]"
            :show-password-strength="mode == 'register'"
            type="password" />
        </div>
      </el-collapse-transition>

      <!-- OTP -->
      <el-collapse-transition>
        <div v-show="mode == 'otp'" class="field input-field">
          <SmInput
            ref="otpRef"
            v-model="formData.otp"
            autocomplete="one-time-code"
            label="OTP"
            :validator="[notEmpty, minLength(6)]" />
        </div>
      </el-collapse-transition>

      <!-- Enter Recovery Code -->
      <el-collapse-transition>
        <div v-show="mode == 'enter-recovery-code'" class="field input-field">
          <SmInput
            ref="otpRef"
            v-model="formData.recoveryCode"
            label="Recovery Code"
            autocomplete="one-time-code"
            :validator="[notEmpty]" />
        </div>
      </el-collapse-transition>

      <!-- Verify Email Code -->
      <el-collapse-transition>
        <div v-show="mode == 'verify-email'" class="field verify-email">
          <SmInput
            ref="verifyMailCodeRef"
            v-model="formData.verifyMailCode"
            autocomplete="one-time-code"
            :label="i18n.t('verify-email-code')"
            :validator="[notEmpty, minLength(6)]"
            type="text" />
        </div>
      </el-collapse-transition>

      <!-- Password helper -->
      <div
        :class="[
          'password-helper',
          { active: mode == 'login-password' || mode == 'forgot-password' },
        ]">
        <!-- Forgot Password -->
        <div v-if="mode == 'login-password'" class="link forgot-password-field">
          <a href="#" @click="mode = 'forgot-password'">
            {{ i18n.t('forgot-password') }}?</a
          >
        </div>
      </div>

      <div class="field button-field">
        <SmButton
          type="primary"
          size="large"
          block
          :loading="loading"
          @click="buttonClickHandler">
          {{ i18n.t(`button.${mode}`) }}
        </SmButton>
      </div>
    </form>

    <!-- Third Party Logins -->
    <div
      :class="[
        'third-party-login-wrapper',
        {
          'third-party-login-wrapper-active': [
            'login-password',
            'register',
          ].includes(mode),
        },
      ]">
      <div class="third-party-login">
        <!-- Divider -->
        <div class="divider">
          <div class="divider-line"></div>
          <div class="divider-text">{{ i18n.t('or') }}</div>
          <div class="divider-line"></div>
        </div>

        <!-- Buttons -->
        <div class="buttons">
          <SmButton
            type="primary"
            size="large"
            outline
            block
            @click="buttonClickHandler(ButtonHandlerOptions.SocialSignin)">
            <div class="third-party-button-content">
              <div class="third-party-button-logo">
                <!-- Logo as svg -->
                <img src="https://logos.saasmetrix.io/microsoft.svg" :alt="i18n.t('microsoft')" />
              </div>
              <div>
                {{ i18n.t(mode) }} {{ i18n.t('with') }}
                {{ i18n.t('microsoft') }}
              </div>
            </div>
          </SmButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    ErrorBrowserLocationChangeRequired,
    ErrorGeneric,
    FrontendApiUpdateVerificationFlowRequest,
    LoginFlow,
    RecoveryFlow,
    RegistrationFlow,
    UpdateLoginFlowBody,
    UpdateRegistrationFlowBody,
    VerificationFlow,
  } from '@ory/client'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'

  import { getCsrfToken, getErrorMessage, isError } from '@/common/util/oryUtil'
  import SmButton from '@/components/sm/SmButton.vue'
  import SmInput from '@/components/sm/SmInput.vue'
  import {
    minLength,
    notEmpty,
    validateEmail,
  } from '@/components/sm/SmInput/SmInputValidator'
  import { SessionStatus, useSessionStore } from '@/stores/sessionStore'

  const router = useRouter()
  const i18n = useI18n()
  const sessionStore = useSessionStore()

  const props = defineProps({
    inviteToken: {
      type: String,
      default: '',
    },
  })

  const emit = defineEmits(['login'])

  enum ButtonHandlerOptions {
    SocialSignin = 'social-signin',
  }

  interface DialogMessage {
    message: string
    type: 'error' | 'success' | 'warning' | 'info'
    visible: boolean
  }

  const dialogMessage: Ref<DialogMessage> = ref({
    message: '',
    type: 'error',
    visible: false,
  })

  // Login view mode
  type LoginViewMode =
    | 'login-password'
    | 'register'
    | 'forgot-password'
    | 'reset-password'
    | 'otp'
    | 'enter-recovery-code'
    | 'verify-email'
    | 'social-signin-completed'

  // Get from query params or default to login-magic
  const mode: Ref<LoginViewMode> = ref(
    (router.currentRoute.value.query.mode as LoginViewMode) || 'login-password'
  )

  // Sync mode with query params
  watch(
    () => mode.value,
    (value) => {
      router.push({ query: { mode: value, inviteToken: props.inviteToken } })
      // Reset message
      dialogMessage.value = {
        message: '',
        type: 'error',
        visible: false,
      }
    }
  )

  watch(
    () => router.currentRoute.value.query.mode,
    (value) => {
      if (value) mode.value = value as LoginViewMode
    }
  )

  const loading = ref(false)

  const formData = ref({
    email: '',
    password: '',
    otp: '',
    recoveryCode: '',
    verifyMailCode: '',
  })

  const verifyFlowId = ref('')

  const emailRef = ref<ComponentExposed<typeof SmInput>>()
  const passwordRef = ref<ComponentExposed<typeof SmInput>>()
  const otpRef = ref<ComponentExposed<typeof SmInput>>()
  const verifyMailCodeRef = ref<ComponentExposed<typeof SmInput>>()

  // Button click handler
  function buttonClickHandler(options: ButtonHandlerOptions | null = null) {
    dialogMessage.value = { message: '', type: 'error', visible: false }
    switch (mode.value) {
      case 'login-password':
        // Send password
        login(options)
        break
      case 'register':
        // Register
        register(options)
        break
      case 'forgot-password':
        // Send password reset link
        recoverPassword()
        break
      case 'otp':
        // Send OTP
        sendOTP()
        break
      case 'enter-recovery-code':
        // Send recovery code
        submitRecoveryFlow()
        break
      case 'verify-email':
        // Verify email
        verifyEmail()
        break
    }
  }

  function backButtonPressed() {
    if (loading.value) return

    switch (mode.value) {
      case 'forgot-password':
        mode.value = 'login-password'
        break
      case 'enter-recovery-code':
        mode.value = 'login-password'
        break
      case 'otp':
        sessionStore.logout().then(() => {
          mode.value = 'login-password'
        })
        break
    }
  }

  async function register(options: ButtonHandlerOptions | null = null) {
    loading.value = true
    const registrationFlow = await sessionStore.frontend
      .createBrowserRegistrationFlow({})
      .catch((error) => {
        handleError(error.response.data)
        loading.value = false
      })

    if (!registrationFlow?.data) {
      loading.value = false
      return
    }
    const csrfToken = getCsrfToken(registrationFlow.data.ui.nodes)

    let body: UpdateRegistrationFlowBody
    if (options == ButtonHandlerOptions.SocialSignin) {
      body = {
        method: 'oidc',
        provider: 'microsoft',
        csrf_token: csrfToken,
      }
    } else {
      body = {
        traits: {
          email: formData.value.email,
        },
        method: 'password',
        password: formData.value.password,
        csrf_token: csrfToken,
      }
    }

    sessionStore.frontend
      .updateRegistrationFlow({
        flow: registrationFlow.data.id,
        updateRegistrationFlowBody: body,
      })
      .then((res) => {
        // email verification required after login
        mode.value = 'verify-email'
        if (!res.data.continue_with) return
        // todo: update ory
        // @ts-expect-error: Thanks to bad ory api for the weird typing (the .flow exists, but does not exist in the sdk type)
        const flowObject = res.data.continue_with.find((obj) => obj.flow)
        // @ts-expect-error: follow-up error
        verifyFlowId.value = flowObject ? flowObject.flow.id : null
      })
      .catch((error) => {
        if (
          options == ButtonHandlerOptions.SocialSignin &&
          error.response.status == 422
        ) {
          window.open(error.response.data.redirect_browser_to, '_self')
        }
        handleError(error.response.data)
      })
      .finally(() => {
        loading.value = false
      })
  }

  async function login(options: ButtonHandlerOptions | null = null) {
    loading.value = true

    // Create login flow
    const loginFlow = await sessionStore.frontend
      .createBrowserLoginFlow({})
      .catch((error) => {
        handleError(error.response.data)
        loading.value = false
      })

    if (!loginFlow?.data) {
      loading.value = false
      return
    }
    const csrfToken = getCsrfToken(loginFlow.data.ui.nodes)

    let body: UpdateLoginFlowBody
    const loginEmail: string = String(formData.value.email)
    if (options == ButtonHandlerOptions.SocialSignin)
      body = {
        csrf_token: csrfToken,
        method: 'oidc',
        provider: 'microsoft',
      }
    else {
      body = {
        password: formData.value.password,
        method: 'password',
        csrf_token: csrfToken,
        identifier: loginEmail,
      }
    }

    // Login the user
    await sessionStore.frontend
      .updateLoginFlow({
        flow: loginFlow.data.id,
        updateLoginFlowBody: body,
      })
      .then(() => {
        emit('login')
        sessionStore.checkSession()
        loading.value = false
      })
      .catch((error) => {
        // oauth_link in case of social_signin comes in the error response
        if (
          options == ButtonHandlerOptions.SocialSignin &&
          error.response.status == 422
        ) {
          const oauth_link = error.response.data.redirect_browser_to
          window.open(oauth_link, '_self')
          return
        } else if (error.response.data?.ui?.messages[0]?.id == 4000010) {
          // TODO: POTENTIAL DANGER, because the error code might change!! Get the constant from ory in any way
          // Email verification required: creating verification flow and sending out a verification email
          let flowId: string
          sessionStore.frontend
            .createBrowserVerificationFlow() // Creating a flow
            .then((res) => {
              flowId = res.data.id
              sessionStore.frontend
                .updateVerificationFlow({
                  // choosing the code-method, and sending out the email
                  flow: flowId,
                  updateVerificationFlowBody: {
                    csrf_token: csrfToken,
                    method: 'code',
                    email: loginEmail,
                  },
                })
                .then(() => {
                  verifyFlowId.value = flowId
                  loading.value = false
                  mode.value = 'verify-email' // changing to verify-email mode, because an email has been sent
                  setTimeout(() => {
                    // showing the error message (needs delay, because otherwise the mode change resets the error message)
                    handleError(error.response.data)
                  }, 5)
                })
                .catch((error) => {
                  handleError(error.response.data)
                  loading.value = false
                })
              res.data
            })
            .catch((error) => {
              handleError(error.response.data)
              loading.value = false
            })
        } else if (
          error.response.status == 422 &&
          error.response.data.error?.id == 'browser_location_change_required' &&
          error.response.data.error?.reason.includes('aal2')
        ) {
          // Do not handle the error, 2FA is required (will be handled below)
          loading.value = false
        } else {
          handleError(error.response.data)
          loading.value = false
        }
      })

    if (options != ButtonHandlerOptions.SocialSignin)
      await sessionStore.frontend
        .toSession()
        .then(() => {
          sessionStore.checkSession()
        })
        .catch((error) => {
          if (error.response.data.error?.id === 'session_aal2_required') {
            mode.value = 'otp'
          }
        })
  }

  async function sendOTP() {
    loading.value = true
    const loginFlow = await sessionStore.frontend
      .createBrowserLoginFlow({ aal: 'aal2' })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        handleError(error.response.data)
        loading.value = false
      })

    if (!loginFlow?.ui) {
      loading.value = false
      return
    }
    const csrfToken = getCsrfToken(loginFlow.ui.nodes)

    sessionStore.frontend
      .updateLoginFlow({
        flow: loginFlow.id,
        updateLoginFlowBody: {
          method: 'totp',
          csrf_token: csrfToken,
          totp_code: formData.value.otp,
        },
      })
      .then(() => {
        emit('login')
        sessionStore.checkSession()
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => {
        loading.value = false
      })
  }

  async function verifyEmail() {
    loading.value = true
    const params: FrontendApiUpdateVerificationFlowRequest = {
      // verifyFlowId is either set by the registration flow or by the route param, set by the email link in the onMounted
      flow: verifyFlowId.value,
      updateVerificationFlowBody: {
        code: formData.value.verifyMailCode,
        method: 'code',
      },
    }
    sessionStore.frontend
      .updateVerificationFlow({ ...params })
      .then((res) => {
        if (isError(res.data)) {
          handleError(res.data)
          return
        }

        emit('login')
        sessionStore.checkSession().then(() => {
          if (sessionStore.status === SessionStatus.LOGGED_IN) {
            router.push({
              path: '/login/company',
              query: { autoLogin: 'true', inviteToken: props.inviteToken },
            })
          } else {
            mode.value = 'login-password'

            setTimeout(() => {
              // Show success message
              dialogMessage.value = {
                message: i18n.t('login.verifyEmailSuccess'),
                type: 'success',
                visible: true,
              }
            }, 100)
          }
        })
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => {
        loading.value = false
      })
  }

  function handleError(
    error:
      | ErrorGeneric
      | RegistrationFlow
      | LoginFlow
      | ErrorBrowserLocationChangeRequired
      | RecoveryFlow
      | VerificationFlow
  ) {
    dialogMessage.value = {
      message: getErrorMessage(error, i18n as ReturnType<typeof useI18n>),
      type: 'error',
      visible: true,
    }
  }

  // Watch for mode changes
  watch(mode, (newMode) => {
    // Reset form data
    if (newMode === 'enter-recovery-code') {
      return
    }

    // Reset form data
    formData.value = {
      email: '',
      password: '',
      otp: '',
      recoveryCode: '',
      verifyMailCode: '',
    }

    // Reset form validation
    try {
      emailRef.value?.resetValidation()
      passwordRef.value?.resetValidation()
      otpRef.value?.resetValidation()
      verifyMailCodeRef.value?.resetValidation()
    } catch (error) {
      console.error(error)
    }

    // Reset loading
    loading.value = false
  })

  /*
Recover password
*/
  const recoveryFlow = ref<RecoveryFlow>()

  function recoverPassword() {
    loading.value = true

    sessionStore.frontend
      .createBrowserRecoveryFlow({})
      .then((flow) => {
        const csrfToken = getCsrfToken(flow.data.ui.nodes)

        return sessionStore.frontend.updateRecoveryFlow({
          flow: flow.data.id,
          updateRecoveryFlowBody: {
            csrf_token: csrfToken,
            email: formData.value.email,
            method: 'code',
          },
        })
      })
      .then((flow) => {
        dialogMessage.value = {
          message: i18n.t('recoveryCodeSent'),
          type: 'success',
          visible: true,
        }
        recoveryFlow.value = flow.data
        mode.value = 'enter-recovery-code'
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => {
        loading.value = false
      })
  }

  function submitRecoveryFlow() {
    loading.value = true

    if (!recoveryFlow.value) {
      loading.value = false
      return
    }

    sessionStore.frontend
      .updateRecoveryFlow({
        // token: formData.value.recoveryCode,
        flow: recoveryFlow.value?.id,
        updateRecoveryFlowBody: {
          csrf_token: getCsrfToken(recoveryFlow.value.ui.nodes),
          method: 'code',
          // email: formData.value.email,
          code: formData.value.recoveryCode,
        },
      })
      .then((res) => {
        if (res.data.ui.messages && res.data.ui.messages[0].type == 'error') {
          handleError(res.data)
          return
        }
        router.push({
          path: '/login/company',
          query: { autoLogin: 'true', inviteToken: props.inviteToken },
        })
      })
      .catch((error) => {
        // Check status code
        if (error.response.status === 422) {
          // Check if 2FA is required (if who am i fails with id 'session_aal2_required')
          sessionStore.frontend
            .toSession()
            .then(() => {
              router.push({
                path: '/login/company',
                query: { autoLogin: 'true', inviteToken: props.inviteToken },
              })
            })
            .catch((error) => {
              if (error.response.data.error?.id === 'session_aal2_required') {
                // Change mode to 2FA
                mode.value = 'otp'
              }
            })
        } else {
          dialogMessage.value = {
            message: i18n.t('recoveryCodeInvalid'),
            type: 'error',
            visible: true,
          }
        }
      })
      .finally(() => {
        loading.value = false
      })
  }

  // Handling query param mode's via link
  onMounted(() => {
    const route = useRoute()
    switch (route.query.mode) {
      case 'verify-email': {
        mode.value = 'verify-email'
        verifyFlowId.value = route.query.flow as string
        sessionStore.frontend
          .getVerificationFlow({ id: verifyFlowId.value })
          .then((res) => {
            // @ts-expect-error - Should exist
            if (!res.data.ui.nodes[0]?.attributes.value) return // Should exist
            // @ts-expect-error - Should exist
            const oneTimeCode = res.data.ui.nodes[0].attributes.value // Should exist
            formData.value.verifyMailCode = oneTimeCode.toString()
            buttonClickHandler()
          })
          .catch((error) => {
            handleError(error.response.data)
          })
        break
      }
      case 'social-signin-completed': {
        emit('login')
        sessionStore.checkSession()
        break
      }
    }

    // Check if invite token is set
    if (props.inviteToken) {
      // Set message
      dialogMessage.value = {
        message: i18n.t('login.inviteTokenSet'),
        type: 'info',
        visible: true,
      }
    }
  })
</script>

<style lang="scss" scoped>
  form {
    margin-top: 20px;
    margin-bottom: 20px;

    .field:not(:first-child) {
      margin-top: 20px;
    }

    .field ~ .forgot-password-field {
      margin-top: 00px;
    }

    .forgot-password-field {
      margin-top: 4px;
      display: flex;
      justify-content: flex-end;

      a {
        font-size: 0.9rem;
      }
    }
  }

  a {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
  }

  @media screen and (max-width: 730px) {
    .form {
      height: 100%;
      padding: 15px 20px;
    }

    .login-container {
      background-color: var(--sm-white);
    }

    .form-content {
      margin-top: 20%;
    }
  }

  /* Header */
  header {
    font-size: 2rem;
    font-weight: 600;
  }

  .header {
    display: flex;
    gap: 10px;
  }

  .header-right {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .header-left {
    display: grid;
    grid-template-columns: 0fr;
    align-items: center;
    transition: grid-template-columns 500ms;
  }

  .header-left.header-left-active {
    grid-template-columns: 1fr;
  }

  .header-left > div {
    overflow: hidden;
  }

  .third-party-login-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    width: 100%;
    transition: grid-template-rows 500ms;
  }

  .third-party-login-wrapper.third-party-login-wrapper-active {
    grid-template-rows: 1fr;
  }

  /*// Third party login*/
  .third-party-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: hidden;
  }

  .third-party-login > .divider {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .third-party-login > .divider > .divider-line {
    flex: 1;
    height: 1px;
    background-color: theme('colors.gray[400]');
  }

  .third-party-login > .divider > .divider-text {
    margin: 0 10px;
    color: theme('colors.gray[400]');
    font-size: 1rem;
    font-weight: 500;
  }

  .third-party-login .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 0px;
    padding-top: 10px;
    overflow: hidden;
  }

  .third-party-login .third-party-button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    cursor: pointer;
  }

  .third-party-login .third-party-button-logo {
    display: grid;
    place-items: center;
  }

  .back-button {
    cursor: pointer;
  }

  /* Password helper grid animation*/
  .password-helper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 300ms;
  }

  .password-helper.active {
    grid-template-rows: 1fr;
  }

  .password-helper > div {
    overflow: hidden;
  }
</style>
