/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AliasIn } from '../models/AliasIn';
import type { AliasModifications } from '../models/AliasModifications';
import type { AliasUpdateIn } from '../models/AliasUpdateIn';
import type { Body_create_user_from_alias_api_v1_management_users__email_or_id__create_user_from_alias_post } from '../models/Body_create_user_from_alias_api_v1_management_users__email_or_id__create_user_from_alias_post';
import type { MergeUserData } from '../models/MergeUserData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserAliasesService {
    /**
     * Add Alias
     * @returns AliasModifications Successful Response
     * @throws ApiError
     */
    public static addAliasApiV1ManagementUsersEmailOrIdAliasPost({
        emailOrId,
        requestBody,
        force = false,
        removeFromOtherUsers = false,
        mergeUsers = false,
        preview = false,
        xTransactionId,
    }: {
        emailOrId: string,
        requestBody: AliasIn,
        /**
         * Add the alias, even if it exists at other users.
         */
        force?: boolean,
        /**
         * Remove the alias from other users and reassign licences with the alias.
         */
        removeFromOtherUsers?: boolean,
        /**
         * If the alias is an existing user, the users will be merged similar to the `merge-users` endpoint
         */
        mergeUsers?: boolean,
        /**
         * Does not perform database operation and shows, which records would be modified
         */
        preview?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<AliasModifications> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/users/{email_or_id}/alias',
            path: {
                'email_or_id': emailOrId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'force': force,
                'remove_from_other_users': removeFromOtherUsers,
                'merge_users': mergeUsers,
                'preview': preview,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Alias
     * @returns AliasModifications Successful Response
     * @throws ApiError
     */
    public static patchAliasApiV1ManagementUsersEmailOrIdAliasUserNamePatch({
        userName,
        emailOrId,
        requestBody,
        preview = false,
        xTransactionId,
    }: {
        userName: string,
        emailOrId: string,
        requestBody: AliasUpdateIn,
        /**
         * Does not perform database operation and shows, which records would be modified
         */
        preview?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<AliasModifications> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/management/users/{email_or_id}/alias/{user_name}',
            path: {
                'user_name': userName,
                'email_or_id': emailOrId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'preview': preview,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Alias
     * @returns AliasModifications Successful Response
     * @throws ApiError
     */
    public static removeAliasApiV1ManagementUsersEmailOrIdAliasUserNameDelete({
        userName,
        emailOrId,
        unassignLicences = false,
        preview = false,
        xTransactionId,
    }: {
        userName: string,
        emailOrId: string,
        /**
         * Remove licences assignments of the user and the alias.
         */
        unassignLicences?: boolean,
        /**
         * Does not perform database operation and shows, which records would be modified
         */
        preview?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<AliasModifications> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/management/users/{email_or_id}/alias/{user_name}',
            path: {
                'user_name': userName,
                'email_or_id': emailOrId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'unassign_licences': unassignLicences,
                'preview': preview,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Merge Users
     * @returns AliasModifications Successful Response
     * @throws ApiError
     */
    public static mergeUsersApiV1ManagementUsersEmailOrIdMergeUsersPost({
        emailOrId,
        requestBody,
        preview = false,
        xTransactionId,
    }: {
        emailOrId: string,
        requestBody: MergeUserData,
        /**
         * Does not perform database operation and shows, which records would be modified
         */
        preview?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<AliasModifications> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/users/{email_or_id}/merge-users',
            path: {
                'email_or_id': emailOrId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'preview': preview,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create User From Alias
     * @returns AliasModifications Created User
     * @throws ApiError
     */
    public static createUserFromAliasApiV1ManagementUsersEmailOrIdCreateUserFromAliasPost({
        emailOrId,
        requestBody,
        preview = false,
        xTransactionId,
    }: {
        emailOrId: string,
        requestBody: Body_create_user_from_alias_api_v1_management_users__email_or_id__create_user_from_alias_post,
        /**
         * Does not perform database operation and shows, which records would be modified
         */
        preview?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<AliasModifications> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/users/{email_or_id}/create-user-from-alias',
            path: {
                'email_or_id': emailOrId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'preview': preview,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
