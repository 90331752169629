/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Currency } from '../models/Currency';
import type { CurrencyCode } from '../models/CurrencyCode';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CurrencyService {
    /**
     * Get Currencies
     * @returns Currency Successful Response
     * @throws ApiError
     */
    public static getCurrenciesCurrenciesGet({
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        symbolExists,
        symbolEq,
        symbolGt,
        symbolGte,
        symbolIn,
        symbolLt,
        symbolLte,
        symbolNe,
        symbolNin,
        symbol,
        symbolNativeExists,
        symbolNativeEq,
        symbolNativeGt,
        symbolNativeGte,
        symbolNativeIn,
        symbolNativeLt,
        symbolNativeLte,
        symbolNativeNe,
        symbolNativeNin,
        symbolNative,
        decimalDigitsExists,
        decimalDigitsEq,
        decimalDigitsGt,
        decimalDigitsGte,
        decimalDigitsIn,
        decimalDigitsLt,
        decimalDigitsLte,
        decimalDigitsNe,
        decimalDigitsNin,
        decimalDigits,
        roundingExists,
        roundingEq,
        roundingGt,
        roundingGte,
        roundingIn,
        roundingLt,
        roundingLte,
        roundingNe,
        roundingNin,
        rounding,
        exchangeRateExists,
        exchangeRateEq,
        exchangeRateGt,
        exchangeRateGte,
        exchangeRateIn,
        exchangeRateLt,
        exchangeRateLte,
        exchangeRateNe,
        exchangeRateNin,
        exchangeRate,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        xTransactionId,
    }: {
        idExists?: (boolean | null),
        idEq?: (CurrencyCode | null),
        idGt?: (CurrencyCode | null),
        idGte?: (CurrencyCode | null),
        idIn?: (Array<CurrencyCode> | null),
        idLt?: (CurrencyCode | null),
        idLte?: (CurrencyCode | null),
        idNe?: (CurrencyCode | null),
        idNin?: (Array<CurrencyCode> | null),
        id?: (CurrencyCode | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        symbolExists?: (boolean | null),
        symbolEq?: (string | null),
        symbolGt?: (string | null),
        symbolGte?: (string | null),
        symbolIn?: (Array<string> | null),
        symbolLt?: (string | null),
        symbolLte?: (string | null),
        symbolNe?: (string | null),
        symbolNin?: (Array<string> | null),
        symbol?: (string | null),
        symbolNativeExists?: (boolean | null),
        symbolNativeEq?: (string | null),
        symbolNativeGt?: (string | null),
        symbolNativeGte?: (string | null),
        symbolNativeIn?: (Array<string> | null),
        symbolNativeLt?: (string | null),
        symbolNativeLte?: (string | null),
        symbolNativeNe?: (string | null),
        symbolNativeNin?: (Array<string> | null),
        symbolNative?: (string | null),
        decimalDigitsExists?: (boolean | null),
        decimalDigitsEq?: (number | null),
        decimalDigitsGt?: (number | null),
        decimalDigitsGte?: (number | null),
        decimalDigitsIn?: (Array<number> | null),
        decimalDigitsLt?: (number | null),
        decimalDigitsLte?: (number | null),
        decimalDigitsNe?: (number | null),
        decimalDigitsNin?: (Array<number> | null),
        decimalDigits?: (number | null),
        roundingExists?: (boolean | null),
        roundingEq?: (number | null),
        roundingGt?: (number | null),
        roundingGte?: (number | null),
        roundingIn?: (Array<number> | null),
        roundingLt?: (number | null),
        roundingLte?: (number | null),
        roundingNe?: (number | null),
        roundingNin?: (Array<number> | null),
        rounding?: (number | null),
        exchangeRateExists?: (boolean | null),
        exchangeRateEq?: (number | null),
        exchangeRateGt?: (number | null),
        exchangeRateGte?: (number | null),
        exchangeRateIn?: (Array<number> | null),
        exchangeRateLt?: (number | null),
        exchangeRateLte?: (number | null),
        exchangeRateNe?: (number | null),
        exchangeRateNin?: (Array<number> | null),
        exchangeRate?: (number | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<Currency>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/currencies',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'symbol.$exists': symbolExists,
                'symbol.$eq': symbolEq,
                'symbol.$gt': symbolGt,
                'symbol.$gte': symbolGte,
                'symbol.$in': symbolIn,
                'symbol.$lt': symbolLt,
                'symbol.$lte': symbolLte,
                'symbol.$ne': symbolNe,
                'symbol.$nin': symbolNin,
                'symbol': symbol,
                'symbol_native.$exists': symbolNativeExists,
                'symbol_native.$eq': symbolNativeEq,
                'symbol_native.$gt': symbolNativeGt,
                'symbol_native.$gte': symbolNativeGte,
                'symbol_native.$in': symbolNativeIn,
                'symbol_native.$lt': symbolNativeLt,
                'symbol_native.$lte': symbolNativeLte,
                'symbol_native.$ne': symbolNativeNe,
                'symbol_native.$nin': symbolNativeNin,
                'symbol_native': symbolNative,
                'decimal_digits.$exists': decimalDigitsExists,
                'decimal_digits.$eq': decimalDigitsEq,
                'decimal_digits.$gt': decimalDigitsGt,
                'decimal_digits.$gte': decimalDigitsGte,
                'decimal_digits.$in': decimalDigitsIn,
                'decimal_digits.$lt': decimalDigitsLt,
                'decimal_digits.$lte': decimalDigitsLte,
                'decimal_digits.$ne': decimalDigitsNe,
                'decimal_digits.$nin': decimalDigitsNin,
                'decimal_digits': decimalDigits,
                'rounding.$exists': roundingExists,
                'rounding.$eq': roundingEq,
                'rounding.$gt': roundingGt,
                'rounding.$gte': roundingGte,
                'rounding.$in': roundingIn,
                'rounding.$lt': roundingLt,
                'rounding.$lte': roundingLte,
                'rounding.$ne': roundingNe,
                'rounding.$nin': roundingNin,
                'rounding': rounding,
                'exchange_rate.$exists': exchangeRateExists,
                'exchange_rate.$eq': exchangeRateEq,
                'exchange_rate.$gt': exchangeRateGt,
                'exchange_rate.$gte': exchangeRateGte,
                'exchange_rate.$in': exchangeRateIn,
                'exchange_rate.$lt': exchangeRateLt,
                'exchange_rate.$lte': exchangeRateLte,
                'exchange_rate.$ne': exchangeRateNe,
                'exchange_rate.$nin': exchangeRateNin,
                'exchange_rate': exchangeRate,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Currency By Code
     * @returns Currency Successful Response
     * @throws ApiError
     */
    public static getCurrencyByCodeCurrenciesCurrencyCodeGet({
        currencyCode,
        xTransactionId,
    }: {
        currencyCode: CurrencyCode,
        xTransactionId?: (string | null),
    }): CancelablePromise<Currency> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/currencies/{currency_code}',
            path: {
                'currency_code': currencyCode,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
