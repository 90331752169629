/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobExecutionBase_Literal_software_user_add__BackgroundJobSoftwareUserAddExecutionData_ } from './BackgroundJobExecutionBase_Literal_software_user_add__BackgroundJobSoftwareUserAddExecutionData_';
import type { BackgroundJobSoftwareUserAddData } from './BackgroundJobSoftwareUserAddData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
export type BackgroundJobSoftwareUserAdd = {
    created: string;
    updated: string;
    deleted: boolean;
    deleted_at: (string | null);
    _id: string;
    type: BackgroundJobSoftwareUserAdd.type;
    status: BackgroundJobStatus;
    executions: Array<BackgroundJobExecutionBase_Literal_software_user_add__BackgroundJobSoftwareUserAddExecutionData_>;
    data: BackgroundJobSoftwareUserAddData;
    last_executed_at: (string | null);
    next_execution_at: (string | null);
    execution_token: string;
    created_by_job_id: (string | null);
    created_by_identity_id: (string | null);
};
export namespace BackgroundJobSoftwareUserAdd {
    export enum type {
        SOFTWARE_USER_ADD = 'software_user_add',
    }
}

