<!--
Dashboard
-->
<template>
  <div class="home">
    <!-- Header -->
    <ViewHeader>
      <template #title>
        <Transition name="fade">
          <div v-if="sessionStore.currentUser" class="text-md text-left">
            {{
              i18n.t('views.dashboard.header.title', {
                name: getName(sessionStore.currentUser),
              })
            }}
          </div>
        </Transition>
        <h2>
          {{ i18n.t('views.dashboard.header.subTitle') }}
        </h2>
      </template>
    </ViewHeader>

    <div class="dashboard-grid">
      <!-- StatsCard -->
      <el-card
        shadow="never"
        class="small-stats-card dashboard-card"
        :body-style="{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          justifyContent: 'space-between',
          padding: '5px 30px',
          alignItems: 'center',
        }">
        <StatsElement
          v-for="s in statsCardData"
          :key="s.i18nKey"
          :display-i18n-key="s.i18nKey"
          :display-number="s.displayNumber"
          :is-price="s.isPrice"
          clickable
          :color="s.color"
          :icon="s.icon"
          @click="s.callback" />
      </el-card>
      <div class="line-chart-card dashboard-card">
        <InformationChartCard />
      </div>
      <ActivityCard class="last-activity-card dashboard-card" />
      <OptimizationsCard
        class="optimizations-card dashboard-card"
        :card-text-style="{
          color: 'var(--el-text-color-secondary)',
          fontWeight: 600,
        }" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import ActivityCard from '@/components/ActivityCard.vue'
  import OptimizationsCard from '@/components/BaseOptimizationsCard/BaseOptimizationsCard.vue'
  import ViewHeader from '@/components/ViewHeader.vue'
  import { useSessionStore } from '@/stores/sessionStore'
  import { DashboardService, SoftwareCostService, ResourceCounts, UserProfile } from '@/client'

  import { useRouter } from 'vue-router'

  type Stat = {
    i18nKey: string
    icon: string
    displayNumber: number | string | undefined
    color: string
    isPrice: boolean
    callback: () => unknown
  }

  const i18n = useI18n()
  const sessionStore = useSessionStore()
  const router = useRouter()

  const countStats = ref<ResourceCounts>()
  const costs = ref<number>()

  const statsCardData = computed<Stat[]>(() => {
    return [
      {
        i18nKey: 'managedUsers',
        icon: 'md-person-round',
        displayNumber: countStats.value?.accounts,
        isPrice: false,
        color: 'yellow',
        callback: () => router.push({ path: '/users' }),
      },
      {
        i18nKey: 'managedLicenses',
        icon: 'md-description-round',
        displayNumber: countStats.value?.licences,
        isPrice: false,
        color: 'red',
        callback: () => router.push({ path: '/licences' }),
      },
      {
        i18nKey: 'applications',
        icon: 'md-gridview-round',
        displayNumber: countStats.value?.software,
        isPrice: false,
        color: 'purple',
        callback: () => router.push({ path: '/applications' }),
      },
      {
        i18nKey: 'yearlySaasSpends',
        icon: 'md-person-round',
        displayNumber: (costs.value || 0) * 12,
        isPrice: true,
        color: 'primary',
        callback: () => router.push({ path: '/applications' }),
      },
    ]
  })

  function getName(user: UserProfile) {
    return user.first_name || user.display_name
  }

  async function fetchStats() {
    countStats.value =
      await DashboardService.getResourceCountsApiV1DashboardResourceCountsGet(
        {}
      )
    const c = await SoftwareCostService.getCostsApiV1SoftwareCostGet({})
    costs.value = c.total
  }

  fetchStats()
</script>

<style scoped>
  /* Header */
  .welcome-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1.2fr;
    grid-template-rows: repeat(8, minmax(70px, 1fr));
    gap: 20px;
    grid-template-areas:
      'small-stats-card small-stats-card small-stats-card small-stats-card'
      'line-chart-card line-chart-card line-chart-card last-activity-card'
      'line-chart-card line-chart-card line-chart-card last-activity-card'
      'line-chart-card line-chart-card line-chart-card last-activity-card'
      'line-chart-card line-chart-card line-chart-card last-activity-card'
      'optimizations-card optimizations-card optimizations-card last-activity-card'
      'optimizations-card optimizations-card optimizations-card last-activity-card'
      'optimizations-card optimizations-card optimizations-card last-activity-card';
  }

  .small-stats-card {
    grid-area: small-stats-card;
    height: 100%;
  }

  .line-chart-card {
    grid-area: line-chart-card;
  }

  .savings-card {
    grid-area: savings-card;
  }

  .last-activity-card {
    grid-area: last-activity-card;
    height: 100%;
  }

  .optimizations-card {
    grid-area: optimizations-card;
    height: 100%;
  }
</style>

<style lang="scss">
  .dashboard-grid {
    .dashboard-card.el-card {
      border-radius: var(--border-radius) !important;
      -webkit-box-shadow: var(--shadow);
      box-shadow: var(--shadow);
      box-sizing: content-box;
    }
  }
</style>
