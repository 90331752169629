/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableSubAccount } from '../models/AvailableSubAccount';
import type { SubAccount_Output } from '../models/SubAccount_Output';
import type { SubAccountUpdateIn } from '../models/SubAccountUpdateIn';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SoftwareSubaccountsService {
    /**
     * Get Available Sub Accounts
     * @returns AvailableSubAccount Successful Response
     * @throws ApiError
     */
    public static getAvailableSubAccountsApiV1SoftwareSoftwareSoftwareIdAvailableSubAccountsGet({
        softwareId,
        xTransactionId,
    }: {
        softwareId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<AvailableSubAccount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}/available-sub-accounts',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Sub Accounts
     * @returns SubAccount_Output Successful Response
     * @throws ApiError
     */
    public static getSubAccountsApiV1SoftwareSoftwareSoftwareIdSubAccountsGet({
        softwareId,
        cached = true,
        xTransactionId,
    }: {
        softwareId: string,
        cached?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<SubAccount_Output>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}/sub_accounts',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'cached': cached,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Sub Account
     * @returns SubAccount_Output Successful Response
     * @throws ApiError
     */
    public static getSubAccountApiV1SoftwareSoftwareSoftwareIdSubAccountsSubAccountIdGet({
        subAccountId,
        softwareId,
        cached = true,
        xTransactionId,
    }: {
        subAccountId: string,
        softwareId: string,
        cached?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<SubAccount_Output> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}/sub_accounts/{sub_account_id}',
            path: {
                'sub_account_id': subAccountId,
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'cached': cached,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Sub Account
     * @returns SubAccount_Output Successful Response
     * @throws ApiError
     */
    public static updateSubAccountApiV1SoftwareSoftwareSoftwareIdSubAccountsSubAccountIdPatch({
        softwareId,
        subAccountId,
        requestBody,
        cached = true,
        xTransactionId,
    }: {
        softwareId: string,
        subAccountId: string,
        requestBody: SubAccountUpdateIn,
        cached?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<SubAccount_Output> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/software/software/{software_id}/sub_accounts/{sub_account_id}',
            path: {
                'software_id': softwareId,
                'sub_account_id': subAccountId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'cached': cached,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
