/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SoftwareAuthorizationOutError = {
    type: SoftwareAuthorizationOutError.type;
    error_code: string;
    error_message: string;
};
export namespace SoftwareAuthorizationOutError {
    export enum type {
        ERROR = 'error',
    }
}

