<template>
  <SmDialog v-model:visibility="visibility" :title="i18n.t('deleteUser')">
    <UserCardSmall :user="props.user" />
    <div class="spacing" />

    <SmDialogMessage
      :type="message.type"
      :message="message.message"
      :visible="message.visible" />

    <div class="spacing" />

    <h3>{{ i18n.t('licenses') }}</h3>

    <!-- Spacing -->
    <div class="spacing" />

    <UserDeleteLicenceTable ref="userDeleteLicenseTable" :user="props.user" />

    <template #footer>
      <span class="dialog-footer">
        <SmButton
          v-if="!deleteUsersLoading && !userDeleted"
          outline
          @click="visibility = false"
          >{{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton
          v-if="!userDeleted"
          type="primary"
          :loading="deleteUsersLoading"
          @click="handleButtonClick"
          >{{
            userDeleteLicenseTable?.allLicensesSelected
              ? i18n.t('deleteUser')
              : i18n.t('deleteLicenses')
          }}</SmButton
        >
        <!-- Close -->

        <SmButton v-if="userDeleted" type="primary" outline @click="closeDialog"
          >{{ i18n.t('general.close') }}
        </SmButton>
      </span>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import { Account } from '@/client'
  import UserDeleteLicenceTable from './UserDeleteLicenceTable.vue'

  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'
  const i18n = useI18n()

  const userDeleteLicenseTable =
    ref<ComponentExposed<typeof UserDeleteLicenceTable>>()

  const visibility = defineModel<boolean>('visibility', {
    default: true,
  })

  const emit = defineEmits(['user-deleted', 'license-deleted'])
  const props = defineProps({
    user: {
      type: Object as PropType<Account>,
      required: true,
    },
  })

  // Deletion
  const deleteUsersLoading = ref(false)
  const userDeleted = ref(false)

  async function handleButtonClick() {
    userDeleteLicenseTable.value?.excecute()
  }

  function closeDialog() {
    emit('user-deleted', props.user)
    emit('license-deleted')

    if (userDeleteLicenseTable.value?.results)
      userDeleteLicenseTable.value.results = {}
    userDeleted.value = false
    visibility.value = false
  }

  // Message
  const message: ComputedRef<{
    type: DialogMessageTypes
    message: string
    visible: boolean
  }> = computed(() => {
    // If actions are executed
    if (userDeleted.value) {
      // If all users are deleted
      if (userDeleteLicenseTable.value?.allLicenseDeletionsSuccessful) {
        return {
          type: 'success',
          message: i18n.t('deletionSuccessful'),
          visible: true,
        }
      } else {
        return {
          type: 'error',
          message: i18n.t('licensesDeletionNotSuccessful'),
          visible: true,
        }
      }
    }

    if (!userDeleteLicenseTable.value?.allLicensesSelected) {
      return {
        type: 'warning',
        message: i18n.t('userDeleteNotAllLicensesDeleted'),
        visible: true,
      }
    } else {
      return {
        type: 'success',
        message: '',
        visible: false,
      }
    }
  })
</script>

<style lang="scss" scoped>
  .spacing {
    height: 0.8rem;
  }
</style>
