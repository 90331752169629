/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CompanyData = {
    _id: CompanyData._id;
    name: (string | null);
    created: string;
    is_admin: boolean;
    /**
     * Whether the user is in trial mode.
     */
    trial: boolean;
    trial_expire_date: (string | null);
    trial_started_date: (string | null);
    trial_duration_in_days: number;
    eligible_for_trial: boolean;
    /**
     * If `true` then the data is demo data an may deleted any time.
     */
    demo: boolean;
    /**
     * If true, the company has an active subscription or paid upfront
     */
    paid: boolean;
};
export namespace CompanyData {
    export enum _id {
        COMPANY = 'company',
    }
}

