<!-- SM Time Input -->
<template>
  <div
    class="sm-time-input flex w-full items-center justify-start rounded border border-gray-300 px-4 py-2 dark:border-gray-600"
    :class="{ 'cursor-not-allowed': props.disabled }">
    <slot name="prepend">
      <v-icon name="md-accesstime" scale="1.1" class="mr-4" />
    </slot>

    <input
      v-model="formattedTime"
      maxlength="5"
      :placeholder="props.placeholder"
      class="w-full"
      :class="{ 'pointer-events-none': props.disabled }"
      inputmode="numeric"
      @keypress="onKeyPress"
      @input="onInput"
      @blur="onBlur" />

    <slot name="append"></slot>
  </div>
</template>

<script setup lang="ts" generic="T">
  import { computed } from 'vue'

  const time = defineModel('time', { type: String, default: '1230' })

  const props = defineProps({
    placeholder: {
      type: String,
      default: 'Uhrzeit wählen',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  })

  watchEffect(() => {
    if (props.disabled) time.value = ''
  })

  const formattedTime = computed({
    get() {
      if (time.value.length <= 2) {
        return time.value // Only show formatted Time until 2 digits are entered
      }
      return `${time.value.slice(0, 2)}:${time.value.slice(2, 4)}`
    },
    set(value) {
      const digits = value.replace(/\D/g, '').slice(0, 4) // Allow only up to 4 digits, remove non-digits
      time.value = digits
    },
  })

  const onInput = (e: Event) => {
    const target = e.target as HTMLInputElement
    let value = target.value.replace(/\D/g, '') // Strictly remove all non-digit characters
    if (value.length > 4) value = value.slice(0, 4)

    const hours = value.slice(0, 2)
    const minutes = value.slice(2, 4)

    if (hours > '23') value = '23' + minutes // Limit hours to 24
    if (minutes > '59') value = hours + '59' // Limit minutes to 60

    time.value = value
  }

  // Prevent typing of anything except digits
  const onKeyPress = (e: KeyboardEvent) => {
    if (props.disabled) e.preventDefault()

    const key = String.fromCharCode(e.keyCode || e.which)
    if (!/[0-9]/.test(key)) {
      e.preventDefault() // Block non-numeric characters
    }
  }

  const onBlur = () => {
    if (time.value.length < 4) {
      time.value = time.value.padEnd(4, '0') // Fill missing digits with zeros
    }
  }
</script>

<style lang="scss"></style>
