/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_cancel_subscription_api_v1_billing_subscription_cancel_post } from '../models/Body_cancel_subscription_api_v1_billing_subscription_cancel_post';
import type { Body_create_subscription_api_v1_billing_subscription_create_subscription_post } from '../models/Body_create_subscription_api_v1_billing_subscription_create_subscription_post';
import type { Body_update_subscription_api_v1_billing_subscription_update_post } from '../models/Body_update_subscription_api_v1_billing_subscription_update_post';
import type { CreateSubscriptionResponse } from '../models/CreateSubscriptionResponse';
import type { Invoice } from '../models/Invoice';
import type { SetupIntentResponse } from '../models/SetupIntentResponse';
import type { SubscriptionInfoOut } from '../models/SubscriptionInfoOut';
import type { SubscriptionProduct } from '../models/SubscriptionProduct';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BillingService {
    /**
     * Webhook
     * @returns any Successful Response
     * @throws ApiError
     */
    public static webhookApiV1BillingWebhookPost({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/billing/webhook',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Invoices
     * Get all invoices for the current company.
     * @returns Invoice Successful Response
     * @throws ApiError
     */
    public static getInvoicesApiV1BillingInvoicesGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<Invoice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/billing/invoices',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Upcoming Invoice
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getUpcomingInvoiceApiV1BillingInvoicesUpcomingGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<(Invoice | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/billing/invoices/upcoming',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Subscription
     * @returns SubscriptionInfoOut Successful Response
     * @throws ApiError
     */
    public static getSubscriptionApiV1BillingSubscriptionInfoGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<SubscriptionInfoOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/billing/subscription/info',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Available Subscription
     * @returns SubscriptionProduct Successful Response
     * @throws ApiError
     */
    public static getAvailableSubscriptionApiV1BillingSubscriptionAvailableGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<SubscriptionProduct>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/billing/subscription/available',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Subscription
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateSubscriptionApiV1BillingSubscriptionUpdatePost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: Body_update_subscription_api_v1_billing_subscription_update_post,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/billing/subscription/update',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Cancel Subscription
     * If a subscription is active or in trial, sets a flag such that the subscription will not continue after the current period
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cancelSubscriptionApiV1BillingSubscriptionCancelPost({
        xTransactionId,
        requestBody,
    }: {
        xTransactionId?: (string | null),
        requestBody?: Body_cancel_subscription_api_v1_billing_subscription_cancel_post,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/billing/subscription/cancel',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Continue Subscription
     * If a subscription is flagged to be canceled, resets the flag such that the subscription will continue
     * @returns any Successful Response
     * @throws ApiError
     */
    public static continueSubscriptionApiV1BillingSubscriptionContinuePost({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/billing/subscription/continue',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Setup Intent
     * @returns SetupIntentResponse Successful Response
     * @throws ApiError
     */
    public static getSetupIntentApiV1BillingSubscriptionSetupIntentGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<SetupIntentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/billing/subscription/setup-intent',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Subscription
     * @returns CreateSubscriptionResponse Successful Response
     * @throws ApiError
     */
    public static createSubscriptionApiV1BillingSubscriptionCreateSubscriptionPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: Body_create_subscription_api_v1_billing_subscription_create_subscription_post,
        xTransactionId?: (string | null),
    }): CancelablePromise<CreateSubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/billing/subscription/create-subscription',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
