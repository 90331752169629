<template>
  <div ref="dropdownRef">
    <div
      ref="referenceRef"
      :class="visible ? 'active' : 'inactive'"
      class="trigger"
      @click="toggle">
      <slot name="trigger">
        <v-icon name="md-morevert-round" scale="1.2" />
      </slot>
    </div>

    <teleport to="#dropdowns">
      <transition name="fade-slide-down" appear>
        <div
          v-if="visible"
          ref="floatingRef"
          :style="{ zIndex: zIndex }"
          :class="['dropdown', props.dropdownClass, props.type]"
          @click="handleClickInside">
          <div
            :style="{
              maxHeight: props.maxHeight,
              'overflow-y': 'auto',
              'overflow-x': 'hidden',
            }">
            <slot> </slot>
          </div>

          <div v-if="props.type === 'bubble'" ref="arrowRef" class="arrow" />
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script setup lang="ts">
  import {
    arrow,
    computePosition,
    flip,
    offset,
    Placement,
    shift,
  } from '@floating-ui/dom'
  import { onClickOutside, useElementHover } from '@vueuse/core'
  import { nanoid } from 'nanoid'
  import { openDropdowns } from './SmDropdownState'
  import { getZindex } from '@/common/util/zIndex'

  const referenceRef = ref<HTMLElement>()
  const floatingRef = ref<HTMLElement>()
  const arrowRef = ref<HTMLElement>()
  const dropdownRef = ref<HTMLElement>()

  const id = nanoid()

  const visible = ref(false)
  let zIndex = 2000 // random value, will be overwritten in the watch method below

  const props = defineProps({
    placement: {
      type: String as PropType<Placement>,
      default: 'bottom',
    },
    trigger: {
      type: String as PropType<'click' | 'hover'>,
      default: 'click',
    },
    closeOnClickInside: {
      type: Boolean,
      default: true,
    },
    maxHeight: {
      type: String,
      default: 'none',
    },
    dropdownClass: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'bubble' | 'panel'>,
      default: 'bubble',
    },
  })

  if (props.trigger === 'hover') {
    const referenceHovered = useElementHover(referenceRef)
    const floatingHovered = useElementHover(floatingRef)
    const arrowHovered = useElementHover(arrowRef)

    const anyHovered = computed(() => {
      return (
        referenceHovered.value || floatingHovered.value || arrowHovered.value
      )
    })

    // Open if reference is hovered
    watch(referenceHovered, () => {
      if (referenceHovered.value) {
        setTimeout(() => {
          if (referenceHovered.value) {
            visible.value = true
            setTimeout(() => {
              calculatePosition()
            }, 1)
          }
        }, 100)
      }
    })

    // Close if nothing is hovered and after 100ms delay if still nothing is hovered
    watch(anyHovered, () => {
      if (!anyHovered.value) {
        setTimeout(() => {
          if (!anyHovered.value) {
            visible.value = false
          }
        }, 200)
      }
    })
  }

  onClickOutside(
    floatingRef,
    () => {
      // Only close if last opened dropdown
      if (openDropdowns[openDropdowns.length - 1] === id) {
        if (props.trigger === 'click') {
          openDropdowns.pop()
          visible.value = false
        }
      }
    },
    { ignore: [referenceRef] }
  )

  function handleClickInside() {
    if (props.trigger === 'click' && props.closeOnClickInside) {
      openDropdowns.pop()
      visible.value = false
    }
  }

  onMounted(async () => {
    calculatePosition()
  })

  function toggle() {
    if (props.trigger === 'click') {
      visible.value = !visible.value
      setTimeout(() => {
        calculatePosition()
      }, 1)
    }
  }

  function setVisibility(value: boolean) {
    visible.value = value
  }

  async function calculatePosition() {
    if (!referenceRef.value || !floatingRef.value) return
    // if (!arrowRef.value) return

    const middleware = [
      offset(8), // Offset the floating element by 8px from the reference element
      flip(), // Flip the floating element if it would be cut off
      shift({ padding: 20 }), // Space around the floating element
    ]

    if (props.type === 'bubble' && arrowRef.value) {
      middleware.push(arrow({ element: arrowRef.value })) // Add an arrow to the floating element
    }

    if (props.type === 'panel') {
      const referenceWidth = referenceRef.value.offsetWidth
      floatingRef.value.style.minWidth = `${referenceWidth}px`
    }

    const { x, y, middlewareData, placement } = await computePosition(
      referenceRef.value,
      floatingRef.value,
      {
        placement: props.placement,
        middleware: middleware,
      }
    )

    // Set the position of the floating element
    Object.assign(floatingRef.value.style, {
      left: `${x}px`,
      top: `${y}px`,
    })

    // Set the position of the arrow
    const arrowY = middlewareData.arrow?.y
    const arrowX = middlewareData.arrow?.x

    const oppositePlacement = {
      top: 'bottom',
      bottom: 'top',
      left: 'right',
      right: 'left',
    }[placement.split('-')[0]] as 'top' | 'bottom' | 'left' | 'right'

    if (props.type === 'bubble' && arrowRef.value) {
      // Set the base position of the arrow
      const arrowStyle = {
        left: `${arrowX}px`,
        top: `${arrowY}px`,
        [oppositePlacement]: '-4px',
      }

      // Apply rotation if the arrow is placed at the bottom
      if (oppositePlacement === 'bottom') {
        arrowStyle.transform = 'rotate(225deg)'
      }

      Object.assign(arrowRef.value.style, arrowStyle)
    }
  }

  watch(visible, () => {
    if (visible.value) {
      zIndex = getZindex()
      openDropdowns.push(id)
    }
  })

  defineExpose({
    setVisibility,
    visible,
  })
</script>

<style lang="scss" scoped>
  :deep(svg) {
    cursor: pointer;
  }
</style>

<style lang="scss">
  $background-color: var(--sm-elevation);
  $color: var(--sm-contrast);

  .dropdown {
    position: absolute;
    z-index: 10000;
    background-color: $background-color;
    color: $color;
    border-radius: var(--border-radius);
    border: 1px solid rgba(167, 167, 167, 0.56);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    min-width: 100px;
    max-width: 300px;
    transition:
      opacity 0.2s ease,
      transform 0.2s ease;
    pointer-events: all;

    // Color of the icons
    fill: $color;

    &.panel {
      max-width: unset;
      border-radius: var(--border-radius-base);
    }
  }

  .arrow {
    border-top: 1px solid rgba(167, 167, 167, 0.56);
    border-left: 1px solid rgba(167, 167, 167, 0.56);

    position: absolute;
    height: 8px;
    width: 8px;

    background-color: $background-color;

    transform: rotate(45deg);
    pointer-events: none;

    border-radius: 2px;
  }
</style>
