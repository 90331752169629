<!-- Button Component -->
<template>
  <button
    type="button"
    :class="[
      'sm-button',
      type,
      `sm-button-${size}`,
      { 'sm-button-loading': loading },
      { 'sm-button-shadow': shadow },
      { 'sm-button-outline': outline },
    ]"
    :disabled="disabled || loading"
    @click="click">
    <!-- Loading State -->
    <Transition name="slide-up" mode="out-in">
      <div v-if="loading" class="sm-button-loading-wrapper">
        <div class="sm-button-loading-icon" />
      </div>
      <div v-else class="sm-button-content">
        <slot>
          <!-- Content -->
          {{ text }}
        </slot>
      </div>
    </Transition>
  </button>
</template>

<script setup lang="ts">
  const emit = defineEmits(['click'])

  type ButtonType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning'
  type ButtonSize = 'smaller' | 'small' | 'medium' | 'large' | 'xlarge'

  const props = defineProps({
    type: {
      type: String as PropType<ButtonType>,
      default: 'primary',
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: 'medium',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    prevent: {
      type: Boolean,
      default: false,
    },
  })

  const background = computed(() => {
    switch (props.type) {
      case 'primary':
        return 'var(--sm-cta)'
      case 'secondary':
        return 'var(--sm-primary)'
      case 'success':
        return 'var(--sm-primary)'
      case 'danger':
        return 'var(--sm-magenta)'
      case 'warning':
        return 'var(--sm-orange)'
      default:
        return 'var(--sm-primary)'
    }
  })

  const color = computed(() => {
    switch (props.type) {
      case 'primary':
        return 'var(--sm-white)'
      case 'secondary':
        return 'var(--sm-black)'
      case 'success':
        return 'var(--sm-white)'
      case 'danger':
        return 'var(--sm-white)'
      case 'warning':
        return 'var(--sm-white)'
      default:
        return 'var(--sm-white)'
    }
  })

  const click = (event: Event) => {
    if (props.prevent) {
      event.preventDefault()
    }
    emit('click')
  }
</script>

<style lang="scss">
  .sm-button {
    border: none;
    background-color: v-bind(background);
    color: v-bind(color);

    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    text-align: center;
    transition: all 0.15s ease;
    white-space: nowrap;

    &:hover {
      box-shadow: 0 10px 20px -10px rgb(128, 128, 128);
      transform: translateY(-2px);

      .ov-icon {
        fill: v-bind(color);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;

      &:hover {
        box-shadow: var(--shadow);
        transform: translateY(0);
      }
    }

    &-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // Outline
    &-outline {
      background-color: transparent;
      border: 1px solid v-bind(background);
      color: var(--sm-contrast);
      box-shadow: none;

      &:hover {
        background-color: v-bind(background);
        color: v-bind(color);
        fill: white;
      }
    }

    // Sizes
    &-smaller {
      font-size: 0.8rem;
      height: 1.8rem;
      line-height: 22px;
    }

    &-small {
      font-size: 1rem;
      height: 2.3rem;
      line-height: 28px;
      display: flex;
    }

    &-medium {
      font-size: 1rem;
      height: 32px;
      line-height: 32px;
    }

    &-large {
      font-size: 1rem;
      font-weight: 600;
      height: 45px;
      line-height: 40px;
      width: 100%;
    }

    &-xlarge {
      font-size: 1.5rem;
      height: 48px;
      line-height: 48px;
    }

    &-loading {
      cursor: pointer !important;
    }

    // Loading State
    &-loading-icon {
      animation: loading 1s infinite;
      border: 2px solid v-bind(color);
      border-radius: 50%;
      border-top-color: transparent;
      border-right-color: transparent;
      height: 16px;
      width: 16px;
    }

    &-loading-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
    }

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    // Space between buttons
    & + & {
      margin-left: 8px;
    }
  }
</style>
