<template>
  <!-- settings-container defined in parent -->
  <div class="settings-container">
    <div class="float-left">
      <h3 style="margin: 0">{{ i18n.t('views.settings.account.header') }}</h3>
    </div>
    <!-- Email, Name, Telefonnummer -->
    <div>
      <el-form
        v-loading="loading"
        label-width="170px"
        label-position="left"
        require-asterisk-position="right"
        @submit.prevent>
        <el-form-item>
          <SmDialogMessage
            :message="smMessage.message"
            :visible="smMessage.visible"
            :type="smMessage.type" />
        </el-form-item>
        <el-form-item
          :label="i18n.t('views.settings.account.form.name')"
          prop="name">
          <el-input v-model="currentUser.display_name" />
        </el-form-item>
        <el-form-item
          :label="i18n.t('views.settings.account.form.email')"
          prop="email">
          <el-input v-model="currentUser.email" disabled />
        </el-form-item>

        <el-form-item>
          <div class="form-buttons-wrapper">
            <SmButton :loading="requestLoading" @click="uploadUserData">
              {{ i18n.t('views.settings.account.form.saveButton') }}
            </SmButton>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { UserProfileService } from '@/client'
  import { getApiErrorMessage } from '@/common/util/apiError'
  import { getCsrfToken, getErrorMessage } from '@/common/util/oryUtil'
  import { turnFullnameIntoFirstAndLastName } from '@/common/util/userName'
  import { useSessionStore } from '@/stores/sessionStore'

  const sessionStore = useSessionStore()
  const i18n = useI18n()
  const loading = ref(true)
  const requestLoading = ref(false)

  const smMessage: Ref<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }> = ref({ message: '', type: 'success', visible: false })

  type OryFormData = {
    email: string
    name: { first: string; last: string }
  }

  let currentUser = unref(sessionStore.currentUser) || {
    email: '',
    display_name: '',
  }

  const initialDisplayName = new String(currentUser.display_name).toString()

  if (sessionStore.currentUser) loading.value = false

  async function uploadUserData() {
    smMessage.value.visible = false
    if (!currentUser) return

    const displayName = currentUser.display_name
    const transformedName = turnFullnameIntoFirstAndLastName(displayName)
    const firstName = transformedName.firstname
    const lastName = transformedName.lastname

    const oryRequestData: OryFormData = {
      email: currentUser.email,
      name: { first: firstName, last: lastName },
    }

    requestLoading.value = true

    const updateFlow = await sessionStore.frontend.createBrowserSettingsFlow()
    const updateFlowID = updateFlow.data.id

    const csrfToken = getCsrfToken(updateFlow.data.ui.nodes)

    const sendSuccess = () => {
      smMessage.value.message = i18n.t('successfullySaved')
      smMessage.value.type = 'success'
      smMessage.value.visible = true
    }
    const sendError = (message: string) => {
      smMessage.value.message = message
      smMessage.value.type = 'error'
      smMessage.value.visible = true
    }

    const updateOryProfile = (data: OryFormData) => {
      return sessionStore.frontend.updateSettingsFlow({
        flow: updateFlowID,
        updateSettingsFlowBody: {
          csrf_token: csrfToken,
          method: 'profile',
          traits: data,
        },
      })
    }

    // first update the ory profile, and then update the backend profile
    // if ory update succeeds, but backend update fails, we revert the ory changes
    updateOryProfile(oryRequestData)
      .then(() => {
        UserProfileService.updateUserProfileUserPatch({
          requestBody: {
            first_name: firstName,
            last_name: lastName,
            display_name: displayName,
          },
        }).then(
          () => {
            sendSuccess()
          },
          (error) => {
            sendError(
              getApiErrorMessage(error, i18n as ReturnType<typeof useI18n>)
            )
            // if the backend request fails, we need to revert the changes in the ory profile
            const initialNameTransformed =
              turnFullnameIntoFirstAndLastName(initialDisplayName)
            updateOryProfile({
              email: currentUser.email,
              name: {
                first: initialNameTransformed.firstname,
                last: initialNameTransformed.lastname,
              },
            })
          }
        )
      })
      .catch((error) => {
        sendError(
          getErrorMessage(error.data, i18n as ReturnType<typeof useI18n>)
        )
      })
      .finally(() => {
        requestLoading.value = false
      })
  }

  watch(
    () => sessionStore.currentUser,
    () => {
      if (sessionStore.currentUser) {
        currentUser = unref(sessionStore.currentUser)
        loading.value = false
      }
    },
    { immediate: true }
  )
</script>

<style scoped>
  .float-left {
    float: left;
  }

  .delete-button-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }

  .form-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
</style>
