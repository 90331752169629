<template>
  <TasksJobEditDialog
    v-model:visible="tasksJobEditDialogVisible"
    v-model:job-id="tasksJobToEdit" />

  <TasksJobScheduleDialog
    v-model:visible="tasksJobScheduleDialogVisible"
    v-model:job-id="tasksJobToSchedule" />

  <DeleteConfirmDialog
    v-model:visible="tasksJobAbortDialogVisible"
    :title="i18n.t('abortJob')"
    :sub-title="i18n.t('abortJobConfirmation')"
    :loading="abortLoading"
    :delete-btn-label="abortBtnLabel"
    @cancel="tasksJobAbortDialogVisible = false"
    @delete="abortBtnFn()">
    <SmDialogMessage
      :message="abortMsg"
      :visible="showAbortResponse"
      :type="abortType"
      class="mb-4" />
    <span>{{ i18n.t('abortJobConfirmation') }}</span>
  </DeleteConfirmDialog>

  <TasksJobTableHeader
    @create-job="openCreateJobDialog()"
    @update-filter="(newFilters) => updateFilter(newFilters)" />

  <SmTable
    :data="backgroundJobs"
    :columns="columns"
    key-field="_id"
    class="!h-auto overflow-hidden"
    @scroll-end-reached="loadMoreBackgroundjobs">
    <template #message="{ row }">
      <!-- todo: map types to data -->
      <component
        :is="getTypeComponent(row.type)"
        v-if="getTypeComponent(row.type)"
        :data="row.data"
        class="py-5" />
    </template>

    <template #status="{ row }">
      <SmTooltip :content="getStatus(row.status).text" :active="true">
        <div class="flex items-center gap-2">
          <span
            class="indicator-dot"
            :class="getStatus(row.status).color"></span>
          {{ getStatus(row.status).text }}
        </div>
      </SmTooltip>
    </template>

    <template #creator="{ row }">
      <div class="flex items-center gap-4">
        <SmAvatar
          v-if="row.created_by_identity_id"
          :name="getMemberName(row.created_by_identity_id)"
          size="xsmall"
          class="bg-gray-200"
          form="circle" />
        <TextMultiline
          v-if="row.created_by_identity_id"
          :text="getMemberName(row.created_by_identity_id)" />
        <TextMultiline
          v-else
          class="text-muted"
          :text="i18n.t('createdAutomatically')" />
      </div>
    </template>

    <template #execution="{ row }">
      <p
        v-if="
          row.status === BackgroundJobStatus.COMPLETED ||
          row.status === BackgroundJobStatus.ERROR
        "
        class="text-contrast-muted">
        {{
          i18n.t('dayAtTime', {
            day: translateDate(row.last_executed_at as string),
            time: dateToString(row.last_executed_at as string),
          })
        }}
      </p>
      <p v-else-if="row.status == BackgroundJobStatus.ENQUEUED">
        {{
          i18n.t('dayAtTime', {
            day: translateDate(row.next_execution_at as string),
            time: dateToString(row.next_execution_at as string),
          })
        }}
      </p>
      <p v-else></p>
    </template>

    <template #actions="{ row }">
      <SmDropdown
        v-if="
          row.status == BackgroundJobStatus.ENQUEUED ||
          row.status == BackgroundJobStatus.PAUSED
        "
        trigger="click"
        class="ml-auto mr-4">
        <!-- Show User -->
        <SmDropdownItem
          v-if="row.status == BackgroundJobStatus.ENQUEUED"
          icon="md-pause"
          @click="pauseJob(row._id)">
          {{ i18n.t('pauseJob') }}
        </SmDropdownItem>

        <!-- Edit User -->
        <SmDropdownItem
          v-if="row.status == BackgroundJobStatus.ENQUEUED"
          icon="io-close"
          @click="openAbortJobDialog(row._id)">
          {{ i18n.t('abortJob') }}
        </SmDropdownItem>

        <!-- schedule job -->
        <SmDropdownItem
          v-if="row.status == BackgroundJobStatus.PAUSED"
          icon="md-accesstime"
          @click="openScheduleJobDialog(row._id)">
          {{ i18n.t('scheduleJob') }}
        </SmDropdownItem>
      </SmDropdown>
      <p v-else></p>
    </template>
  </SmTable>
</template>

<script setup lang="ts">
  import {
    BackgroundJobAccountGroupAccountsAdded,
    BackgroundJobAccountGroupAccountsRemoved,
    BackgroundJobSoftwareUserAdd,
    BackgroundJobSoftwareUserEnable,
    BackgroundJobSoftwareUserLicenseAdd,
    BackgroundJobSoftwareUserLicenseRemove,
    BackgroundJobSoftwareUserRemove,
    BackgroundJobStatus,
    BackgroundJobType,
    CompanyIdentityAccessWithUserProfile,
  } from '@/client'
  import { Column } from '@/components/sm/SmTable.types'
  import dayjs from 'dayjs'
  import { useI18n } from 'vue-i18n'
  import TasksJobMessageAccountGroupAccountsAdded from '@/components/TasksJobMessageAccountGroupAccountsAdded.vue'
  import TasksJobMessageSoftwareUserEnable from './TasksJobMessageSoftwareUserEnable.vue'
  import TasksJobMessageSoftwareUserAdd from './TasksJobMessageSoftwareUserAdd.vue'
  import TasksJobMessageSoftwareUserRemove from './TasksJobMessageSoftwareUserRemove.vue'
  import TasksJobMessageSoftwareUserLicenseAdd from './TasksJobMessageSoftwareUserLicenseAdd.vue'
  import TasksJobMessageSoftwareUserLicenseRemove from './TasksJobMessageSoftwareUserLicenseRemove.vue'

  import utc from 'dayjs/plugin/utc'
  import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
  import localizedFormat from 'dayjs/plugin/localizedFormat'
  import { sendToast } from './sm/SmNotification'
  import {
    backgroundjobStore,
    getBackgroundjobs,
    getBackgroundjobsSubscription,
  } from '@/stores/backgroundjob.store'
  import { Subscription } from 'rxjs'
  import SmDialogMessage, { DialogMessageTypes } from './sm/SmDialogMessage.vue'
  import { Status } from './TasksJobTableHeader.vue'
  import { BackgroundjobFilterInstance } from '@/stores/backgroundjob.utils'
  import { useSessionStore } from '@/stores/sessionStore'

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)

  const i18n = useI18n()
  const tz = dayjs.tz.guess()

  const sessionStore = useSessionStore()

  const abortLoading = ref(false)
  const abortDone = ref(false)
  const showAbortResponse = ref(false)
  const abortMsg = ref('')
  const abortType = ref<DialogMessageTypes>('success')
  const abortBtnLabel = ref(i18n.t('confirm'))

  const tasksJobToEdit = ref<string>('')
  const tasksJobEditDialogVisible = ref(false)

  const tasksJobToSchedule = ref<string>('')
  const tasksJobScheduleDialogVisible = ref(false)

  const tasksJobToAbort = ref<string>('')
  const tasksJobAbortDialogVisible = ref(false)

  const backgroundjobCountLabel = computed(() => {
    return `${filteredCount.value} ${i18n.t('backgroundjob', { count: filteredCount.value })}`
  })

  const columns: Column<
    | BackgroundJobAccountGroupAccountsAdded
    | BackgroundJobAccountGroupAccountsRemoved
    | BackgroundJobSoftwareUserAdd
    | BackgroundJobSoftwareUserEnable
    | BackgroundJobSoftwareUserLicenseAdd
    | BackgroundJobSoftwareUserLicenseRemove
    | BackgroundJobSoftwareUserRemove
  >[] = [
    {
      key: 'message',
      label: i18n.t('description'),
      sortable: false,
      width: 4,
      footer: backgroundjobCountLabel,
    },
    {
      key: 'status',
      label: i18n.t('status'),
      sortable: true,
      width: 1,
    },
    {
      key: 'creator',
      label: i18n.t('creator'),
      width: 1,
    },
    {
      key: 'execution',
      label: i18n.t('date'),
      sortable: true,
      width: 1,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      width: '50px',
    },
  ]

  function abortBtnFn() {
    if (!abortDone.value) {
      abortJob(tasksJobToAbort.value!)
    } else {
      tasksJobAbortDialogVisible.value = false
    }
  }

  function translateDate(date: string): string {
    const dateObject = dayjs(date).tz(tz)

    if (
      dateObject.format('DD.MM.YYYY') === dayjs(new Date()).format('DD.MM.YYYY')
    )
      return i18n.t('views.dashboard.lastActivity.today')
    if (
      dateObject.format('DD.MM.YYYY') ===
      dayjs(new Date()).subtract(1, 'days').format('DD.MM.YYYY')
    ) {
      return i18n.t('views.dashboard.lastActivity.yesterday')
    }
    return dateObject.format('DD.MM.YYYY')
  }

  function dateToString(date: string): string {
    const dateObject = dayjs(date, { utc: true }).tz(tz)
    return dateObject.format('HH:mm')
  }

  /**
   *  Backgroundjob functions
   */

  function pauseJob(jobId: string) {
    backgroundjobStore
      .pauseBackgroundjob(jobId)
      .then(() => {
        sendToast(i18n.t('pauseJobSuccess'), undefined, 'success')
      })
      .catch(() => {
        sendToast(i18n.t('pauseJobFailed'), undefined, 'error')
      })
  }

  function abortJob(jobId: string) {
    abortLoading.value = true

    backgroundjobStore
      .abortBackgroundjob(jobId)
      .then(() => {
        abortMsg.value = i18n.t('abortJobSuccess')
        showAbortResponse.value = true
        abortLoading.value = false
        abortDone.value = true
        abortBtnLabel.value = i18n.t('close')
      })
      .catch(() => {
        abortMsg.value = i18n.t('abortJobFailed')
        abortType.value = 'error'
        showAbortResponse.value = true
        abortLoading.value = false
      })
  }

  function openAbortJobDialog(jobId: string) {
    tasksJobToAbort.value = jobId ? jobId : ''
    tasksJobAbortDialogVisible.value = true
  }

  function openScheduleJobDialog(jobId: string) {
    tasksJobToSchedule.value = jobId ? jobId : ''
    tasksJobScheduleDialogVisible.value = true
  }

  function openCreateJobDialog(jobId?: string) {
    tasksJobEditDialogVisible.value = true
    tasksJobToEdit.value = jobId ?? ''
  }

  /**
   *  Row functions
   */

  function getMemberName(id: string): string {
    const member = sessionStore.companyMembers?.find(
      (member: CompanyIdentityAccessWithUserProfile) => member._id === id
    )

    if (!member || !member.profile) return i18n.t('general.unknown')

    return member.profile.display_name
  }

  function getStatus(status: BackgroundJobStatus): Status {
    switch (status) {
      case BackgroundJobStatus.ENQUEUED:
        return {
          text: i18n.t('jobStatus.enqueued'),
          color: 'bg-contrast-muted',
        }
      case BackgroundJobStatus.ERROR:
        return { text: i18n.t('jobStatus.error'), color: 'bg-red' }
      case BackgroundJobStatus.PAUSED:
        return { text: i18n.t('jobStatus.paused'), color: 'bg-blue' }
      case BackgroundJobStatus.USER_ACTION_REQUIRED:
        return {
          text: i18n.t('jobStatus.userActionRequired'),
          color: 'bg-blue',
        }
      case BackgroundJobStatus.ABORTED:
        return { text: i18n.t('jobStatus.aborted'), color: 'bg-orange' }
      case BackgroundJobStatus.COMPLETED:
        return { text: i18n.t('jobStatus.completed'), color: 'bg-primary' }
      case BackgroundJobStatus.DEPRECATED:
        return { text: i18n.t('jobStatus.deprecated'), color: 'bg-yellow' }
    }
  }

  function getTypeComponent(type: BackgroundJobType | string) {
    switch (type as BackgroundJobType) {
      case BackgroundJobType.ACCOUNT_GROUP_ACCOUNTS_ADDED:
        return TasksJobMessageAccountGroupAccountsAdded
      case BackgroundJobType.SOFTWARE_USER_ADD:
        return TasksJobMessageSoftwareUserAdd
      case BackgroundJobType.SOFTWARE_USER_ENABLE:
        return TasksJobMessageSoftwareUserEnable
      case BackgroundJobType.SOFTWARE_USER_LICENSE_ADD:
        return TasksJobMessageSoftwareUserLicenseAdd
      case BackgroundJobType.SOFTWARE_USER_LICENSE_REMOVE:
        return TasksJobMessageSoftwareUserLicenseRemove
      case BackgroundJobType.SOFTWARE_USER_REMOVE:
        return TasksJobMessageSoftwareUserRemove
      default:
        return null
    }
  }

  /**
   * Filters
   */

  const filters = ref<BackgroundjobFilterInstance[]>([])
  function updateFilter(newFilters: BackgroundjobFilterInstance[]) {
    filters.value = newFilters
    reloadBackgroundjobs()
  }

  /**
   * Load Background Jobs
   */

  const backgroundJobs = ref<
    Array<
      | BackgroundJobAccountGroupAccountsAdded
      | BackgroundJobAccountGroupAccountsRemoved
      | BackgroundJobSoftwareUserAdd
      | BackgroundJobSoftwareUserEnable
      | BackgroundJobSoftwareUserLicenseAdd
      | BackgroundJobSoftwareUserLicenseRemove
      | BackgroundJobSoftwareUserRemove
    >
  >([])

  const INITIAL_ROW_LIMIT = 300
  const ROW_EXPAND_COUNT = 100
  const currentSubscription = ref<Subscription>()
  const loadingBackgroundjobs = ref<boolean>(false)
  const totalBackgroundjobsCount = ref<number>(0)
  const filteredCount = ref<number>(0)

  async function reloadBackgroundjobs() {
    loadingBackgroundjobs.value = true
    currentSubscription.value?.unsubscribe()

    getBackgroundjobsSubscription(filters.value, {
      limit: INITIAL_ROW_LIMIT,
    }).then((result) => {
      currentSubscription.value = result.subscribe((data) => {
        backgroundJobs.value = data
      })

      loadingBackgroundjobs.value = false
    })
  }

  async function loadMoreBackgroundjobs() {
    loadingBackgroundjobs.value = true
    getBackgroundjobs(filters.value, {
      limit: ROW_EXPAND_COUNT,
      skip: backgroundJobs.value.length,
    }).then((response) => {
      backgroundJobs.value = backgroundJobs.value.concat(response)
      loadingBackgroundjobs.value = false
    })
  }

  function countBackgroundjobs() {
    backgroundjobStore.getBackgroundjobCount(filters.value).then((count) => {
      filteredCount.value = count.filtered
      totalBackgroundjobsCount.value = count.total
    })
  }

  watch(
    () => backgroundJobs.value,
    async () => {
      countBackgroundjobs()
    }
  )

  /**
   * Lifecycle hooks
   */

  onMounted(async () => {
    reloadBackgroundjobs()
    await sessionStore.getCompanyMembers()
  })

  onUnmounted(() => {
    currentSubscription.value?.unsubscribe()
  })
</script>

<style lang="scss" scoped>
  .indicator-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
</style>
