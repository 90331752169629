import { event } from 'vue-gtag'

import { CompanyManagementService, SoftwareOut } from '@/client'
import { useGlobalStore } from '@/stores/globalStore'

export async function executeLeaveDemo() {
  const globalStore = useGlobalStore()
  await CompanyManagementService.removeDemoDataApiV1CompanyRemoveDemoDataPost(
    {}
  )

  // Track google analytics event
  event('demo_end', { method: 'Google' })

  // Close popup
  globalStore.currentPopup = null

  // reload page
  window.location.reload()
}

// Demo Software Tags
export function getDemoModeTag(software: SoftwareOut | undefined) {
  const store = useGlobalStore()

  // Demo mode
  if (store.companyInfo?.demo && software?.software_name) {
    const demoMapping: Record<string, string> = {
      Google: 'oauth',
      Mailchimp: 'oauth',
      Calendly: 'oauth',
      Hubspot: 'oauth',
      Miro: 'bot',
      Trello: 'oauth',
      Notion: 'bot',
      Figma: 'bot',
      Sentry: 'token',
      Dropbox: 'oauth',
      Teamviewer: 'oauth',
      DocuSign: 'oauth',
    }
    if (software.software_name in demoMapping) {
      return demoMapping[software.software_name]
    }
    return 'manually'
  }

  // Normal mode
  return software?.type || 'manually'
}
