<template>
  <canvas id="myChart" style="height: 100%"></canvas>
</template>

<script lang="ts" setup>
  import { Chart, ChartData, ChartOptions } from 'chart.js'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import { Optimization, SoftwareOut, SubAccount_Output } from '@/client'
  import { useSoftwareStore } from '@/stores/softwareStore'

  const softwareStore = useSoftwareStore()
  const router = useRouter()

  const props = defineProps({
    optimizations: {
      type: Array as PropType<Optimization[]>,
      required: true,
    },
  })

  const i18n = useI18n()

  export interface SSOSubAccount extends SubAccount_Output {
    _id: string
    type: 'sso'
    software_id: string
  }

  const chartData = computed(() => {
    const count: Record<string, number> = {}
    for (const element of props.optimizations) {
      if (!element.software_id) {
        continue
      }
      if (count[element.software_id]) {
        count[element.software_id] += 1
      } else {
        count[element.software_id] = 1
      }
    }
    return count
  })

  const chartCounts = computed(() => Object.values(chartData.value))
  const chartLabels = computed(() => Object.keys(chartData.value))

  const style = getComputedStyle(document.body)
  const notHighlighted = style.getPropertyValue('--el-border-color')
  const highlighted = '#5a28ff'

  const data = computed<ChartData<'bar'>>(() => {
    const backgroundColors = chartCounts.value.map((number) => {
      if (Math.max(...chartCounts.value) === number) {
        return highlighted
      } else if (Math.min(...chartCounts.value) === number) {
        return highlighted
      } else {
        return notHighlighted
      }
    })

    return {
      labels: chartLabels.value.map(
        (id) => getSoftware(id)?.display_name || getSoftware(id)?.software_name
      ),
      datasets: [
        {
          data: chartCounts.value,
          backgroundColor: backgroundColors,
          borderRadius: 25,
          borderSkipped: false,
          width: 20,
          barThickness: 15,
        },
      ],
    }
  })

  const software = ref<SoftwareOut[]>()
  const options = computed<ChartOptions<'bar'>>(() => ({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return i18n.t('views.dashboard.optimizations.tooltip', {
              softwareName:
                getSoftware(context.label)?.display_name ||
                getSoftware(context.label)?.software_name,
              count: context.parsed.y,
            })
          },
        },
      },
    },
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : null
      if (target instanceof HTMLElement) {
        target.style.cursor = chartElement.length ? 'pointer' : 'default'
      }
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart',
      delay: (context) => context.dataIndex * 100,
    },
  }))

  function getSoftware(id: string) {
    return software.value?.find((s) => s._id === id)
  }

  function openApplicationPage(
    software: SoftwareOut | SSOSubAccount,
    editMode: boolean = false
  ) {
    if (software.type === 'sso') {
      router.push({
        path: '/applications/' + software.software_id + '/' + software._id,
        query: { sso: 'true', edit: editMode ? 'true' : 'false' },
      })
    } else {
      router.push({
        path: '/applications/' + software._id,
        query: { edit: editMode ? 'true' : 'false' },
      })
    }
  }

  // softwareStore needs correct implementation, so that this is cached!!!
  onMounted(async () => {
    software.value = await softwareStore.getSoftware()
    const chart = new Chart('myChart', {
      type: 'bar',
      data: data.value,
      options: options.value,
    })
    const canvas = document.getElementById('myChart')

    if (canvas) {
      canvas.onclick = (event) => {
        const res = chart.getElementsAtEventForMode(
          event,
          'nearest',
          { intersect: true },
          true
        )

        const application = getSoftware(chartLabels.value[res[0].index])

        if (res.length === 0) {
          return
        }

        if (application) {
          openApplicationPage(application)
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
  #myChart {
    height: 100%;
    width: 100%;
  }
</style>
