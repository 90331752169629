/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_update_company_identity_access_role_api_v1_company_identity_accesses__identity_id__role_patch } from '../models/Body_update_company_identity_access_role_api_v1_company_identity_accesses__identity_id__role_patch';
import type { CompanyAccessTokensResponse } from '../models/CompanyAccessTokensResponse';
import type { CompanyData } from '../models/CompanyData';
import type { CompanyDataUpdateIn } from '../models/CompanyDataUpdateIn';
import type { CompanyIdentityAccessWithUserProfile } from '../models/CompanyIdentityAccessWithUserProfile';
import type { Features } from '../models/Features';
import type { IdentityAccess } from '../models/IdentityAccess';
import type { Invite } from '../models/Invite';
import type { InviteIn } from '../models/InviteIn';
import type { InviteStatus } from '../models/InviteStatus';
import type { InviteUpdateIn } from '../models/InviteUpdateIn';
import type { PaginationSortOrder } from '../models/PaginationSortOrder';
import type { Scopes } from '../models/Scopes';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CompanyManagementService {
    /**
     * Get Company Access Tokens
     * @returns CompanyAccessTokensResponse Successful Response
     * @throws ApiError
     */
    public static getCompanyAccessTokensApiV1CompanyAccessTokensGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        jtiExists,
        jtiEq,
        jtiGt,
        jtiGte,
        jtiIn,
        jtiLt,
        jtiLte,
        jtiNe,
        jtiNin,
        jti,
        issExists,
        issEq,
        issGt,
        issGte,
        issIn,
        issLt,
        issLte,
        issNe,
        issNin,
        iss,
        audExists,
        audEq,
        audGt,
        audGte,
        audIn,
        audLt,
        audLte,
        audNe,
        audNin,
        aud,
        subExists,
        subEq,
        subGt,
        subGte,
        subIn,
        subLt,
        subLte,
        subNe,
        subNin,
        sub,
        cidExists,
        cidEq,
        cidGt,
        cidGte,
        cidIn,
        cidLt,
        cidLte,
        cidNe,
        cidNin,
        cid,
        scpExists,
        scpEq,
        scpGt,
        scpGte,
        scpIn,
        scpLt,
        scpLte,
        scpNe,
        scpNin,
        scpAll,
        scpSize,
        scp,
        expExists,
        expEq,
        expGt,
        expGte,
        expIn,
        expLt,
        expLte,
        expNe,
        expNin,
        exp,
        iatExists,
        iatEq,
        iatGt,
        iatGte,
        iatIn,
        iatLt,
        iatLte,
        iatNe,
        iatNin,
        iat,
        email,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        notesExists,
        notesEq,
        notesGt,
        notesGte,
        notesIn,
        notesLt,
        notesLte,
        notesNe,
        notesNin,
        notes,
        isLoginAccessToken,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        jtiExists?: (boolean | null),
        jtiEq?: (string | null),
        jtiGt?: (string | null),
        jtiGte?: (string | null),
        jtiIn?: (Array<string> | null),
        jtiLt?: (string | null),
        jtiLte?: (string | null),
        jtiNe?: (string | null),
        jtiNin?: (Array<string> | null),
        jti?: (string | null),
        issExists?: (boolean | null),
        issEq?: (string | null),
        issGt?: (string | null),
        issGte?: (string | null),
        issIn?: (Array<string> | null),
        issLt?: (string | null),
        issLte?: (string | null),
        issNe?: (string | null),
        issNin?: (Array<string> | null),
        iss?: (string | null),
        audExists?: (boolean | null),
        audEq?: (string | null),
        audGt?: (string | null),
        audGte?: (string | null),
        audIn?: (Array<string> | null),
        audLt?: (string | null),
        audLte?: (string | null),
        audNe?: (string | null),
        audNin?: (Array<string> | null),
        aud?: (string | null),
        subExists?: (boolean | null),
        subEq?: (string | null),
        subGt?: (string | null),
        subGte?: (string | null),
        subIn?: (Array<string> | null),
        subLt?: (string | null),
        subLte?: (string | null),
        subNe?: (string | null),
        subNin?: (Array<string> | null),
        sub?: (string | null),
        cidExists?: (boolean | null),
        cidEq?: (string | null),
        cidGt?: (string | null),
        cidGte?: (string | null),
        cidIn?: (Array<string> | null),
        cidLt?: (string | null),
        cidLte?: (string | null),
        cidNe?: (string | null),
        cidNin?: (Array<string> | null),
        cid?: (string | null),
        scpExists?: (boolean | null),
        scpEq?: (Scopes | null),
        scpGt?: (Scopes | null),
        scpGte?: (Scopes | null),
        scpIn?: (Array<Scopes> | null),
        scpLt?: (Scopes | null),
        scpLte?: (Scopes | null),
        scpNe?: (Scopes | null),
        scpNin?: (Array<Scopes> | null),
        scpAll?: (Array<Scopes> | null),
        scpSize?: (number | null),
        scp?: (Scopes | null),
        expExists?: (boolean | null),
        expEq?: (number | null),
        expGt?: (number | null),
        expGte?: (number | null),
        expIn?: (Array<number> | null),
        expLt?: (number | null),
        expLte?: (number | null),
        expNe?: (number | null),
        expNin?: (Array<number> | null),
        exp?: (number | null),
        iatExists?: (boolean | null),
        iatEq?: (number | null),
        iatGt?: (number | null),
        iatGte?: (number | null),
        iatIn?: (Array<number> | null),
        iatLt?: (number | null),
        iatLte?: (number | null),
        iatNe?: (number | null),
        iatNin?: (Array<number> | null),
        iat?: (number | null),
        email?: (string | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        notesExists?: (boolean | null),
        notesEq?: (string | null),
        notesGt?: (string | null),
        notesGte?: (string | null),
        notesIn?: (Array<string> | null),
        notesLt?: (string | null),
        notesLte?: (string | null),
        notesNe?: (string | null),
        notesNin?: (Array<string> | null),
        notes?: (string | null),
        isLoginAccessToken?: (boolean | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<CompanyAccessTokensResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/company/access-tokens',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'jti.$exists': jtiExists,
                'jti.$eq': jtiEq,
                'jti.$gt': jtiGt,
                'jti.$gte': jtiGte,
                'jti.$in': jtiIn,
                'jti.$lt': jtiLt,
                'jti.$lte': jtiLte,
                'jti.$ne': jtiNe,
                'jti.$nin': jtiNin,
                'jti': jti,
                'iss.$exists': issExists,
                'iss.$eq': issEq,
                'iss.$gt': issGt,
                'iss.$gte': issGte,
                'iss.$in': issIn,
                'iss.$lt': issLt,
                'iss.$lte': issLte,
                'iss.$ne': issNe,
                'iss.$nin': issNin,
                'iss': iss,
                'aud.$exists': audExists,
                'aud.$eq': audEq,
                'aud.$gt': audGt,
                'aud.$gte': audGte,
                'aud.$in': audIn,
                'aud.$lt': audLt,
                'aud.$lte': audLte,
                'aud.$ne': audNe,
                'aud.$nin': audNin,
                'aud': aud,
                'sub.$exists': subExists,
                'sub.$eq': subEq,
                'sub.$gt': subGt,
                'sub.$gte': subGte,
                'sub.$in': subIn,
                'sub.$lt': subLt,
                'sub.$lte': subLte,
                'sub.$ne': subNe,
                'sub.$nin': subNin,
                'sub': sub,
                'cid.$exists': cidExists,
                'cid.$eq': cidEq,
                'cid.$gt': cidGt,
                'cid.$gte': cidGte,
                'cid.$in': cidIn,
                'cid.$lt': cidLt,
                'cid.$lte': cidLte,
                'cid.$ne': cidNe,
                'cid.$nin': cidNin,
                'cid': cid,
                'scp.$exists': scpExists,
                'scp.$eq': scpEq,
                'scp.$gt': scpGt,
                'scp.$gte': scpGte,
                'scp.$in': scpIn,
                'scp.$lt': scpLt,
                'scp.$lte': scpLte,
                'scp.$ne': scpNe,
                'scp.$nin': scpNin,
                'scp.$all': scpAll,
                'scp.$size': scpSize,
                'scp': scp,
                'exp.$exists': expExists,
                'exp.$eq': expEq,
                'exp.$gt': expGt,
                'exp.$gte': expGte,
                'exp.$in': expIn,
                'exp.$lt': expLt,
                'exp.$lte': expLte,
                'exp.$ne': expNe,
                'exp.$nin': expNin,
                'exp': exp,
                'iat.$exists': iatExists,
                'iat.$eq': iatEq,
                'iat.$gt': iatGt,
                'iat.$gte': iatGte,
                'iat.$in': iatIn,
                'iat.$lt': iatLt,
                'iat.$lte': iatLte,
                'iat.$ne': iatNe,
                'iat.$nin': iatNin,
                'iat': iat,
                'email': email,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'notes.$exists': notesExists,
                'notes.$eq': notesEq,
                'notes.$gt': notesGt,
                'notes.$gte': notesGte,
                'notes.$in': notesIn,
                'notes.$lt': notesLt,
                'notes.$lte': notesLte,
                'notes.$ne': notesNe,
                'notes.$nin': notesNin,
                'notes': notes,
                'is_login_access_token': isLoginAccessToken,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Company Access Token By Id
     * @returns void
     * @throws ApiError
     */
    public static deleteCompanyAccessTokenByIdApiV1CompanyAccessTokensCompanyAccessTokenIdDelete({
        companyAccessTokenId,
        xTransactionId,
    }: {
        companyAccessTokenId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/company/access-tokens/{company_access_token_id}',
            path: {
                'company_access_token_id': companyAccessTokenId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Demo Data
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeDemoDataApiV1CompanyRemoveDemoDataPost({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/company/remove-demo-data',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Demo Data
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getDemoDataApiV1CompanyGenerateDemoDataPost({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/company/generate-demo-data',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Company Identity Accesses With User Profile
     * @returns CompanyIdentityAccessWithUserProfile Successful Response
     * @throws ApiError
     */
    public static getCompanyIdentityAccessesWithUserProfileApiV1CompanyIdentityAccessesGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<CompanyIdentityAccessWithUserProfile>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/company/identity-accesses',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Company Identity Access Role
     * @returns IdentityAccess Successful Response
     * @throws ApiError
     */
    public static updateCompanyIdentityAccessRoleApiV1CompanyIdentityAccessesIdentityIdRolePatch({
        identityId,
        requestBody,
        xTransactionId,
    }: {
        identityId: string,
        requestBody: Body_update_company_identity_access_role_api_v1_company_identity_accesses__identity_id__role_patch,
        xTransactionId?: (string | null),
    }): CancelablePromise<IdentityAccess> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/company/identity-accesses/{identity_id}/role',
            path: {
                'identity_id': identityId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Company Info
     * @returns CompanyData Successful Response
     * @throws ApiError
     */
    public static getCompanyInfoApiV1CompanyInfoGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<CompanyData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/company/info',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Company Info
     * @returns CompanyData Successful Response
     * @throws ApiError
     */
    public static patchCompanyInfoApiV1CompanyInfoPatch({
        requestBody,
        xTransactionId,
    }: {
        requestBody: CompanyDataUpdateIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<CompanyData> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/company/info',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Company Features
     * @returns Features Successful Response
     * @throws ApiError
     */
    public static getCompanyFeaturesApiV1CompanyFeaturesGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<Features> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/company/features',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Invites
     * @returns Invite Successful Response
     * @throws ApiError
     */
    public static getInvitesApiV1CompanyInvitesGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        statusExists,
        statusEq,
        statusGt,
        statusGte,
        statusIn,
        statusLt,
        statusLte,
        statusNe,
        statusNin,
        status,
        inviteeExists,
        inviteeEq,
        inviteeGt,
        inviteeGte,
        inviteeIn,
        inviteeLt,
        inviteeLte,
        inviteeNe,
        inviteeNin,
        invitee,
        invitedEmailExists,
        invitedEmailEq,
        invitedEmailGt,
        invitedEmailGte,
        invitedEmailIn,
        invitedEmailLt,
        invitedEmailLte,
        invitedEmailNe,
        invitedEmailNin,
        invitedEmail,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        statusExists?: (boolean | null),
        statusEq?: (InviteStatus | null),
        statusGt?: (InviteStatus | null),
        statusGte?: (InviteStatus | null),
        statusIn?: (Array<InviteStatus> | null),
        statusLt?: (InviteStatus | null),
        statusLte?: (InviteStatus | null),
        statusNe?: (InviteStatus | null),
        statusNin?: (Array<InviteStatus> | null),
        status?: (InviteStatus | null),
        inviteeExists?: (boolean | null),
        inviteeEq?: (string | null),
        inviteeGt?: (string | null),
        inviteeGte?: (string | null),
        inviteeIn?: (Array<string> | null),
        inviteeLt?: (string | null),
        inviteeLte?: (string | null),
        inviteeNe?: (string | null),
        inviteeNin?: (Array<string> | null),
        invitee?: (string | null),
        invitedEmailExists?: (boolean | null),
        invitedEmailEq?: (string | null),
        invitedEmailGt?: (string | null),
        invitedEmailGte?: (string | null),
        invitedEmailIn?: (Array<string> | null),
        invitedEmailLt?: (string | null),
        invitedEmailLte?: (string | null),
        invitedEmailNe?: (string | null),
        invitedEmailNin?: (Array<string> | null),
        invitedEmail?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<Invite>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/company/invites',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'status.$exists': statusExists,
                'status.$eq': statusEq,
                'status.$gt': statusGt,
                'status.$gte': statusGte,
                'status.$in': statusIn,
                'status.$lt': statusLt,
                'status.$lte': statusLte,
                'status.$ne': statusNe,
                'status.$nin': statusNin,
                'status': status,
                'invitee.$exists': inviteeExists,
                'invitee.$eq': inviteeEq,
                'invitee.$gt': inviteeGt,
                'invitee.$gte': inviteeGte,
                'invitee.$in': inviteeIn,
                'invitee.$lt': inviteeLt,
                'invitee.$lte': inviteeLte,
                'invitee.$ne': inviteeNe,
                'invitee.$nin': inviteeNin,
                'invitee': invitee,
                'invited_email.$exists': invitedEmailExists,
                'invited_email.$eq': invitedEmailEq,
                'invited_email.$gt': invitedEmailGt,
                'invited_email.$gte': invitedEmailGte,
                'invited_email.$in': invitedEmailIn,
                'invited_email.$lt': invitedEmailLt,
                'invited_email.$lte': invitedEmailLte,
                'invited_email.$ne': invitedEmailNe,
                'invited_email.$nin': invitedEmailNin,
                'invited_email': invitedEmail,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Send Invite
     * @returns Invite Successful Response
     * @throws ApiError
     */
    public static sendInviteApiV1CompanyInvitesPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: InviteIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<Invite> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/company/invites',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Invite
     * @returns Invite Successful Response
     * @throws ApiError
     */
    public static getInviteApiV1CompanyInvitesInviteIdGet({
        inviteId,
        xTransactionId,
    }: {
        inviteId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Invite> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/company/invites/{invite_id}',
            path: {
                'invite_id': inviteId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Invite
     * @returns Invite Successful Response
     * @throws ApiError
     */
    public static patchInviteApiV1CompanyInvitesInviteIdPatch({
        inviteId,
        requestBody,
        xTransactionId,
    }: {
        inviteId: string,
        requestBody: InviteUpdateIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<Invite> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/company/invites/{invite_id}',
            path: {
                'invite_id': inviteId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
