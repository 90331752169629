/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSoftwareUserAddData } from './BackgroundJobSoftwareUserAddData';
export type BackgroundJobSoftwareUserAddPostIn = {
    type: BackgroundJobSoftwareUserAddPostIn.type;
    data: BackgroundJobSoftwareUserAddData;
    /**
     * Time in seconds when to enqueue. Does not enqueue if value is `null`.
     */
    enqueue_in?: (number | null);
};
export namespace BackgroundJobSoftwareUserAddPostIn {
    export enum type {
        SOFTWARE_USER_ADD = 'software_user_add',
    }
}

