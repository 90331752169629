<template>
  <SmDialog v-model:visibility="visibility">
    <!-- Dialog header -->
    <template #title>
      <div class="dialog-header">
        <el-image
          class="software-image"
          :src="`https://logos.saasmetrix.io/${props.softwareName}.png`">
          <template #error>
            <v-icon name="md-hideimage-round" scale="1.6" />
          </template>
        </el-image>
        <h2>{{ softwareName }}: {{ i18n.t('2fa') }}</h2>
      </div>
    </template>
    <SoftwareAuthFlowContent
      :software-name="softwareName"
      :software-type="softwareType"
      :software-id="softwareId"
      :auth-type="authType"
      @authentication-completed="handleAuthenticationCompleted" />
  </SmDialog>
</template>

<script setup lang="ts">
  // Imports

  import { useI18n } from 'vue-i18n'

  import { SoftwareTypes } from '@/client'
  import { AuthType } from '@/stores/softwareStore'

  import SoftwareAuthFlowContent from './SoftwareAuthFlowContent.vue'

  const i18n = useI18n()

  const emit = defineEmits(['update:visibility', 'authentication-completed'])

  const props = defineProps<{
    visibility: boolean
    softwareName: string
    softwareId?: string
    softwareType: SoftwareTypes
    authType: AuthType
  }>()

  const visibility = computed({
    get() {
      return props.visibility
    },
    set(value: boolean) {
      emit('update:visibility', value)
    },
  })

  function handleAuthenticationCompleted() {
    emit('authentication-completed')
    visibility.value = false
  }
</script>

<style scoped lang="scss">
  .dialog-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
  .software-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }
</style>
