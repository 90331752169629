<template>
  <SoftwareDisconnectDialog
    v-model:visibility="softwareDisconnectDialogVisible"
    :software="props.software" />

  <SmDialog
    v-model:visibility="localShowDrawer"
    :title="
      i18n.t('drawers.applicationDetailDrawer.header.softwareEdit', {
        softwareName: props.software.display_name,
      })
    ">
    <el-tabs v-model="userMode" @tab-change="checkIfUserTabIsShown">
      <el-tab-pane
        v-if="
          existingRequirementComponents.includes(
            (
              (props.software.type || '') + props.software.software_name
            ).toLowerCase()
          )
        "
        :label="
          i18n.t('drawers.applicationDetailDrawer.tabs.softwareInformation', {
            softwareName: props.software.display_name,
          })
        "
        name="SoftwareInformation"
        lazy>
        <ApplicationDetailTab :software="props.software" />
      </el-tab-pane>
      <el-tab-pane
        :label="
          i18n.t('drawers.applicationDetailDrawer.tabs.softwareEdit', {
            softwareName: props.software.display_name,
          })
        "
        name="EditSoftware"
        lazy>
        <!-- licenceModelControl is disabled, because software already exists -->
        <!-- isAlreadyExistingSoftware is true, because software already exists -->
        <!-- disabling name input, because software was already created -->
        <SoftwareForm
          v-model:show-drawer="localShowDrawer"
          :software-data="software"
          :software-type="software.type || undefined"
          :software-id="software._id"
          :software-name="software.software_name"
          :show-licence-model-control="false"
          :is-already-existing-software="true"
          @software-changed="
            () => {
              closeDrawer()
              emit('softwareChanged')
            }
          ">
          <slot name="software-edit-items" />
        </SoftwareForm>
      </el-tab-pane>

      <el-tab-pane
        v-if="software.information.sso_provider"
        name="applications"
        :label="i18n.t('applications')">
        <h4>{{ i18n.t('displayedApplications') }}:</h4>
        <div style="height: 1rem" />
        <SmTable
          ref="ssoAppTable"
          selectable
          :columns="[{ width: 1, key: 'name', label: i18n.t('general.name') }]"
          :data="allSubAccounts"
          key-field="id">
          <template #name="{ row }">
            <div
              class="sub-account-select-names"
              @click="changeSebaccountSelected(row)">
              <ApplicationIcon :software-name="row.name" />
              {{ row.name }}
            </div>
          </template>
        </SmTable>
      </el-tab-pane>

      <!-- Groups -->
      <el-tab-pane
        v-if="
          software.licence_groups &&
          Object.keys(software.licence_groups).length !== 0
        "
        :label="i18n.t('groups')"
        name="Groups"
        :lazy="true">
        <SmInput
          v-model="search"
          label="Search"
          size="medium"
          outline
          style="margin: 10px; width: initial" />
        <ApplicationDetailGroupTab
          :software="props.software"
          :searchterm="search" />
      </el-tab-pane>
    </el-tabs>

    <!-- Slots -->

    <template #footer>
      <SmButton
        v-if="localTab === 'applications'"
        type="primary"
        :loading="loadingSubAccountSelection"
        class="ml-auto mt-4 block"
        @click="handleSubAccountSelection">
        {{ i18n.t('general.save') }}
      </SmButton>
    </template>
    <template #close>
      <div style="display: flex; align-items: center; gap: 0.8rem">
        <v-icon
          class="cursor-pointer transition duration-300 hover:fill-magenta"
          name="md-delete"
          @click="handleDeleteSoftware" />
        <SmButtonClose @close="localShowDrawer = false" />
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { TabPaneName } from 'element-plus'

  import { useI18n } from 'vue-i18n'

  import {
    AvailableSubAccount,
    SoftwareOut,
    SoftwareService,
    SoftwareSubaccountsService,
    SubAccount_Output,
  } from '@/client'
  import SoftwareDisconnectDialog from '@/components/SoftwareDisconnectDialog.vue'

  import SmTable from '@/components/sm/SmTable.vue'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import ApplicationDetailGroupTab from './tabs/ApplicationDetailGroupTab.vue'
  import ApplicationDetailTab from './tabs/ApplicationDetailTab.vue'
  import { sendToast } from '@/components/sm/SmNotification'

  // Define Props and Emits
  const emit = defineEmits([
    'update:showLicenceView',
    'update:showDrawer',
    'update:tab',
    'update:licences',
    'userSaved',
    'closeDrawer',
    'softwareChanged',
    'licenses-changed',
  ])

  const props = withDefaults(
    defineProps<{
      software: SoftwareOut
      showDrawer: boolean
      editMode?: boolean
      tab: string
      selectedSubAccountId: string | null
    }>(),
    {
      showDrawer: false,
      editMode: true,
      tab: 'EditSoftware',
      selectedSubAccountId: null,
    }
  )

  const i18n = useI18n()

  const softwareDisconnectDialogVisible = ref(false)
  const ssoAppTable = ref<ComponentExposed<typeof SmTable<SubAccount_Output>>>()
  const allSubAccounts = ref<AvailableSubAccount[]>([])
  const subAccountsInitialized = ref(false)
  const loadingSubAccountSelection = ref(false)

  const localShowDrawer = computed({
    get: () => props.showDrawer,
    set: (value) => emit('update:showDrawer', value),
  })

  const pages = import.meta.glob(
    '/src/components/BaseApplicationDetails/modules/**.vue'
  )

  const existingRequirementComponents = Object.keys(pages).map((key) => {
    return (
      key.match(
        '\\/src\\/components\\/BaseApplicationDetails\\/modules\\/(.*)\\.vue'
      ) as RegExpMatchArray
    )[1].toLowerCase()
  })

  const search = ref('')

  const localTab = computed({
    get: () => props.tab,
    set: (value) => emit('update:tab', value),
  })

  // ref to check, if the user tab was selected,
  // if it was selected at least once, then set the
  // prop "was-already-selected" of "ApplicationDetailsSelectUserTab"
  //
  // --> This is needed, to tell the UserTab Component
  // when to render the users (if the users get rendered instantly,
  // the drawer begins to delay, so we now render the users,
  // when the tab was selected)
  //
  // Set the value with a timeout, because otherwise the el-tabs
  // animation starts lagging
  const userTabWasSelected = ref(false)

  function handleSubAccountSelection() {
    loadingSubAccountSelection.value = true
    if (!subAccountsInitialized.value) return
    let new_filter = []
    if (props.software.config.sub_accounts_filter_is_whitelist) {
      new_filter = ssoAppTable.value!.selection.map((s) => s.id)
    } else {
      const selected = ssoAppTable.value!.selection.map((s) => s.id)
      new_filter = allSubAccounts.value
        .map((s) => s.id)
        .filter((s) => !selected.includes(s))
    }
    SoftwareService.putSoftwareApiV1SoftwareSoftwareSoftwareIdPut({
      softwareId: props.software._id,
      requestBody: {
        config: {
          sub_accounts_filter: new_filter,
        },
      },
    }).then(() => {
      loadingSubAccountSelection.value = false
      sendToast(i18n.t('successfullySaved'), undefined, 'success')
      emit('softwareChanged')
    })
  }

  function changeSebaccountSelected(row: AvailableSubAccount) {
    if (!subAccountsInitialized.value) return
    ssoAppTable.value!.selectById(row.id)
  }

  function checkIfUserTabIsShown(name: TabPaneName) {
    localTab.value = name.toString()

    if (name == 'Select') {
      setTimeout(() => {
        userTabWasSelected.value = true
      }, 20)
      return
    }
    userTabWasSelected.value = false
  }

  // User Mode
  const userMode = ref(props.tab)
  const dialogShown = ref(false)

  function closeDrawer() {
    if (userMode.value === 'EditSoftware') {
      emit('userSaved')
      dialogShown.value = true
    } else {
      localShowDrawer.value = false
    }
  }

  function handleDeleteSoftware() {
    softwareDisconnectDialogVisible.value = true
    localShowDrawer.value = false
  }

  // When parent changes
  watch(
    () => props.tab,
    (value) => {
      userMode.value = value
      localTab.value = value
    }
  )

  watch(
    () => ssoAppTable.value,
    () => {
      if (!props.software.information.sso_provider) return
      if (!ssoAppTable.value) return
      subAccountsInitialized.value = false

      SoftwareSubaccountsService.getAvailableSubAccountsApiV1SoftwareSoftwareSoftwareIdAvailableSubAccountsGet(
        {
          softwareId: props.software._id,
        }
      ).then((s) => {
        allSubAccounts.value = s
        if (!ssoAppTable.value) return

        nextTick(() => {
          allSubAccounts.value.forEach((s) => {
            const isInFilter =
              props.software.config.sub_accounts_filter.includes(s.id)
            if (
              (props.software.config.sub_accounts_filter_is_whitelist &&
                isInFilter) ||
              (!props.software.config.sub_accounts_filter_is_whitelist &&
                !isInFilter)
            )
              ssoAppTable.value?.selectById(s.id)
          })
          subAccountsInitialized.value = true
        })
      })
    }
  )
</script>

<!-- Needs to be global becaus its teleported -->
<style lang="scss">
  .sub-account-select-names {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
</style>
