/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BillingUnit {
    PER_USER = 'per-user',
    FLATRATE = 'flatrate',
    QUOTA = 'quota',
    UNKNOWN = 'unknown',
}
