<template>
  <SmDialog
    v-model:visibility="visibility"
    size="large"
    height="50vh"
    :body-style="{ overflow: 'hidden' }"
    :title="i18n.t('settings')">
    <div class="settings-grid">
      <!-- Sticky -->
      <div class="settings-sidebar">
        <SettingsSidebar v-model:active-tab="localActiveTab" />
      </div>
      <div style="height: 100%" class="settings-content">
        <SettingsAccountPage v-if="localActiveTab === 'account'" />
        <SettingsPasswordPage v-if="localActiveTab === 'password'" />
        <SettingsCompanyPage v-if="localActiveTab === 'company'" />
        <SettingsHelpPage v-if="localActiveTab === 'help'" />
        <SettingsSubscriptionPage v-if="localActiveTab === 'subscription'" />
        <SettingsAPIPage v-if="localActiveTab === 'api'" />
      </div>
    </div>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'

  const visibility = defineModel('visibility', {
    type: Boolean,
    default: false,
  })
  const i18n = useI18n()

  type Tabs =
    | 'account'
    | 'password'
    | 'help'
    | 'subscription'
    | 'company'
    | 'api'

  const props = defineProps({
    activeTab: {
      type: String as PropType<Tabs>,
      default: undefined,
    },
  })

  const route = useRoute()
  const localActiveTab = ref<string>()

  if (!props.activeTab) {
    localActiveTab.value = route.query.activeTab
      ? (route.query.activeTab as string)
      : 'account'
  }

  if (!props.activeTab) {
    watch(
      () => route.query,
      () => {
        if (route.query.activeTab)
          localActiveTab.value = route.query.activeTab as string
      }
    )
  }
</script>

<style scoped>
  .settings-grid {
    display: grid;
    grid-template-columns: 170px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .settings-content {
    padding-right: 20px;
    padding-left: 20px;
    max-height: calc(50vh - 15px); /* 50 vh is dialog height */
    overflow-y: auto;
  }

  :deep(.settings-container) {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: clamp(300px, 100%, 700px);
  }

  .settings-sidebar {
    max-height: calc(50vh - 15px); /* 50 vh is dialog height */
    overflow-y: auto;
  }
</style>
