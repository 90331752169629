/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobAccountGroupAccountsRemovedExecutionData } from './BackgroundJobAccountGroupAccountsRemovedExecutionData';
import type { BackgroundJobStatus } from './BackgroundJobStatus';
import type { ErrorDetails } from './ErrorDetails';
export type BackgroundJobExecutionBase_Literal_account_group_accounts_removed__BackgroundJobAccountGroupAccountsRemovedExecutionData_ = {
    type: BackgroundJobExecutionBase_Literal_account_group_accounts_removed__BackgroundJobAccountGroupAccountsRemovedExecutionData_.type;
    execution_id: string;
    executed_at: string;
    data?: (BackgroundJobAccountGroupAccountsRemovedExecutionData | null);
    status?: BackgroundJobStatus;
    error_event_id?: (string | null);
    background_job_ids_enqueued?: Array<string>;
    error?: (ErrorDetails | null);
};
export namespace BackgroundJobExecutionBase_Literal_account_group_accounts_removed__BackgroundJobAccountGroupAccountsRemovedExecutionData_ {
    export enum type {
        ACCOUNT_GROUP_ACCOUNTS_REMOVED = 'account_group_accounts_removed',
    }
}

