<template>
  <div
    style="display: flex; align-items: center; justify-content: space-between">
    <div style="color: var(--el-text-color-regular)">
      {{ i18n.t('groupAddMicrosoftForm.synchronizeData') }}
    </div>
    <SmTooltip :content="tooltipContent" placement="top">
      <SmSwitch v-model="groupSyncActive" />
    </SmTooltip>
  </div>
  <transition name="expand">
    <div v-if="groupSyncActive">
      <SmDialogMessage
        style="margin-bottom: 1rem"
        :type="dialogMessage.type"
        :message="dialogMessage.message"
        :visible="dialogMessage.visible" />

      <el-form ref="formRef" label-width="9rem">
        <el-form-item
          :label="i18n.t('groupAddMicrosoftForm.dataSources')"
          prop="dataSources">
          <ElSelect
            v-model="datasource.data_source_id"
            :placeholder="i18n.t('groupAddMicrosoftForm.selectDataSource')"
            filterable
            clearable
            lazy>
            <ElOption
              v-for="dataSource in connectedDataSources"
              :key="dataSource._id"
              :label="dataSource.name"
              :value="dataSource._id">
            </ElOption>
          </ElSelect>
        </el-form-item>
        <el-form-item :label="i18n.t('groups')" prop="groups">
          <ElSelectV2
            v-model="datasource.external_id"
            :options="groupSelectOptions"
            :loading="loadingGroups"
            :placeholder="i18n.t('groupAddMicrosoftForm.selectGroup')"
            :disabled="
              !datasource.data_source_id ||
              !connectedDataSources.length ||
              availableGroups.length == 0
            "
            filterable
            clearable
            lazy>
          </ElSelectV2>
        </el-form-item>
      </el-form>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import {
    AccountGroupIn,
    DataSource,
    DataSourceAvailableGroup,
    DataSourcesService,
  } from '@/client'
  import { ElSelect, ElForm } from 'element-plus'
  import { useI18n } from 'vue-i18n'
  import type { FormInstance } from 'element-plus'
  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'

  const i18n = useI18n()
  const connectedDataSources = ref<DataSource[]>([])
  const availableGroups = ref<DataSourceAvailableGroup[]>([])
  const tooltipContent = i18n.t('groupAddMicrosoftForm.tooltip')
  const formRef = ref<FormInstance>()

  const accountGroup = defineModel<AccountGroupIn>('accountGroup', {
    required: true,
  })

  interface AccountGroupDataSourceEdit {
    data_source_id?: string
    external_id?: string
  }

  const datasource = ref<AccountGroupDataSourceEdit>({})

  onMounted(() => {
    if (accountGroup.value.data_source) {
      datasource.value = {
        data_source_id: accountGroup.value.data_source.data_source_id,
        external_id: accountGroup.value.data_source.external_id,
      }
    }
  })

  // Selection
  const groupSyncActive = ref(accountGroup.value.data_source !== undefined)

  const groupSelectOptions = computed(() => {
    return availableGroups.value.map((group) => ({
      label: group.name,
      value: group.external_id,
    }))
  })

  // ############################
  // ####### Data Loading #######
  // ############################

  const loadingGroups = ref(false)

  function getConnectedDataSources() {
    DataSourcesService.getDataSourcesApiV1DataSourceDataSourcesGet({}).then(
      (res) => {
        connectedDataSources.value = res
      }
    )
  }

  watch(
    () => datasource.value.data_source_id,
    (newDataSourceId) => {
      if (newDataSourceId) {
        getAvailableGroups()
      } else {
        datasource.value.external_id = undefined
      }
    }
  )

  watch(groupSyncActive, (newValue) => {
    if (!newValue) {
      datasource.value.data_source_id = undefined
      datasource.value.external_id = undefined
    }
  })

  onMounted(() => {
    getConnectedDataSources()
    if (accountGroup.value.data_source?.data_source_id) {
      getAvailableGroups()
    }
  })

  // ############################
  // ##### Form Validation ######
  // ############################

  const dialogMessage = ref({
    // Content of the dialog message
    message: '',
    visible: false,
    type: 'warning' as DialogMessageTypes,
  })

  function checkForm(): boolean {
    // No datasource selected
    if (groupSyncActive.value && !datasource.value.data_source_id) {
      dialogMessage.value = {
        message: i18n.t('selectDataSourceMissingError'),
        visible: true,
        type: 'warning',
      }
      return false

      // No group selected
    } else if (groupSyncActive.value && !datasource.value.external_id) {
      dialogMessage.value = {
        message: i18n.t('selectedGroupMissingError'),
        visible: true,
        type: 'warning',
      }
      return false
    }

    // Everything is fine
    return true
  }

  // Reset error message when the user changes the selected data source or group
  watch(
    () => [
      groupSyncActive.value,
      datasource.value.data_source_id && datasource.value.external_id,
    ],
    () => {
      dialogMessage.value.visible = false
    }
  )

  watch(
    datasource,
    () => {
      if (
        groupSyncActive.value &&
        datasource.value.data_source_id &&
        datasource.value.external_id
      ) {
        accountGroup.value.data_source = {
          data_source_id: datasource.value.data_source_id,
          external_id: datasource.value.external_id,
        }
      } else {
        accountGroup.value.data_source = undefined
      }
    },
    { deep: true }
  )

  defineExpose({
    formRef,
    checkForm,
  })

  // ############################
  // ####### Untils #######
  // #####################

  function getAvailableGroups() {
    if (datasource.value.data_source_id) {
      loadingGroups.value = true
      DataSourcesService.getDataSourceAvailableGroupsApiV1DataSourceDataSourcesDataSourceIdAvailableGroupsGet(
        {
          dataSourceId: datasource.value.data_source_id,
        }
      )
        .then((response) => {
          availableGroups.value = response
        })
        .catch((error) => {
          console.error('Error when retrieving the groups:', error)
        })
        .finally(() => {
          loadingGroups.value = false
        })
    }
  }
</script>

<style lang="scss">
  .expand-enter-active,
  .expand-leave-active {
    overflow: hidden;
    transition:
      opacity 0.1s ease,
      max-height 0.1s ease;
  }
  .expand-enter-from,
  .expand-leave-to {
    opacity: 0;
    max-height: 0;
  }
  .expand-enter-to,
  .expand-leave-from {
    opacity: 1;
    max-height: 100px;
  }
</style>
