/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OptimizationType {
    UNUSED = 'unused',
    LEAVING = 'leaving',
    DUPLICATE = 'duplicate',
    REDUNDANT_LICENSE = 'redundant_license',
}
