<template>
  <component
    :is="pageComponents[filename]"
    v-if="pageComponents[filename]"
    :software-data="softwareData" />
  <component
    :is="pageComponents['generalinformation']"
    v-else
    :software-data="softwareData" />
</template>

<script lang="ts" setup>
  import { SoftwareOut } from '@/client'

  /**
   * Props and emits
   */
  const props = defineProps<{
    softwareData: SoftwareOut
  }>()

  /**
   * Computes the filename of the software requirements file
   */
  const filename = computed(() => {
    if (!props.softwareData.type) return 'generalinformation'

    return `${
      props.softwareData.type
    }${props.softwareData.software_name.toLowerCase()}`
  })

  /**
   * Defines the async component for the software requirements
   * All software requirements are defined in the components/old/SoftwareRequirements/user folder
   * these files are imported and saves in the pageComponents object
   */
  const pages = import.meta.glob('./modules/*.vue')
  const pageComponents: Record<string, unknown> = {}
  Object.keys(pages).forEach((key) => {
    const name: string = (
      key.match('\\.\\/modules\\/(.*?).vue') as RegExpMatchArray
    )[1].toLowerCase()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageComponents[name] = defineAsyncComponent(() => pages[key]() as any)
  })
</script>
