<template>
  <!-- settings-container defined in parent -->
  <div class="settings-container">
    <div class="settings-group">
      <SettingsOTPSetupDialog v-model:visibility="showOTPActivateDialog" />
      <SettingsOTPDeactivateDialog
        v-model:visibility="showOTPDeactivateDialog" />
      <h3>
        {{ i18n.t('views.settings.password.header') }}
      </h3>
      <SmDialogMessage
        style="margin-top: 0"
        :message="passwordChangeMessage.message"
        :type="passwordChangeMessage.type"
        :visible="passwordChangeMessage.visible" />
      <!-- Change Password -->
      <div>
        <form class="passwort-change">
          <!-- New Password -->
          <label class="form-label">
            {{ i18n.t('newPassword') }}
          </label>
          <SmInput
            v-model="formData.newPassword"
            type="password"
            label="**********"
            autocomplete="new-password"
            show-password-strength
            size="medium"
            outline />

          <!-- New Password Confirm -->
          <label class="form-label">
            {{ i18n.t('newPasswordConfirm') }}
          </label>
          <SmInput
            ref="newPasswordConfirmRef"
            v-model="formData.newPasswordConfirm"
            type="password"
            autocomplete="new-password"
            label="**********"
            size="medium"
            :validator="[equalValidator(formData.newPassword)]"
            outline />
        </form>
        <div style="width: 100%; margin-top: 1rem">
          <div style="margin-left: auto; width: fit-content">
            <SmButton
              type="primary"
              size="medium"
              :loading="changePasswordLoading"
              @click="changePassword">
              {{ i18n.t('save') }}
            </SmButton>
          </div>
        </div>
      </div>

      <!-- Logout Timer -->
      <div class="page-header">
        <h3 style="margin: 0">
          {{ i18n.t('logoutTimer.automaticLogout') }}
        </h3>
      </div>
      <el-form-item :label="i18n.t('logoutTimer.automaticLogoutEnable')">
        <div class="automatic-logout">
          <SmCheckbox v-model="timeOutActivated" />
          <el-select-v2
            v-model="automaticUpdateTime"
            style="width: 120px"
            size="small"
            :placeholder="i18n.t('logoutTimer.chooseLogoutTime')"
            :disabled="!timeOutActivated"
            :options="[
              { label: i18n.t('general.minute', 1), value: 60000 },
              { label: i18n.t('general.minute', 3), value: 180000 },
              { label: i18n.t('general.minute', 5), value: 300000 },
              { label: i18n.t('general.minute', 10), value: 600000 },
              { label: i18n.t('general.minute', 15), value: 900000 },
            ]"
            @change="handleTimeoutChange" />
        </div>
      </el-form-item>
    </div>

    <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
    <h3>OTP {{ i18n.t('settings') }}</h3>
    <p>{{ i18n.t('dialogs.twoFADialog.text1') }}</p>
    <SmButton v-if="!otpEnabled" @click="showOTPActivateDialog = true">
      {{ i18n.t('otpActivate') }}
    </SmButton>
    <SmButton
      v-else
      outline
      type="danger"
      @click="showOTPDeactivateDialog = true">
      {{ i18n.t('otpDeactivate') }}
    </SmButton>

    <!-- Sessions -->
    <SettingsActiveSessions />
  </div>
</template>

<script lang="ts" setup>
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import { getCsrfToken } from '@/common/util/oryUtil'
  import SmDialogMessage, {
    DialogMessageTypes,
  } from '@/components/sm/SmDialogMessage.vue'
  import SmInput from '@/components/sm/SmInput.vue'
  import { usePreferenceStore } from '@/stores/preferenceStore'
  import { useSessionStore } from '@/stores/sessionStore'

  import { equalValidator } from './sm/SmInput/SmInputValidator'
  import { sendToast } from './sm/SmNotification'

  interface PasswordChangeMessage {
    type: DialogMessageTypes
    message: string
    visible: boolean
  }

  // Element Refs
  const newPasswordConfirmRef = ref<ComponentExposed<typeof SmInput> | null>(
    null
  )

  const showOTPActivateDialog = ref(false)
  const showOTPDeactivateDialog = ref(false)

  const preferenceStore = usePreferenceStore()
  const i18n = useI18n()

  const sessionStore = useSessionStore()

  const formData = ref({
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  })

  const passwordChangeMessage = ref<PasswordChangeMessage>({
    type: 'success',
    message: '',
    visible: false,
  })

  const automaticUpdateTime = computed({
    get() {
      if (!timeOutActivated.value) return null
      return preferenceStore.preferences?.automatic_logout_time
    },
    set(logoutTime) {
      if (logoutTime)
        preferenceStore.setPreference('automatic_logout_time', logoutTime)
    },
  })

  const timeOutActivated = computed({
    get() {
      if (!preferenceStore.preferences?.automatic_logout_time) return false
      if (preferenceStore.preferences.automatic_logout_time < 0) return false
      return true
    },
    set(logoutActivated: boolean) {
      if (!logoutActivated) {
        preferenceStore.setPreference('automatic_logout_time', -1)
        return
      }
      preferenceStore.setPreference('automatic_logout_time', 60000)
    },
  })

  function handleTimeoutChange(timeoutDelay: number) {
    preferenceStore.setPreference('automatic_logout_time', timeoutDelay)
    sendToast(i18n.t('successfullySaved'), undefined, 'success')
  }

  // **************************************
  // Change Password
  // **************************************
  const changePasswordLoading = ref(false)

  async function changePassword() {
    if (!newPasswordConfirmRef.value) return
    const validationResult = newPasswordConfirmRef.value.validate()

    // Reset message
    passwordChangeMessage.value = {
      type: 'success',
      message: '',
      visible: false,
    }

    if (validationResult) {
      passwordChangeMessage.value = {
        type: 'error',
        message: i18n.t('passwordChangeErrorDontMatch'),
        visible: true,
      }
      return
    }
    changePasswordLoading.value = true

    const settingsFlow = await sessionStore.frontend.createBrowserSettingsFlow()

    // Get csrf token
    const csrfToken = getCsrfToken(settingsFlow.data.ui.nodes)

    sessionStore.frontend
      .updateSettingsFlow({
        flow: settingsFlow.data.id,
        updateSettingsFlowBody: {
          method: 'password',
          password: formData.value.newPassword,
          csrf_token: csrfToken,
        },
      })
      .then(() => {
        passwordChangeMessage.value = {
          type: 'success',
          message: i18n.t('passwordChangeSuccess'),
          visible: true,
        }
      })
      .catch(() => {
        passwordChangeMessage.value = {
          type: 'error',
          message: i18n.t('passwordChangeError'),
          visible: true,
        }
      })
      .finally(() => {
        changePasswordLoading.value = false
      })
  }

  const otpEnabled = computed(() => {
    const authMethod =
      sessionStore.currentOrySession?.authentication_methods?.find(
        (method) => method.method === 'totp'
      )
    return !!authMethod
  })
</script>

<style scoped lang="scss">
  .form-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .automatic-logout {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .otp-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  // Change Password
  .passwort-change {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .settings-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
</style>
