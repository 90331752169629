/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataSource } from '../models/DataSource';
import type { DataSourceAvailableGroup } from '../models/DataSourceAvailableGroup';
import type { DataSourceIn } from '../models/DataSourceIn';
import type { PaginationSortOrder } from '../models/PaginationSortOrder';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DataSourcesService {
    /**
     * Get Data Sources
     * @returns DataSource Successful Response
     * @throws ApiError
     */
    public static getDataSourcesApiV1DataSourceDataSourcesGet({
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        integrationIdExists,
        integrationIdEq,
        integrationIdGt,
        integrationIdGte,
        integrationIdIn,
        integrationIdLt,
        integrationIdLte,
        integrationIdNe,
        integrationIdNin,
        integrationId,
        identifierExists,
        identifierEq,
        identifierGt,
        identifierGte,
        identifierIn,
        identifierLt,
        identifierLte,
        identifierNe,
        identifierNin,
        identifier,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        integrationIdExists?: (boolean | null),
        integrationIdEq?: (string | null),
        integrationIdGt?: (string | null),
        integrationIdGte?: (string | null),
        integrationIdIn?: (Array<string> | null),
        integrationIdLt?: (string | null),
        integrationIdLte?: (string | null),
        integrationIdNe?: (string | null),
        integrationIdNin?: (Array<string> | null),
        integrationId?: (string | null),
        identifierExists?: (boolean | null),
        identifierEq?: (string | null),
        identifierGt?: (string | null),
        identifierGte?: (string | null),
        identifierIn?: (Array<string> | null),
        identifierLt?: (string | null),
        identifierLte?: (string | null),
        identifierNe?: (string | null),
        identifierNin?: (Array<string> | null),
        identifier?: (string | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<DataSource>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/data_sources',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'integration_id.$exists': integrationIdExists,
                'integration_id.$eq': integrationIdEq,
                'integration_id.$gt': integrationIdGt,
                'integration_id.$gte': integrationIdGte,
                'integration_id.$in': integrationIdIn,
                'integration_id.$lt': integrationIdLt,
                'integration_id.$lte': integrationIdLte,
                'integration_id.$ne': integrationIdNe,
                'integration_id.$nin': integrationIdNin,
                'integration_id': integrationId,
                'identifier.$exists': identifierExists,
                'identifier.$eq': identifierEq,
                'identifier.$gt': identifierGt,
                'identifier.$gte': identifierGte,
                'identifier.$in': identifierIn,
                'identifier.$lt': identifierLt,
                'identifier.$lte': identifierLte,
                'identifier.$ne': identifierNe,
                'identifier.$nin': identifierNin,
                'identifier': identifier,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Data Sources
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countDataSourcesApiV1DataSourceDataSourcesCountGet({
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        integrationIdExists,
        integrationIdEq,
        integrationIdGt,
        integrationIdGte,
        integrationIdIn,
        integrationIdLt,
        integrationIdLte,
        integrationIdNe,
        integrationIdNin,
        integrationId,
        identifierExists,
        identifierEq,
        identifierGt,
        identifierGte,
        identifierIn,
        identifierLt,
        identifierLte,
        identifierNe,
        identifierNin,
        identifier,
        xTransactionId,
    }: {
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        integrationIdExists?: (boolean | null),
        integrationIdEq?: (string | null),
        integrationIdGt?: (string | null),
        integrationIdGte?: (string | null),
        integrationIdIn?: (Array<string> | null),
        integrationIdLt?: (string | null),
        integrationIdLte?: (string | null),
        integrationIdNe?: (string | null),
        integrationIdNin?: (Array<string> | null),
        integrationId?: (string | null),
        identifierExists?: (boolean | null),
        identifierEq?: (string | null),
        identifierGt?: (string | null),
        identifierGte?: (string | null),
        identifierIn?: (Array<string> | null),
        identifierLt?: (string | null),
        identifierLte?: (string | null),
        identifierNe?: (string | null),
        identifierNin?: (Array<string> | null),
        identifier?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/data_sources/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'integration_id.$exists': integrationIdExists,
                'integration_id.$eq': integrationIdEq,
                'integration_id.$gt': integrationIdGt,
                'integration_id.$gte': integrationIdGte,
                'integration_id.$in': integrationIdIn,
                'integration_id.$lt': integrationIdLt,
                'integration_id.$lte': integrationIdLte,
                'integration_id.$ne': integrationIdNe,
                'integration_id.$nin': integrationIdNin,
                'integration_id': integrationId,
                'identifier.$exists': identifierExists,
                'identifier.$eq': identifierEq,
                'identifier.$gt': identifierGt,
                'identifier.$gte': identifierGte,
                'identifier.$in': identifierIn,
                'identifier.$lt': identifierLt,
                'identifier.$lte': identifierLte,
                'identifier.$ne': identifierNe,
                'identifier.$nin': identifierNin,
                'identifier': identifier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Data Source
     * @returns DataSource Successful Response
     * @throws ApiError
     */
    public static getDataSourceApiV1DataSourceDataSourcesDataSourceIdGet({
        dataSourceId,
        xTransactionId,
    }: {
        dataSourceId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<DataSource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/data_sources/{data_source_id}',
            path: {
                'data_source_id': dataSourceId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put Data Sources
     * @returns DataSource Successful Response
     * @throws ApiError
     */
    public static putDataSourcesApiV1DataSourceDataSourcesDataSourceIdPut({
        dataSourceId,
        requestBody,
        xTransactionId,
    }: {
        dataSourceId: string,
        requestBody: DataSourceIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<DataSource> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/data_source/data_sources/{data_source_id}',
            path: {
                'data_source_id': dataSourceId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Data Source
     * @returns void
     * @throws ApiError
     */
    public static deleteDataSourceApiV1DataSourceDataSourcesDataSourceIdDelete({
        dataSourceId,
        xTransactionId,
    }: {
        dataSourceId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/data_source/data_sources/{data_source_id}',
            path: {
                'data_source_id': dataSourceId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Data Source Available Groups
     * @returns DataSourceAvailableGroup Successful Response
     * @throws ApiError
     */
    public static getDataSourceAvailableGroupsApiV1DataSourceDataSourcesDataSourceIdAvailableGroupsGet({
        dataSourceId,
        xTransactionId,
    }: {
        dataSourceId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<DataSourceAvailableGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/data_sources/{data_source_id}/available-groups',
            path: {
                'data_source_id': dataSourceId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
