import { PreferenceLanguages } from '@/client'
import { usePreferenceStore } from '@/stores/preferenceStore'
import {
  DateFormatter,
  ResolvedDateTimeFormatOptions,
} from '@internationalized/date'

export function displayDate(
  date: Date,
  style: ResolvedDateTimeFormatOptions['timeStyle'] = 'long'
) {
  const preferenceStore = usePreferenceStore()
  const languageFormat = ref('de-De')

  if (preferenceStore.preferences?.language === PreferenceLanguages.EN) {
    languageFormat.value = 'en-US'
  }

  const df = new DateFormatter(languageFormat.value, {
    dateStyle: style,
  })

  return df.format(date)
}
