<template>
  <SmTooltip :active="showTooltip" placement="bottom">
    <!-- Tooltips -->
    <template #content>
      <!-- No Input -->
      <div v-if="!props.password" class="sm-password-strength-empty">
        <p>{{ i18n.t('sm.passwordStrength.empty') }}</p>
      </div>
      <!-- Tipps -->
      <ul
        v-else-if="result && result?.feedback?.suggestions.length > 0"
        class="sm-password-strength-suggestions">
        <li
          v-for="suggestion in result?.feedback?.suggestions"
          :key="suggestion">
          {{ suggestion }}
        </li>
      </ul>
      <!-- Password is secure -->
      <div v-else-if="result && result?.score >= 4">
        <span>{{ i18n.t('sm.passwordStrength.secure') }}</span>
      </div>
    </template>

    <!-- Progress Bar -->
    <div class="sm-password-strength">
      <!-- Progress bars -->
      <div
        v-for="i in 4"
        :key="i"
        :class="{
          'sm-password-strength-progress': true,
          'progress-moved': result && result?.score >= i,
        }">
        <div class="sm-password-strength-progress-bar"></div>
      </div>
    </div>
  </SmTooltip>
</template>

<script lang="ts" setup>
  import { zxcvbnAsync, zxcvbnOptions, ZxcvbnResult } from '@zxcvbn-ts/core'
  import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
  import * as zxcvbnDePackage from '@zxcvbn-ts/language-de'
  import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'
  import { useI18n } from 'vue-i18n'

  import SmTooltip from '../SmTooltip.vue'

  const i18n = useI18n()

  const props = defineProps({
    password: {
      type: String,
      default: '',
    },
  })
  const result: Ref<ZxcvbnResult | null> = ref(null)

  const translations = computed(() => {
    if (i18n.locale.value === 'de') {
      return zxcvbnDePackage.translations
    } else {
      return zxcvbnEnPackage.translations
    }
  })

  const options = {
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary, // English
      ...zxcvbnDePackage.dictionary, // German
    },
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    useLevenshteinDistance: true,
    translations: translations.value,
  }
  zxcvbnOptions.setOptions(options)

  async function useZxcvbn() {
    if (props.password) {
      result.value = await zxcvbnAsync(props.password)
    } else {
      result.value = null
    }
  }

  watch(
    () => props.password,
    () => {
      useZxcvbn()
    }
  )

  const showTooltip = computed(() => {
    return (
      (result.value && result.value?.feedback?.suggestions?.length > 0) ||
      result.value?.score === 4
    )
  })
</script>

<style lang="scss">
  $height: 8px;

  .sm-password-strength {
    width: 100%;
    height: $height;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 6px;

    margin-top: 6px;

    &-suggestions {
      padding: 0.5rem 1rem;
      margin: 0;
    }
  }

  .progress-bar {
    height: $height;
    border-radius: 4px;
    transition: 0.4s linear;
    transition-property: width, background-color;
  }

  .sm-password-strength-progress {
    height: $height;

    border-radius: 30px;
    background: var(--sm-contrast-muted);
  }

  .sm-password-strength-progress-bar {
    height: $height;
    border-radius: 30px;
    background-color: var(--sm-contrast-muted);
    transition: 0.4s linear;
    transition-property: width, background-color;
  }

  //   ***** Animation *****
  //   Define colors for each progress bar
  $colors: (
    1: var(--sm-magenta),
    2: var(--sm-orange),
    3: var(--sm-primary),
    4: var(--sm-primary),
  );

  //   Generic animation
  @mixin progressAnimation(
    $color,
    $name: 'progressAnimation',
    $duration: 333ms
  ) {
    @keyframes #{$name} {
      0% {
        width: 0%;
        //   Change this to get a color transition (Starting color)
        background-color: $color;
      }
      100% {
        width: 100%;
        //  Change this to get a color transition (Ending color)
        background-color: $color;
      }
    }
    animation-name: #{$name};
    animation-duration: $duration;

    // Final state of the animation
    width: 100%;
    background-color: $color;
  }

  @each $key, $color in $colors {
    .sm-password-strength-progress:nth-child(#{$key}).progress-moved
      .sm-password-strength-progress-bar {
      // Define the animation for each key
      //   Note: Color is not possible when variable is used
      @include progressAnimation($color, 'progressAnimation-#{$key}');
    }
  }
</style>
