<template>
  <SmDialog
    v-model:visibility="visible"
    :size="dialogSize"
    :title="drawerTitle">
    <SmSteps
      v-model:active-step-key="activeStepKey"
      content-height="calc(70vh - 4rem)"
      :steps="steps">
      <!-- User Form Content -->
      <template #step-user-form>
        <UserForm
          ref="userForm"
          v-model:user="account"
          :extra-items="[{ name: 'tags', required: false, label: 'Tags' }]">
          <template #extra-item-tags>
            <TagSelect
              :selected="account.tags"
              :available-tags="allTags"
              @add-tag="addTagToUser"
              @delete-tag="removeTagFromUser">
              <div class="align wrap cursor-pointer flex-row gap-1">
                <span v-for="tagId in account.tags" :key="tagId">
                  <TagComponent :tag="getTag(tagId) as Tag" />
                </span>
                <v-icon name="hi-plus" />
              </div>
            </TagSelect>
          </template>
        </UserForm>
      </template>
      <!-- License Selection Content -->
      <template #step-license-select>
        <GroupAddSoftware
          v-model:selection="licenseSelection"
          :all-price-informations
          :all-software-licenses
          :all-applications="allApps" />
      </template>
      <!-- Overview Content for selected Licenses and User -->
      <template #step-overview>
        <div class="user-overview flex-col gap-1">
          <div class="align flex-row gap-1">
            <SmAvatar
              class="bg-gray-200"
              :name="
                getUserNameDisplay(account.first_name, account.last_name)
              " />
            <div class="flex-col">
              <h3>
                {{ getUserNameDisplay(account.first_name, account.last_name) }}
              </h3>
              <p>
                {{ account.email }}
              </p>
            </div>
          </div>
          <h3>{{ i18n.t('userInformation') }}</h3>
          <SmDescriptions
            :descriptions="[
              {
                key: 'firstName',
                title: i18n.t('general.firstName'),
                content: account.first_name || undefined,
              },
              {
                key: 'lastName',
                title: i18n.t('general.lastName'),
                content: account.last_name || undefined,
              },
              {
                key: 'phone',
                title: i18n.t('phone'),
                content: account.phone || undefined,
              },
              {
                key: 'employeeId',
                title: i18n.t('general.employeeId'),
                content: account.employee_id || undefined,
              },
            ]">
          </SmDescriptions>
          <SmDescriptions
            :number-columns="1"
            :descriptions="[{ key: 'tags', title: 'Tags' }]">
            <template #field-tags>
              <span v-for="tagId in account.tags" :key="tagId">
                <TagComponent :tag="getTag(tagId) as Tag" />
              </span>
            </template>
          </SmDescriptions>
          <h3 v-if="!isEmpty(licenseSelection)">{{ i18n.t('licenses') }}</h3>
          <div v-if="!isEmpty(licenseSelection)" class="flex-col gap-2">
            <span v-for="(app, appId) of licenseSelection" :key="appId">
              <span v-for="(models, subAccountId) in app" :key="subAccountId">
                <div
                  v-if="hasEntries(models)"
                  class="align pad-b-1 flex-row gap-1">
                  <ApplicationIcon
                    size="30px"
                    :software-name="getApp(appId)?.software_name"
                    :software-type="getApp(appId)?.type" />
                  <h4>
                    {{ getSoftwareDisplayName(getApp(appId)) }}
                  </h4>
                  <span
                    v-if="getSubAcc(appId, subAccountId)"
                    class="subaccount-text">
                    {{ getSubAcc(appId, subAccountId)!.name }}
                  </span>
                </div>
                <LicenseModelEditTable
                  v-if="hasEntries(models)"
                  :sub-account-id="subAccountId.toString()"
                  :selectable="false"
                  :price-informations="allPriceInformations"
                  :software-licenses="filterSoftwareLicenses(appId.toString())"
                  :software="getApp(appId) as SoftwareOut" />
              </span>
            </span>
          </div>
        </div>
      </template>
      <template #step-overview-next-button="{ goToNextStep }">
        <SmButton @click="submitAllRequests(goToNextStep)">
          {{ i18n.t('createUser') }}
        </SmButton>
      </template>
      <!-- Request Confirmation Content for user and license requests -->
      <template #step-request-confirmation>
        <div class="pad-b-1 flex-col gap-2">
          <span v-for="(feedbackItem, index) in requests" :key="index">
            <RequestFeedbackDisplay
              v-if="feedbackItem.type == 'user-post'"
              v-model:status="feedbackItem.status"
              :success-message="
                i18n.t('accountCreatedSuccessfully', { email: account.email })
              "
              :request="feedbackItem.request">
              <SmAvatar
                size="small"
                class="bg-gray-200"
                :name="
                  getUserNameDisplay(account.first_name, account.last_name)
                " />
              <div class="flex-col">
                <h3>
                  {{
                    getUserNameDisplay(account.first_name, account.last_name)
                  }}
                </h3>
                <span>
                  {{ i18n.t('creatingAnAccountFor', { email: account.email }) }}
                </span>
              </div>
            </RequestFeedbackDisplay>
            <RequestFeedbackDisplay
              v-else-if="
                requests &&
                feedbackItem.type == 'license-post' &&
                requests[index - 1].status == 'finished'
              "
              v-model:status="feedbackItem.status"
              :success-message="
                i18n.t('licenseCreatedSuccessfully', {
                  email: feedbackItem.resource.email,
                  softwareName: getApp(feedbackItem.resource.software_id)!
                    .software_name,
                })
              "
              :request="feedbackItem.request">
              <ApplicationIcon
                size="40px"
                :software-name="
                  getApp(feedbackItem.resource.software_id)!.software_name
                "
                :software-type="
                  getApp(feedbackItem.resource.software_id)!.type
                " />
              <div class="flex-col">
                <div class="align flex-row gap-1">
                  <h3>
                    {{
                      getSoftwareDisplayName(
                        getApp(feedbackItem.resource.software_id)!
                      )
                    }}
                  </h3>
                  <span class="subaccount-text">
                    {{
                      getSubAcc(
                        feedbackItem.resource.software_id,
                        feedbackItem.resource.sub_account_id
                      )!.name
                    }}
                  </span>
                </div>
                <span>
                  {{
                    i18n.t('creatingALicenseFor', {
                      email: feedbackItem.resource.email,
                    })
                  }}
                </span>
              </div>
            </RequestFeedbackDisplay>
            <div
              v-if="
                requests &&
                index < requests.length - 1 &&
                requests[index].status == 'finished'
              "
              class="divider" />
          </span>
        </div>
      </template>
      <template #step-request-confirmation-back-button>
        <!-- The span is necessary to hide the back-button -->
        <span></span>
      </template>
      <template #finish-button>
        <SmButton @click="handleClose"> {{ i18n.t('close') }} </SmButton>
      </template>
    </SmSteps>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import {
    AccountIn,
    Licence_Input,
    PriceInformation,
    SoftwareLicense,
    SoftwareLicenseMini,
    SoftwareOut,
    Tag,
    UserLicencesService,
    UsersService,
  } from '@/client'
  import { SelectionState } from '@/components/GroupAddSoftware.vue'
  import UserForm from '@/components/UserForm.vue'

  // Utility-type for below, Conditionally resolving to return-type of UserPost or LicensePost request
  type RequestReturn<T extends 'license-post' | 'user-post'> =
    T extends 'user-post'
      ? ReturnType<(typeof UsersService)['addUserApiV1ManagementUsersPost']>
      : ReturnType<
          (typeof UserLicencesService)['postLicenceApiV1SoftwareLicencesPost']
        >

  type RequestFeedbackInfo<T extends 'license-post' | 'user-post'> = {
    type: T
    request: () => RequestReturn<T>
    resource: T extends 'license-post' ? Licence_Input : AccountIn
    status: 'not-started' | 'loading' | 'finished'
  }

  const props = defineProps<{
    allApps: SoftwareOut[]
    allSoftwareLicenses: SoftwareLicense[]
    allPriceInformations: PriceInformation[]
    allTags: Tag[]
  }>()

  const i18n = useI18n()

  const account = ref<AccountIn & { tags: string[] }>({
    email: '',
    tags: [],
  })

  const userForm = ref<InstanceType<typeof UserForm>>()
  const licenseSelection = ref<SelectionState>({})
  // all selected License-Models by appId (better should be by appId & subAccountId)
  // First entry of requests is the user-post-request, following entries must be license-post-requests
  const requests =
    ref<
      [
        RequestFeedbackInfo<'user-post'>,
        ...RequestFeedbackInfo<'license-post'>[],
      ]
    >()
  const steps = ref([
    {
      key: 'user-form',
      nextText: i18n.t('chooseLicenses'),
      // Validation of the form is required to enter next step
      nextStepValidator: () => {
        return (userForm.value?.validate() as Promise<boolean>) || false
      },
    },
    {
      key: 'license-select',
      nextText: i18n.t('overview'),
      backText: i18n.t('editUser'),
    },
    {
      key: 'overview',
      backText: i18n.t('editLicenses'),
    },
    {
      key: 'request-confirmation',
      backText: i18n.t('overview'),
    },
  ])
  const activeStepKey = ref('user-form') // starting with user-form
  const visible = defineModel<boolean>('visible', { default: false })

  // dialog will be bigger for license-selection
  const dialogSize = computed(() => {
    switch (activeStepKey.value) {
      case 'license-select':
        return 'large' as const
      default:
        return 'medium' as const
    }
  })

  const drawerTitle = computed(() => {
    switch (activeStepKey.value) {
      case 'user-form':
        return i18n.t('createUser')
      case 'license-select':
        return i18n.t('addLicenses')
      case 'overview':
        return i18n.t('overview')
      case 'request-confirmation':
        return i18n.t('confirmation')
      default:
        return i18n.t('createUser')
    }
  })

  function filterSoftwareLicenses(appId: string) {
    return props.allSoftwareLicenses.filter((s) => s.software_id == appId)
  }

  function isEmpty(state: SelectionState) {
    let isEmpty = true
    for (const app of Object.values(state)) {
      if (app == null) continue
      for (const subAccEntry of Object.values(app)) {
        for (const model of Object.values(subAccEntry)) {
          if (model != null) {
            isEmpty = false
            break
          }
        }
      }
    }
    return isEmpty
  }

  function hasEntries<T>(record: Record<string, T>) {
    return Object.keys(removeNull(record)).length > 0
  }

  function removeNull<T>(record: Record<string, T | null>) {
    const returnRecord: Record<string, T> = {}
    for (const [id, entry] of Object.entries(record)) {
      if (entry != null) returnRecord[id] = entry
    }
    return returnRecord
  }

  function addTagToUser(tagId: string) {
    account.value.tags.push(tagId)
  }
  function removeTagFromUser(tagId: string) {
    account.value.tags = account.value.tags.filter((t) => t != tagId)
  }

  // Mapping DB Models to Mini_Input for license-post-requests
  function mapLicenseModels(
    models: Record<string, SoftwareLicense | null>
  ): Record<string, SoftwareLicenseMini> {
    const returnModels: Record<string, SoftwareLicenseMini> = {}
    const mappedModels = Object.values(models).map((m) => {
      if (m == null) return null
      return {
        name: m.name,
      }
    })
    Object.keys(models).forEach((key, index) => {
      if (mappedModels[index] == null) return
      returnModels[key] = mappedModels[index]!
    })
    return returnModels
  }

  function getUserNameDisplay(
    first_name: string | undefined | null,
    last_name: string | undefined | null
  ) {
    let name = ''
    if (first_name) name += first_name + ' '
    if (last_name) name += last_name
    if (!first_name && !last_name) name = i18n.t('noNameSpecified')
    return name
  }

  function getSoftwareDisplayName(a?: SoftwareOut) {
    if (!a) return i18n.t('noNameSpecified')
    return a.display_name || a.software_name
  }

  function getTag(tagId: string) {
    return props.allTags.find((t) => t._id == tagId)
  }
  function getSubAcc(appId: string | number, id: string | number) {
    return getApp(appId)?.sub_accounts[id]
  }
  function getApp(id: string | number) {
    return props.allApps.find((a) => a._id == id)
  }

  // Making user-post request and all license-post requests
  function submitAllRequests(goToNextStep: () => unknown) {
    const userPostRequest = () => {
      return UsersService.addUserApiV1ManagementUsersPost({
        requestBody: account.value,
      })
    }

    const userRequestInfo: RequestFeedbackInfo<'user-post'> = {
      type: 'user-post',
      request: userPostRequest,
      resource: account.value,
      status: 'not-started',
    }
    requests.value = [userRequestInfo]

    for (const [softwareId, softwareSelect] of Object.entries(
      licenseSelection.value
    )) {
      if (softwareSelect == null) continue
      for (const [subAccountId, subAccountSelect] of Object.entries(
        softwareSelect
      )) {
        if (!hasEntries(subAccountSelect)) continue
        const licence: Licence_Input = {
          email: account.value.email,
          sub_account_id: subAccountId,
          software_id: softwareId,
          software_licenses: mapLicenseModels(subAccountSelect),
        }
        const licenseRequest = () => {
          return UserLicencesService.postLicenceApiV1SoftwareLicencesPost({
            requestBody: licence,
          })
        }
        const licenseRequestInfo: RequestFeedbackInfo<'license-post'> = {
          type: 'license-post',
          request: licenseRequest,
          resource: licence,
          status: 'not-started',
        }
        requests.value!.push(licenseRequestInfo)
      }
    }

    goToNextStep()
  }

  function handleClose() {
    visible.value = false
    activeStepKey.value = 'user-form'
    account.value = { email: '', tags: [] }
    licenseSelection.value = {}
    requests.value = undefined
  }
</script>

<style scoped lang="scss">
  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .align {
    align-items: center;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .gap-1 {
    gap: 1rem;
  }

  .gap-2 {
    gap: 2rem;
  }

  .pad-b-1 {
    padding-bottom: 1rem;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .user-overview {
    padding-bottom: 2rem;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .subaccount-text {
    font-size: var(--font-size-small);
    margin-left: -0.5rem;
    color: var(--sm-contrast-muted);
  }

  .divider {
    margin-top: 2rem;
    margin-left: 5%;
    border-top: 1px solid var(--sm-contrast-muted);
    width: 90%;
    height: 1px;
  }
</style>
