import { PreferenceLanguages } from '@/client'
import { usePreferenceStore } from '@/stores/preferenceStore'
import { useColorMode } from '@vueuse/core'

function applyColorMode(isDark: boolean) {
  const mode = useColorMode()
  mode.value = isDark ? 'dark' : 'light'
}

function applyLanguage(lang: PreferenceLanguages) {
  const preferenceStore = usePreferenceStore()
  preferenceStore.setPreference('language', lang)
}

export function applyPreference(
  preference: string,
  value: string | number | boolean | PreferenceLanguages | unknown
) {
  switch (preference) {
    case 'isDark':
      return applyColorMode(value as boolean)
    case 'language':
      return applyLanguage(value as PreferenceLanguages)
    default:
      return
  }
}
