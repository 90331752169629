<!-- SM Datepicker -->
<template>
  <div v-if="props.isDropdown" ref="dropdown" class="sm-datepicker">
    <SmDropdown
      ref="dropdownRef"
      :close-on-click-inside="false"
      :class="{ 'cursor-not-allowed': props.disabled }"
      dropdown-class="min-w-64 !max-w-80"
      max-height="500px"
      type="panel">
      <template #trigger>
        <div
          class="dropdown-select flex border border-gray-300 dark:border-gray-600">
          <slot name="trigger">
            <v-icon name="hi-calendar" scale="1.1" class="mr-4" />
            <template v-if="date"> {{ displayDate(date) }}</template>
            <template v-else>
              <span class="text-contrast-muted">{{
                props.placeholder || i18n.t('pleaseSelectDate')
              }}</span>
            </template>
          </slot>

          <!-- dropdown indicator -->
          <v-icon
            name="md-expandmore-round"
            scale="1.5"
            class="dropdown-indicator ml-auto cursor-pointer" />
        </div>
      </template>

      <!-- Add calender -->
      <Calendar
        v-if="!props.isRange"
        v-model="calendarDate"
        :weekday-format="'short'"
        :locale="locale"
        :min-value="today(getLocalTimeZone())"
        :week-starts-on="1" />

      <div v-if="props.isRange" class="flex gap-6">
        <div
          v-if="props.quickOptions"
          class="flex min-w-40 flex-col gap-1 border-r pr-6">
          <SmDropdownItem
            v-for="option of props.quickOptions"
            :key="option.label"
            :value="option"
            class="flex-col items-start gap-0"
            :class="{ 'bg-gray-100 dark:bg-gray-600': dateMatch(option) }"
            @click="setDateRange(option)">
            <p class="block">{{ option.label }}</p>
          </SmDropdownItem>
        </div>

        <RangeCalendar
          v-model="calendarDateRange"
          style="max-width: 240px"
          class="p-0"
          :weekday-format="'short'"
          :locale="locale"
          :week-starts-on="1" />
      </div>
    </SmDropdown>
  </div>

  <div v-else-if="!props.isDropdown">
    <Calendar
      v-if="!props.isRange"
      v-model="calendarDate"
      :weekday-format="'short'"
      :locale="locale"
      :min-value="today(getLocalTimeZone())"
      :week-starts-on="1" />

    <div v-if="props.isRange" class="flex gap-6">
      <div
        v-if="props.quickOptions"
        class="flex min-w-40 flex-col gap-1 border-r pr-6">
        <SmDropdownItem
          v-for="option of props.quickOptions"
          :key="option.label"
          :value="option"
          class="flex-col items-start gap-0"
          :class="{ 'bg-gray-100 dark:bg-gray-600': dateMatch(option) }"
          @click="setDateRange(option)">
          <p class="block">{{ option.label }}</p>
        </SmDropdownItem>
      </div>

      <RangeCalendar
        v-model="calendarDateRange"
        style="max-width: 240px"
        class="p-0"
        :weekday-format="'short'"
        :locale="locale"
        :week-starts-on="1" />
    </div>
  </div>
</template>

<script lang="ts">
  export type QuickOption = {
    label: string
    start: Date | null
    end: Date | null
  }
</script>

<script setup lang="ts">
  import {
    CalendarDate,
    DateValue,
    getLocalTimeZone,
    parseDate,
    today,
  } from '@internationalized/date'
  import SmDropdown from './SmDropdown.vue'
  import { displayDate } from '@/common/util/timeUtils'
  import dayjs from 'dayjs'
  import localizedFormat from 'dayjs/plugin/localizedFormat'
  import utc from 'dayjs/plugin/utc'
  import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
  import { usePreferenceStore } from '@/stores/preferenceStore'
  import { DateRange } from 'radix-vue'
  import { useI18n } from 'vue-i18n'

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)

  const i18n = useI18n()

  const emit = defineEmits(['update:date', 'update:daterange'])
  const date = defineModel('date', { type: Object as PropType<Date> })
  const daterange = defineModel('daterange', {
    type: Object as PropType<Date[]>,
  })

  const preferenceStore = usePreferenceStore()

  const props = defineProps({
    isDropdown: {
      type: Boolean,
      default: true,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    quickOptions: {
      type: Array<QuickOption>,
      default: false,
    },
  })

  const locale = computed(() => {
    switch (preferenceStore.preferences?.language) {
      case 'de':
        return 'de-DE'
      case 'en':
        return 'en-US'
      default:
        return 'de-DE'
    }
  })

  const calendarDateRange = computed<DateRange | undefined>({
    get() {
      if (daterange.value) {
        return {
          start: dateToCalenderDate(daterange.value[0]),
          end: dateToCalenderDate(daterange.value[1]),
        }
      } else {
        return undefined
      }
    },
    set(value) {
      if (value && value.start && value.end) {
        const start = value.start.toDate
          ? value.start.toDate(getLocalTimeZone())
          : undefined
        const end = value.end.toDate
          ? value.end.toDate(getLocalTimeZone())
          : undefined

        emit('update:daterange', [start, end])
      }
    },
  })

  // Computed property to convert native Date to DateValue
  const calendarDate = computed<DateValue | undefined>({
    get() {
      return date.value ? dateToCalenderDate(date.value) : undefined
    },
    set(value) {
      // Convert CalendarDate back to native Date
      if (value) {
        const nativeDate = value.toDate(getLocalTimeZone())
        emit('update:date', nativeDate)
      } else {
        emit('update:date', undefined)
      }
    },
  })

  function setDateRange(option: QuickOption) {
    const dateRange: DateRange = {
      start: dateToCalenderDate(
        option.start ?? dayjs().subtract(5, 'year').toDate()
      ),
      end: dateToCalenderDate(option.end ?? dayjs().add(5, 'year').toDate()),
    }

    calendarDateRange.value = dateRange
  }

  function dateToCalenderDate(date: Date): CalendarDate {
    return parseDate(dayjs(date).format('YYYY-MM-DD'))
  }

  function dateMatch(option: QuickOption) {
    if (!daterange.value) return false
    if (
      dayjs(daterange.value[0]).isSame(option.start, 'day') &&
      dayjs(daterange.value[1]).isSame(option.end, 'day')
    ) {
      return true
    }
    return false
  }
</script>

<style lang="scss">
  .sm-datepicker {
    .trigger {
      .dropdown-indicator {
        transition: all 0.2s ease;
        transform: rotate(0deg);
      }
      &.active {
        .dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }
  }
  input {
    background: none;
  }

  .dropdown-select {
    display: flex;
    align-items: center;

    padding: 0.5rem 1rem;

    border-radius: var(--border-radius-base);
    font-size: 1rem;
  }

  .dropdown-item {
    border-radius: var(--border-radius-base);
  }

  .is-error .dropdown-select {
    border-color: var(--sm-red);
  }
</style>
