<template>
  <SmDialog
    v-if="settingsDialogOpen"
    v-model:visibility="settingsDialogOpen"
    :title="i18n.t('graphSettings')"
    size="small">
    <p class="mb-4">
      {{ i18n.t('chartDateRangeDesc') }}
    </p>
    <el-date-picker
      v-model="requestedRange"
      type="monthrange"
      range-separator="-"
      :start-placeholder="i18n.t('views.documents.dateRange.from')"
      :end-placeholder="i18n.t('views.documents.dateRange.to')"
      format="MMM. YYYY"
      @update:model-value="settingsDialogOpen = false" />

    <!-- Footer -->
    <template #footer>
      <div class="close-btn">
        <SmButton @click="settingsDialogOpen = false">
          {{ i18n.t('close') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>

  <el-card
    class="information-chart-card"
    :body-style="{
      height: '100%',
      position: 'relative',
    }">
    <!-- Tabs -->
    <el-tabs v-if="!props.hideTabs" v-model="activeTabName">
      <el-tab-pane
        name="account_amount"
        :label="i18n.t('views.dashboard.informationCard.tabs.users')"
        lazy />
      <el-tab-pane
        name="licence_amount"
        :label="i18n.t('views.dashboard.informationCard.tabs.licences')"
        lazy />
      <el-tab-pane
        name="software_amount"
        :label="i18n.t('views.dashboard.informationCard.tabs.applications')"
        lazy />
      <el-tab-pane
        name="cost"
        :label="i18n.t('views.dashboard.informationCard.tabs.saasSpends')"
        lazy />
    </el-tabs>

    <!-- Chart -->
    <div class="chart">
      <InformationChart
        v-model:requestedRange="requestedRange"
        :field="activeTabName"
        :chart-color="chartPrimaryColor" />
    </div>

    <!-- Control Button -->
    <div class="graph-control-button" @click="handleSettingsButtonClick">
      <v-icon
        name="md-settings-round"
        class="fill-contrast-muted"
        scale="1.1" />
    </div>
  </el-card>
</template>

<script lang="ts" setup>
  import 'chartjs-plugin-style'

  import { useI18n } from 'vue-i18n'

  import { MetadataQueryField } from '@/client'
  import InformationChart from '@/components/charts/InformationChart.vue'

  const props = defineProps({
    softwareId: {
      type: String as PropType<string | undefined | null>,
      default: undefined,
    },
    hideTabs: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    chartType: {
      type: String as PropType<MetadataQueryField>,
      default: undefined,
    },
  })

  const i18n = useI18n()

  const settingsDialogOpen = ref(false)

  // Range that should be requested from the server
  // Default is the last 60 days
  const requestedRange = ref<[Date, Date]>([
    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
    new Date(),
  ])

  const activeTabName = ref<MetadataQueryField>(
    props.chartType || MetadataQueryField.ACCOUNT_AMOUNT
  )

  function getCssColorByName(name: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(name)
  }

  const chartPrimaryColor = computed(() => {
    if (activeTabName.value === 'account_amount') {
      return getCssColorByName('--sm-yellow')
    } else if (activeTabName.value === 'software_amount') {
      return getCssColorByName('--sm-purple')
    } else if (activeTabName.value === 'licence_amount') {
      return getCssColorByName('--sm-red')
    } else return getCssColorByName('--sm-primary')
  })

  function handleSettingsButtonClick() {
    settingsDialogOpen.value = true
  }
</script>

<style lang="scss">
  .information-chart-card {
    height: 100%;

    .el-tabs__item {
      padding: 0 10px !important;
      height: 35px !important;

      &:hover {
        color: v-bind(chartPrimaryColor);
      }
    }

    .el-tabs__item.is-active {
      color: v-bind(chartPrimaryColor);
    }

    .el-tabs__active-bar {
      background-color: v-bind(chartPrimaryColor);
    }

    .chart {
      position: relative;
      height: calc(100% - 40px);
      width: 100%;

      canvas {
        height: 100%;
      }
    }
  }

  .graph-control-button {
    position: absolute;
    top: 10px;
    right: 5px;
    margin: 10px;
    padding: 5px;
    border-radius: 50%;

    cursor: pointer;
  }

  .close-btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;
  }
</style>
