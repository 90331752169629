/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SoftwareInformation } from '../models/SoftwareInformation';
import type { SoftwareStatus } from '../models/SoftwareStatus';
import type { SoftwareTypes } from '../models/SoftwareTypes';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SoftwareIntegrationsService {
    /**
     * Get Integrations
     * Get all integrations matching the given criteria. If no criteria are given, all integrations are returned.
     *
     * If a software name is given, only one (list with one element) or no (empty list) integration is returned.
     * @returns SoftwareInformation Successful Response
     * @throws ApiError
     */
    public static getIntegrationsApiV1SoftwareIntegrationsGet({
        softwareName,
        softwareType,
        softwareStatus,
        xTransactionId,
    }: {
        /**
         * Software name
         */
        softwareName?: (string | null),
        /**
         * Software type
         */
        softwareType?: (SoftwareTypes | null),
        /**
         * Software status
         */
        softwareStatus?: (Array<SoftwareStatus> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<SoftwareInformation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/integrations',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'software_name': softwareName,
                'software_type': softwareType,
                'software_status': softwareStatus,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Integration
     * @returns SoftwareInformation Successful Response
     * @throws ApiError
     */
    public static getIntegrationApiV1SoftwareIntegrationsSoftwareNameGet({
        softwareName,
        xTransactionId,
    }: {
        /**
         * Software name
         */
        softwareName: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareInformation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/integrations/{software_name}',
            path: {
                'software_name': softwareName,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Public Integrations
     * @returns SoftwareInformation Successful Response
     * @throws ApiError
     */
    public static getPublicIntegrationsIntegrationsGet({
        xTransactionId,
    }: {
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<SoftwareInformation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/integrations',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
