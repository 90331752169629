<!--
    This component is used to select an alias from a list of emails.

    Following options are available:
    - assign an alias from a list of emails
    - remove an alias

    All requests are made to the backend.

    TODO:
    - add a loading state
    - loading notification
    - use SmSelect instead of ElSelect
 -->

<template>
  <div style="width: 90%">
    <TextMultiline v-if="props.license.account_id">
      {{
        `${getAccountById(props.license.account_id)?.email} (${
          props.license.email
        })`
      }}
    </TextMultiline>
    <el-select-v2
      v-else
      v-model="displayName"
      :options="options"
      filterable
      size="small"
      @change="(newVal: string) => handleAliasChange(newVal)">
    </el-select-v2>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { Account, LicenceOut, UserAliasesService } from '@/client'

  import { sendToast } from './sm/SmNotification'

  const i18n = useI18n()

  const props = defineProps({
    users: {
      type: Object as PropType<Account[]>,
      required: true,
    },

    license: {
      type: Object as PropType<LicenceOut>,
      required: true,
    },
  })

  //   Events
  // - licenseChanged: Emitted when an alias is added to an account
  const emit = defineEmits(['update:selection', 'licenseChanged'])

  const displayName = computed({
    // getter
    get() {
      if (props.license.account_id) {
        const account = getAccountById(props.license.account_id)
        return `${account?.email} (${props.license.email})`
      }
      // In this case this is a username
      return props.license.email
    },
    // setter
    set(newValue) {
      handleAliasChange(newValue as string)
    },
  })

  // Options for the select
  const options = computed(() => {
    return props.users
      .map((user) => {
        return {
          value: user.email,
          label: user.email,
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  })

  function getAccountById(id: string) {
    return props.users.find((user) => user._id === id)
  }

  function handleAliasChange(newAlias: string) {
    UserAliasesService.addAliasApiV1ManagementUsersEmailOrIdAliasPost({
      // Current email
      emailOrId: newAlias,
      requestBody: {
        // Username that should be added to the account
        user_name: props.license.email,
      },
    }).then(async () => {
      emit('licenseChanged')
      sendToast(i18n.t('alias.addAliasSuccess'), undefined, 'success')
    })
  }
</script>

<style lang="scss" scoped></style>
