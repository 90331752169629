/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Optimization } from '../models/Optimization';
import type { OptimizationStatus } from '../models/OptimizationStatus';
import type { OptimizationType } from '../models/OptimizationType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OptimizationsService {
    /**
     * Get Optimizations
     * @returns Optimization Successful Response
     * @throws ApiError
     */
    public static getOptimizationsApiV1ManagementOptimizationsGet({
        limit,
        offset,
        cached = false,
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        licenceIdExists,
        licenceIdEq,
        licenceIdGt,
        licenceIdGte,
        licenceIdIn,
        licenceIdLt,
        licenceIdLte,
        licenceIdNe,
        licenceIdNin,
        licenceId,
        licenceIdsExists,
        licenceIdsEq,
        licenceIdsGt,
        licenceIdsGte,
        licenceIdsIn,
        licenceIdsLt,
        licenceIdsLte,
        licenceIdsNe,
        licenceIdsNin,
        licenceIds,
        accountIdExists,
        accountIdEq,
        accountIdGt,
        accountIdGte,
        accountIdIn,
        accountIdLt,
        accountIdLte,
        accountIdNe,
        accountIdNin,
        accountId,
        statusExists,
        statusEq,
        statusGt,
        statusGte,
        statusIn,
        statusLt,
        statusLte,
        statusNe,
        statusNin,
        status,
        optimizationTypeExists,
        optimizationTypeEq,
        optimizationTypeGt,
        optimizationTypeGte,
        optimizationTypeIn,
        optimizationTypeLt,
        optimizationTypeLte,
        optimizationTypeNe,
        optimizationTypeNin,
        optimizationType,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        xTransactionId,
    }: {
        limit?: number,
        offset?: number,
        cached?: boolean,
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        licenceIdExists?: (boolean | null),
        licenceIdEq?: (string | null),
        licenceIdGt?: (string | null),
        licenceIdGte?: (string | null),
        licenceIdIn?: (Array<string> | null),
        licenceIdLt?: (string | null),
        licenceIdLte?: (string | null),
        licenceIdNe?: (string | null),
        licenceIdNin?: (Array<string> | null),
        licenceId?: (string | null),
        licenceIdsExists?: (boolean | null),
        licenceIdsEq?: (string | null),
        licenceIdsGt?: (string | null),
        licenceIdsGte?: (string | null),
        licenceIdsIn?: (Array<string> | null),
        licenceIdsLt?: (string | null),
        licenceIdsLte?: (string | null),
        licenceIdsNe?: (string | null),
        licenceIdsNin?: (Array<string> | null),
        licenceIds?: (string | null),
        accountIdExists?: (boolean | null),
        accountIdEq?: (string | null),
        accountIdGt?: (string | null),
        accountIdGte?: (string | null),
        accountIdIn?: (Array<string> | null),
        accountIdLt?: (string | null),
        accountIdLte?: (string | null),
        accountIdNe?: (string | null),
        accountIdNin?: (Array<string> | null),
        accountId?: (string | null),
        statusExists?: (boolean | null),
        statusEq?: (OptimizationStatus | null),
        statusGt?: (OptimizationStatus | null),
        statusGte?: (OptimizationStatus | null),
        statusIn?: (Array<OptimizationStatus> | null),
        statusLt?: (OptimizationStatus | null),
        statusLte?: (OptimizationStatus | null),
        statusNe?: (OptimizationStatus | null),
        statusNin?: (Array<OptimizationStatus> | null),
        status?: (OptimizationStatus | null),
        optimizationTypeExists?: (boolean | null),
        optimizationTypeEq?: (OptimizationType | null),
        optimizationTypeGt?: (OptimizationType | null),
        optimizationTypeGte?: (OptimizationType | null),
        optimizationTypeIn?: (Array<OptimizationType> | null),
        optimizationTypeLt?: (OptimizationType | null),
        optimizationTypeLte?: (OptimizationType | null),
        optimizationTypeNe?: (OptimizationType | null),
        optimizationTypeNin?: (Array<OptimizationType> | null),
        optimizationType?: (OptimizationType | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<Optimization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/optimizations',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'cached': cached,
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'licence_id.$exists': licenceIdExists,
                'licence_id.$eq': licenceIdEq,
                'licence_id.$gt': licenceIdGt,
                'licence_id.$gte': licenceIdGte,
                'licence_id.$in': licenceIdIn,
                'licence_id.$lt': licenceIdLt,
                'licence_id.$lte': licenceIdLte,
                'licence_id.$ne': licenceIdNe,
                'licence_id.$nin': licenceIdNin,
                'licence_id': licenceId,
                'licence_ids.$exists': licenceIdsExists,
                'licence_ids.$eq': licenceIdsEq,
                'licence_ids.$gt': licenceIdsGt,
                'licence_ids.$gte': licenceIdsGte,
                'licence_ids.$in': licenceIdsIn,
                'licence_ids.$lt': licenceIdsLt,
                'licence_ids.$lte': licenceIdsLte,
                'licence_ids.$ne': licenceIdsNe,
                'licence_ids.$nin': licenceIdsNin,
                'licence_ids': licenceIds,
                'account_id.$exists': accountIdExists,
                'account_id.$eq': accountIdEq,
                'account_id.$gt': accountIdGt,
                'account_id.$gte': accountIdGte,
                'account_id.$in': accountIdIn,
                'account_id.$lt': accountIdLt,
                'account_id.$lte': accountIdLte,
                'account_id.$ne': accountIdNe,
                'account_id.$nin': accountIdNin,
                'account_id': accountId,
                'status.$exists': statusExists,
                'status.$eq': statusEq,
                'status.$gt': statusGt,
                'status.$gte': statusGte,
                'status.$in': statusIn,
                'status.$lt': statusLt,
                'status.$lte': statusLte,
                'status.$ne': statusNe,
                'status.$nin': statusNin,
                'status': status,
                'optimization_type.$exists': optimizationTypeExists,
                'optimization_type.$eq': optimizationTypeEq,
                'optimization_type.$gt': optimizationTypeGt,
                'optimization_type.$gte': optimizationTypeGte,
                'optimization_type.$in': optimizationTypeIn,
                'optimization_type.$lt': optimizationTypeLt,
                'optimization_type.$lte': optimizationTypeLte,
                'optimization_type.$ne': optimizationTypeNe,
                'optimization_type.$nin': optimizationTypeNin,
                'optimization_type': optimizationType,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Optimizations
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countOptimizationsApiV1ManagementOptimizationsCountGet({
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        licenceIdExists,
        licenceIdEq,
        licenceIdGt,
        licenceIdGte,
        licenceIdIn,
        licenceIdLt,
        licenceIdLte,
        licenceIdNe,
        licenceIdNin,
        licenceId,
        licenceIdsExists,
        licenceIdsEq,
        licenceIdsGt,
        licenceIdsGte,
        licenceIdsIn,
        licenceIdsLt,
        licenceIdsLte,
        licenceIdsNe,
        licenceIdsNin,
        licenceIds,
        accountIdExists,
        accountIdEq,
        accountIdGt,
        accountIdGte,
        accountIdIn,
        accountIdLt,
        accountIdLte,
        accountIdNe,
        accountIdNin,
        accountId,
        statusExists,
        statusEq,
        statusGt,
        statusGte,
        statusIn,
        statusLt,
        statusLte,
        statusNe,
        statusNin,
        status,
        optimizationTypeExists,
        optimizationTypeEq,
        optimizationTypeGt,
        optimizationTypeGte,
        optimizationTypeIn,
        optimizationTypeLt,
        optimizationTypeLte,
        optimizationTypeNe,
        optimizationTypeNin,
        optimizationType,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        xTransactionId,
    }: {
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        licenceIdExists?: (boolean | null),
        licenceIdEq?: (string | null),
        licenceIdGt?: (string | null),
        licenceIdGte?: (string | null),
        licenceIdIn?: (Array<string> | null),
        licenceIdLt?: (string | null),
        licenceIdLte?: (string | null),
        licenceIdNe?: (string | null),
        licenceIdNin?: (Array<string> | null),
        licenceId?: (string | null),
        licenceIdsExists?: (boolean | null),
        licenceIdsEq?: (string | null),
        licenceIdsGt?: (string | null),
        licenceIdsGte?: (string | null),
        licenceIdsIn?: (Array<string> | null),
        licenceIdsLt?: (string | null),
        licenceIdsLte?: (string | null),
        licenceIdsNe?: (string | null),
        licenceIdsNin?: (Array<string> | null),
        licenceIds?: (string | null),
        accountIdExists?: (boolean | null),
        accountIdEq?: (string | null),
        accountIdGt?: (string | null),
        accountIdGte?: (string | null),
        accountIdIn?: (Array<string> | null),
        accountIdLt?: (string | null),
        accountIdLte?: (string | null),
        accountIdNe?: (string | null),
        accountIdNin?: (Array<string> | null),
        accountId?: (string | null),
        statusExists?: (boolean | null),
        statusEq?: (OptimizationStatus | null),
        statusGt?: (OptimizationStatus | null),
        statusGte?: (OptimizationStatus | null),
        statusIn?: (Array<OptimizationStatus> | null),
        statusLt?: (OptimizationStatus | null),
        statusLte?: (OptimizationStatus | null),
        statusNe?: (OptimizationStatus | null),
        statusNin?: (Array<OptimizationStatus> | null),
        status?: (OptimizationStatus | null),
        optimizationTypeExists?: (boolean | null),
        optimizationTypeEq?: (OptimizationType | null),
        optimizationTypeGt?: (OptimizationType | null),
        optimizationTypeGte?: (OptimizationType | null),
        optimizationTypeIn?: (Array<OptimizationType> | null),
        optimizationTypeLt?: (OptimizationType | null),
        optimizationTypeLte?: (OptimizationType | null),
        optimizationTypeNe?: (OptimizationType | null),
        optimizationTypeNin?: (Array<OptimizationType> | null),
        optimizationType?: (OptimizationType | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/optimizations/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'licence_id.$exists': licenceIdExists,
                'licence_id.$eq': licenceIdEq,
                'licence_id.$gt': licenceIdGt,
                'licence_id.$gte': licenceIdGte,
                'licence_id.$in': licenceIdIn,
                'licence_id.$lt': licenceIdLt,
                'licence_id.$lte': licenceIdLte,
                'licence_id.$ne': licenceIdNe,
                'licence_id.$nin': licenceIdNin,
                'licence_id': licenceId,
                'licence_ids.$exists': licenceIdsExists,
                'licence_ids.$eq': licenceIdsEq,
                'licence_ids.$gt': licenceIdsGt,
                'licence_ids.$gte': licenceIdsGte,
                'licence_ids.$in': licenceIdsIn,
                'licence_ids.$lt': licenceIdsLt,
                'licence_ids.$lte': licenceIdsLte,
                'licence_ids.$ne': licenceIdsNe,
                'licence_ids.$nin': licenceIdsNin,
                'licence_ids': licenceIds,
                'account_id.$exists': accountIdExists,
                'account_id.$eq': accountIdEq,
                'account_id.$gt': accountIdGt,
                'account_id.$gte': accountIdGte,
                'account_id.$in': accountIdIn,
                'account_id.$lt': accountIdLt,
                'account_id.$lte': accountIdLte,
                'account_id.$ne': accountIdNe,
                'account_id.$nin': accountIdNin,
                'account_id': accountId,
                'status.$exists': statusExists,
                'status.$eq': statusEq,
                'status.$gt': statusGt,
                'status.$gte': statusGte,
                'status.$in': statusIn,
                'status.$lt': statusLt,
                'status.$lte': statusLte,
                'status.$ne': statusNe,
                'status.$nin': statusNin,
                'status': status,
                'optimization_type.$exists': optimizationTypeExists,
                'optimization_type.$eq': optimizationTypeEq,
                'optimization_type.$gt': optimizationTypeGt,
                'optimization_type.$gte': optimizationTypeGte,
                'optimization_type.$in': optimizationTypeIn,
                'optimization_type.$lt': optimizationTypeLt,
                'optimization_type.$lte': optimizationTypeLte,
                'optimization_type.$ne': optimizationTypeNe,
                'optimization_type.$nin': optimizationTypeNin,
                'optimization_type': optimizationType,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Single Optimization
     * @returns Optimization Successful Response
     * @throws ApiError
     */
    public static getSingleOptimizationApiV1ManagementOptimizationsOptimizationIdGet({
        optimizationId,
        xTransactionId,
    }: {
        optimizationId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Optimization> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/management/optimizations/{optimization_id}',
            path: {
                'optimization_id': optimizationId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Optimization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteOptimizationApiV1ManagementOptimizationsOptimizationIdDelete({
        optimizationId,
        xTransactionId,
    }: {
        optimizationId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/management/optimizations/{optimization_id}',
            path: {
                'optimization_id': optimizationId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Hide Optimization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static hideOptimizationApiV1ManagementOptimizationsOptimizationIdHidePost({
        optimizationId,
        xTransactionId,
    }: {
        optimizationId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/optimizations/{optimization_id}/hide',
            path: {
                'optimization_id': optimizationId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Unhide Optimization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static unhideOptimizationApiV1ManagementOptimizationsOptimizationIdUnhidePost({
        optimizationId,
        xTransactionId,
    }: {
        optimizationId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/optimizations/{optimization_id}/unhide',
            path: {
                'optimization_id': optimizationId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Execute Optimization
     * @returns Optimization Successful Response
     * @throws ApiError
     */
    public static executeOptimizationApiV1ManagementOptimizationsOptimizationIdExecutePost({
        optimizationId,
        xTransactionId,
    }: {
        optimizationId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Optimization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/management/optimizations/{optimization_id}/execute',
            path: {
                'optimization_id': optimizationId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
