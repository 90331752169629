<template>
  <div class="environment-select">
    <div class="environment-select-decoration">
      <img
        src="/img/ChangeColor.png"
        class="environment-select-decoration-img" />
    </div>
    <div class="environment-select-content">
      <div>
        <span style="font-size: 1.17em; font-weight: 700; margin-bottom: 20px">
          {{
            i18n.t(
              'dialogs.onboardingDialog.content.environment.selectLanguage'
            )
          }}
        </span>
        <div class="select-card-container">
          <el-card
            :class="{
              'button-card': true,
              'button-card-active': selectedLanguage === 'de',
            }"
            :body-style="{
              padding: '12px 20px',
            }"
            @click="() => (selectedLanguage = PreferenceLanguages.DE)">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span style="font-size: 50px">🇩🇪</span>
          </el-card>
          <el-card
            :class="{
              'button-card': true,
              'button-card-active': selectedLanguage === 'en',
            }"
            :body-style="{
              padding: '12px 20px',
            }"
            @click="() => (selectedLanguage = PreferenceLanguages.EN)">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span style="font-size: 50px">🇺🇸</span>
          </el-card>
        </div>
        <div style="margin-top: 30px">
          <span
            style="font-size: 1.17em; font-weight: 700; margin-bottom: 20px">
            {{
              i18n.t(
                'dialogs.onboardingDialog.content.environment.selectColorMode'
              )
            }}
          </span>
          <div class="select-card-container">
            <el-card
              :class="{
                'button-card': true,
                'button-card-active':
                  currentAppearance == PreferenceAppearanceThemes.DARK_THEME,
              }"
              :body-style="themeSelectCardBodyStyle"
              @click="changeAppearance(PreferenceAppearanceThemes.DARK_THEME)">
              <v-icon name="md-darkmode-round" scale="3" />
            </el-card>
            <el-card
              :class="{
                'button-card': true,
                'button-card-active':
                  currentAppearance == PreferenceAppearanceThemes.LIGHT_THEME,
              }"
              :body-style="themeSelectCardBodyStyle"
              @click="changeAppearance(PreferenceAppearanceThemes.LIGHT_THEME)">
              <v-icon name="md-lightmode-round" scale="3" />
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { PreferenceAppearanceThemes, PreferenceLanguages } from '@/client'
  import { usePreferenceStore } from '@/stores/preferenceStore'

  const preferenceStore = usePreferenceStore()

  const themeSelectCardBodyStyle = {
    padding: '22px 20px',
    display: 'flex',
    justifyContent: 'center',
    placeItems: 'center',
    height: 'calc(100% - 44px)',
  }

  const i18n = useI18n()

  const selectedLanguage = ref<PreferenceLanguages>(
    i18n.locale.value as PreferenceLanguages
  )

  watch(
    () => selectedLanguage.value,
    () => {
      preferenceStore.setPreference('language', selectedLanguage.value)
      i18n.locale.value = selectedLanguage.value
    }
  )

  const currentAppearance = computed(
    () => preferenceStore.preferences?.appearance
  )

  function changeAppearance(theme: PreferenceAppearanceThemes) {
    preferenceStore.setPreference('appearance', theme)
  }

  async function confirm(): Promise<boolean> {
    return true
  }

  defineExpose({
    confirm,
  })
</script>

<style lang="scss">
  .button-card {
    height: 100px !important;
    width: 100px !important;
    cursor: pointer;
    aspect-ratio: 1;

    // Center the content
    display: grid;
    place-items: center;

    &:hover {
      scale: 1.03;
    }
  }

  .button-card-active {
    background-color: var(--sm-primary) !important;

    svg {
      color: var(--sm-background) !important;
    }
  }

  .environment-select-content {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .environment-select-decoration {
    display: grid;
    place-content: center;
    max-height: 100%;
    width: 100%;
  }

  .environment-select-decoration-img {
    // This is a ugly hack to make the image responsive
    max-height: 35vh;
    object-fit: contain;
    width: auto;
    height: auto;
  }

  .environment-select {
    height: 100%;
    width: inherit;
    display: grid;
    grid-template-columns: 50% 50%;

    // Center the content
    place-items: center;
  }

  .select-card-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
  }
</style>
