<template>
  <div>
    <div class="software-source-filter">
      <div
        v-for="(active, type) in activeSourceFilter"
        :key="type"
        class="software-type">
        <div style="display: flex">
          <LicenceStatusSmall
            :status="{
              color: color_map[type],
              text: i18n.t(`general.applicationTypeTag.${type}`),
            }" />
          <span>&nbsp;({{ softwareTypeCount[type] }})</span>
        </div>
        <SmSwitch
          v-model="activeSourceFilter[type]"
          style="font-size: 0.75rem" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { SoftwareOut, SoftwareTypes } from '@/client'
  import { useI18n } from 'vue-i18n'
  import { SoftwareSourceFilterState } from './ApplicationsTable.vue'

  const i18n = useI18n()

  const activeSourceFilter = defineModel<SoftwareSourceFilterState>(
    'activeSourceFilter',
    {
      required: true,
    }
  )

  interface Props {
    softwares: SoftwareOut[]
  }

  const props = defineProps<Props>()

  const softwareTypeCount = computed(() => {
    const types: Record<string, number> = {
      manually: 0,
      integration: 0,
      sso: 0,
    }
    for (const software of props.softwares) {
      if (!software.type) {
        continue
      }

      if (software.information.sso_provider) {
        types.sso += Object.keys(software.sub_accounts).length
      } else if (
        software.type === SoftwareTypes.OAUTH ||
        software.type === SoftwareTypes.TOKEN ||
        software.type === SoftwareTypes.BOT
      ) {
        types.integration += 1
      } else if (software.type === SoftwareTypes.MANUALLY) {
        types.manually += 1
      }
    }
    return types
  })

  const color_map = {
    manually: ' var(--sm-contrast-muted)',
    sso: 'var(--sm-blue)',
    integration: 'var(--sm-primary)',
  }
</script>

<style>
  .software-source-filter {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .software-type {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }
</style>
