<template>
  <div style="display: flex; flex-direction: row; align-items: center">
    <!-- Indicator Dot -->
    <div style="margin-right: 6px">
      <!-- Custom Status -->
      <SmTooltip
        v-if="showIndicator && typeof props.status === 'object'"
        :content="props.status.text"
        :active="!showText">
        <div class="indicator-dot"></div>
      </SmTooltip>

      <!-- Licence Status -->
      <SmTooltip
        v-else-if="showIndicator"
        :content="i18n.t(`licenseStatus.${status}`)"
        :active="!showText">
        <div class="indicator-dot"></div>
      </SmTooltip>
    </div>
    <span v-if="showText && typeof props.status === 'object'">{{
      props.status.text
    }}</span>
    <span v-else-if="showText">{{ i18n.t(`licenseStatus.${status}`) }}</span>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { LicenceStatus } from '@/client'

  export interface CustomStatus {
    color: string
    text: string
  }

  interface Props {
    status: LicenceStatus | CustomStatus
    showText?: boolean
    showIndicator?: boolean
  }

  const i18n = useI18n()
  const props = withDefaults(defineProps<Props>(), {
    showText: true,
    showIndicator: true,
  })

  const statusColor = computed(() => {
    if (typeof props.status === 'object') {
      return props.status.color
    }

    switch (props.status) {
      case 'active':
        return 'var(--sm-primary)'
      case 'invited':
        return 'var(--sm-orange)'
      case 'disabled':
        return ' var(--sm-contrast-muted)'
      case 'invite-cancelled':
        return 'var(--sm-magenta)'
      default:
        return '#FFC107'
    }
  })
</script>

<style lang="scss" scoped>
  .indicator-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: v-bind(statusColor);
  }
</style>
