<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  const visibility = defineModel<boolean>('visibility', {
    default: false,
  })
</script>

<template>
  <SmDialog v-model:visibility="visibility" :title="i18n.t('contactSupport')">
    <SettingsHelpPage
      :show-headline="false"
      @feedback-send="visibility = false" />
  </SmDialog>
</template>
