<template>
  <div :class="['sm-tag', { selected: props.selected }]">
    {{ props.tag.name }}
  </div>
</template>
<script setup lang="ts">
  import { RGB_Output } from '@/client'

  const background = computed(() => {
    return `rgba(${props.tag.color.r}, ${props.tag.color.g}, ${props.tag.color.b}, 0.1)`
  })

  const text = computed(() => {
    return `rgba(${props.tag.color.r}, ${props.tag.color.g}, ${props.tag.color.b}, 1)`
  })

  interface TagProps {
    name: string
    color: RGB_Output
  }

  interface Props {
    tag: TagProps
    selected?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    selected: false,
  })
</script>
<style lang="scss">
  .sm-tag {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    font-size: 0.85rem;
    color: v-bind(text);
    background-color: v-bind(background);
    border: 1px solid v-bind(text);
    border-radius: var(--border-radius);
    height: fit-content;
    line-height: 1.7;
    width: fit-content;

    min-height: 1rem;

    transition: all 0.2s ease-in-out;
  }

  .sm-tag.selected {
    background-color: v-bind(text);
    color: white;
    font-weight: bold;
  }
</style>
