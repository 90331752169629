// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import handleError from '@/middlewares/handleError'
import { Software } from '@/types/software'

export function getSoftwares(
  ip: string,
  token: string | undefined
): Promise<Software[]> {
  const customHeaders = new Headers()
  customHeaders.append('Authorization', `Bearer ${token}`)

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: customHeaders,
    redirect: 'follow',
  }

  return fetch(`${ip}/api/v1/software/software`, requestOptions)
    .then(handleError)
    .then(async (response) => {
      const softwares = await response.json()

      return softwares.map((software: Software) => {
        return {
          ...software,
          description: software.desc,
          description_short: software.desc_short,
        }
      })
    })
    .catch((error) => console.error('Get Software', error))
}

/**
 * @param  {string} ip - IP of the server
 * @param  {string|undefined} token - Token of the user
 * @param  {string} softwareName - Name of the software
 *
 * This function returns a single software
 */
export function getSoftware(
  ip: string,
  token: string | undefined,
  softwareName: string,
  showNotification?: boolean
): Promise<Software> {
  const customHeaders = new Headers()
  customHeaders.append('Authorization', `Bearer ${token}`)

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: customHeaders,
    redirect: 'follow',
  }

  return fetch(`${ip}/api/v1/software/software/${softwareName}`, requestOptions)
    .then((response) => handleError(response, showNotification))
    .then(async (response) => {
      const software = await response.json()

      return {
        ...software,
        description: software.desc,
        description_short: software.desc_short,
      }
    })
    .catch((error) => console.error('Get Software', error))
}

export function deleteSoftware(
  ip: string,
  jwt: string | undefined,
  software_name: string
): Promise<Record<string, unknown>> {
  const customHeaders: Headers = new Headers()
  customHeaders.append('Authorization', `Bearer ${jwt}`)

  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: customHeaders,
    redirect: 'follow',
  }

  return fetch(
    `${ip}/api/v1/software/software/${encodeURIComponent(software_name)}`,
    requestOptions
  )
    .then(handleError)
    .then(async (response) => await response.json())
    .catch((error) => console.error('error', error))
}

export function saveSoftware(
  ip: string,
  jwt: string | undefined,
  software_data: Software
): Promise<Record<string, string | Software>> {
  const customHeaders: Headers = new Headers()
  customHeaders.append('Authorization', `Bearer ${jwt}`)
  customHeaders.append('Content-Type', `application/json`)

  // const formdata = new FormData()
  // Object.keys(software_data).forEach((key_name) => {
  //   let _val = software_data[key_name as keyof typeof software_data]
  //   formdata.append(key_name, JSON.stringify(_val))
  // })

  software_data.desc = software_data.description
  software_data.desc_short = software_data.description_short

  const body = JSON.stringify({ ...software_data })

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: customHeaders,
    body: body,
    redirect: 'follow',
  }

  return fetch(`${ip}/api/v1/software/software`, requestOptions)
    .then(handleError)
    .then(async (response) => await response.json())
}

export function editSoftware(
  ip: string,
  jwt: string | undefined,
  software_data: Software,
  sendNotification: boolean
): Promise<Software> {
  const customHeaders: Headers = new Headers()
  customHeaders.append('Authorization', `Bearer ${jwt}`)
  customHeaders.append('Content-Type', `application/json`)

  // const formdata = new FormData()
  // Object.keys(software_data).forEach((key_name) => {
  //   let _val = software_data[key_name as keyof typeof software_data]
  //   formdata.append(key_name, JSON.stringify(_val))
  // })

  software_data.desc = software_data.description
  software_data.desc_short = software_data.description_short

  const body = JSON.stringify({ ...software_data })

  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: customHeaders,
    body: body,
    redirect: 'follow',
  }

  return fetch(`${ip}/api/v1/software/software`, requestOptions)
    .then((response) => handleError(response, sendNotification))
    .then(async (response) => await response.json())
}
