/**
 * This code calucate the table of
 * The Reason: The Table column width dont match the email length
 */

const minWidth = 8
const estimatedLetterWidth = 8
const SORTABLE_ICON_WIDTH = 24 // from .el-table .caret-wrappe

const maxColumnWidth = 150

export function rowWidth(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData: Record<string, any>,
  rowName: string,
  rowLabel: string,
  isSortable?: boolean,
  extraPaddingLabel?: number,
  extraPaddingContent?: number
) {
  let extraWidth = 0
  // Add extra padding if needed
  extraWidth += extraPaddingLabel || 0
  extraWidth += extraPaddingContent || 0

  // Add width for sortable column
  extraWidth += isSortable ? SORTABLE_ICON_WIDTH : 0

  let maxContentLength = 0
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData.forEach((row: any) => {
    const content = row[rowName]

    if (content != null && (content.length || 0) > maxContentLength) {
      maxContentLength = content.length || 0
    }
  })

  // Use the longest lenght to calculate the column width (Content, Label)
  const maxColumnLength = Math.max(maxContentLength, rowLabel.length)

  let columnWidth = maxColumnLength * estimatedLetterWidth + extraWidth
  columnWidth = Math.min(maxColumnWidth, columnWidth)
  columnWidth = Math.max(minWidth, columnWidth)

  return columnWidth + 'px'
}
