/* Input validator for SmInput component

Usage:
  <SmInput
    ...
    validators={[validateEmail, minLength(3), maxLength(10), equal('123')]}
    ...
  />

Available validators:
  - validateEmail
  - minLength(min: number)
  - maxLength(max: number)
  - equal(value: string)
  - notEmpty
 */

import i18n from '@/i18n/i18n'
import dayjs from 'dayjs'

// Types
export enum ValidationStatus {
  success = 'success',
  error = 'error',
  warning = 'warning',
  validating = 'validating',
}
export type ValidationResult = {
  status: ValidationStatus
  message: string
} | null
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationRule = (value: any) => ValidationResult | null

// Email validator
export function validateEmail(value: string): ValidationResult {
  const isMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
  if (!isMail) {
    return {
      status: ValidationStatus.error,
      message: i18n.global.t('error.validation.unvalidEmail'),
    }
  }
  return null
}

// Min length validator
export function minLength(min: number): ValidationRule {
  return (value: string): ValidationResult => {
    if (value.length < min) {
      return {
        status: ValidationStatus.error,
        message: i18n.global.t('error.validation.unvalidMinLength', {
          min: min,
        }),
      }
    }
    return null
  }
}

// Max length validator
export function maxLength(max: number): ValidationRule {
  return (value: string): ValidationResult => {
    if (value.length > max) {
      return {
        status: ValidationStatus.error,
        message: i18n.global.t('error.validation.unvalidMaxLength', {
          max: max,
        }),
      }
    }
    return null
  }
}

// Equal validator
export function equalValidator(value: string | Ref<string>): ValidationRule {
  let toCompare = ''

  return function equal(input: string): ValidationResult {
    if (typeof value !== 'string') {
      toCompare = value.value
    } else {
      toCompare = value
    }

    if (input !== toCompare) {
      return {
        status: ValidationStatus.error,
        message: i18n.global.t('error.validation.unvalidEqual'),
      }
    }
    return null
  }
}

export function notEqual<T>(value: T): ValidationRule {
  return function _notEqual(input: T): ValidationResult {
    if (input === value) {
      return {
        status: ValidationStatus.error,
        message: i18n.global.t('error.validation.unvalidNotEqual'),
      }
    }
    return null
  }
}

// Not empty validator
export function notEmpty(value: string): ValidationResult {
  if (value === '') {
    return {
      status: ValidationStatus.error,
      message: i18n.global.t('error.validation.required'),
    }
  }
  return null
}

export function isRequired(
  value: string | number | boolean | null | undefined
): ValidationResult {
  if (value === '' || value === null || value === undefined) {
    return {
      status: ValidationStatus.error,
      message: i18n.global.t('error.validation.required'),
    }
  }
  return null
}

export function isDateAfterNow(
  buffer: number,
  acceptNull = false
): ValidationRule {
  return (value: string): ValidationResult => {
    if (acceptNull && value === null) {
      return null
    }
    const nowWithBuffer = dayjs().add(buffer || 0, 'second')

    if (!dayjs(value).isAfter(nowWithBuffer)) {
      return {
        status: ValidationStatus.error,
        message: i18n.global.t('error.validation.isDateAfterNow'),
      }
    }
    return null
  }
}
