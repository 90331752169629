/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_add_document_api_v1_software_documents_post } from '../models/Body_add_document_api_v1_software_documents_post';
import type { DocumentInfo } from '../models/DocumentInfo';
import type { DocumentUpdate } from '../models/DocumentUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DocumentsService {
    /**
     * Get Document Info List
     * @returns DocumentInfo Successful Response
     * @throws ApiError
     */
    public static getDocumentInfoListApiV1SoftwareDocumentsGet({
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        titleExists,
        titleEq,
        titleGt,
        titleGte,
        titleIn,
        titleLt,
        titleLte,
        titleNe,
        titleNin,
        title,
        notesExists,
        notesEq,
        notesGt,
        notesGte,
        notesIn,
        notesLt,
        notesLte,
        notesNe,
        notesNin,
        notes,
        dateExists,
        dateEq,
        dateGt,
        dateGte,
        dateIn,
        dateLt,
        dateLte,
        dateNe,
        dateNin,
        date,
        xTransactionId,
    }: {
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        titleExists?: (boolean | null),
        titleEq?: (string | null),
        titleGt?: (string | null),
        titleGte?: (string | null),
        titleIn?: (Array<string> | null),
        titleLt?: (string | null),
        titleLte?: (string | null),
        titleNe?: (string | null),
        titleNin?: (Array<string> | null),
        title?: (string | null),
        notesExists?: (boolean | null),
        notesEq?: (string | null),
        notesGt?: (string | null),
        notesGte?: (string | null),
        notesIn?: (Array<string> | null),
        notesLt?: (string | null),
        notesLte?: (string | null),
        notesNe?: (string | null),
        notesNin?: (Array<string> | null),
        notes?: (string | null),
        dateExists?: (boolean | null),
        dateEq?: (string | null),
        dateGt?: (string | null),
        dateGte?: (string | null),
        dateIn?: (Array<string> | null),
        dateLt?: (string | null),
        dateLte?: (string | null),
        dateNe?: (string | null),
        dateNin?: (Array<string> | null),
        date?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<DocumentInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/documents',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'title.$exists': titleExists,
                'title.$eq': titleEq,
                'title.$gt': titleGt,
                'title.$gte': titleGte,
                'title.$in': titleIn,
                'title.$lt': titleLt,
                'title.$lte': titleLte,
                'title.$ne': titleNe,
                'title.$nin': titleNin,
                'title': title,
                'notes.$exists': notesExists,
                'notes.$eq': notesEq,
                'notes.$gt': notesGt,
                'notes.$gte': notesGte,
                'notes.$in': notesIn,
                'notes.$lt': notesLt,
                'notes.$lte': notesLte,
                'notes.$ne': notesNe,
                'notes.$nin': notesNin,
                'notes': notes,
                'date.$exists': dateExists,
                'date.$eq': dateEq,
                'date.$gt': dateGt,
                'date.$gte': dateGte,
                'date.$in': dateIn,
                'date.$lt': dateLt,
                'date.$lte': dateLte,
                'date.$ne': dateNe,
                'date.$nin': dateNin,
                'date': date,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Document
     * @returns DocumentInfo Successful Response
     * @throws ApiError
     */
    public static addDocumentApiV1SoftwareDocumentsPost({
        formData,
        xTransactionId,
    }: {
        formData: Body_add_document_api_v1_software_documents_post,
        xTransactionId?: (string | null),
    }): CancelablePromise<DocumentInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/software/documents',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Document Info
     * @returns DocumentInfo Successful Response
     * @throws ApiError
     */
    public static getDocumentInfoApiV1SoftwareDocumentsDocumentIdGet({
        documentId,
        xTransactionId,
    }: {
        documentId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<DocumentInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Document
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteDocumentApiV1SoftwareDocumentsDocumentIdDelete({
        documentId,
        xTransactionId,
    }: {
        documentId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/software/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Document
     * @returns DocumentInfo Successful Response
     * @throws ApiError
     */
    public static updateDocumentApiV1SoftwareDocumentsDocumentIdPut({
        documentId,
        requestBody,
        xTransactionId,
    }: {
        documentId: string,
        requestBody: DocumentUpdate,
        xTransactionId?: (string | null),
    }): CancelablePromise<DocumentInfo> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/software/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Download Document
     * Returns the document - just the file not the metadata!
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadDocumentApiV1SoftwareDocumentsDocumentIdDownloadGet({
        documentId,
        xTransactionId,
    }: {
        documentId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/documents/{document_id}/download',
            path: {
                'document_id': documentId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Document List
     * @returns DocumentInfo Successful Response
     * @throws ApiError
     */
    public static getDocumentListApiV1SoftwareDocumentsListGet({
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        titleExists,
        titleEq,
        titleGt,
        titleGte,
        titleIn,
        titleLt,
        titleLte,
        titleNe,
        titleNin,
        title,
        notesExists,
        notesEq,
        notesGt,
        notesGte,
        notesIn,
        notesLt,
        notesLte,
        notesNe,
        notesNin,
        notes,
        dateExists,
        dateEq,
        dateGt,
        dateGte,
        dateIn,
        dateLt,
        dateLte,
        dateNe,
        dateNin,
        date,
        xTransactionId,
    }: {
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        titleExists?: (boolean | null),
        titleEq?: (string | null),
        titleGt?: (string | null),
        titleGte?: (string | null),
        titleIn?: (Array<string> | null),
        titleLt?: (string | null),
        titleLte?: (string | null),
        titleNe?: (string | null),
        titleNin?: (Array<string> | null),
        title?: (string | null),
        notesExists?: (boolean | null),
        notesEq?: (string | null),
        notesGt?: (string | null),
        notesGte?: (string | null),
        notesIn?: (Array<string> | null),
        notesLt?: (string | null),
        notesLte?: (string | null),
        notesNe?: (string | null),
        notesNin?: (Array<string> | null),
        notes?: (string | null),
        dateExists?: (boolean | null),
        dateEq?: (string | null),
        dateGt?: (string | null),
        dateGte?: (string | null),
        dateIn?: (Array<string> | null),
        dateLt?: (string | null),
        dateLte?: (string | null),
        dateNe?: (string | null),
        dateNin?: (Array<string> | null),
        date?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<DocumentInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/documents/list',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'title.$exists': titleExists,
                'title.$eq': titleEq,
                'title.$gt': titleGt,
                'title.$gte': titleGte,
                'title.$in': titleIn,
                'title.$lt': titleLt,
                'title.$lte': titleLte,
                'title.$ne': titleNe,
                'title.$nin': titleNin,
                'title': title,
                'notes.$exists': notesExists,
                'notes.$eq': notesEq,
                'notes.$gt': notesGt,
                'notes.$gte': notesGte,
                'notes.$in': notesIn,
                'notes.$lt': notesLt,
                'notes.$lte': notesLte,
                'notes.$ne': notesNe,
                'notes.$nin': notesNin,
                'notes': notes,
                'date.$exists': dateExists,
                'date.$eq': dateEq,
                'date.$gt': dateGt,
                'date.$gte': dateGte,
                'date.$in': dateIn,
                'date.$lt': dateLt,
                'date.$lte': dateLte,
                'date.$ne': dateNe,
                'date.$nin': dateNin,
                'date': date,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Documents
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countDocumentsApiV1SoftwareDocumentsCountGet({
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        titleExists,
        titleEq,
        titleGt,
        titleGte,
        titleIn,
        titleLt,
        titleLte,
        titleNe,
        titleNin,
        title,
        notesExists,
        notesEq,
        notesGt,
        notesGte,
        notesIn,
        notesLt,
        notesLte,
        notesNe,
        notesNin,
        notes,
        dateExists,
        dateEq,
        dateGt,
        dateGte,
        dateIn,
        dateLt,
        dateLte,
        dateNe,
        dateNin,
        date,
        xTransactionId,
    }: {
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        titleExists?: (boolean | null),
        titleEq?: (string | null),
        titleGt?: (string | null),
        titleGte?: (string | null),
        titleIn?: (Array<string> | null),
        titleLt?: (string | null),
        titleLte?: (string | null),
        titleNe?: (string | null),
        titleNin?: (Array<string> | null),
        title?: (string | null),
        notesExists?: (boolean | null),
        notesEq?: (string | null),
        notesGt?: (string | null),
        notesGte?: (string | null),
        notesIn?: (Array<string> | null),
        notesLt?: (string | null),
        notesLte?: (string | null),
        notesNe?: (string | null),
        notesNin?: (Array<string> | null),
        notes?: (string | null),
        dateExists?: (boolean | null),
        dateEq?: (string | null),
        dateGt?: (string | null),
        dateGte?: (string | null),
        dateIn?: (Array<string> | null),
        dateLt?: (string | null),
        dateLte?: (string | null),
        dateNe?: (string | null),
        dateNin?: (Array<string> | null),
        date?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/documents/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'title.$exists': titleExists,
                'title.$eq': titleEq,
                'title.$gt': titleGt,
                'title.$gte': titleGte,
                'title.$in': titleIn,
                'title.$lt': titleLt,
                'title.$lte': titleLte,
                'title.$ne': titleNe,
                'title.$nin': titleNin,
                'title': title,
                'notes.$exists': notesExists,
                'notes.$eq': notesEq,
                'notes.$gt': notesGt,
                'notes.$gte': notesGte,
                'notes.$in': notesIn,
                'notes.$lt': notesLt,
                'notes.$lte': notesLte,
                'notes.$ne': notesNe,
                'notes.$nin': notesNin,
                'notes': notes,
                'date.$exists': dateExists,
                'date.$eq': dateEq,
                'date.$gt': dateGt,
                'date.$gte': dateGte,
                'date.$in': dateIn,
                'date.$lt': dateLt,
                'date.$lte': dateLte,
                'date.$ne': dateNe,
                'date.$nin': dateNin,
                'date': date,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
