<template>
  <div ref="tooltip" class="sm-tooltip__content" :class="props.class">
    <slot />
  </div>

  <Teleport to="#tooltips">
    <Transition name="fade" mode="out-in">
      <div v-show="showTooltip" ref="contentRef" class="sm-tooltip__tooltip">
        <div id="arrow" ref="arrow" data-popper-arrow></div>
        <slot name="content">
          {{ props.content }}
        </slot>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
  import { createPopper, type Placement } from '@popperjs/core'
  import { useElementHover } from '@vueuse/core'

  const tooltip = ref<HTMLElement | null>(null)
  const contentRef = ref<HTMLElement | null>(null)

  const arrow = ref<HTMLElement | null>(null)

  const popper = ref<ReturnType<typeof createPopper> | null>(null)

  const props = defineProps({
    placement: {
      type: String as PropType<Placement>,
      default: 'auto',
    },
    content: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: Number,
      default: 100,
    },
    minimumDisplayTime: {
      type: Number,
      default: 300,
    },
    maxWidth: {
      type: Number,
      default: 300,
    },
    class: {
      type: String,
      default: '',
    },
  })

  const isHovering = useElementHover(tooltip)
  const showTooltip = computed(() => {
    if (waitingMinimumDisplayTime.value) {
      return true
    }
    return props.active && isHovering.value && delaySatisfied.value
  })

  const delaySatisfied = ref(false) // true if the delay has passed

  // Delay
  watch(isHovering, (value) => {
    //  Delay
    if (value) {
      // If the user is still hovering, show the tooltip (delaySatisfied = true)
      setTimeout(() => {
        if (isHovering.value) {
          delaySatisfied.value = true
        }
      }, props.delay)
    } else {
      delaySatisfied.value = false
    }
  })

  // Minimum display time
  const waitingMinimumDisplayTime = ref(false) // true if the minimum display time has passed
  watch(showTooltip, (value) => {
    if (value) {
      waitingMinimumDisplayTime.value = true
      setTimeout(() => {
        waitingMinimumDisplayTime.value = false
      }, props.minimumDisplayTime)
    }
  })

  onMounted(() => {
    if (tooltip.value && contentRef.value && arrow.value) {
      popper.value = createPopper(
        tooltip.value,
        contentRef.value as HTMLElement, // It is an HTMLElement - trust me
        {
          placement: props.placement,
          modifiers: [
            {
              name: 'arrow',
              options: {
                element: arrow.value,
                padding: 10,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }
      )
    }
  })

  watch(showTooltip, (value) => {
    if (value) {
      popper.value?.update()
    }
  })

  const maxWidth = computed(() => {
    return props.maxWidth + 'px'
  })

  onBeforeUnmount(() => {
    popper.value?.destroy()
  })
</script>

<style lang="scss">
  .sm-tooltip {
    &__tooltip {
      box-sizing: border-box;
      position: absolute;

      display: block;

      padding: 0.5rem 1rem;
      background-color: var(--sm-cta);

      border-radius: var(--border-radius);
      color: var(--sm-white);
      font-size: var(--font-size-small);
      line-height: 1.5;
      box-shadow: var(--sm-shadow);

      max-width: v-bind(maxWidth);

      z-index: 20000;

      word-break: break-word;

      #arrow,
      #arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
      }

      #arrow::before {
        content: '';
        transform: rotate(45deg);
        background-color: var(--sm-cta);
      }
    }
  }

  *[data-popper-placement^='top'] > #arrow {
    bottom: -3px;
  }

  *[data-popper-placement^='bottom'] > #arrow {
    top: -3px;
  }

  *[data-popper-placement^='left'] > #arrow {
    right: -3px;
  }

  *[data-popper-placement^='right'] > #arrow {
    left: -3px;
  }
</style>
