<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="sm-selectionGrid">
    <div class="sm-selectionGrid-wrapper">
      <div style="display: flex; gap: 10px; align-items: center">
        <SmTooltip :content="i18n.t('justShowSelected')">
          <v-icon
            name="md-filterlist-round"
            scale="1.2"
            @click="toggleShowSelected" />
        </SmTooltip>

        <slot name="options" />

        <!-- Searchbar -->
        <SmInput
          v-model="searchQuery"
          size="small"
          outline
          :label="
            i18n.t(
              'dialogs.onboardingDialog.content.applicationSelect.searchPlaceholder'
            )
          " />
      </div>

      <!-- List of cards -->
      <div ref="wrapper" style="height: 50vh">
        <RecycleScroller
          ref="scroller"
          class="scroller"
          :items="filteredItemList"
          :item-size="150"
          :grid-items="itemRowCount"
          :item-secondary-size="itemWidth">
          <template #default="{ item, index }">
            <div class="item" @click="handleItemClick(item)">
              <!-- <img :key="item.id" :src="item.value.avatar" /> -->
              <slot
                name="item"
                :item="item as T"
                :index="index"
                :selected="selection.includes(item[props.id])">
                <div class="index">
                  {{ item }}
                </div>
              </slot>
            </div>
          </template>
        </RecycleScroller>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends Record<string, any>">
  import { useI18n } from 'vue-i18n'

  import SmInput from './SmInput.vue'

  import { useElementSize } from '@vueuse/core'

  const scroller = ref()
  const wrapper = ref()

  const onlyShowSelected = ref(false)

  const { width } = useElementSize(wrapper)

  const itemRowCount = computed(() => {
    return Math.floor(width.value / 150)
  })
  const itemWidth = computed(() => {
    return Math.floor(width.value / itemRowCount.value)
  })

  const emit = defineEmits<{
    select: [value: T]
  }>()

  type ID = keyof T
  type LabelKey = keyof T

  interface Props<T> {
    // Update Props to include the generic type parameter T
    items: T[]
    id: ID
    labelKey: LabelKey
  }

  const props = defineProps<Props<T>>() // Update defineProps to include the generic type parameter T

  const i18n = useI18n()
  const selection = defineModel<ID[]>('selection', {
    default: [],
  })

  const searchQuery = ref<string>('')

  const itemsWithID = computed(() => {
    return props.items.map((item) => {
      if (!item[props.id]) {
        item[props.id] = item[props.labelKey]
      }
      return item
    })
  })

  const itemsSorted = computed(() => {
    return itemsWithID.value.sort((a, b) => a.label.localeCompare(b.label))
  })

  const filteredItemList = computed(() => {
    if (onlyShowSelected.value) {
      return itemsSorted.value.filter((item) =>
        selection.value.includes(item[props.id])
      )
    }

    if (searchQuery.value.length === 0) {
      return itemsSorted.value
    }

    const lowerCaseSearchQuery = searchQuery.value.toLowerCase()

    return itemsSorted.value.filter((item) => {
      const lowerCaseLabel = item[props.labelKey].toLowerCase()
      const additionalSearchTerms = item.additionalSearchTerms?.join('') || ''
      const lowerCaseItem = lowerCaseLabel + additionalSearchTerms

      return lowerCaseItem.includes(lowerCaseSearchQuery)
    })
  })

  // Add or remove item from selection
  const handleItemClick = (item: T) => {
    const selectionIndex = selection.value.indexOf(item[props.id])

    if (selectionIndex !== -1) {
      selection.value.splice(selectionIndex, 1)
      emit('select', item)
    } else {
      selection.value.push(item[props.id])
      emit('select', item)
    }
  }

  async function confirm(): Promise<boolean> {
    return true
  }

  function toggleShowSelected() {
    onlyShowSelected.value = !onlyShowSelected.value
  }

  defineExpose({ confirm })
</script>

<style lang="scss">
  .item {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scroller {
    height: 100%;
  }

  .sm-selectionGrid {
    height: 100%;

    &-wrapper {
      padding: 20px;
      border-radius: var(--border-radius);
      border: 1px solid var(--el-border-color);
    }

    .sm-selectionGrid-grid-wrapper {
      height: clamp(200px, 50vh, 500px);
    }
  }

  .search-input {
    margin-bottom: 10px;
  }
</style>
