<template>
  <div class="close-icon" @click="handleClose">
    <v-icon name="io-close" scale="1.3" class="close-icon" />
  </div>
</template>

<script lang="ts" setup>
  const emit = defineEmits(['close'])

  function handleClose() {
    emit('close')
  }
</script>

<style lang="scss" scoped>
  .close-icon {
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      scale: 1.1;
      fill: var(--el-color-danger);
    }
  }
</style>
