import {
  ErrorBrowserLocationChangeRequired,
  ErrorGeneric,
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  UiNode,
  VerificationFlow,
} from '@ory/client'
import { useI18n } from 'vue-i18n'

export function getCsrfToken(nodes: UiNode[]) {
  // Weird type casting because of missing types in the ory api
  const attrs = nodes.find((node) => {
    const attrs = node.attributes as unknown as Record<string, string>
    return attrs.name === 'csrf_token'
  })?.attributes as unknown as Record<string, string | undefined>
  return attrs.value
}

export function isError(
  response: VerificationFlow | LoginFlow | RegistrationFlow | RecoveryFlow
): boolean {
  let isError = false
  if (response.ui.messages)
    response.ui.messages.forEach((message) => {
      if (message.type == 'error') {
        isError = true
        return
      }
    })
  else if (response.ui.nodes)
    response.ui.nodes.forEach((node) => {
      if (node.messages.length > 0 && node.messages[0].type == 'error') {
        isError = true
        return
      }
    })
  return isError
}

function isErrorGeneric(error: object): error is ErrorGeneric {
  return 'error' in error
}

function isRegistrationOrVerificationOrLoginFlow(
  error: object
): error is LoginFlow {
  return 'ui' in error
}

function isErrorBrowserLocationRequired(
  error: object
): error is ErrorBrowserLocationChangeRequired {
  return 'redirect_browser_to' in error
}

export function getErrorMessage(
  error:
    | ErrorGeneric
    | RegistrationFlow
    | LoginFlow
    | ErrorBrowserLocationChangeRequired
    | RecoveryFlow
    | VerificationFlow,
  i18n: ReturnType<typeof useI18n>
): string {
  let message: string | null = null
  if (isErrorGeneric(error)) {
    message =
      error.error.message || i18n.t('error.unknown.unknown_error.message')
  } else if (isRegistrationOrVerificationOrLoginFlow(error)) {
    if (!error.ui) message = i18n.t('error.unknown.unknown_error.message')
    else if (!error.ui.messages || error.ui.messages.length == 0) {
      for (const node of error.ui.nodes) {
        if (node.messages.length > 0 && node.messages[0].type == 'error') {
          message = node.messages[0].text
          break
        }
      }
      if (!message) message = i18n.t('error.unknown.unknown_error.message')
    } else {
      for (const m of error.ui.messages)
        if (m.type == 'error') {
          message = m.text
          break
        }
      if (!message) message = i18n.t('error.unknown.unknown_error.message')
    }
  } else if (isErrorBrowserLocationRequired(error)) {
    message =
      error.error?.error.message ||
      i18n.t('error.unknown.unknown_error.message')
  } else message = i18n.t('error.unknown.unknown_error.message')
  return message as string
}
