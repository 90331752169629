export default {
  properties: {
    tags: {
      items: {
        type: 'string',
      },
      title: 'Tags',
      type: 'array',
    },
    _id: {
      title: ' Id',
      type: 'string',
      maxLength: 50,
    },
    id: {
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Id',
    },
    email: {
      description: 'email or username',
      title: 'Email',
      type: 'string',
      maxLength: 50,
    },
    software_id: {
      title: 'Software Id',
      type: 'string',
      maxLength: 50,
    },
    first_name: {
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'First Name',
    },
    last_name: {
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Last Name',
    },
    name: {
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Name',
    },
    status: {
      allOf: [
        {
          enum: ['active', 'disabled', 'invited', 'invite-cancelled'],
          title: 'LicenceStatus',
          type: 'string',
        },
      ],
    },
    icon: {
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Icon',
    },
    software_licenses: {
      additionalProperties: {
        properties: {
          name: {
            title: 'Name',
            type: 'string',
          },
          icon: {
            anyOf: [
              {
                type: 'string',
              },
              {
                type: 'null',
              },
            ],
            title: 'Icon',
          },
          auto_created: {
            title: 'Auto Created',
            type: 'boolean',
          },
          last_login_time: {
            anyOf: [
              {
                format: 'date-time',
                type: 'string',
              },
              {
                type: 'null',
              },
            ],
            title: 'Last Login Time',
          },
        },
        required: ['name'],
        title: 'SoftwareLicenseMini',
        type: 'object',
      },
      title: 'Software Licenses',
      type: 'object',
    },
    application_specific: {
      title: 'Application Specific',
      type: 'object',
    },
    is_guest: {
      title: 'Is Guest',
      type: 'boolean',
    },
    data: {
      title: 'Data',
      type: 'object',
    },
    role: {
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Role',
    },
    l_roles: {
      anyOf: [
        {
          items: {},
          type: 'array',
        },
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'L Roles',
    },
    deleted_at: {
      anyOf: [
        {
          format: 'date-time',
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Deleted At',
    },
    licence_groups: {
      items: {
        type: 'string',
      },
      title: 'Licence Groups',
      type: 'array',
    },
    last_login_time: {
      anyOf: [
        {
          format: 'date-time',
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Last Login Time',
    },
    created: {
      anyOf: [
        {
          format: 'date-time',
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Created',
    },
    company: {
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Company',
    },
    sub_account_id: {
      title: 'Sub Account Id',
      type: 'string',
    },
    account_id: {
      anyOf: [
        {
          type: 'string',
        },
        {
          type: 'null',
        },
      ],
      title: 'Account Id',
    },
    statistics: {
      anyOf: [
        {
          type: 'object',
        },
        {
          properties: {
            active_user_detail: {
              anyOf: [
                {
                  properties: {
                    reportRefreshDate: {
                      title: 'Reportrefreshdate',
                      type: 'string',
                    },
                    userPrincipalName: {
                      title: 'Userprincipalname',
                      type: 'string',
                    },
                    displayName: {
                      title: 'Displayname',
                      type: 'string',
                    },
                    isDeleted: {
                      title: 'Isdeleted',
                      type: 'boolean',
                    },
                    deletedDate: {
                      title: 'Deleteddate',
                      type: 'string',
                    },
                    hasExchangeLicense: {
                      title: 'Hasexchangelicense',
                      type: 'boolean',
                    },
                    hasOneDriveLicense: {
                      title: 'Hasonedrivelicense',
                      type: 'boolean',
                    },
                    hasSharePointLicense: {
                      title: 'Hassharepointlicense',
                      type: 'boolean',
                    },
                    hasSkypeForBusinessLicense: {
                      title: 'Hasskypeforbusinesslicense',
                      type: 'boolean',
                    },
                    hasYammerLicense: {
                      title: 'Hasyammerlicense',
                      type: 'boolean',
                    },
                    hasTeamsLicense: {
                      title: 'Hasteamslicense',
                      type: 'boolean',
                    },
                    exchangeLastActivityDate: {
                      title: 'Exchangelastactivitydate',
                      type: 'string',
                    },
                    oneDriveLastActivityDate: {
                      title: 'Onedrivelastactivitydate',
                      type: 'string',
                    },
                    sharePointLastActivityDate: {
                      title: 'Sharepointlastactivitydate',
                      type: 'string',
                    },
                    skypeForBusinessLastActivityDate: {
                      title: 'Skypeforbusinesslastactivitydate',
                      type: 'string',
                    },
                    yammerLastActivityDate: {
                      title: 'Yammerlastactivitydate',
                      type: 'string',
                    },
                    teamsLastActivityDate: {
                      title: 'Teamslastactivitydate',
                      type: 'string',
                    },
                    exchangeLicenseAssignDate: {
                      title: 'Exchangelicenseassigndate',
                      type: 'string',
                    },
                    oneDriveLicenseAssignDate: {
                      title: 'Onedrivelicenseassigndate',
                      type: 'string',
                    },
                    sharePointLicenseAssignDate: {
                      title: 'Sharepointlicenseassigndate',
                      type: 'string',
                    },
                    skypeForBusinessLicenseAssignDate: {
                      title: 'Skypeforbusinesslicenseassigndate',
                      type: 'string',
                    },
                    yammerLicenseAssignDate: {
                      title: 'Yammerlicenseassigndate',
                      type: 'string',
                    },
                    teamsLicenseAssignDate: {
                      title: 'Teamslicenseassigndate',
                      type: 'string',
                    },
                    assignedProducts: {
                      items: {
                        type: 'string',
                      },
                      title: 'Assignedproducts',
                      type: 'array',
                    },
                  },
                  required: [
                    'reportRefreshDate',
                    'userPrincipalName',
                    'displayName',
                    'isDeleted',
                    'deletedDate',
                    'hasExchangeLicense',
                    'hasOneDriveLicense',
                    'hasSharePointLicense',
                    'hasSkypeForBusinessLicense',
                    'hasYammerLicense',
                    'hasTeamsLicense',
                    'exchangeLastActivityDate',
                    'oneDriveLastActivityDate',
                    'sharePointLastActivityDate',
                    'skypeForBusinessLastActivityDate',
                    'yammerLastActivityDate',
                    'teamsLastActivityDate',
                    'exchangeLicenseAssignDate',
                    'oneDriveLicenseAssignDate',
                    'sharePointLicenseAssignDate',
                    'skypeForBusinessLicenseAssignDate',
                    'yammerLicenseAssignDate',
                    'teamsLicenseAssignDate',
                    'assignedProducts',
                  ],
                  title: 'Office365ActiveUserDetail',
                  type: 'object',
                },
                {
                  type: 'null',
                },
              ],
            },
          },
          title: 'MicrosoftLicenceStatistics',
          type: 'object',
        },
      ],
      title: 'Statistics',
    },
  },
  required: ['email', 'software_id', 'sub_account_id'],
  title: 'LicenceOut',
  type: 'object',
  version: 0,
  primaryKey: '_id',
  indexes: ['email', 'software_id'],
} as const
