/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSoftwareUserEnableData } from './BackgroundJobSoftwareUserEnableData';
export type BackgroundJobSoftwareUserEnablePostIn = {
    type: BackgroundJobSoftwareUserEnablePostIn.type;
    data: BackgroundJobSoftwareUserEnableData;
    /**
     * Time in seconds when to enqueue. Does not enqueue if value is `null`.
     */
    enqueue_in?: (number | null);
};
export namespace BackgroundJobSoftwareUserEnablePostIn {
    export enum type {
        SOFTWARE_USER_ENABLE = 'software_user_enable',
    }
}

