<template>
  {{ props.selection }}
  <div>{{ i18n.t('hello') }}</div>
</template>

<script setup lang="ts">
  import { AccountGroup, AccountGroupIn, AccountGroupUpdateIn } from '@/client'
  import { SelectionState } from './GroupAddSoftware.vue'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  interface Props {
    accountGroup: AccountGroupIn | AccountGroup | AccountGroupUpdateIn
    selection: SelectionState
  }

  const props = defineProps<Props>()
</script>
