import { defineStore } from 'pinia'

import {
  SoftwareLicensesService,
  SoftwareOut,
  SoftwareService,
  SoftwareTypes,
  SoftwareUpdateIn,
} from '@/client'
import { useFetch } from '@/common/useFetch'

export enum AuthType {
  AUTHENTICATE = 'authenticate',
  REAUTHENTICATE = 'reauthenticate',
}

export type SoftwareReauthenticateInfo = {
  software: SoftwareOut
  callback: () => any
}

export type Software2FaInfo = {
  softwareName: string
  softwareId: string
  softwareType: SoftwareTypes
  authType: AuthType
  callback: () => any
}

export const useSoftwareStore = defineStore('softwareStore', () => {
  const software = ref<SoftwareOut[] | null>(null)
  const lastReload = ref<Date | null>(null)
  const twoFactorAuthToHandle = ref<Software2FaInfo>()
  const reauthSoftwareToHandle = ref<SoftwareReauthenticateInfo>()

  function setSoftware(newSoftware: SoftwareOut[] | null) {
    software.value = newSoftware
  }

  function setReauthSoftwareToHandle(information: SoftwareReauthenticateInfo) {
    reauthSoftwareToHandle.value = information
  }

  function clearReauthSoftwareToHandle() {
    if (reauthSoftwareToHandle.value) {
      reauthSoftwareToHandle.value.callback()
      delete reauthSoftwareToHandle.value
    }
  }

  function set2FaSoftwareToHandle(information: Software2FaInfo) {
    twoFactorAuthToHandle.value = information
  }

  function clear2FaSoftwareToHandle() {
    if (twoFactorAuthToHandle.value) twoFactorAuthToHandle.value.callback()
    delete twoFactorAuthToHandle.value
  }

  async function reloadSoftware() {
    const { data, error, loading, fetchData } = useFetch(
      () =>
        SoftwareService.getSoftwareListApiV1SoftwareSoftwareGet({
          extended: true,
          excludeLicenceGroups: true,
        }),
      undefined,
      {
        sendToast: false,
        loadingToastTitle: 'notification.loadingSoftware',
        successToastTitle: 'notification.loadingSoftwareSuccess',
      }
    )
    if (!data.value) data.value = []
    return fetchData().then(() => {
      setSoftware(data.value as SoftwareOut[])
      lastReload.value = new Date()
      if (!data.value) data.value = []
      return { data: data.value, error: error.value, loading: loading.value }
    })
  }

  //   Getter
  // Following getter are supported:
  // - getSoftware: Gets all software that match the filter

  async function getSoftware(
    { allowCached }: { allowCached?: boolean } = { allowCached: true }
  ) {
    let data: SoftwareOut[] | null = software.value
    if (!isSoftwareLoaded.value || !allowCached || !lastReload.value) {
      // Reload software
      const response = await reloadSoftware()
      if (response.data) {
        data = response.data as SoftwareOut[] // reloadSoftware calls backend with {extended: true}, therefore we can cast to SoftwareOut[]
      }
    }
    if (!data) data = []
    return data
  }

  async function updateSoftware(software: SoftwareUpdateIn, id: string) {
    return SoftwareService.putSoftwareApiV1SoftwareSoftwareSoftwareIdPut({
      softwareId: id,
      requestBody: software,
    }).then((software: SoftwareOut) => {
      reloadSoftware()
      return Promise.resolve(software)
    })
  }

  async function deleteLicenseModel(modelId: string) {
    return SoftwareLicensesService.deleteSoftwareSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdDelete(
      { softwareLicenseId: modelId }
    ).then(() => {
      reloadSoftware()
      return Promise.resolve()
    })
  }

  function fetchSoftware() {
    const { data, error, loading, fetchData } = useFetch(() =>
      SoftwareService.getSoftwareListApiV1SoftwareSoftwareGet({})
    )
    const promise = fetchData()
    return { data, error, loading, promise }
  }

  interface GetSoftwareByIdOptions {
    cached?: boolean
    useLocalCache?: boolean
    excludeLicenceGroups?: boolean
  }

  async function getSoftwareById(
    id: string,
    options: GetSoftwareByIdOptions = {}
  ): Promise<SoftwareOut | undefined> {
    const {
      cached = true,
      useLocalCache = true,
      excludeLicenceGroups = true,
    } = options

    if (useLocalCache && software.value) {
      const matchingSoftware = software.value.find((s) => s._id === id)
      if (matchingSoftware) return matchingSoftware
    }

    return SoftwareService.getSoftwareApiV1SoftwareSoftwareSoftwareIdGet({
      includeDeleted: true, // when fetching with Id, we want to get deleted software too
      softwareId: id,
      cached: cached,
      excludeLicenceGroups: excludeLicenceGroups,
    })
  }

  //   Computed
  // Following computed are supported:
  // - isSoftwareLoaded: Returns true if software are loaded
  const isSoftwareLoaded = computed(() => software.value !== null)

  return {
    software,
    twoFactorAuthToHandle,
    reauthSoftwareToHandle,
    lastReload,
    setSoftware,
    reloadSoftware,
    getSoftware,
    fetchSoftware,
    getSoftwareById,
    setReauthSoftwareToHandle,
    clearReauthSoftwareToHandle,
    set2FaSoftwareToHandle,
    clear2FaSoftwareToHandle,
    isSoftwareLoaded,
    updateSoftware,
    deleteLicenseModel,
  }
})
