import { defineStore } from 'pinia'
import {
  PriceInformation,
  PriceInformationPostIn,
  SoftwareLicensesService,
} from '@/client'

import { useApplicationDetailsStore } from '@/views/ApplicationsDetail/ApplicationDetailsView.store'
import { sendToast } from '@/components/sm/SmNotification'
import i18n from '@/i18n/i18n'

export const usePriceInformationStore = defineStore(
  'priceInformationStore',
  () => {
    type LicenseId = string
    const priceInformationCache = ref<{ [key: LicenseId]: PriceInformation[] }>(
      {}
    )
    const editStatus = ref<{ [key: string]: boolean }>({})

    async function loadPriceInformation(licenseId: LicenseId) {
      await SoftwareLicensesService.getSoftwareLicensePriceInformationsForSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdPriceInformationsGet(
        { softwareLicenseId: licenseId }
      )
        .then((res) => {
          priceInformationCache.value[licenseId] = res
        })
        .catch(() => {
          sendToast(
            i18n.global.t('licenseModelDeleteFailed.title'),
            i18n.global.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.alerts.loadedPriceModelError'
            ),
            'error'
          )
        })
    }

    async function createPriceInformation(
      licenseId: LicenseId,
      priceInformation: PriceInformationPostIn
    ) {
      SoftwareLicensesService.createSoftwareLicensePriceInformationApiV1SoftwareSoftwareLicensesSoftwareLicenseIdPriceInformationsPost(
        {
          softwareLicenseId: licenseId,
          requestBody: priceInformation,
        }
      )
        .then((res) => {
          const applicationDetailsViewStore = useApplicationDetailsStore()

          if (!priceInformationCache.value[licenseId]) {
            priceInformationCache.value[licenseId] = []
          }

          priceInformationCache.value[licenseId].push(res)

          applicationDetailsViewStore.loadQuota(licenseId)
        })
        .catch(() => {
          sendToast(
            i18n.global.t('licenseModelDeleteFailed.title'),
            i18n.global.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.alerts.createdPriceModelError'
            ),
            'error'
          )
        })
    }

    async function updatePriceInformation(
      licenseModelId: string,
      priceInformation: PriceInformation
    ) {
      return await SoftwareLicensesService.updateSoftwareLicensePriceInformationApiV1SoftwareSoftwareLicensePriceInformationsPriceInformationIdPatch(
        {
          priceInformationId: priceInformation._id,
          requestBody: priceInformation,
        }
      )
        .then((updatedPriceInformation) => {
          const applicationDetailsViewStore = useApplicationDetailsStore()

          if (priceInformationCache.value[licenseModelId]) {
            priceInformationCache.value[licenseModelId] =
              priceInformationCache.value[licenseModelId].map((item) => {
                if (item._id === priceInformation._id) {
                  return priceInformation
                }
                return item
              })
          }

          applicationDetailsViewStore.loadQuota(licenseModelId)

          return updatedPriceInformation
        })
        .catch(() => {
          sendToast(
            i18n.global.t('licenseModelDeleteFailed.title'),
            i18n.global.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.alerts.savePriceModelError'
            ),
            'error'
          )
        })
    }

    async function deletePriceInformation(
      licenseModelId: string,
      priceInformationId: string
    ) {
      await SoftwareLicensesService.deleteSoftwareLicensePriceInformationApiV1SoftwareSoftwareLicensePriceInformationsPriceInformationIdDelete(
        {
          priceInformationId: priceInformationId,
        }
      )
        .then(() => {
          const applicationDetailsViewStore = useApplicationDetailsStore()

          if (priceInformationCache.value[licenseModelId]) {
            priceInformationCache.value[licenseModelId] =
              priceInformationCache.value[licenseModelId].filter(
                (item) => item._id !== priceInformationId
              )
          }

          applicationDetailsViewStore.loadQuota(licenseModelId)

          sendToast(
            i18n.global.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.alerts.deletePriceModel'
            ),
            undefined,
            'success'
          )
        })
        .catch(() => {
          sendToast(
            i18n.global.t('licenseModelDeleteFailed.title'),
            i18n.global.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.alerts.deletePriceModelError'
            ),
            'error'
          )
        })
    }

    function getPriceInformation(licenseId: LicenseId) {
      if (priceInformationCache.value[licenseId]) {
        const cache: PriceInformation[] = priceInformationCache.value[licenseId]
        return cache
      }
    }

    function hasMultiplePriceInformation(id: LicenseId) {
      return priceInformationCache.value[id].length > 1 ? true : false
    }

    return {
      priceInformationCache,
      editStatus,
      loadPriceInformation,
      createPriceInformation,
      updatePriceInformation,
      deletePriceInformation,
      getPriceInformation,
      hasMultiplePriceInformation,
    }
  }
)
