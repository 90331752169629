<template>
  <Teleport to="#toast">
    <TransitionGroup tag="div" name="list" class="sm-notification-list">
      <SmNotificationItem
        v-for="item in items"
        :key="item.id"
        :message="item.message"
        :title="item.title"
        :type="item.type"
        @close="close(item.id)" />
    </TransitionGroup>
  </Teleport>
</template>

<script setup lang="ts">
  import { notificationManager } from '../SmNotification'
  import SmNotificationItem from './SmNotificationItem.vue'

  const toastManager = notificationManager()

  const items = computed(() => toastManager.getNotifications)

  function close(id: symbol) {
    toastManager.removeNotification(id)
  }
</script>

<style lang="scss">
  #toast {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9999;
    pointer-events: none;
  }

  .sm-notification-list {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .list-move,
  .list-enter-active,
  .list-leave-active {
    transition: all 0.3s ease;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(40px);
  }

  .list-leave-active {
    position: absolute;
  }
</style>
