import * as Sentry from '@sentry/vue'
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js'
import ElementPlus from 'element-plus'
import { createPinia } from 'pinia'
import { createApp, DirectiveBinding } from 'vue'

import App from './App.vue'
import router from './router/index'

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
)
// Icons
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { addIcons, OhVueIcon } from 'oh-vue-icons'
import {
  MdTaskaltRound,
  MdWebassetRound,
  CiBtc,
  FaAndroid,
  FaApple,
  FaChrome,
  FaEdge,
  FaFirefox,
  FaLinux,
  FaOpera,
  FaSafari,
  FaWindows,
  FcGoogle,
  HiChevronRight,
  HiPlus,
  HiRefresh,
  HiSolidPlus,
  HiTemplate,
  IoAddCircle,
  IoAlert,
  IoApps,
  IoArrowRedoCircle,
  IoBug,
  IoCard,
  IoCaretDownOutline,
  IoCash,
  IoCheckmark,
  IoCheckmarkCircle,
  IoCheckmarkSharp,
  IoChevronBack,
  IoClose,
  IoCloseCircle,
  IoCloudDownload,
  IoCopy,
  IoCreate,
  IoCube,
  IoDocumentAttach,
  IoDocumentText,
  IoDownload,
  IoExit,
  IoEye,
  IoHammer,
  IoHelpBuoy,
  IoHome,
  IoInformationCircle,
  IoKey,
  IoLibrary,
  IoLockClosed,
  IoLogOut,
  IoMoon,
  IoNotifications,
  IoPerson,
  IoPersonAdd,
  IoReader,
  IoReaderOutline,
  IoReload,
  IoReloadCircle,
  IoSave,
  IoSettings,
  IoSparkles,
  IoSunny,
  IoSync,
  IoTicket,
  IoTime,
  IoTrailSign,
  IoTrailSignOutline,
  IoTrash,
  IoWallet,
  IoWarning,
  MdLightbulboutlineRound,
  MdAddRound,
  MdArrowbackiosnewRound,
  MdArrowbackiosRound,
  MdArrowdownwardRound,
  MdArrowforwardiosRound,
  MdArrowforwardRound,
  MdBoltRound,
  MdBookmarksRound,
  MdBusinessRound,
  MdCancelRound,
  MdPriorityhighRound,
  MdChatbubbleRound,
  MdChatOutlined,
  MdChatRound,
  MdCheckcircleRound,
  MdCheckRound,
  MdClose,
  MdCloseRound,
  MdFilterlistRound,
  MdClouddownloadRound,
  MdCloudupload,
  MdCreateRound,
  MdDarkmodeOutlined,
  MdDarkmodeRound,
  MdDelete,
  MdDeleteforeverRound,
  MdDeleteRound,
  MdDescriptionRound,
  MdDnsRound,
  MdDomaindisabledRound,
  MdDoneRound,
  MdExpandmoreRound,
  MdFilteralt,
  MdFilteraltRound,
  MdFoldercopyRound,
  MdGridviewRound,
  MdGroup,
  MdGroupRound,
  MdGroupsRound,
  MdDragindicator,
  MdHelpRound,
  MdHideimageRound,
  MdHomeRound,
  MdImagenotsupported,
  MdInfoRound,
  MdKeyoffRound,
  MdKeyRound,
  MdLanguageRound,
  MdLightmodeOutlined,
  MdLightmodeRound,
  MdLink,
  MdLinkoffRound,
  MdLogoutRound,
  MdModeeditRound,
  MdMorevertRound,
  MdPause,
  MdPaymentsRound,
  MdPersonaddalt1Round,
  MdPersonRound,
  MdReceiptlong,
  MdReceiptlongRound,
  MdRefreshRound,
  MdRocketlaunchRound,
  MdRotaterightRound,
  MdSettingsOutlined,
  MdSettingsRound,
  MdSync,
  MdTablerowsOutlined,
  MdUploadfileOutlined,
  MdVerifiedOutlined,
  MdVisibilityoffRound,
  MdVisibilityRound,
  MdWarningamberRound,
  MdWebassetoffRound,
  MdWebhookRound,
  PrSpinner,
  RiDonutChartLine,
  MdReplay,
  HiBell,
  MdAccesstime,
  HiSolidCollection,
  BiPlayCircle,
  HiSolidMinus,
  HiCalendar,
} from 'oh-vue-icons/icons'

import i18n from './i18n/i18n'

const app = createApp(App)

addIcons(
  MdTaskaltRound,
  IoSunny,
  IoAlert,
  MdWebassetRound,
  HiPlus,
  MdArrowforwardRound,
  MdTablerowsOutlined,
  CiBtc,
  MdPaymentsRound,
  IoCloseCircle,
  IoSync,
  IoCheckmark,
  IoCheckmarkCircle,
  MdVerifiedOutlined,
  IoCheckmarkSharp,
  IoClose,
  MdRocketlaunchRound,
  MdPause,
  MdFoldercopyRound,
  MdBoltRound,
  MdReceiptlong,
  FaLinux,
  MdCheckRound,
  MdChatbubbleRound,
  MdInfoRound,
  FaChrome,
  MdDeleteforeverRound,
  MdModeeditRound,
  MdExpandmoreRound,
  MdCheckcircleRound,
  MdDarkmodeRound,
  MdLightmodeRound,
  MdPriorityhighRound,
  MdPersonaddalt1Round,
  MdBoltRound,
  MdImagenotsupported,
  MdGridviewRound,
  MdBusinessRound,
  MdClouddownloadRound,
  MdHelpRound,
  IoDownload,
  MdRotaterightRound,
  FaOpera,
  MdBookmarksRound,
  MdChatRound,
  MdArrowbackiosRound,
  FaFirefox,
  FaSafari,
  MdWebhookRound,
  MdCheckRound,
  MdLanguageRound,
  MdBoltRound,
  FcGoogle,
  MdRefreshRound,
  MdHomeRound,
  IoSettings,
  IoAddCircle,
  MdVisibilityoffRound,
  MdDnsRound,
  FaAndroid,
  FaWindows,
  IoNotifications,
  IoClose,
  MdWebassetoffRound,
  FaApple,
  MdDomaindisabledRound,
  MdFilterlistRound,
  MdDeleteRound,
  FaEdge,
  MdUploadfileOutlined,
  IoPerson,
  MdFilteralt,
  MdGroupRound,
  MdCloudupload,
  IoReloadCircle,
  MdSync,
  MdFilteraltRound,
  MdCloseRound,
  IoInformationCircle,
  MdDoneRound,
  IoPerson,
  IoCash,
  MdHelpRound,
  MdArrowbackiosnewRound,
  MdChatOutlined,
  MdVisibilityRound,
  MdClose,
  MdArrowforwardiosRound,
  IoExit,
  IoDownload,
  PrSpinner,
  MdDarkmodeOutlined,
  IoTicket,
  MdWarningamberRound,
  IoCaretDownOutline,
  MdKeyRound,
  IoTime,
  IoArrowRedoCircle,
  IoCopy,
  IoKey,
  IoMoon,
  IoHammer,
  MdKeyoffRound,
  MdLogoutRound,
  HiRefresh,
  IoCard,
  RiDonutChartLine,
  MdAddRound,
  IoClose,
  MdHideimageRound,
  HiSolidPlus,
  MdPersonRound,
  MdDescriptionRound,
  IoCreate,
  MdCreateRound,
  IoPersonAdd,
  IoSync,
  IoCheckmark,
  IoSave,
  IoLibrary,
  MdLightmodeOutlined,
  MdSettingsOutlined,
  MdSettingsRound,
  MdMorevertRound,
  MdLinkoffRound,
  IoReload,
  MdGroupsRound,
  MdCancelRound,
  HiChevronRight,
  IoCheckmarkCircle,
  IoCloseCircle,
  IoTrailSignOutline,
  IoTrailSign,
  IoHome,
  MdGroup,
  MdLink,
  IoCube,
  IoWallet,
  IoLogOut,
  IoSparkles,
  IoTrash,
  IoBug,
  IoHome,
  IoApps,
  IoDocumentText,
  IoChevronBack,
  IoLockClosed,
  IoHelpBuoy,
  IoWarning,
  IoCloudDownload,
  IoDocumentAttach,
  HiTemplate,
  IoReaderOutline,
  IoEye,
  IoReader,
  MdReceiptlongRound,
  MdArrowdownwardRound,
  MdDelete,
  MdDragindicator,
  MdReplay,
  MdLightbulboutlineRound,
  HiBell,
  MdAccesstime,
  HiSolidCollection,
  BiPlayCircle,
  HiSolidMinus,
  HiCalendar
)

const sentryConfig = {
  app,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.moduleMetadataIntegration(),
    Sentry.httpClientIntegration(),
  ],
  tracesSampleRate: 1.0,

  // Replay options
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,

  // Vue options
  trackComponents: ['App'],

  // This option is required for capturing headers and cookies.
  sendDefaultPii: true,
}

let dsn = ''

if (import.meta.env.MODE === 'production') {
  dsn = 'https://0955374b13f44454a1d2430edeabd8c0@sentry.saasmetrix.io/1'
} else if (import.meta.env.MODE === 'staging') {
  dsn = 'https://609733beb9b6446297027c6044f0fd7e@sentry.saasmetrix.io/10'
} else if (import.meta.env.MODE === 'beta') {
  dsn = 'https://319df925eda045bba63a74edd1aa7354@sentry.saasmetrix.io/2'
} else if (import.meta.env.MODE === 'localhost') {
  dsn = 'https://a06d19fd2f314d22a8ff455b44c51d76@sentry.saasmetrix.io/9'
}

if (dsn) {
  Sentry.init({
    ...sentryConfig,
    dsn,
  })
}

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import './styles/element-plus/index.scss'
import './styles/index.scss'

import VueGtag from 'vue-gtag'
// @ts-expect-error: No types available
import VirtualScroller from 'vue-virtual-scroller'

import { CompanyAccessRole } from '@/client'

import { useSessionStore } from './stores/sessionStore'

app
  .use(autoAnimatePlugin)
  .use(router)
  .use(createPinia())
  .use(i18n)
  .component('v-icon', OhVueIcon)
  .use(ElementPlus)
  .use(VirtualScroller)
  .use(VueGtag, {
    config: { id: 'G-8L3EZQEQTT' },
    includes: [{ id: 'GTM-5QLBMV9' }],
  })

const sessionStore = useSessionStore()

app.directive('require-permission', {
  async mounted(el, binding: DirectiveBinding<CompanyAccessRole>) {
    if (!(await sessionStore.hasPermission(binding.value))) {
      el.remove()
    }
  },
})

app.mount('#app')
export { i18n }
