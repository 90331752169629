import { ElNotification } from 'element-plus'

import i18n from '@/i18n/i18n'
import { useGlobalStore } from '@/stores/globalStore'

/* eslint-disable  @typescript-eslint/no-explicit-any */
export default async function handleError(
  response: any,
  showNotification?: boolean
): Promise<any> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { t, te } = i18n.global

  // If the response is not okay, then we need to handle the error
  if (!response.ok) {
    const jsonResponse = await response.json()

    const error: string | undefined = jsonResponse?.detail?.error

    // If the user is not authenticated
    if (
      response.status == 401 &&
      jsonResponse.detail.software === 'saasmetrix'
    ) {
      if (showNotification === undefined || showNotification === true)
        ElNotification.error({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          title: t('error.request.401.title', jsonResponse.detail.data || {}),
          message: t(
            error + '.message' || 'error.request.401.message',
            jsonResponse.detail.data || {}
          ),
        })
      // Redirect to login page
      const store = useGlobalStore()

      clearLocalStorage()

      sessionStorage.clear()
      store.user.jwt = undefined
      store.user.user = undefined
      location.href = '/login'
    }

    // If a endpoint returns a not serializable Object
    if (error === undefined) {
      if (showNotification === undefined || showNotification === true)
        ElNotification.error({
          title: t('error.request.unknown.title'),
          message: t('error.request.unknown.message'),
        })
      return
    }

    if (error && te(error + '.title') && te(error + '.message')) {
      if (showNotification === undefined || showNotification === true)
        ElNotification.error({
          title: t(error + '.title', jsonResponse.detail.data || {}),
          message: t(error + '.message', jsonResponse.detail.data || {}),
        })
    } else if (response.status === 400) {
      if (showNotification === undefined || showNotification === true)
        ElNotification.error({
          title: t('error.request.401.title', jsonResponse.detail.data || {}),
          message: t(
            error + '.message' || 'error.request.401.message',
            jsonResponse.detail.data || {}
          ),
        })
    } else if (response.status === 403) {
      if (showNotification === undefined || showNotification === true)
        ElNotification.error({
          title: t('error.request.403.title', jsonResponse.detail.data || {}),
          message: t(
            error + '.message' || 'error.request403.message',
            jsonResponse.detail.data || {}
          ),
        })
    } else if (response.status === 404) {
      if (showNotification === undefined || showNotification === true)
        ElNotification.error({
          title: t('error.request.404.title', jsonResponse.detail.data || {}),
          message: t(
            error + '.message' || 'error.request.404.message',
            jsonResponse.detail.data || {}
          ),
        })
    } else if (Math.floor(response.status / 100) === 5) {
      if (showNotification === undefined || showNotification === true)
        ElNotification.error({
          title: t('error.request.5xx.title', jsonResponse.detail.data || {}),
          message: t(
            error + '.message' || 'error.request.5XX.message',
            jsonResponse.detail.data || {}
          ),
        })
    } else {
      if (showNotification === undefined || showNotification === true)
        ElNotification.error({
          title: t(
            'error.request.unknown.title',
            jsonResponse.detail.data || {}
          ),
          message: t(
            'error.request.unknown.message',
            jsonResponse.detail.data || {}
          ),
        })
    }

    throw {
      ...jsonResponse,
    }
  }
  return response
}

// Keys that should be deleted from local storage when logging out
const nonPersistensLocalStorageKeys: string[] = ['access_token']

// Logout the user and delete all local storage keys
function clearLocalStorage() {
  Object.keys(localStorage).forEach((key) => {
    if (!nonPersistensLocalStorageKeys.includes(key)) return
    localStorage.removeItem(key)
  })
}
