import handleError from '@/middlewares/handleError'

export const getDocument = (
  ip: string,
  jwt: string | undefined,
  id: string
): Promise<Blob> => {
  const headersList = {
    Accept: '*/*',
    Authorization: `Bearer ${jwt}`,
  }
  return fetch(
    `${ip}/api/v1/software/documents/${encodeURIComponent(id)}/download`,
    {
      method: 'GET',
      headers: headersList,
    }
  )
    .then(handleError)
    .then((resp) => resp.blob())
}
