/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SoftwareAuthorizationOutSuccess = {
    type: SoftwareAuthorizationOutSuccess.type;
};
export namespace SoftwareAuthorizationOutSuccess {
    export enum type {
        SUCCESS = 'success',
    }
}

