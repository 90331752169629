<template>
  <SmDialog
    v-model:visibility="visibility"
    :size="size"
    :title="i18n.t('addUsersTo', { name: accountGroup?.name })">
    <SmSteps
      v-model:active-step-key="activeStepKey"
      :steps="steps"
      content-height="calc(70vh - 4rem)">
      <template #step-choose-users>
        <SmSelectionGrid
          id="id"
          v-model:selection="selection"
          label-key="label"
          :items="gridUsers">
          <template #item="{ item, selected }">
            <UserGridItem :item="item" :selected="selected" />
          </template>
        </SmSelectionGrid>
      </template>

      <template #step-choose-options>
        <h3 class="options-header">{{ i18n.t('options') }}:</h3>
        <div class="option">
          <SmCheckbox v-model:modelValue="addingOptions.assignLicenses">
            {{ i18n.t('assignLicenses') }}
          </SmCheckbox>
          <p
            class="explanation text-sm"
            @click="
              addingOptions.assignLicenses = !addingOptions.assignLicenses
            ">
            {{ i18n.t('groupsUserAddDialog.assignLicensesExplanation') }}
          </p>
        </div>
        <SmDialogMessage
          :visible="message.visible"
          :type="message.type"
          :message="message.message" />
      </template>
      <template #finish-button>
        <SmButton :loading="loading" @click="handleAddUser">
          {{ i18n.t('addUsers', selection.length) }}
        </SmButton>
      </template>
    </SmSteps>
  </SmDialog>
</template>

<script setup lang="ts">
  import { AccountGroup, Account, UserGroupsService } from '@/client'
  import { getApiErrorMessage } from '@/common/util/apiError'
  import { selectionGridItem } from '@/components/sm/SmSelectionGrid.types'
  import { useI18n } from 'vue-i18n'
  import { DialogSize } from './sm/SmDialog.vue'
  import { sendToast } from './sm/SmNotification'

  const i18n = useI18n()
  const loading = ref(false)
  const emit = defineEmits<{ (event: 'groupsChanged'): void }>()

  const addingOptions = ref({ assignLicenses: true })

  const message = ref({
    type: 'error' as 'error' | 'success',
    visible: false as boolean,
    message: '' as string,
  })

  const accountGroup = defineModel('accountGroup', {
    type: Object as PropType<AccountGroup>,
  })

  const activeStepKey = ref<'choose-users' | 'choose-options'>('choose-users')

  const steps = computed(() => [
    {
      key: 'choose-users',
      nextText: i18n.t('chooseOptions'),
    },
    {
      key: 'choose-options',
    },
  ])
  const visibility = defineModel('visibility', {
    type: Boolean,
    default: false,
  })

  const selection: Ref<(keyof selectionGridItem)[]> = ref([])
  const props = defineProps<{ allAccounts: Account[] }>()

  function handleAddUser() {
    // Get Accounts from selection
    const accountsSelection = props.allAccounts.filter((a) =>
      selection.value.includes(a._id as keyof selectionGridItem)
    )

    const promises: Promise<unknown>[] = []
    loading.value = true
    accountsSelection.forEach((u) => {
      promises.push(
        UserGroupsService.addAccountToAccountGroupApiV1ManagementGroupsAccountGroupIdAccountsAccountIdPost(
          {
            accountId: u._id!,
            accountGroupId: accountGroup.value!._id,
            assignLicenses: addingOptions.value.assignLicenses,
          }
        )
      )
    })
    Promise.all(promises)
      .then(() => {
        loading.value = false
        visibility.value = false
        sendToast(i18n.t('accountsAddedSuccessfully'), undefined, 'success')
      })
      .catch((err) => {
        message.value.visible = true
        message.value.message = getApiErrorMessage(err, i18n)
      })
      .finally(() => {
        loading.value = false
        // Emitting `groupsChanged` in `finally`, because partial changes could have happened
        emit('groupsChanged')
      })
  }

  const gridUsers = computed(() => {
    const filteredUsers = props.allAccounts.filter(
      (u) => !accountGroup.value?.accounts.includes(u._id)
    )
    const gridItems: selectionGridItem[] = filteredUsers.map((u) => {
      return {
        id: u._id,
        label: u.name || u.email,
        additionalSearchTerms: [u.email],
      }
    })

    return gridItems
  })

  defineExpose({ activeStepKey: activeStepKey.value })

  const size = computed<DialogSize>(() => {
    if (activeStepKey.value === 'choose-users') return 'large'
    return 'small'
  })

  watch(
    () => visibility.value,
    (val) => {
      if (val) return
      // Resetting error message and selection
      addingOptions.value.assignLicenses = true
      activeStepKey.value = 'choose-users'
      selection.value.splice(0)
      message.value.visible = false
    }
  )
</script>

<style scoped lang="scss">
  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .explanation {
    width: 80%;
    color: var(--sm-contrast-muted);
    margin: 0.5rem 0 0 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
  }

  .options-header {
    padding-bottom: 0.5rem;
  }
</style>
