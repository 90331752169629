/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MetadataQueryField {
    SOFTWARE_AMOUNT = 'software_amount',
    LICENCE_AMOUNT = 'licence_amount',
    COST = 'cost',
    OPTIMIZATION_AMOUNT = 'optimization_amount',
    ACCOUNT_AMOUNT = 'account_amount',
    SAVING_AMOUNT = 'saving_amount',
}
